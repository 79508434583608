import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../context/DataContext'
import Feed from './cardComponent/Feed'
import ToTop from './controlComponent/ToTop'
import { FaUserNinja, FaTrash } from 'react-icons/fa'

const Thumbtack = () => {
  const { thumbtackedId, setThumbtackedId, candidates, width } = useContext(DataContext)
  // console.count(thumbtackedId)

  const [filterResult, setFilterResult] = useState([])

  useEffect(() => {

    setFilterResult(candidates.filter(candidate =>
      (thumbtackedId.includes(candidate.id))))


  }, [candidates, thumbtackedId])


  return (
    <main className='w-full grow bg-neutral-50 scroll-m-8 min-h-screen'>
      {
        width >= 1024 ?
        <div className='spacer1'></div> :
        <div className='spacer2'></div>
      }
      {
        filterResult.length
          ?
          <div>
            <Feed candidates={filterResult} />
            <div className='flex justify-center pb-2'>
              <div onClick={() => setThumbtackedId([])} className='flex flex-row text-slate-900 bg-slate-200 rounded-lg px-6 py-2
                  hover:bg-rose-400 hover:text-white cursor-pointer'>
                <div className='p-1'>
                  <FaTrash size='1rem' />
                </div>
                <div>
                  {"移除所有選取"}
                </div>
              </div>
            </div>
          </div>
          :
          <div className='flex justify-center item-center h-[80vh]'>
            {/* <div className='spacer layer1'></div> */}
            <div className='flex-row'>
              <div className=' text-neutral-200 flex justify-center'>
                <FaUserNinja size={48} />
              </div>
              <div className='text-xl text-neutral-800 '>
                {/* {"No candidate in stock..."} */}
                {"沒有選取任何候選人"}
              </div>
            </div>

          </div>
      }
      <ToTop />
    </main>
  )
}

export default Thumbtack