import React from 'react'

const BasicInfoItem = ({ icon, info }) => {
    return (
        <div className='flex flex-row py-1 text-slate-800'>
            <div className='text-xl'>
                {icon}
            </div>
            <div className='text-sm px-2'>
                {info ? info : "no data"}
            </div>
        </div>
    )
};

export default BasicInfoItem