import React from 'react'

import { FaRegCheckSquare } from 'react-icons/fa'

const Politics = ({ politics, size }) => {
    const mapItem = (list) => {
        return (
            list.length !== 0 ? (list.map((item, i) => (
                <div className='flex flex-row' key={i}>
                    <FaRegCheckSquare fontSize={24} />
                    <li className='pl-1 border-b-2 border-solid border-slate-50 mb-2 w-full'>
                        {item}
                    </li>
                </div>
            ))) : <li>資料整理中</li>
        )
    }
    return (
        <>
            {(size === 'large')
                ?
                <div className='rounded-xl bg-slate-200 p-2 px-4 py-2 text-sm mx-2 mb-2'>
                    <div className='flex flex-row font-bold'>
                        {/* <div>
                            <FaRegCheckSquare fontSize={24} />
                        </div> */}
                        <div>
                            政見
                        </div>
                    </div>
                    <ul className='leading-6 '>
                        {mapItem(politics)}
                    </ul>
                </div>
                :
                <div className="mx-4 my-1">
                    <input id="collapsiblePlt" className="toggle" type="checkbox" />
                    <label htmlFor="collapsiblePlt"
                        className="lbl-toggle text-center p-1 text-neutral-700 bg-slate-200 shadow-sm cursor-pointer rounded-md transition-all duration-300 ease-out hover:text-neutral-800" tabIndex="0">
                        政見
                    </label>
                    <div className="collapsible-content overflow-hidden rounded shadow-sm">
                        <div className="content-inner">
                            <div className='mb-0'>
                                <ul className='leading-6 '>
                                    {mapItem(politics)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Politics