import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import DataContext from '../../context/DataContext'

import Rating from '@mui/material/Rating';
import axios from 'axios'
import { FaInfoCircle, FaInfo } from 'react-icons/fa'


const RatingBoard = () => {
    const { uuid, ratedId, setRatedId } = useContext(DataContext)
    const { id } = useParams();
    const [value, setValue] = useState(0)
    const [hasRated, setHasRated] = useState(false)

    const updateRaing = async (url, normalizedValue) => {
        if (url) {
            const res = await axios.put(url, {
                uuid: uuid,
                id: id,
                rate: normalizedValue
            });

        }
        // console.log(res)
    }

    const ratingUrlMap = {
        'local': '',
        'dev': 'http://localhost:5010/yssy-elec/asia-east1/app/rating',
        'firebase': 'https://asia-east1-yssy-elec.cloudfunctions.net/app/rating'
    }


    useEffect(() => {
        const item = ratedId.find(item => item.id === id)
        if (item) {
            setHasRated(true)
            setValue(item.value)
        }
    }, [id])

    const handleRating = async (e, newValue) => {
        setRatedId([...ratedId, { id: id, value: newValue }])
        setValue(newValue)
        setHasRated(true)
        const normalizedValue = newValue * 20
        try {
            const url = 
            updateRaing(ratingUrlMap[process.env.REACT_APP_MODE], normalizedValue)

        } catch (err) {
            console.error(err)
        }
    }
    // const bgColor = 'bg-neutral-100'

    // dark:text-neutral-300
    // sx={customStyle} <Rating/>
    return (
        <div className='flex flex-row items-center justify-between border-rose-300 py-1 px-2 rounded-xl border-2'>
            <div className=' flex flex-row'>
                <div className='text-xs text-slate-500 bg-slate-200 rounded-full w-4 h-4 relative'>
                    <FaInfo />
                    <div className='opacity-0 hover:opacity-80 hover:bg-slate-200 hover:text-slate-800 inset-0 z-10 absolute w-20 h-16 p-2 border-2 border-slate-100 rounded-lg'>
                        {"匿名評分會在後台做批次處理"}
                    </div>
                </div>
                <div className='text-slate-700 text-lg'>
                    {(hasRated) ? "評分已送出！" : "給個評分吧！"}
                </div>
            </div>
            <div className='px-4 pt-1'>
                <Rating
                    name="simple-controlled"
                    value={value}
                    precision={0.5}
                    size="large"
                    onChange={(e, newValue) => {
                        handleRating(e, newValue)
                    }}
                    disabled={hasRated}
                />

            </div>

        </div>
    )
}

export default RatingBoard