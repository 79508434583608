import React, { useContext } from 'react'
import DataContext from '../../context/DataContext'
import { Link } from 'react-router-dom'
import Location, { findLocation } from '../candidateComponent/Location'
import Position from '../candidateComponent/Position'
import HeadPic from './HeadPic'

const CardItem = ({ candidate }) => {
    const { partyName } = useContext(DataContext)
    // console.log(candidate.party)
    // TODO: add try catch block and loading image 
    // const displayStatement = (candidate.statement).length <= 7 ?
    //     candidate.statement : `${(candidate.statement).slice(0, 6)}...`

    const displayName = (candidate.name).length <= 5 ? candidate.name : `${(candidate.name).slice(0, 4)}...`
    // console.log(partyName[candidate.party])
    // const backgroundColor = partyName[candidate.party].color
    // candidate.party === "中國國民黨" ?
    // "rgb(118, 175, 239)" : candidate.party === "民主進步黨" ?
    // "rgb(170, 220, 181)" : null;

    // const borderColor = candidate.result ? 'border-pink-400' : 'border-slate-200'; #e2e8f0 "#e7e5e4"
    const borderColor = 'border-slate-200';
    const bgColor = partyName[candidate.party].color ? partyName[candidate.party].color : "#e2e8f0";
    const termBadgeColor = candidate.term === 2018 ? 'bg-indigo-300' : 'bg-rose-400';

    return (
        <div style={{
            width: "min(100%, 150px)",
            // height: "min(100%, 150px)",
            backgroundColor: bgColor
        }}
            className={`card flex flex-col justify-start shadow-sm
                    items-center scroll-mt-4 bg-slate-200 relative
                    rounded-xl p-2 border-solid border-2 ${borderColor}`}
        >
            <Link to={`/candidate/${candidate.id}`}>
                <figure className='flex flex-col flex-nowrap items-center hover:scale-110'>
                    <HeadPic candidateImage={candidate.image} name={candidate.name} type='card'
                        result={candidate.result} degree={candidate.degree} />
                    <figcaption className='font-bold text-base mt-1 text-center'>{displayName}</figcaption>
                </figure>
                <div className='text-neutral-700 text-sm text-center pb-1 grid grid-row justify-center'>
                    {/* {handleDisplayPosition(candidate)} */}
                    <div className=''><Location location={candidate.location} type={0} /></div>
                    <div><Position position={candidate.position} location={candidate.location} result={candidate.result} term={candidate.term} type={0} /></div>
                </div>
                <div className={`inline-flex absolute justify-center items-center w-10 h-5 text-sm 
                 text-neutral-50 top-2  rounded-lg right-1 opacity-90 font-bold ${termBadgeColor}`}>
                    {/* bg-rose-400  bg-[#6667AB] bg-[#5f4b8b] bg-indigo-300*/}
                    {candidate.term}
                </div>
                {/* <div class="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white dark:border-gray-900">20</div> */}
            </Link>
        </div>
    )
}

export default CardItem