import React, { useState, useContext, useEffect } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import DataContext from '../../context/DataContext'
import LargeCandidatePage from './LargeCandidatePage';
import SmallCandidatePage from './SmallCandidatePage';
import isEqual from 'lodash.isequal';
import axios from 'axios'
import { FaUserNinja } from 'react-icons/fa'
import ToTop from '../controlComponent/ToTop'

const CandidatePage = () => {
    const navigate = useNavigate();
    const { candidates, setCandidates,
        positionName, partyName, width, uuid } = useContext(DataContext)
    const { id } = useParams();
    const candidate = candidates.find(candidate => (candidate.id).toString() === id);
    // const url =`http://localhost:3500/cdd2018dtl/${id}`
    // const { detailsData, fetchError, isLoading } = useAxiosFetch(`http://localhost:3500/cdd2018dtl/${id}`);
    const [details, setDetails] = useState(
        {
            id: 0,
            gender: 1,
            birthPlace: "",
            age: [
                60,
                1,
                1
            ],
            number: 1,
            degree: 3,

            educations: ["no data"],
            experiences: ["no data"],
            politics: ["no data"],
            tickets: {
                ticket: 30,
                appendix: 25,
                all: 100
            }
        }
    )
    const [ratings, setRatings] = useState({
        rating: {
            rateCount: 1,
            rate: 60
        }
    }
    )

    const [compCandidates, setCompCandidates] = useState([])
    const [recCandidates, setRecCandidates] = useState([])

    const ratingUrlMap = {
        'local': `http://localhost:3500/candRat/${id}`,
        'dev': 'http://localhost:5010/yssy-elec/asia-east1/app/rating',
        'firebase': 'https://asia-east1-yssy-elec.cloudfunctions.net/app/rating'
    }

    const detailUrlMap = {
        'local': `http://localhost:3500/candDtl/${id}`,
        'dev': 'http://localhost:5010/yssy-elec/asia-east1/app/detail',
        'firebase': 'https://asia-east1-yssy-elec.cloudfunctions.net/app/detail'
    }

    useEffect(() => {
        // const getDetails = (url) => {
        //     axios.get(url)
        //         .then((res) => {
        //             // console.log("res", res)
        //             setDetails(res.data)
        //         })
        //         .catch((err) => {
        //             console.error(err)
        //         })
        // }

        const getDetailsLocal = async (url) => {
            const res = await axios.get(url)
            setDetails(res.data)
        }
        const getRatingsLocal = async (url) => {
            const res = await axios.get(url)
            setRatings(res.data)
        }

        const getDetailsFirebase = async (url, id) => {
            const res = await axios.post(url, {
                "message": id,
                "uuid": uuid

            })
            if (Number(res.headers["content-length"]) > 0) {
                setDetails(res.data)
            }
            // setDetails(res.data)
        }
        const getRatingsFirebase = async (url, id) => {
            const res = await axios.post(url, {
                "message": id,
                "uuid": uuid
            })
            // if (Number(res.headers.get("content-length")) > 0) {
            //     setRatings(res.data)
            // }
            if (Number(res.headers["content-length"]) > 0) {
                setRatings(res.data)
            }
            // .headers["content-length"]
            // setRatings(res.data)
        }
        try {
            if (process.env.REACT_APP_MODE === 'local') {
                getDetailsLocal(detailUrlMap[process.env.REACT_APP_MODE])
                if (candidate.term === 2022 || candidate.result === 1) {
                    getRatingsLocal(ratingUrlMap[process.env.REACT_APP_MODE])
                }
                

            } else if (process.env.REACT_APP_MODE === 'dev'){
                getDetailsFirebase(detailUrlMap[process.env.REACT_APP_MODE], id)
                if (candidate.term === 2022 || candidate.result === 1) {
                    getRatingsFirebase(ratingUrlMap[process.env.REACT_APP_MODE], id)
                }

            } else {
                getDetailsFirebase(detailUrlMap[process.env.REACT_APP_MODE], id)
                if (candidate.term === 2022 || candidate.result === 1) {
                    getRatingsFirebase(ratingUrlMap[process.env.REACT_APP_MODE], id)
                }
            }
        } catch (err) {
            console.log("err", err)
            // navigate('/')

        }
        try {

            setCompCandidates(
                candidates.filter(function (c) {
                    if (c.id !== candidate.id && c.term === candidate.term) {
                        return isEqual(c.location, candidate.location)
                    }
                    return false
                }))

            setRecCandidates(
                candidates.filter(function (c) {
                    if (c.id !== candidate.id) {
                        return c.name === candidate.name
                    }
                    return false
                })
            )

        } catch (err) {
            console.error(err)
            // navigate('/')

        }

        // TODO: comp candidate must rerender when id change
    }, [id, candidates])

    // recommend itself

    // const recCandidates = candidates.slice(0, 6)
    // console.log(details)

    return (
        // <main className='flex justify-start items-center flex-col min-h-screen scroll-mt-4 bg-slate-100'>
        <main className='bg-neutral-100'>
            {candidate &&
                (width >= 1024
                    ? LargeCandidatePage({ candidate, details, ratings, positionName, partyName, recCandidates, compCandidates })
                    : SmallCandidatePage({ candidate, details, ratings, positionName, partyName, recCandidates, compCandidates }))
            }
            {!candidate &&
                <div className='grid place-items-center h-screen'>
                    <div>
                        <div className='grid place-items-center text-neutral-200'>
                            <FaUserNinja size={48} />
                        </div>
                        <div className='text-xl text-neutral-800'>
                            {/* {"No candidate in stock..."} */}
                            {"沒有找到候選人"}
                        </div>
                    </div>
                </div>
            }
            <ToTop />
        </main>
    )
}

export default CandidatePage
