import React from 'react'
import { locations } from "../../docs/location";

const Location = ({ location, type }) => {

    let groupDistricts = []
    let locationStr = []
    let multiDist = ""
    let groupStr = ""

    try {
        if (location.city) {
            // city can only be one
            const city = locations.find(loc => loc.code === location.city)
            locationStr.push(city.name)

            if (location.district) {
                let group = location.district.substring(0, 3)
                let district = location.district.substring(3, 7)
                // console.log(location)
                if (group.substring(1, 3) !== "00") {
                    groupStr = `第${group.substring(1, 3)}選區`
                    locationStr.push(groupStr)

                    // groupDistricts = city.subLocation.filter(item => item.group.includes(group))
                    // groupDistricts.forEach(item => {
                    //     locationStr.push(item.name)
                    // })
                }
                let dist = { subLocation: [] }

                if (district === "'000") {
                    groupDistricts = city.subLocation.filter(item => item.group.includes(group))
                    groupDistricts.forEach(item => {
                        locationStr.push(item.name)
                    })

                } else {
                    dist = city.subLocation.find(item => item.code === district)
                    locationStr.push(dist.name)

                }
                // console.log(group, district)

                if (location.viliage) {
                    let viliage = dist.subLocation.find(item => item.code === location.viliage)
                    locationStr.push(viliage.name)
                }

                if (groupDistricts.length > 1) {
                    if (groupDistricts.length === 2) {
                        multiDist = groupDistricts[0].name.substring(0, 2) + groupDistricts[1].name
                    } else {
                        multiDist = groupStr
                    }

                }
            };
        }

    } catch (err) {
        console.log(location)
        console.error(err)
    }

    return (
            // card view
            (
                locationStr
                    ? (
                        (groupDistricts.length > 1)
                            ? <div>{multiDist}</div>
                            // show last location
                            : <div>{locationStr[locationStr.length - 1]}</div>
                    )
                    : "loc_error"
            )

    )
}

export default Location