import React from 'react'
// import { positionName } from "../../docs/position";
import elecLogo from '../../image/elec4.svg'
import { locations } from "../../docs/location";


const Position = ({ position, location, result, term, type }) => {
    let locationStr = []
    let groupDistricts = []
    try {
        if (location.city) {
            const city = locations.find(loc => loc.code === location.city)
            locationStr.push(city.name)
            if (location.district) {
                let group = location.district.substring(0, 3)
                let district = location.district.substring(3, 7)
                let dist = {subLocation : []}

                dist = city.subLocation.find(item => item.code === district)
                if (district === "'000") {
                    groupDistricts = city.subLocation.filter(item => item.group.includes(group))
                    groupDistricts.forEach(item => {
                        locationStr.push(item.name)
                    })

                } else {
                    dist = city.subLocation.find(item => item.code === district)
                    locationStr.push(dist.name)

                }

                if (location.viliage) {
                    let viliage = dist.subLocation.find(item => item.code === location.viliage)
                    locationStr.push(viliage.name)
                }
            };
        }
        // console.log(locationStr)

    } catch (err) {
        console.error(err)
    }
    let elecLocation = locationStr[locationStr.length - 1]


    // 0: "直轄市長",
    // 1: "縣市長",
    // 2: "直轄市議員",
    // 3: "縣市議員",
    // 4: "鄉鎮市長",
    // 5: "直轄市山地原住民區長",
    // 6: "鄉鎮市民代表",
    // 7: "直轄市山地原住民區民代表",
    // 8: "村里長"
    let p = ""

    switch (position) {
        case 0:
            p = type ? "直轄市長" : "市長"
            break
        case 1:
            p = (elecLocation.includes("縣")) ? "縣長" : "市長"
            break
        case 2:
            p = type ? "直轄市議員" : "市議員"
            break
        case 3:
            // console.log(locationStr)
            p = (locationStr[0].includes("縣")) ? "縣議員" : "市議員"
            break
        case 4:
            p = (elecLocation.includes("鄉")) ? "鄉長" : ((elecLocation.includes("鎮")) ? "鎮長" : "市長")
            break
        case 5:
            p = type ? "山地原住民區長" : "原區長"
            break
        case 6:
            p = type ? "鄉鎮市民代表" :
                ((elecLocation.includes("鄉")) ? "鄉民代" : ((elecLocation.includes("鎮")) ? "鎮民代" : "市民代"))
            break
        case 7:
            p = type ? "山地原住民區民代" : "原區代"
            break
        case 8:
            p = (elecLocation.includes("村")) ? "村長" : "里長"
            break
        default:
            p = "error"
    }
    if (type === 0) {
        // card view
        if (result) {
            return (
                <div>
                    {p}
                    <img src={elecLogo} alt='' className='inline-block w-4 h-4' />
                </div>
            )
        }
        return (
            <div className='flex flex-nowrap'>
                {p}
                <div className='text-xs border rounded-md p-0.5 border-slate-400 text-slate-600'>
                    {term === 2018 ? '未上' : '候選'}
                </div>
            </div>)
    }
    return (<div>{p}</div>)
}

export default Position