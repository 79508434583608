import React from 'react'
import Totop from './controlComponent/ToTop'

const Disclaimer = () => {
  return (
    <main className='w-full grow p-4 overflow-y-auto bg-neutral-100 min-h-screen'>
      <div className='bg-slate-200 rounded-lg mx-4 my-2 text-neutral-700 px-2'>
        <div className='text-2xl p-4 font-bold'>
          {"使用規範及免責聲明："}
        </div>
        <div className='p-2'>
          {"使用者瀏覽及使用本網站資訊，即代表已經確認及接納本網站的使用規範及免責聲明，自承風險。 對於任何因使用、瀏覽或無法使用本網站而產生的直接、間接、附帶、相應而生、特別、懲戒性或懲罰性的損害或有關損失利潤或損失收益的損害賠償，本網站不會承擔任何責任。"}
          <div className='p-4'>
            {"本網站之內容："}
            <div className='text-md'>
              {"本網站提供所有內容僅供參考之用，本網站可隨時更改資料而不做另行通知。本網站刊載內容取自中央選舉委員會網站之公開選舉相關資訊及歷史選舉公報所載項目，部分資訊取自網路和公開新聞，非能保證所有資料完整性、正確性、即時性。對於因使用或依賴本網站的資料或任何網頁所載，包括但不限於任何錯誤、誤差、遺漏、或侵權性質、誹謗性質或虛假性質的信息或任何其他可導致冒犯或在其他方面引致發生任何追索或投訴的資料或遺漏，而導致之任何損失或損害，本網站不會承擔任何有關責任。"}
            </div>
          </div>
          <div className='p-4'>
            {"本網站提供之服務："}
            <div>
              {"本網站服務僅供簡易查詢之用，並不具有完整性、正確性、即時性之保證。在使用本網站服務過程中，必須遵循以下原則："}
              <ul>
                <li>
                  {"(1) 遵守中華民國有關的法律和法規；"}
                </li>
                <li>
                  {"(2) 遵守所有與網路服務有關的網路協定、規定和程式；"}
                </li>
                <li>
                  {"(3) 不得為任何非法目的而使用網路服務系統；"}
                </li>
                <li>
                  {"(4) 不使用任何設備、軟體或程序，干擾或試圖干擾本網站、本網站之正常運作；"}
                </li>
                <li>
                  {"(5) 不允許侵入、強行入侵、接通、使用或企圖侵入、接通、使用本網站伺服器及未經本網站對您發出許可的任何資料區；"}
                </li>
                <li>
                  {"(6) 不得限制、制止其他使用者使用並享用本服務；"}
                </li>
                <li>
                  {"(7) 如發現任何非法使用或任何出現安全漏洞的情況，應立即告知本網站。"}
                </li>
              </ul>
            </div>
          </div>

          <div className='p-4'>
            {"本網站之評論："}
            <div>
              {"本網站所載之評論，為評論作者之個人意見，並不代表本網站之立場。若本網站所刊載評論造成任何損失或損害，本網站不會承擔任何有關責任。"}
            </div>
          </div>
          <div className='p-4'>
            {"本網站之版權："}
            <div>
              {"本網站的所有資料除公開公眾資訊外，均受知識產權法例及權利所保障。本網站資訊內容受著作權法保護者，除有合理使用情形外，應取得該著作財產權人同意或授權後，方得利用。上述合理使用情形，說明如下："}
            <ul>
              <li>
                {"(1)本網站上所刊載資訊，在合理範圍內，得重製、公開播送或公開傳輸，利用時，並請註明出處。"}
              </li>
              <li>
                {"(2)本網站上之資訊，可為個人或家庭非營利之目的而重製。"}
              </li>
              <li>
                {"(3)為報導、評論、教學、研究或其他正當目的，在合理範圍內，得引用本網站上之資訊，引用時，並請註明出處。"}
              </li>
            </ul>
            </div>
          </div>
          <div className='p-4'>
            {"本網站之連結："}
            <div>
              {"為供使用者便利，本網站僅提供相關網站之連結，對使用者涉及該網站內容之使用行為係屬使用者之個人行為，本網站不負任何責任。"}
            </div>

          </div>
          <div className='p-4'>
            {"免責聲明更新："}
            <div>
              {"在給予或不給予事先通知下，本網站保留隨時更新本免責聲明的權利，任何更改於本網站發佈時，立即生效。請使用者在每次瀏覽本網站時，務必查看此免責聲明。如使用者繼續使用本網站，即代表使用者同意接受更改後的免責聲明約束。"}
            </div>
          </div>
        </div>
      </div>
      <Totop />
    </main>
  )
}

export default Disclaimer