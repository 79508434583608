import React from 'react'
import Educations from './Educations';
import Experiences from './Experiences';
import Politics from './Politics';

const Details = ({ details, type }) => {
    switch (type) {
        case 'large':
            return (
                <div className=' grid grid-rows'>
                    <div className='grid grid-cols-2 justify-center'>
                        <div>
                            <Educations educations={details.educations} size="large" />
                        </div>
                        <div>
                            <Experiences experiences={details.experiences} size="large" />
                        </div>
                    </div>
                    <div>
                        <Politics politics={details.politics} size="large" />
                    </div>
                </div>
            )
        default:
            return (
                <div className=''>
                    <Educations educations={details.educations} />
                    <Experiences experiences={details.experiences} />
                    <Politics politics={details.politics} />
                </div>
            )
    }
}

export default Details