import React, { useContext } from 'react'
import { FaLaptop, FaTabletAlt, FaMobileAlt, FaAddressBook, FaInfoCircle, FaSearch, FaBuilding, FaSearchLocation, FaIdBadge, FaCity, FaChartPie, FaChartBar, FaThumbtack } from 'react-icons/fa'
import DataContext from '../../context/DataContext'
import { Link, useNavigate } from 'react-router-dom';
import homeLogo from '../../image/home.svg'
import elecLogo from '../../image/elec4.svg'
import Switch from '../controlComponent/Switch';
import SelectMulti from '../controlComponent/SelectMulti'
import { updateLocationOptions } from '../controlComponent/SelectMulti';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
// 找 候選人 職位 政黨 ...

const Header = () => {
  const navigate = useNavigate();
  const { selected, setSelected, search, setSearch, hideTextSearch } = useContext(DataContext)
  const textSearch = hideTextSearch ? "none" : "flex"
  const handleClickHome = () => {
    navigate('/')
    setSearch("")
    setSelected(prevData => ({
      "options": updateLocationOptions([]),
      "selectedOptions": []
    }))
  }
  return (
    <header className='w-screen px-1 pt-1 flex justify-between 
    items-center whitespace-nowrap text-neutral-800 bg-neutral-700
    '>
      {/* <header className='w-screen p-1 flex justify-between 
    items-center whitespace-nowrap lg:border-b-2 text-neutral-50 bg-neutral-700
    border-stone-500'></header> */}
      <div onClick={handleClickHome} className="min-w-[200px] py-2 pl-2 rounded-3xl cursor-pointer"
      >
        <img src={elecLogo} alt='' className='inline-block w-6 h-6' />
        <img src={homeLogo} alt='' className='inline-block p-1 h-7' />
      </div>
      <div className='hidden lg:flex w-full justify-center'>
        <div className='min-w-[350px]'>
          <Link to='/'>
            <SelectMulti
              selectedState={selected}
              setSelectedState={setSelected}
            />
          </Link>
        </div>

        <div style={{ display: textSearch }} className='ml-1'>
          {/* <Box
            component="form"
            sx={{
              '& > :not(style)': { borderRadius: "5px", backgroundColor: "white", minWidth: "350px"},
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              placeholder="找 候選人 選期 政黨"
              type="search"
              variant="outlined"
              fullWidth
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                input: { color: '#262626' },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "#fb7185"
                  }
                }
              }}

            />
          </Box> */}
          <Link to='/'>
            <form className='searchForm ml-1' onSubmit={(e) => e.preventDefault()}>
              <label htmlFor='search'>search posts</label>
              <input
                id='search'
                type="search"
                placeholder='找 候選人 選期 政黨'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
          </Link>
        </div>
      </div>
      <div className='flex flex-nowrap items-center'>
        {/* <div className='text-sm mr-4' style={{ color: (value18 ? "#fff" : "rgb(190, 190, 190)"), display: (!candidatePageDisplay ? 'inline' : 'none') }}>
          <Switch
            context={"2018"}
            isOn={value18}
            // onColor="#06D6A0"
            onColor="#2dd4bf"
            handleToggle={() => setValue18(!value18)}
          />
        </div> */}
        <div onClick={() => { navigate('/') }}
        // style={{ display: (candidatePageDisplay ? 'inline' : 'none') }}
        // className={(candidatePageDisplay ? 'inline' : 'hidden')}
        >
          <HeaderIcon icon={<FaSearch size='24' />} />
        </div>
        {/* <div onClick={() => { console.log('click statics'); navigate('/statics') }}>
          <HeaderIcon icon={<FaChartPie size='24' />} />
        </div>
        <div onClick={() => { console.log('click thumbtack'); navigate('/thumbtack')}}>
          <HeaderIcon icon={<FaThumbtack size='24' />} />
        </div> */}
        <div onClick={() => { navigate('/thumbtack') }}>
          <HeaderIcon icon={<FaThumbtack size='24' />} />
        </div>

      </div>
    </header >
  )
}

const HeaderIcon = ({ icon }) => {
  return (
    <div className='headerIcon'>
      {icon}
    </div>
  )
};

export default Header