import React from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// export const options = {
//     indexAxis: "y",
//     scales: {
//         x: {
//             stacked: true,
//             suggestedMin: 0,
//             suggestedMax: 2000000
//         },
//         y: {
//             stacked: true,
//             display: false,
//         }
//     },
//     elements: {
//         bar: {
//             borderWidth: 1
//         }
//     },
//     responsive: true,
//     plugins: {
//         legend: {
//             position: "bottom"
//         },
//         title: {
//             display: true,
//             text: "得票"
//         }
//     }
// };

// const labels = ["Projection"];

// export const data = {
//     labels,
//     datasets: [
//         {
//             label: "Dataset 1",
//             data: labels.map(() => faker.datatype.number({ min: 100, max: 1000 })),
//             borderColor: "rgb(255, 99, 132)",
//             backgroundColor: "rgba(255, 99, 132, 0.5)"
//         },
//         {
//             label: "Dataset 2",
//             data: labels.map(() => faker.datatype.number({ min: 100, max: 1000 })),
//             borderColor: "rgb(53, 162, 235)",
//             backgroundColor: "rgba(53, 162, 235, 0.5)"
//         }
//     ]
// };

const Tickets = ({ details, type, result }) => {
    const secondLabel = result
        ? ((details.tickets.ticket > details.tickets.appendix) ? "次高得票" : "最高得票")
        : "當選人得票"
    
    const thickness = 20
    // 110, 231, 183 emerald-300
    // 52, 211, 153 emerald-400
    const labels = ["票數"];
    const data = {
        labels,
        datasets: [
            {
                barThickness: thickness,
                maxBarThickness: thickness,
                label: "得票",
                data: [details.tickets.ticket],
                borderColor: "rgba(110, 231, 183, 0.5)",
                backgroundColor: "rgba(110, 231, 183, 0.6)"
            },
            {
                barThickness: thickness,
                maxBarThickness: thickness,
                label: secondLabel,
                data: [details.tickets.appendix],
                borderColor: "rgba(251, 113, 133, 0.5)",
                backgroundColor: "rgba(251, 113, 133, 0.6)"
            },
            {
                barThickness: thickness,
                maxBarThickness: thickness,
                label: "其他得票",
                data: [(details.tickets.all - (details.tickets.ticket + details.tickets.appendix))],
                borderColor: "rgba(148, 163, 184, 0.5)",
                backgroundColor: "rgba(148, 163, 184, 0.6)"
            }
        ]
    };
    const optionsLarge = {
        indexAxis: "y",
        scales: {
            x: {
                stacked: true,
                suggestedMin: 0,
                max: details.tickets.all,
                ticks: {
                    maxTicksLimit: 4
                }
            },
            y: {
                stacked: true,
                display: false,
            }
        },
        elements: {
            bar: {
                borderWidth: 1
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
                labels: {
                    font: {
                        size: '14'
                    }
                }
            },
            title: {
                display: false,
                text: "得票佔比"
            }
        }
    };
    const optionsSmall = {
        indexAxis: "y",
        scales: {
            x: {
                stacked: true,
                display: false,
                suggestedMin: 0,
                max: details.tickets.all,
                ticks: {
                    maxTicksLimit: 4
                }
            },
            y: {
                stacked: true,
                display: false,
            }
        },
        elements: {
            bar: {
                borderWidth: 1
            }
        },
        responsive: true,
        plugins: {
            legend: {
                // display: false,
                position: "bottom",
                labels: {
                    font: {
                        size: '14'
                    }
                }
            },
            title: {
                display: false,
                text: "得票佔比"
            }
        }
    };
    const formatter = Intl.NumberFormat('en', { notation: 'compact' })
    const percentage = ((details.tickets.ticket * 100) / details.tickets.all)


    switch (type) {
        case 'large':
            return (
                <div className='mt-2 '>
                    <div className='flex'>
                        <div className='flex flex-row w-1/3 justify-start px-4 text-neutral-700 text-lg'>
                            {"選票佔比"}
                        </div>
                        <div className='flex flex-row w-2/3 justify-center px-4 my-1 text-sm'>
                            <div className='px-2 bg-slate-200 text-neutral-700 rounded-lg mx-1'>
                                {`得票: ${formatter.format(details.tickets.ticket)}`}
                            </div>
                            <div className='px-2 bg-slate-200 text-neutral-700 rounded-lg mx-1'>
                                {`選區票數: ${formatter.format(details.tickets.all)}`}
                            </div>
                            <div className='px-2 bg-slate-200 text-neutral-700 rounded-lg mx-1'>
                                {`佔比: ${percentage.toFixed(1)}%`}
                            </div>
                        </div>
                    </div>
                    <div className=' grid grid-rows mx-1 mt-1 min-h-[50px]'>
                        <Bar options={optionsLarge} data={data} height={40} />
                    </div>
                </div>

            )
        default:
            return (
                <div className='mt-1'>
                    <div className='flex flex-row justify-center px-4 my-1 text-sm'>
                        <div className='px-2 bg-slate-200 text-neutral-700 rounded-lg mx-1'>
                            {`得票: ${formatter.format(details.tickets.ticket)}`}
                        </div>
                        <div className='px-2 bg-slate-200 text-neutral-700 rounded-lg mx-1'>
                            {`選區票數: ${formatter.format(details.tickets.all)}`}
                        </div>
                        <div className='px-2 bg-slate-200 text-neutral-700 rounded-lg mx-1'>
                            {`佔比: ${percentage.toFixed(1)}%`}
                        </div>
                    </div>
                    <div className='flex mx-4 min-h-full my-1'>
                        <Bar options={optionsSmall} data={data} height={50} />
                    </div>
                </div>
            )
    }
}

export default Tickets