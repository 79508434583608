import React, { useState, useContext, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

import DataContext from '../../context/DataContext'
import BasicInfoItem from './BasicInfoItem';
import Location from './Location';
import LocationTag from './LocationTag';
import Position from './Position';
import HeadPic from '../cardComponent/HeadPic';
import RateBlock from './RateBlock';
import BasicInfoButton from './BasicInfoButton';

import {
    FaThumbtack, FaUsers, FaBirthdayCake, FaIdBadge, FaBaby,
    FaStreetView, FaCalendarAlt, FaIdCardAlt
} from 'react-icons/fa'
import { positionName } from '../../docs/position';

class ThumbtackItem extends React.Component {
    constructor(props) {
        super(props)
        //   this.state = { fade: false }
        //   this.state = state
    }
    render() {
        const fade = this.props.state.fade

        return (
            <button
                //   ref='button'
                onClick={() => {
                    this.props.setState({ fade: !this.props.state.fade });
                }}
                //   onAnimationEnd={() => this.setState({ fade: false })}
                className={fade ? 'fade' : 'rotate-45 text-slate-400'}>
                <FaThumbtack size={this.props.size} />
            </button>
        )
    }
}


const BasicInfo = ({ candidate, details, ratings, type, partyName }) => {
    const { id } = useParams();
    // thumbtackedId, setThumbtackedId
    const { thumbtackedId, setThumbtackedId, setSearch } = useContext(DataContext)

    const [isThumbtacked, setIsThumbtacked] = useState({ fade: false })

    // let isThumbtacked = false
    const bgColor = partyName[candidate.party].color ? partyName[candidate.party].color : "#e2e8f0"

    useEffect(() => {
        setIsThumbtacked({ fade: thumbtackedId.includes(parseInt(id)) })
    }, [id])

    useEffect(() => {
        isThumbtacked.fade
            // user add this id to thumbstack
            ? setThumbtackedId(prevState => ([...prevState, parseInt(id)]))
            // user remove this id from thumbtack
            : setThumbtackedId(thumbtackedId.filter(item => item !== parseInt(id)));
    }, [isThumbtacked])
    const termBadgeColor = candidate.term === 2018 ? 'bg-indigo-300' : 'bg-rose-400';

    const showRateBlock = () => {
        return (
            (candidate.term === 2022 || candidate.result === 1) ?
                (<li className='flex justify-start'>
                    <RateBlock ratings={ratings} />
                </li>) :
                (<></>)
        )
    }
    // console.log("isThumbtacked", isThumbtacked)
    // console.log("thumbtackedId", thumbtackedId)
    switch (type) {
        case 'large':
            return (
                <div className='grid grid-cols-2 w-full p-2'
                    style={{ backgroundColor: bgColor }}>
                    <div className='grid justify-center'>
                        <figure className='p-4'>
                            <HeadPic candidateImage={candidate.image} name={candidate.name} type='large'
                                result={candidate.result} degree={details.degree} />
                        </figure>
                        {/* <Popularity type='large'/> */}
                        <div className='flex flex-row justify-center pb-2 px-2'>
                            <div className='text-3xl pr-2'>
                                {candidate.name}
                            </div>
                            {/* <div className="text-xl" onClick={() => {console.log("click thumbtack")}}>
                                <FaThumbtack />
                            </div> */}
                            <div className='bg-neutral-100 w-[35px] rounded-md mb-1 h-[40px] shadow-sm flex items-center justify-center' >
                                <ThumbtackItem
                                    state={isThumbtacked}
                                    setState={setIsThumbtacked}
                                    size={28}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='flex flex-row pb-2 pt-4'>
                            <div className='rounded-full bg-slate-50 border-pink-600 border-solid text-pink-600 h-8 w-8 border-2 
                            items-center justify-center flex mr-2 text-xl'>
                                {details.number ? details.number : ''}
                            </div>
                            <Link onClick={() => setSearch(`${positionName[candidate.position]}`)} to={'/'}>
                                <div className='text-xl'>
                                    <Position position={candidate.position} location={candidate.location} type={1} />
                                </div>
                            </Link>
                            {candidate.result
                                ? <Link onClick={() => setSearch('當選')} to={'/'}>
                                    <div className='shadow-sm mx-2 p-1 rounded-lg bg-slate-50 text-red-500 border-2 bordre-solid border-red-400'>
                                        當選
                                    </div>
                                </Link>
                                : <div className='shadow-sm mx-2 p-1 rounded-lg bg-slate-300 text-slate-600
                                border-2 bordre-solid border-slate-300'>
                                    {candidate.term === 2018 ? ' 未選上' : '候選'}
                                </div>
                            }
                        </div>
                        <ul className=''>
                            <li className='relative'>
                                {/* <div className="opacity-0 hover:opacity-100 absolute duration-300 inset-0 z-10 flex 
                                justify-end items-center text-sm text-neutral-200 p-4">
                                    選區
                                </div> */}
                                {/* className='shadow-sm p-1 mx-1 my-1 rounded-lg bg-slate-200 text-slate-700 w-fit whitespace-nowrap cursor-pointer' */}
                                <BasicInfoItem icon={<FaStreetView />} info={<LocationTag location={candidate.location} />} />
                            </li>
                            <li className='relative'>
                                {/* <div className="opacity-0 hover:opacity-100 absolute duration-300 inset-0 z-10 flex 
                                justify-end items-center text-sm text-neutral-200 p-4">
                                    選期
                                </div> */}
                                {/* <BasicInfoItem icon={<FaCalendarAlt />} info={candidate.term} /> */}
                                <div className='text-xl flex flex-row my-2'>
                                    <FaCalendarAlt />
                                    <Link onClick={() => setSearch(`${candidate.term}`)} to={'/'}>
                                        <div className={`ml-2 inline-flex justify-center items-center w-10 h-5 text-sm 
                                text-neutral-50 rounded-lg font-bold shadow-sm ${termBadgeColor}`}>
                                            {candidate.term}
                                        </div>
                                    </Link>
                                </div>
                            </li>
                            <li className='relative'>
                                {/* <div className="opacity-0 hover:opacity-100 absolute duration-300 inset-0 z-10 flex 
                                justify-end items-center text-sm text-neutral-200 p-4">
                                    當時年紀
                                </div> */}
                                <BasicInfoItem icon={<FaBirthdayCake />} info={candidate.term - 1911 - details.age[0]} />
                            </li>
                            <li className='relative'>
                                {/* <div className="opacity-0 hover:opacity-100 absolute duration-300 inset-0 z-10 flex 
                                justify-end items-center text-sm text-neutral-200 p-4">
                                    出生地
                                </div> */}
                                <BasicInfoItem icon={<FaBaby />} info={details.birthPlace} />
                            </li>
                            <li className='relative'>
                                {/* <div className="opacity-0 hover:opacity-100 absolute duration-300 inset-0 z-10 flex 
                                justify-end items-center text-sm text-neutral-200 p-4">
                                    性別
                                </div> */}
                                <BasicInfoItem icon={<FaIdBadge />} info={details.gender === 1 ? "男" : "女"} />
                            </li>
                            <li className='relative'>
                                {/* <div className="opacity-0 hover:opacity-100 absolute duration-300 inset-0 z-10 flex 
                                justify-end items-center text-sm text-neutral-200 p-4">
                                    推薦政黨
                                </div> */}
                                <Link onClick={() => setSearch(`${partyName[candidate.party].name}`)} to={'/'}>
                                    <BasicInfoButton icon={<FaUsers />} info={partyName[candidate.party].name} />
                                </Link>
                            </li>
                            {candidate.abo
                                ? (candidate.abo === 1)
                                    ?
                                    (<li><Link onClick={() => setSearch("原住民")} to={'/'}>
                                        <BasicInfoButton icon={<FaIdCardAlt />} info={"平地原住民"} />
                                    </Link></li>)
                                    :
                                    (<li><Link onClick={() => setSearch("原住民")} to={'/'}>
                                        <BasicInfoButton icon={<FaIdCardAlt />} info={"山地原住民"} />
                                    </Link></li>)
                                : <></>}
                            {showRateBlock()}
                        </ul>
                    </div>
                </div >

            )
        default:
            return (
                <>
                    <div className='p-2'
                        style={{ backgroundColor: bgColor }}>
                        <div className='grid justify-center'>
                            <figure className='pt-2'>
                                <HeadPic candidateImage={candidate.image} name={candidate.name} type='small'
                                    result={candidate.result} degree={details.degree} />
                            </figure>
                        </div>
                    </div>
                    <div className='grid justify-center '>
                        <div className='flex flex-row pt-4 justify-center mx-2'>
                            <div className='text-3xl pr-2'>
                                {candidate.name}
                            </div>
                            <ThumbtackItem
                                state={isThumbtacked}
                                setState={setIsThumbtacked}
                                size={24}
                            />
                        </div>
                    </div>
                    <div className='grid justify-center '>
                        <div className='flex flex-row py-2 text-xl'>
                            <div className='rounded-full bg-slate-50 border-pink-600 
                        border-solid text-pink-600 h-7 w-7 border-2 
                        items-center justify-center flex mr-2'>
                                {details.number ? details.number : ''}
                            </div>
                            <div className='text-2xl'>
                                <Position position={candidate.position} location={candidate.location} type={1} />
                            </div>
                            {candidate.result
                                ? <Link onClick={() => setSearch('當選')} to={'/'}>
                                    <div className='shadow-sm mx-2 p-1 rounded-lg bg-slate-50 text-red-500 
                                            border-2 bordre-solid border-red-400'>
                                        當選
                                    </div>
                                </Link>
                                :
                                <div className='shadow-sm mx-2 p-1 rounded-lg bg-slate-300 text-slate-700
                                border-2 bordre-solid border-slate-300'>
                                    {candidate.term === 2018 ? ' 未選上' : '候選'}
                                </div>
                            }
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex flex-col justify-center items-center'>
                            <div className='text-xl flex flex-row mb-2'>
                                <FaCalendarAlt />
                                <Link onClick={() => setSearch(`${candidate.term}`)} to={'/'}>
                                    <div className={`ml-2 inline-flex justify-center items-center w-10 h-5 text-sm 
                                text-neutral-50 rounded-lg font-bold shadow-sm ${termBadgeColor}`}>
                                        {candidate.term}
                                    </div>
                                </Link>
                            </div>
                            <div className='mx-4'>
                                <BasicInfoItem icon={<FaStreetView />} info={<LocationTag location={candidate.location} />} />
                            </div>
                            <Link onClick={() => setSearch(`${partyName[candidate.party].name}`)} to={'/'}>
                                <BasicInfoButton icon={<FaUsers />} info={partyName[candidate.party].name} />
                            </Link>
                            {candidate.abo
                                ? (candidate.abo === 1)
                                    ?
                                    (<Link onClick={() => setSearch("原住民")} to={'/'}>
                                        <BasicInfoButton icon={<FaIdCardAlt />} info={"平地原住民"} />
                                    </Link>)
                                    :
                                    (<Link onClick={() => setSearch("原住民")} to={'/'}>
                                        <BasicInfoButton icon={<FaIdCardAlt />} info={"山地原住民"} />
                                    </Link>)
                                : <></>}

                            <div className='flex flex-row justify-center'>
                                {/* FIXME: when details don't exist will read error */}
                                <BasicInfoItem icon={<FaBirthdayCake />} info={candidate.term - 1911 - details.age[0]} />
                                <BasicInfoItem icon={<FaBaby />} info={details.birthPlace} />
                                {/* </div>
                        <div className='flex flex-row justify-center'> */}
                                <BasicInfoItem icon={<FaIdBadge />} info={details.gender === 1 ? "男" : "女"} />
                            </div>
                        </div>
                    </div>
                </>
            )
    }
}

export default BasicInfo