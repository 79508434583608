import { createContext, useState, useEffect } from 'react';
import useAxiosFetch from '../hooks/useAxiosFetch';
// import useFirestore from '../hooks/useFirestore';
import { useLocation } from 'react-router-dom';
// import { firestore } from '../context/Firebase'
import { locations } from "../docs/location";
import { positionName } from "../docs/position";
import { partyName } from "../docs/party";
// import { data } from "../docs/candidate";
// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth'
// import { useCollection } from 'react-firebase-hooks/firestore'
import useWindowsSize from '../hooks/useWindowSize';
// import { updateLocationOptions } from '../page/controlComponent/SelectMulti';
import { v4 as uuidv4 } from 'uuid';
import { searchEngine } from './SearchEngine';
import LoadData from './LoadData';

// const auth = firebase.auth();
// const firestore = firebase.firestore();

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const { width } = useWindowsSize();
    const [candidates, setCandidates] = useState([]);
    const [search, setSearch] = useState('');
    const [searchResult, setSearchResult] = useState([])
    // const [value18, setValue18] = useState(true);
    // const [value22, setValue22] = useState(true);
    const [uuid, setUuid] = useState('')
    const [selected, setSelected] = useState({
        selectedOptions: [],
        options: locations.map((location) => (
            {
                label: location.name,
                value: location.code
            }
        ))
    });
    const [ratedId, setRatedId] = useState([])
    useEffect(() => {
        let uuid = ""
        try {
            uuid = localStorage.getItem('uuid');
            let u = uuidv4()
            if (!uuid) {
                let uuid1 = u.slice(0, 19)
                let uuid2 = u.slice(22, 35)
                uuid = `${uuid1}edc${uuid2}`
                localStorage.setItem('uuid', uuid);
            }

        } catch (err) {
            console.error(err)
        }
        setUuid(uuid);
    }, [])

    const [hideTextSearch, setHideTextSearch] = useState(false)
    const [candidatePageDisplay, setCandidatePageDisplay] = useState(false)
    const [thumbtackedId, setThumbtackedId] = useState([])
    const [followData, setFollowData] = useState([])


    // Load badic candidate data
    // console.log("REACT_APP_MODE", process.env.REACT_APP_MODE)
    // http://localhost:5010/yssy-elec/asia-east1/app
    // https://asia-east1-yssy-elec.cloudfunctions.net/app

    const dataUrlMap = {
        'local': 'http://localhost:3500/candInf',
        'dev': 'http://localhost:5010/yssy-elec/asia-east1/app/data',
        'firebase': 'https://asia-east1-yssy-elec.cloudfunctions.net/app/data'
    }

    const { data, fetchError, isLoading } = useAxiosFetch(dataUrlMap[process.env.REACT_APP_MODE])


    // Load more candidate data
    // let data1 = []
    // const { data1, fetchError1, isLoading1 } = LoadData()
    // data1 = LoadData()



    const path = useLocation().pathname

    useEffect(() => {
        setCandidatePageDisplay(
            (path.split('/')[1] === 'candidate') ||
            (path.split('/')[1] === 'thumbtack') ||
            (path.split('/')[1] === 'about') ||
            (path.split('/')[1] === 'disclaimer') ||
            (path.split('/')[1] === 'report')
        )
    }, [path, candidatePageDisplay])


    useEffect(() => {
        setHideTextSearch(path.split('/')[1] === 'statics')
    }, [path])

    useEffect(() => {
        console.count(data.length)
        setCandidates(data);
    }, [data])

    // useEffect(() => {
    //     try {
    //         console.log("data1", data1.length)
    //         setTimeout(() => {


    //         }, 10000)

    //         let moreData = candidates.concat(data1)
    //         console.log("more", moreData.length)
    //         setCandidates(moreData);


    //     } catch (err) {
    //         console.error(err)
    //     }
    // }, [data1])


    useEffect(() => {
        try {
            setSearchResult(searchEngine(candidates, selected, search));

        } catch (err) {
            console.error(err)
        }

    }, [candidates, search, selected])

    // console.log("selected", selected.selectedOptions)

    return (
        <DataContext.Provider value={{
            search, setSearch, width,
            candidates, setCandidates,
            searchResult, fetchError, isLoading,
            ratedId, setRatedId,
            selected, setSelected, candidatePageDisplay,
            positionName, partyName,
            locations, hideTextSearch, setHideTextSearch,
            thumbtackedId, setThumbtackedId, path, uuid
        }}>
            {children}

        </DataContext.Provider>
    )
}

export default DataContext;