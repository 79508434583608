import React from 'react'
import { FaSchool } from 'react-icons/fa'

const Educations = ({ educations, size }) => {

    const mapItem = (list) => {
        return (
            list.length !== 0 ? (list.map((item, i) => (
                <li className='border-b-2 border-solid border-slate-50 mb-2' key={i}>
                    {item}
                </li>
            ))) : <li>資料整理中</li>
        )
    }
    return (
        <>
            {(size === 'large')
                ?
                <div className='rounded-xl bg-slate-200 p-2 px-4 py-2 text-sm m-2'>
                    <div className='flex flex-row font-bold'>
                        {/* <div>
                            <FaSchool fontSize={24} />
                        </div> */}
                        <div>
                            學歷
                        </div>
                    </div>
                    <ul>
                        {mapItem(educations)}
                    </ul>
                </div>
                :
                <div className="mx-4 my-1">
                    <input id="collapsibleEdu" className="toggle" type="checkbox" />
                    <label htmlFor="collapsibleEdu"
                        className="lbl-toggle text-center p-1 text-neutral-700 bg-slate-200 shadow-sm cursor-pointer rounded-md transition-all duration-300 ease-out hover:text-neutral-800" tabIndex="0">
                        學歷
                    </label>
                    <div className="collapsible-content overflow-hidden rounded shadow-sm">
                        <div className="content-inner">
                            <div className='mb-0'>
                                <ul>
                                    {mapItem(educations)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Educations