export const locations = [
  {
    "name": "台北市",
    "code": "'63'000",
    "subLocation": [
      {
        "name": "士林區",
        "code": "'110",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "仁勇里",
            "code": "'0001",
          },
          {
            "name": "義信里",
            "code": "'0002",
          },
          {
            "name": "福林里",
            "code": "'0003",
          },
          {
            "name": "福德里",
            "code": "'0004",
          },
          {
            "name": "福志里",
            "code": "'0005",
          },
          {
            "name": "舊佳里",
            "code": "'0006",
          },
          {
            "name": "福佳里",
            "code": "'0007",
          },
          {
            "name": "後港里",
            "code": "'0008",
          },
          {
            "name": "福中里",
            "code": "'0009",
          },
          {
            "name": "前港里",
            "code": "'0010",
          },
          {
            "name": "百齡里",
            "code": "'0011",
          },
          {
            "name": "承德里",
            "code": "'0012",
          },
          {
            "name": "福華里",
            "code": "'0013",
          },
          {
            "name": "明勝里",
            "code": "'0014",
          },
          {
            "name": "福順里",
            "code": "'0015",
          },
          {
            "name": "富光里",
            "code": "'0016",
          },
          {
            "name": "葫蘆里",
            "code": "'0017",
          },
          {
            "name": "葫東里",
            "code": "'0018",
          },
          {
            "name": "社子里",
            "code": "'0019",
          },
          {
            "name": "社新里",
            "code": "'0020",
          },
          {
            "name": "社園里",
            "code": "'0021",
          },
          {
            "name": "永倫里",
            "code": "'0022",
          },
          {
            "name": "福安里",
            "code": "'0023",
          },
          {
            "name": "富洲里",
            "code": "'0024",
          },
          {
            "name": "岩山里",
            "code": "'0025",
          },
          {
            "name": "名山里",
            "code": "'0026",
          },
          {
            "name": "德行里",
            "code": "'0027",
          },
          {
            "name": "德華里",
            "code": "'0028",
          },
          {
            "name": "聖山里",
            "code": "'0029",
          },
          {
            "name": "忠誠里",
            "code": "'0030",
          },
          {
            "name": "芝山里",
            "code": "'0031",
          },
          {
            "name": "東山里",
            "code": "'0032",
          },
          {
            "name": "三玉里",
            "code": "'0033",
          },
          {
            "name": "蘭雅里",
            "code": "'0034",
          },
          {
            "name": "蘭興里",
            "code": "'0035",
          },
          {
            "name": "天福里",
            "code": "'0036",
          },
          {
            "name": "天祿里",
            "code": "'0037",
          },
          {
            "name": "天壽里",
            "code": "'0038",
          },
          {
            "name": "天和里",
            "code": "'0039",
          },
          {
            "name": "天山里",
            "code": "'0040",
          },
          {
            "name": "天玉里",
            "code": "'0041",
          },
          {
            "name": "天母里",
            "code": "'0042",
          },
          {
            "name": "永福里",
            "code": "'0043",
          },
          {
            "name": "公館里",
            "code": "'0044",
          },
          {
            "name": "新安里",
            "code": "'0045",
          },
          {
            "name": "陽明里",
            "code": "'0046",
          },
          {
            "name": "菁山里",
            "code": "'0047",
          },
          {
            "name": "平等里",
            "code": "'0048",
          },
          {
            "name": "溪山里",
            "code": "'0049",
          },
          {
            "name": "翠山里",
            "code": "'0050",
          },
          {
            "name": "臨溪里",
            "code": "'0051",
          }
        ]
      },
      {
        "name": "北投區",
        "code": "'120",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "建民里",
            "code": "'0001",
          },
          {
            "name": "文林里",
            "code": "'0002",
          },
          {
            "name": "石牌里",
            "code": "'0003",
          },
          {
            "name": "福興里",
            "code": "'0004",
          },
          {
            "name": "榮光里",
            "code": "'0005",
          },
          {
            "name": "榮華里",
            "code": "'0006",
          },
          {
            "name": "裕民里",
            "code": "'0007",
          },
          {
            "name": "振華里",
            "code": "'0008",
          },
          {
            "name": "永和里",
            "code": "'0009",
          },
          {
            "name": "永欣里",
            "code": "'0010",
          },
          {
            "name": "永明里",
            "code": "'0011",
          },
          {
            "name": "東華里",
            "code": "'0012",
          },
          {
            "name": "吉利里",
            "code": "'0013",
          },
          {
            "name": "吉慶里",
            "code": "'0014",
          },
          {
            "name": "尊賢里",
            "code": "'0015",
          },
          {
            "name": "立賢里",
            "code": "'0016",
          },
          {
            "name": "立農里",
            "code": "'0017",
          },
          {
            "name": "八仙里",
            "code": "'0018",
          },
          {
            "name": "洲美里",
            "code": "'0019",
          },
          {
            "name": "奇岩里",
            "code": "'0020",
          },
          {
            "name": "清江里",
            "code": "'0021",
          },
          {
            "name": "中央里",
            "code": "'0022",
          },
          {
            "name": "長安里",
            "code": "'0023",
          },
          {
            "name": "大同里",
            "code": "'0024",
          },
          {
            "name": "溫泉里",
            "code": "'0025",
          },
          {
            "name": "林泉里",
            "code": "'0026",
          },
          {
            "name": "中心里",
            "code": "'0027",
          },
          {
            "name": "中庸里",
            "code": "'0028",
          },
          {
            "name": "開明里",
            "code": "'0029",
          },
          {
            "name": "中和里",
            "code": "'0030",
          },
          {
            "name": "智仁里",
            "code": "'0031",
          },
          {
            "name": "秀山里",
            "code": "'0032",
          },
          {
            "name": "文化里",
            "code": "'0033",
          },
          {
            "name": "豐年里",
            "code": "'0034",
          },
          {
            "name": "稻香里",
            "code": "'0035",
          },
          {
            "name": "桃源里",
            "code": "'0036",
          },
          {
            "name": "一德里",
            "code": "'0037",
          },
          {
            "name": "關渡里",
            "code": "'0038",
          },
          {
            "name": "泉源里",
            "code": "'0039",
          },
          {
            "name": "湖山里",
            "code": "'0040",
          },
          {
            "name": "大屯里",
            "code": "'0041",
          },
          {
            "name": "湖田里",
            "code": "'0042",
          }
        ]
      },
      {
        "name": "南港區",
        "code": "'090",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "南港里",
            "code": "'0001",
          },
          {
            "name": "中南里",
            "code": "'0002",
          },
          {
            "name": "新富里",
            "code": "'0003",
          },
          {
            "name": "三重里",
            "code": "'0004",
          },
          {
            "name": "東新里",
            "code": "'0005",
          },
          {
            "name": "新光里",
            "code": "'0006",
          },
          {
            "name": "東明里",
            "code": "'0007",
          },
          {
            "name": "西新里",
            "code": "'0008",
          },
          {
            "name": "玉成里",
            "code": "'0009",
          },
          {
            "name": "合成里",
            "code": "'0010",
          },
          {
            "name": "成福里",
            "code": "'0011",
          },
          {
            "name": "萬福里",
            "code": "'0012",
          },
          {
            "name": "鴻福里",
            "code": "'0013",
          },
          {
            "name": "百福里",
            "code": "'0014",
          },
          {
            "name": "聯成里",
            "code": "'0015",
          },
          {
            "name": "舊莊里",
            "code": "'0016",
          },
          {
            "name": "中研里",
            "code": "'0017",
          },
          {
            "name": "九如里",
            "code": "'0018",
          },
          {
            "name": "仁福里",
            "code": "'0019",
          },
          {
            "name": "重陽里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "內湖區",
        "code": "'100",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "西湖里",
            "code": "'0001",
          },
          {
            "name": "西康里",
            "code": "'0002",
          },
          {
            "name": "西安里",
            "code": "'0003",
          },
          {
            "name": "港墘里",
            "code": "'0005",
          },
          {
            "name": "港都里",
            "code": "'0006",
          },
          {
            "name": "港富里",
            "code": "'0007",
          },
          {
            "name": "港華里",
            "code": "'0008",
          },
          {
            "name": "內湖里",
            "code": "'0009",
          },
          {
            "name": "湖濱里",
            "code": "'0010",
          },
          {
            "name": "紫星里",
            "code": "'0011",
          },
          {
            "name": "大湖里",
            "code": "'0012",
          },
          {
            "name": "金龍里",
            "code": "'0013",
          },
          {
            "name": "金瑞里",
            "code": "'0014",
          },
          {
            "name": "碧山里",
            "code": "'0015",
          },
          {
            "name": "紫雲里",
            "code": "'0016",
          },
          {
            "name": "清白里",
            "code": "'0017",
          },
          {
            "name": "葫洲里",
            "code": "'0018",
          },
          {
            "name": "紫陽里",
            "code": "'0019",
          },
          {
            "name": "瑞陽里",
            "code": "'0020",
          },
          {
            "name": "瑞光里",
            "code": "'0021",
          },
          {
            "name": "五分里",
            "code": "'0022",
          },
          {
            "name": "東湖里",
            "code": "'0023",
          },
          {
            "name": "樂康里",
            "code": "'0024",
          },
          {
            "name": "內溝里",
            "code": "'0025",
          },
          {
            "name": "週美里",
            "code": "'0026",
          },
          {
            "name": "行善里",
            "code": "'0027",
          },
          {
            "name": "石潭里",
            "code": "'0028",
          },
          {
            "name": "湖興里",
            "code": "'0029",
          },
          {
            "name": "湖元里",
            "code": "'0030",
          },
          {
            "name": "安湖里",
            "code": "'0031",
          },
          {
            "name": "秀湖里",
            "code": "'0032",
          },
          {
            "name": "安泰里",
            "code": "'0033",
          },
          {
            "name": "金湖里",
            "code": "'0034",
          },
          {
            "name": "康寧里",
            "code": "'0035",
          },
          {
            "name": "明湖里",
            "code": "'0036",
          },
          {
            "name": "蘆洲里",
            "code": "'0037",
          },
          {
            "name": "麗山里",
            "code": "'0038",
          },
          {
            "name": "寶湖里",
            "code": "'0039",
          },
          {
            "name": "南湖里",
            "code": "'0040",
          }
        ]
      },
      {
        "name": "松山區",
        "code": "'010",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "莊敬里",
            "code": "'0002",
          },
          {
            "name": "東榮里",
            "code": "'0003",
          },
          {
            "name": "三民里",
            "code": "'0004",
          },
          {
            "name": "新益里",
            "code": "'0005",
          },
          {
            "name": "富錦里",
            "code": "'0006",
          },
          {
            "name": "新東里",
            "code": "'0007",
          },
          {
            "name": "富泰里",
            "code": "'0008",
          },
          {
            "name": "介壽里",
            "code": "'0009",
          },
          {
            "name": "精忠里",
            "code": "'0010",
          },
          {
            "name": "東光里",
            "code": "'0011",
          },
          {
            "name": "龍田里",
            "code": "'0012",
          },
          {
            "name": "東昌里",
            "code": "'0013",
          },
          {
            "name": "東勢里",
            "code": "'0014",
          },
          {
            "name": "中華里",
            "code": "'0015",
          },
          {
            "name": "民有里",
            "code": "'0016",
          },
          {
            "name": "民福里",
            "code": "'0017",
          },
          {
            "name": "慈祐里",
            "code": "'0018",
          },
          {
            "name": "安平里",
            "code": "'0019",
          },
          {
            "name": "鵬程里",
            "code": "'0020",
          },
          {
            "name": "自強里",
            "code": "'0021",
          },
          {
            "name": "吉祥里",
            "code": "'0022",
          },
          {
            "name": "新聚里",
            "code": "'0024",
          },
          {
            "name": "復盛里",
            "code": "'0025",
          },
          {
            "name": "中正里",
            "code": "'0026",
          },
          {
            "name": "中崙里",
            "code": "'0027",
          },
          {
            "name": "美仁里",
            "code": "'0028",
          },
          {
            "name": "吉仁里",
            "code": "'0029",
          },
          {
            "name": "敦化里",
            "code": "'0030",
          },
          {
            "name": "復源里",
            "code": "'0031",
          },
          {
            "name": "復建里",
            "code": "'0032",
          },
          {
            "name": "復勢里",
            "code": "'0033",
          },
          {
            "name": "福成里",
            "code": "'0034",
          },
          {
            "name": "松基里",
            "code": "'0035",
          }
        ]
      },
      {
        "name": "信義區",
        "code": "'020",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "西村里",
            "code": "'0001",
          },
          {
            "name": "正和里",
            "code": "'0002",
          },
          {
            "name": "興隆里",
            "code": "'0003",
          },
          {
            "name": "中興里",
            "code": "'0004",
          },
          {
            "name": "新仁里",
            "code": "'0005",
          },
          {
            "name": "興雅里",
            "code": "'0006",
          },
          {
            "name": "敦厚里",
            "code": "'0007",
          },
          {
            "name": "廣居里",
            "code": "'0008",
          },
          {
            "name": "安康里",
            "code": "'0009",
          },
          {
            "name": "六藝里",
            "code": "'0010",
          },
          {
            "name": "雅祥里",
            "code": "'0011",
          },
          {
            "name": "五常里",
            "code": "'0012",
          },
          {
            "name": "五全里",
            "code": "'0013",
          },
          {
            "name": "永吉里",
            "code": "'0014",
          },
          {
            "name": "長春里",
            "code": "'0015",
          },
          {
            "name": "四育里",
            "code": "'0016",
          },
          {
            "name": "四維里",
            "code": "'0017",
          },
          {
            "name": "永春里",
            "code": "'0018",
          },
          {
            "name": "富台里",
            "code": "'0019",
          },
          {
            "name": "國業里",
            "code": "'0020",
          },
          {
            "name": "松隆里",
            "code": "'0021",
          },
          {
            "name": "松友里",
            "code": "'0022",
          },
          {
            "name": "松光里",
            "code": "'0023",
          },
          {
            "name": "中坡里",
            "code": "'0024",
          },
          {
            "name": "中行里",
            "code": "'0025",
          },
          {
            "name": "大道里",
            "code": "'0026",
          },
          {
            "name": "大仁里",
            "code": "'0027",
          },
          {
            "name": "景新里",
            "code": "'0028",
          },
          {
            "name": "惠安里",
            "code": "'0029",
          },
          {
            "name": "三張里",
            "code": "'0030",
          },
          {
            "name": "三犁里",
            "code": "'0031",
          },
          {
            "name": "六合里",
            "code": "'0032",
          },
          {
            "name": "泰和里",
            "code": "'0033",
          },
          {
            "name": "景聯里",
            "code": "'0034",
          },
          {
            "name": "景勤里",
            "code": "'0035",
          },
          {
            "name": "雙和里",
            "code": "'0036",
          },
          {
            "name": "嘉興里",
            "code": "'0037",
          },
          {
            "name": "黎順里",
            "code": "'0038",
          },
          {
            "name": "黎平里",
            "code": "'0039",
          },
          {
            "name": "黎忠里",
            "code": "'0040",
          },
          {
            "name": "黎安里",
            "code": "'0041",
          }
        ]
      },
      {
        "name": "中山區",
        "code": "'040",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "正守里",
            "code": "'0001",
          },
          {
            "name": "正義里",
            "code": "'0002",
          },
          {
            "name": "正得里",
            "code": "'0003",
          },
          {
            "name": "民安里",
            "code": "'0004",
          },
          {
            "name": "康樂里",
            "code": "'0005",
          },
          {
            "name": "中山里",
            "code": "'0006",
          },
          {
            "name": "聚盛里",
            "code": "'0007",
          },
          {
            "name": "集英里",
            "code": "'0008",
          },
          {
            "name": "聚葉里",
            "code": "'0009",
          },
          {
            "name": "恆安里",
            "code": "'0010",
          },
          {
            "name": "晴光里",
            "code": "'0011",
          },
          {
            "name": "圓山里",
            "code": "'0012",
          },
          {
            "name": "劍潭里",
            "code": "'0013",
          },
          {
            "name": "大直里",
            "code": "'0014",
          },
          {
            "name": "成功里",
            "code": "'0015",
          },
          {
            "name": "永安里",
            "code": "'0016",
          },
          {
            "name": "大佳里",
            "code": "'0017",
          },
          {
            "name": "新喜里",
            "code": "'0018",
          },
          {
            "name": "新庄里",
            "code": "'0019",
          },
          {
            "name": "新福里",
            "code": "'0020",
          },
          {
            "name": "松江里",
            "code": "'0021",
          },
          {
            "name": "新生里",
            "code": "'0022",
          },
          {
            "name": "中庄里",
            "code": "'0023",
          },
          {
            "name": "行政里",
            "code": "'0024",
          },
          {
            "name": "行仁里",
            "code": "'0025",
          },
          {
            "name": "行孝里",
            "code": "'0026",
          },
          {
            "name": "下埤里",
            "code": "'0027",
          },
          {
            "name": "江寧里",
            "code": "'0028",
          },
          {
            "name": "江山里",
            "code": "'0029",
          },
          {
            "name": "中吉里",
            "code": "'0030",
          },
          {
            "name": "中原里",
            "code": "'0031",
          },
          {
            "name": "興亞里",
            "code": "'0032",
          },
          {
            "name": "中央里",
            "code": "'0033",
          },
          {
            "name": "朱馥里",
            "code": "'0034",
          },
          {
            "name": "龍洲里",
            "code": "'0035",
          },
          {
            "name": "朱園里",
            "code": "'0036",
          },
          {
            "name": "埤頭里",
            "code": "'0037",
          },
          {
            "name": "朱崙里",
            "code": "'0038",
          },
          {
            "name": "力行里",
            "code": "'0039",
          },
          {
            "name": "復華里",
            "code": "'0040",
          },
          {
            "name": "金泰里",
            "code": "'0041",
          },
          {
            "name": "北安里",
            "code": "'0042",
          }
        ]
      },
      {
        "name": "大同區",
        "code": "'060",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "玉泉里",
            "code": "'0001",
          },
          {
            "name": "建明里",
            "code": "'0002",
          },
          {
            "name": "建功里",
            "code": "'0003",
          },
          {
            "name": "建泰里",
            "code": "'0004",
          },
          {
            "name": "永樂里",
            "code": "'0005",
          },
          {
            "name": "朝陽里",
            "code": "'0006",
          },
          {
            "name": "星明里",
            "code": "'0007",
          },
          {
            "name": "光能里",
            "code": "'0008",
          },
          {
            "name": "大有里",
            "code": "'0009",
          },
          {
            "name": "延平里",
            "code": "'0010",
          },
          {
            "name": "雙連里",
            "code": "'0011",
          },
          {
            "name": "南芳里",
            "code": "'0012",
          },
          {
            "name": "民權里",
            "code": "'0013",
          },
          {
            "name": "景星里",
            "code": "'0014",
          },
          {
            "name": "隆和里",
            "code": "'0015",
          },
          {
            "name": "蓬萊里",
            "code": "'0016",
          },
          {
            "name": "國順里",
            "code": "'0017",
          },
          {
            "name": "國慶里",
            "code": "'0018",
          },
          {
            "name": "揚雅里",
            "code": "'0019",
          },
          {
            "name": "斯文里",
            "code": "'0020",
          },
          {
            "name": "鄰江里",
            "code": "'0021",
          },
          {
            "name": "至聖里",
            "code": "'0022",
          },
          {
            "name": "老師里",
            "code": "'0024",
          },
          {
            "name": "重慶里",
            "code": "'0025",
          },
          {
            "name": "保安里",
            "code": "'0026",
          }
        ]
      },
      {
        "name": "中正區",
        "code": "'050",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "水源里",
            "code": "'0001",
          },
          {
            "name": "富水里",
            "code": "'0002",
          },
          {
            "name": "文盛里",
            "code": "'0003",
          },
          {
            "name": "林興里",
            "code": "'0004",
          },
          {
            "name": "河堤里",
            "code": "'0005",
          },
          {
            "name": "頂東里",
            "code": "'0006",
          },
          {
            "name": "網溪里",
            "code": "'0007",
          },
          {
            "name": "板溪里",
            "code": "'0008",
          },
          {
            "name": "螢圃里",
            "code": "'0009",
          },
          {
            "name": "螢雪里",
            "code": "'0010",
          },
          {
            "name": "永功里",
            "code": "'0011",
          },
          {
            "name": "永昌里",
            "code": "'0012",
          },
          {
            "name": "龍興里",
            "code": "'0013",
          },
          {
            "name": "忠勤里",
            "code": "'0014",
          },
          {
            "name": "廈安里",
            "code": "'0015",
          },
          {
            "name": "愛國里",
            "code": "'0016",
          },
          {
            "name": "南門里",
            "code": "'0017",
          },
          {
            "name": "龍光里",
            "code": "'0018",
          },
          {
            "name": "南福里",
            "code": "'0019",
          },
          {
            "name": "龍福里",
            "code": "'0020",
          },
          {
            "name": "新營里",
            "code": "'0021",
          },
          {
            "name": "建國里",
            "code": "'0022",
          },
          {
            "name": "光復里",
            "code": "'0023",
          },
          {
            "name": "黎明里",
            "code": "'0024",
          },
          {
            "name": "梅花里",
            "code": "'0025",
          },
          {
            "name": "幸福里",
            "code": "'0026",
          },
          {
            "name": "幸市里",
            "code": "'0027",
          },
          {
            "name": "東門里",
            "code": "'0028",
          },
          {
            "name": "文北里",
            "code": "'0029",
          },
          {
            "name": "文祥里",
            "code": "'0030",
          },
          {
            "name": "三愛里",
            "code": "'0031",
          }
        ]
      },
      {
        "name": "萬華區",
        "code": "'070",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "福星里",
            "code": "'0001",
          },
          {
            "name": "萬壽里",
            "code": "'0002",
          },
          {
            "name": "西門里",
            "code": "'0003",
          },
          {
            "name": "新起里",
            "code": "'0004",
          },
          {
            "name": "菜園里",
            "code": "'0005",
          },
          {
            "name": "青山里",
            "code": "'0006",
          },
          {
            "name": "富民里",
            "code": "'0007",
          },
          {
            "name": "福音里",
            "code": "'0008",
          },
          {
            "name": "仁德里",
            "code": "'0009",
          },
          {
            "name": "富福里",
            "code": "'0010",
          },
          {
            "name": "頂碩里",
            "code": "'0011",
          },
          {
            "name": "雙園里",
            "code": "'0012",
          },
          {
            "name": "新忠里",
            "code": "'0013",
          },
          {
            "name": "新和里",
            "code": "'0014",
          },
          {
            "name": "糖廍里",
            "code": "'0015",
          },
          {
            "name": "和平里",
            "code": "'0016",
          },
          {
            "name": "綠堤里",
            "code": "'0017",
          },
          {
            "name": "華江里",
            "code": "'0018",
          },
          {
            "name": "柳鄉里",
            "code": "'0019",
          },
          {
            "name": "日善里",
            "code": "'0020",
          },
          {
            "name": "新安里",
            "code": "'0021",
          },
          {
            "name": "忠德里",
            "code": "'0022",
          },
          {
            "name": "孝德里",
            "code": "'0023",
          },
          {
            "name": "和德里",
            "code": "'0024",
          },
          {
            "name": "錦德里",
            "code": "'0025",
          },
          {
            "name": "全德里",
            "code": "'0026",
          },
          {
            "name": "保德里",
            "code": "'0027",
          },
          {
            "name": "銘德里",
            "code": "'0028",
          },
          {
            "name": "榮德里",
            "code": "'0029",
          },
          {
            "name": "壽德里",
            "code": "'0030",
          },
          {
            "name": "興德里",
            "code": "'0031",
          },
          {
            "name": "華中里",
            "code": "'0032",
          },
          {
            "name": "日祥里",
            "code": "'0033",
          },
          {
            "name": "忠貞里",
            "code": "'0034",
          },
          {
            "name": "凌霄里",
            "code": "'0035",
          },
          {
            "name": "騰雲里",
            "code": "'0036",
          }
        ]
      },
      {
        "name": "大安區",
        "code": "'030",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "德安里",
            "code": "'0001",
          },
          {
            "name": "仁慈里",
            "code": "'0002",
          },
          {
            "name": "和安里",
            "code": "'0003",
          },
          {
            "name": "民炤里",
            "code": "'0004",
          },
          {
            "name": "仁愛里",
            "code": "'0005",
          },
          {
            "name": "義村里",
            "code": "'0006",
          },
          {
            "name": "民輝里",
            "code": "'0007",
          },
          {
            "name": "昌隆里",
            "code": "'0008",
          },
          {
            "name": "誠安里",
            "code": "'0009",
          },
          {
            "name": "光武里",
            "code": "'0010",
          },
          {
            "name": "龍坡里",
            "code": "'0011",
          },
          {
            "name": "龍泉里",
            "code": "'0012",
          },
          {
            "name": "古風里",
            "code": "'0013",
          },
          {
            "name": "古莊里",
            "code": "'0014",
          },
          {
            "name": "龍安里",
            "code": "'0015",
          },
          {
            "name": "錦安里",
            "code": "'0016",
          },
          {
            "name": "福住里",
            "code": "'0017",
          },
          {
            "name": "永康里",
            "code": "'0018",
          },
          {
            "name": "光明里",
            "code": "'0019",
          },
          {
            "name": "錦泰里",
            "code": "'0020",
          },
          {
            "name": "錦華里",
            "code": "'0021",
          },
          {
            "name": "龍圖里",
            "code": "'0022",
          },
          {
            "name": "新龍里",
            "code": "'0023",
          },
          {
            "name": "龍陣里",
            "code": "'0026",
          },
          {
            "name": "龍雲里",
            "code": "'0027",
          },
          {
            "name": "龍生里",
            "code": "'0028",
          },
          {
            "name": "住安里",
            "code": "'0029",
          },
          {
            "name": "義安里",
            "code": "'0030",
          },
          {
            "name": "通化里",
            "code": "'0031",
          },
          {
            "name": "通安里",
            "code": "'0032",
          },
          {
            "name": "臨江里",
            "code": "'0033",
          },
          {
            "name": "法治里",
            "code": "'0034",
          },
          {
            "name": "全安里",
            "code": "'0035",
          },
          {
            "name": "群賢里",
            "code": "'0036",
          },
          {
            "name": "群英里",
            "code": "'0037",
          },
          {
            "name": "虎嘯里",
            "code": "'0038",
          },
          {
            "name": "臥龍里",
            "code": "'0039",
          },
          {
            "name": "龍淵里",
            "code": "'0040",
          },
          {
            "name": "龍門里",
            "code": "'0041",
          },
          {
            "name": "大學里",
            "code": "'0043",
          },
          {
            "name": "芳和里",
            "code": "'0044",
          },
          {
            "name": "黎元里",
            "code": "'0046",
          },
          {
            "name": "黎孝里",
            "code": "'0047",
          },
          {
            "name": "黎和里",
            "code": "'0048",
          },
          {
            "name": "建安里",
            "code": "'0049",
          },
          {
            "name": "建倫里",
            "code": "'0050",
          },
          {
            "name": "敦安里",
            "code": "'0051",
          },
          {
            "name": "正聲里",
            "code": "'0052",
          },
          {
            "name": "敦煌里",
            "code": "'0053",
          },
          {
            "name": "華聲里",
            "code": "'0054",
          },
          {
            "name": "車層里",
            "code": "'0055",
          },
          {
            "name": "光信里",
            "code": "'0056",
          },
          {
            "name": "學府里",
            "code": "'0057",
          }
        ]
      },
      {
        "name": "文山區",
        "code": "'080",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "景行里",
            "code": "'0001",
          },
          {
            "name": "景東里",
            "code": "'0002",
          },
          {
            "name": "景美里",
            "code": "'0003",
          },
          {
            "name": "景慶里",
            "code": "'0004",
          },
          {
            "name": "景仁里",
            "code": "'0005",
          },
          {
            "name": "景華里",
            "code": "'0006",
          },
          {
            "name": "萬有里",
            "code": "'0007",
          },
          {
            "name": "萬祥里",
            "code": "'0008",
          },
          {
            "name": "萬隆里",
            "code": "'0009",
          },
          {
            "name": "萬年里",
            "code": "'0010",
          },
          {
            "name": "萬和里",
            "code": "'0011",
          },
          {
            "name": "萬盛里",
            "code": "'0012",
          },
          {
            "name": "興豐里",
            "code": "'0013",
          },
          {
            "name": "興光里",
            "code": "'0014",
          },
          {
            "name": "興家里",
            "code": "'0015",
          },
          {
            "name": "興得里",
            "code": "'0016",
          },
          {
            "name": "興業里",
            "code": "'0017",
          },
          {
            "name": "興安里",
            "code": "'0018",
          },
          {
            "name": "興福里",
            "code": "'0019",
          },
          {
            "name": "興旺里",
            "code": "'0020",
          },
          {
            "name": "興泰里",
            "code": "'0021",
          },
          {
            "name": "興昌里",
            "code": "'0022",
          },
          {
            "name": "試院里",
            "code": "'0023",
          },
          {
            "name": "華興里",
            "code": "'0024",
          },
          {
            "name": "明義里",
            "code": "'0025",
          },
          {
            "name": "明興里",
            "code": "'0026",
          },
          {
            "name": "木柵里",
            "code": "'0027",
          },
          {
            "name": "木新里",
            "code": "'0028",
          },
          {
            "name": "順興里",
            "code": "'0029",
          },
          {
            "name": "樟林里",
            "code": "'0030",
          },
          {
            "name": "樟新里",
            "code": "'0031",
          },
          {
            "name": "樟腳里",
            "code": "'0032",
          },
          {
            "name": "萬芳里",
            "code": "'0033",
          },
          {
            "name": "博嘉里",
            "code": "'0034",
          },
          {
            "name": "萬興里",
            "code": "'0035",
          },
          {
            "name": "指南里",
            "code": "'0036",
          },
          {
            "name": "老泉里",
            "code": "'0037",
          },
          {
            "name": "忠順里",
            "code": "'0038",
          },
          {
            "name": "萬美里",
            "code": "'0039",
          },
          {
            "name": "政大里",
            "code": "'0040",
          },
          {
            "name": "樟文里",
            "code": "'0041",
          },
          {
            "name": "興邦里",
            "code": "'0042",
          },
          {
            "name": "樟樹里",
            "code": "'0043",
          }
        ]
      }
    ]
  },
  {
    "name": "新北市",
    "code": "'65'000",
    "subLocation": [
      {
        "name": "淡水區",
        "code": "'100",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "中和里",
            "code": "'0001",
          },
          {
            "name": "屯山里",
            "code": "'0002",
          },
          {
            "name": "賢孝里",
            "code": "'0003",
          },
          {
            "name": "興仁里",
            "code": "'0004",
          },
          {
            "name": "蕃薯里",
            "code": "'0005",
          },
          {
            "name": "義山里",
            "code": "'0006",
          },
          {
            "name": "忠山里",
            "code": "'0007",
          },
          {
            "name": "崁頂里",
            "code": "'0008",
          },
          {
            "name": "埤島里",
            "code": "'0009",
          },
          {
            "name": "新興里",
            "code": "'0010",
          },
          {
            "name": "水碓里",
            "code": "'0011",
          },
          {
            "name": "北投里",
            "code": "'0012",
          },
          {
            "name": "水源里",
            "code": "'0013",
          },
          {
            "name": "忠寮里",
            "code": "'0014",
          },
          {
            "name": "樹興里",
            "code": "'0015",
          },
          {
            "name": "坪頂里",
            "code": "'0016",
          },
          {
            "name": "福德里",
            "code": "'0017",
          },
          {
            "name": "竹圍里",
            "code": "'0018",
          },
          {
            "name": "民生里",
            "code": "'0019",
          },
          {
            "name": "八勢里",
            "code": "'0020",
          },
          {
            "name": "竿蓁里",
            "code": "'0021",
          },
          {
            "name": "鄧公里",
            "code": "'0022",
          },
          {
            "name": "中興里",
            "code": "'0023",
          },
          {
            "name": "長庚里",
            "code": "'0024",
          },
          {
            "name": "清文里",
            "code": "'0025",
          },
          {
            "name": "草東里",
            "code": "'0026",
          },
          {
            "name": "協元里",
            "code": "'0027",
          },
          {
            "name": "永吉里",
            "code": "'0028",
          },
          {
            "name": "民安里",
            "code": "'0029",
          },
          {
            "name": "新生里",
            "code": "'0030",
          },
          {
            "name": "文化里",
            "code": "'0031",
          },
          {
            "name": "油車里",
            "code": "'0032",
          },
          {
            "name": "沙崙里",
            "code": "'0033",
          },
          {
            "name": "新義里",
            "code": "'0034",
          },
          {
            "name": "新春里",
            "code": "'0035",
          },
          {
            "name": "新民里",
            "code": "'0036",
          },
          {
            "name": "正德里",
            "code": "'0037",
          },
          {
            "name": "北新里",
            "code": "'0038",
          },
          {
            "name": "民權里",
            "code": "'0039",
          },
          {
            "name": "幸福里",
            "code": "'0040",
          },
          {
            "name": "學府里",
            "code": "'0041",
          },
          {
            "name": "大庄里",
            "code": "'0042",
          }
        ]
      },
      {
        "name": "三芝區",
        "code": "'210",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "八賢里",
            "code": "'0001",
          },
          {
            "name": "埔頭里",
            "code": "'0002",
          },
          {
            "name": "古庄里",
            "code": "'0003",
          },
          {
            "name": "新庄里",
            "code": "'0004",
          },
          {
            "name": "埔坪里",
            "code": "'0005",
          },
          {
            "name": "茂長里",
            "code": "'0006",
          },
          {
            "name": "橫山里",
            "code": "'0007",
          },
          {
            "name": "錫板里",
            "code": "'0008",
          },
          {
            "name": "後厝里",
            "code": "'0009",
          },
          {
            "name": "福德里",
            "code": "'0010",
          },
          {
            "name": "圓山里",
            "code": "'0011",
          },
          {
            "name": "店子里",
            "code": "'0012",
          },
          {
            "name": "興華里",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "石門區",
        "code": "'220",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "德茂里",
            "code": "'0001",
          },
          {
            "name": "富基里",
            "code": "'0002",
          },
          {
            "name": "老梅里",
            "code": "'0003",
          },
          {
            "name": "山溪里",
            "code": "'0004",
          },
          {
            "name": "石門里",
            "code": "'0005",
          },
          {
            "name": "尖鹿里",
            "code": "'0006",
          },
          {
            "name": "乾華里",
            "code": "'0007",
          },
          {
            "name": "茂林里",
            "code": "'0008",
          },
          {
            "name": "草里里",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "八里區",
        "code": "'230",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "龍源里",
            "code": "'0001",
          },
          {
            "name": "米倉里",
            "code": "'0002",
          },
          {
            "name": "大崁里",
            "code": "'0003",
          },
          {
            "name": "埤頭里",
            "code": "'0004",
          },
          {
            "name": "頂罟里",
            "code": "'0005",
          },
          {
            "name": "舊城里",
            "code": "'0006",
          },
          {
            "name": "訊塘里",
            "code": "'0007",
          },
          {
            "name": "荖阡里",
            "code": "'0008",
          },
          {
            "name": "長坑里",
            "code": "'0009",
          },
          {
            "name": "下罟里",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "新莊區",
        "code": "'050",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "海山里",
            "code": "'0001",
          },
          {
            "name": "頭前里",
            "code": "'0002",
          },
          {
            "name": "國泰里",
            "code": "'0003",
          },
          {
            "name": "全安里",
            "code": "'0004",
          },
          {
            "name": "福基里",
            "code": "'0005",
          },
          {
            "name": "豐年里",
            "code": "'0006",
          },
          {
            "name": "忠孝里",
            "code": "'0007",
          },
          {
            "name": "恆安里",
            "code": "'0008",
          },
          {
            "name": "後港里",
            "code": "'0009",
          },
          {
            "name": "文衡里",
            "code": "'0010",
          },
          {
            "name": "中美里",
            "code": "'0011",
          },
          {
            "name": "八德里",
            "code": "'0012",
          },
          {
            "name": "興漢里",
            "code": "'0013",
          },
          {
            "name": "中和里",
            "code": "'0014",
          },
          {
            "name": "西盛里",
            "code": "'0015",
          },
          {
            "name": "榮和里",
            "code": "'0016",
          },
          {
            "name": "中泰里",
            "code": "'0017",
          },
          {
            "name": "瓊林里",
            "code": "'0018",
          },
          {
            "name": "文德里",
            "code": "'0019",
          },
          {
            "name": "中誠里",
            "code": "'0020",
          },
          {
            "name": "裕民里",
            "code": "'0021",
          },
          {
            "name": "文明里",
            "code": "'0022",
          },
          {
            "name": "中港里",
            "code": "'0023",
          },
          {
            "name": "富國里",
            "code": "'0024",
          },
          {
            "name": "思源里",
            "code": "'0025",
          },
          {
            "name": "立人里",
            "code": "'0026",
          },
          {
            "name": "南港里",
            "code": "'0027",
          },
          {
            "name": "仁愛里",
            "code": "'0028",
          },
          {
            "name": "立德里",
            "code": "'0029",
          },
          {
            "name": "信義里",
            "code": "'0030",
          },
          {
            "name": "立言里",
            "code": "'0031",
          },
          {
            "name": "民安里",
            "code": "'0032",
          },
          {
            "name": "和平里",
            "code": "'0033",
          },
          {
            "name": "立功里",
            "code": "'0034",
          },
          {
            "name": "民本里",
            "code": "'0035",
          },
          {
            "name": "化成里",
            "code": "'0036",
          },
          {
            "name": "立志里",
            "code": "'0037",
          },
          {
            "name": "光華里",
            "code": "'0038",
          },
          {
            "name": "思賢里",
            "code": "'0039",
          },
          {
            "name": "營盤里",
            "code": "'0040",
          },
          {
            "name": "光榮里",
            "code": "'0041",
          },
          {
            "name": "自強里",
            "code": "'0042",
          },
          {
            "name": "丹鳳里",
            "code": "'0043",
          },
          {
            "name": "光明里",
            "code": "'0044",
          },
          {
            "name": "自立里",
            "code": "'0045",
          },
          {
            "name": "合鳳里",
            "code": "'0046",
          },
          {
            "name": "幸福里",
            "code": "'0047",
          },
          {
            "name": "雙鳳里",
            "code": "'0048",
          },
          {
            "name": "自信里",
            "code": "'0049",
          },
          {
            "name": "龍鳳里",
            "code": "'0050",
          },
          {
            "name": "中華里",
            "code": "'0051",
          },
          {
            "name": "中隆里",
            "code": "'0052",
          },
          {
            "name": "中原里",
            "code": "'0053",
          },
          {
            "name": "中信里",
            "code": "'0054",
          },
          {
            "name": "四維里",
            "code": "'0055",
          },
          {
            "name": "建福里",
            "code": "'0056",
          },
          {
            "name": "中宏里",
            "code": "'0057",
          },
          {
            "name": "中全里",
            "code": "'0058",
          },
          {
            "name": "立泰里",
            "code": "'0059",
          },
          {
            "name": "全泰里",
            "code": "'0060",
          },
          {
            "name": "仁義里",
            "code": "'0061",
          },
          {
            "name": "立基里",
            "code": "'0062",
          },
          {
            "name": "昌明里",
            "code": "'0063",
          },
          {
            "name": "昌平里",
            "code": "'0064",
          },
          {
            "name": "文聖里",
            "code": "'0065",
          },
          {
            "name": "萬安里",
            "code": "'0066",
          },
          {
            "name": "龍安里",
            "code": "'0067",
          },
          {
            "name": "福營里",
            "code": "'0068",
          },
          {
            "name": "後德里",
            "code": "'0069",
          },
          {
            "name": "建安里",
            "code": "'0070",
          },
          {
            "name": "成德里",
            "code": "'0071",
          },
          {
            "name": "福興里",
            "code": "'0072",
          },
          {
            "name": "昌信里",
            "code": "'0073",
          },
          {
            "name": "昌隆里",
            "code": "'0074",
          },
          {
            "name": "中平里",
            "code": "'0075",
          },
          {
            "name": "立廷里",
            "code": "'0076",
          },
          {
            "name": "泰豐里",
            "code": "'0077",
          },
          {
            "name": "富民里",
            "code": "'0078",
          },
          {
            "name": "龍福里",
            "code": "'0079",
          },
          {
            "name": "祥鳳里",
            "code": "'0080",
          },
          {
            "name": "民全里",
            "code": "'0081",
          },
          {
            "name": "民有里",
            "code": "'0082",
          },
          {
            "name": "光正里",
            "code": "'0083",
          },
          {
            "name": "光和里",
            "code": "'0084",
          }
        ]
      },
      {
        "name": "五股區",
        "code": "'150",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "興珍里",
            "code": "'0001",
          },
          {
            "name": "更寮里",
            "code": "'0002",
          },
          {
            "name": "集福里",
            "code": "'0003",
          },
          {
            "name": "觀音里",
            "code": "'0004",
          },
          {
            "name": "成州里",
            "code": "'0005",
          },
          {
            "name": "成功里",
            "code": "'0006",
          },
          {
            "name": "五龍里",
            "code": "'0007",
          },
          {
            "name": "五股里",
            "code": "'0008",
          },
          {
            "name": "五福里",
            "code": "'0009",
          },
          {
            "name": "德音里",
            "code": "'0010",
          },
          {
            "name": "陸一里",
            "code": "'0011",
          },
          {
            "name": "貿商里",
            "code": "'0012",
          },
          {
            "name": "德泰里",
            "code": "'0013",
          },
          {
            "name": "成德里",
            "code": "'0014",
          },
          {
            "name": "六福里",
            "code": "'0015",
          },
          {
            "name": "集賢里",
            "code": "'0016",
          },
          {
            "name": "民義里",
            "code": "'0017",
          },
          {
            "name": "成泰里",
            "code": "'0018",
          },
          {
            "name": "水碓里",
            "code": "'0019",
          },
          {
            "name": "福德里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "泰山區",
        "code": "'160",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "大科里",
            "code": "'0001",
          },
          {
            "name": "黎明里",
            "code": "'0002",
          },
          {
            "name": "山腳里",
            "code": "'0003",
          },
          {
            "name": "福泰里",
            "code": "'0004",
          },
          {
            "name": "楓樹里",
            "code": "'0005",
          },
          {
            "name": "同榮里",
            "code": "'0006",
          },
          {
            "name": "義學里",
            "code": "'0007",
          },
          {
            "name": "明志里",
            "code": "'0008",
          },
          {
            "name": "貴子里",
            "code": "'0009",
          },
          {
            "name": "貴和里",
            "code": "'0010",
          },
          {
            "name": "同興里",
            "code": "'0011",
          },
          {
            "name": "義仁里",
            "code": "'0012",
          },
          {
            "name": "泰友里",
            "code": "'0013",
          },
          {
            "name": "新明里",
            "code": "'0014",
          },
          {
            "name": "貴賢里",
            "code": "'0015",
          },
          {
            "name": "福興里",
            "code": "'0016",
          },
          {
            "name": "全興里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "林口區",
        "code": "'170",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "東林里",
            "code": "'0001",
          },
          {
            "name": "林口里",
            "code": "'0002",
          },
          {
            "name": "西林里",
            "code": "'0003",
          },
          {
            "name": "菁湖里",
            "code": "'0004",
          },
          {
            "name": "中湖里",
            "code": "'0005",
          },
          {
            "name": "湖北里",
            "code": "'0006",
          },
          {
            "name": "湖南里",
            "code": "'0007",
          },
          {
            "name": "南勢里",
            "code": "'0008",
          },
          {
            "name": "東勢里",
            "code": "'0009",
          },
          {
            "name": "頂福里",
            "code": "'0010",
          },
          {
            "name": "下福里",
            "code": "'0011",
          },
          {
            "name": "嘉寶里",
            "code": "'0012",
          },
          {
            "name": "瑞平里",
            "code": "'0013",
          },
          {
            "name": "太平里",
            "code": "'0014",
          },
          {
            "name": "仁愛里",
            "code": "'0015",
          },
          {
            "name": "麗林里",
            "code": "'0016",
          },
          {
            "name": "麗園里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "三重區",
        "code": "'020",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "大有里",
            "code": "'0001",
          },
          {
            "name": "二重里",
            "code": "'0002",
          },
          {
            "name": "頂崁里",
            "code": "'0003",
          },
          {
            "name": "谷王里",
            "code": "'0004",
          },
          {
            "name": "中興里",
            "code": "'0005",
          },
          {
            "name": "德厚里",
            "code": "'0006",
          },
          {
            "name": "成功里",
            "code": "'0007",
          },
          {
            "name": "福祉里",
            "code": "'0008",
          },
          {
            "name": "福民里",
            "code": "'0009",
          },
          {
            "name": "過田里",
            "code": "'0010",
          },
          {
            "name": "錦田里",
            "code": "'0011",
          },
          {
            "name": "重明里",
            "code": "'0012",
          },
          {
            "name": "光田里",
            "code": "'0013",
          },
          {
            "name": "光陽里",
            "code": "'0014",
          },
          {
            "name": "田心里",
            "code": "'0015",
          },
          {
            "name": "福田里",
            "code": "'0016",
          },
          {
            "name": "田中里",
            "code": "'0017",
          },
          {
            "name": "田安里",
            "code": "'0018",
          },
          {
            "name": "三民里",
            "code": "'0019",
          },
          {
            "name": "光明里",
            "code": "'0020",
          },
          {
            "name": "光正里",
            "code": "'0021",
          },
          {
            "name": "同安里",
            "code": "'0022",
          },
          {
            "name": "同慶里",
            "code": "'0023",
          },
          {
            "name": "菜寮里",
            "code": "'0024",
          },
          {
            "name": "永春里",
            "code": "'0025",
          },
          {
            "name": "中正里",
            "code": "'0026",
          },
          {
            "name": "吉利里",
            "code": "'0027",
          },
          {
            "name": "大同里",
            "code": "'0028",
          },
          {
            "name": "中民里",
            "code": "'0029",
          },
          {
            "name": "平和里",
            "code": "'0030",
          },
          {
            "name": "大安里",
            "code": "'0031",
          },
          {
            "name": "仁德里",
            "code": "'0032",
          },
          {
            "name": "忠孝里",
            "code": "'0033",
          },
          {
            "name": "仁義里",
            "code": "'0034",
          },
          {
            "name": "光榮里",
            "code": "'0035",
          },
          {
            "name": "光輝里",
            "code": "'0036",
          },
          {
            "name": "福星里",
            "code": "'0037",
          },
          {
            "name": "福利里",
            "code": "'0038",
          },
          {
            "name": "清和里",
            "code": "'0039",
          },
          {
            "name": "永興里",
            "code": "'0040",
          },
          {
            "name": "中山里",
            "code": "'0041",
          },
          {
            "name": "大園里",
            "code": "'0042",
          },
          {
            "name": "國隆里",
            "code": "'0043",
          },
          {
            "name": "重陽里",
            "code": "'0044",
          },
          {
            "name": "民生里",
            "code": "'0045",
          },
          {
            "name": "重新里",
            "code": "'0046",
          },
          {
            "name": "正德里",
            "code": "'0047",
          },
          {
            "name": "正義里",
            "code": "'0048",
          },
          {
            "name": "正安里",
            "code": "'0049",
          },
          {
            "name": "自強里",
            "code": "'0050",
          },
          {
            "name": "文化里",
            "code": "'0051",
          },
          {
            "name": "中央里",
            "code": "'0052",
          },
          {
            "name": "雙園里",
            "code": "'0053",
          },
          {
            "name": "錦通里",
            "code": "'0054",
          },
          {
            "name": "錦安里",
            "code": "'0055",
          },
          {
            "name": "長安里",
            "code": "'0056",
          },
          {
            "name": "光華里",
            "code": "'0057",
          },
          {
            "name": "長生里",
            "code": "'0058",
          },
          {
            "name": "開元里",
            "code": "'0059",
          },
          {
            "name": "大德里",
            "code": "'0060",
          },
          {
            "name": "長元里",
            "code": "'0061",
          },
          {
            "name": "長江里",
            "code": "'0062",
          },
          {
            "name": "長泰里",
            "code": "'0063",
          },
          {
            "name": "長福里",
            "code": "'0064",
          },
          {
            "name": "介壽里",
            "code": "'0065",
          },
          {
            "name": "萬壽里",
            "code": "'0066",
          },
          {
            "name": "錦江里",
            "code": "'0067",
          },
          {
            "name": "龍濱里",
            "code": "'0068",
          },
          {
            "name": "龍門里",
            "code": "'0069",
          },
          {
            "name": "秀江里",
            "code": "'0070",
          },
          {
            "name": "奕壽里",
            "code": "'0071",
          },
          {
            "name": "三安里",
            "code": "'0072",
          },
          {
            "name": "福安里",
            "code": "'0073",
          },
          {
            "name": "信安里",
            "code": "'0074",
          },
          {
            "name": "安慶里",
            "code": "'0075",
          },
          {
            "name": "幸福里",
            "code": "'0076",
          },
          {
            "name": "六合里",
            "code": "'0077",
          },
          {
            "name": "六福里",
            "code": "'0078",
          },
          {
            "name": "順德里",
            "code": "'0079",
          },
          {
            "name": "承德里",
            "code": "'0080",
          },
          {
            "name": "瑞德里",
            "code": "'0081",
          },
          {
            "name": "崇德里",
            "code": "'0082",
          },
          {
            "name": "厚德里",
            "code": "'0083",
          },
          {
            "name": "維德里",
            "code": "'0084",
          },
          {
            "name": "尚德里",
            "code": "'0085",
          },
          {
            "name": "培德里",
            "code": "'0086",
          },
          {
            "name": "永德里",
            "code": "'0087",
          },
          {
            "name": "永盛里",
            "code": "'0088",
          },
          {
            "name": "永福里",
            "code": "'0089",
          },
          {
            "name": "永清里",
            "code": "'0090",
          },
          {
            "name": "永安里",
            "code": "'0091",
          },
          {
            "name": "永煇里",
            "code": "'0092",
          },
          {
            "name": "溪美里",
            "code": "'0093",
          },
          {
            "name": "福隆里",
            "code": "'0094",
          },
          {
            "name": "五常里",
            "code": "'0095",
          },
          {
            "name": "五福里",
            "code": "'0096",
          },
          {
            "name": "仁忠里",
            "code": "'0097",
          },
          {
            "name": "慈福里",
            "code": "'0098",
          },
          {
            "name": "慈生里",
            "code": "'0099",
          },
          {
            "name": "慈惠里",
            "code": "'0100",
          },
          {
            "name": "慈愛里",
            "code": "'0101",
          },
          {
            "name": "慈化里",
            "code": "'0102",
          },
          {
            "name": "慈祐里",
            "code": "'0103",
          },
          {
            "name": "五華里",
            "code": "'0104",
          },
          {
            "name": "富貴里",
            "code": "'0105",
          },
          {
            "name": "富華里",
            "code": "'0106",
          },
          {
            "name": "富福里",
            "code": "'0107",
          },
          {
            "name": "碧華里",
            "code": "'0108",
          },
          {
            "name": "五谷里",
            "code": "'0109",
          },
          {
            "name": "博愛里",
            "code": "'0110",
          },
          {
            "name": "福德里",
            "code": "'0111",
          },
          {
            "name": "永吉里",
            "code": "'0112",
          },
          {
            "name": "永發里",
            "code": "'0113",
          },
          {
            "name": "永順里",
            "code": "'0114",
          },
          {
            "name": "永豐里",
            "code": "'0115",
          },
          {
            "name": "立德里",
            "code": "'0116",
          },
          {
            "name": "福樂里",
            "code": "'0117",
          },
          {
            "name": "仁華里",
            "code": "'0118",
          },
          {
            "name": "五順里",
            "code": "'0119",
          }
        ]
      },
      {
        "name": "蘆洲區",
        "code": "'140",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "正義里",
            "code": "'0001",
          },
          {
            "name": "中原里",
            "code": "'0002",
          },
          {
            "name": "中路里",
            "code": "'0003",
          },
          {
            "name": "光華里",
            "code": "'0004",
          },
          {
            "name": "保佑里",
            "code": "'0005",
          },
          {
            "name": "水湳里",
            "code": "'0006",
          },
          {
            "name": "樓厝里",
            "code": "'0007",
          },
          {
            "name": "鷺江里",
            "code": "'0008",
          },
          {
            "name": "恆德里",
            "code": "'0009",
          },
          {
            "name": "溪墘里",
            "code": "'0010",
          },
          {
            "name": "樹德里",
            "code": "'0011",
          },
          {
            "name": "保和里",
            "code": "'0012",
          },
          {
            "name": "仁復里",
            "code": "'0013",
          },
          {
            "name": "得勝里",
            "code": "'0014",
          },
          {
            "name": "南港里",
            "code": "'0015",
          },
          {
            "name": "永樂里",
            "code": "'0016",
          },
          {
            "name": "永康里",
            "code": "'0017",
          },
          {
            "name": "信義里",
            "code": "'0018",
          },
          {
            "name": "玉清里",
            "code": "'0019",
          },
          {
            "name": "復興里",
            "code": "'0020",
          },
          {
            "name": "水河里",
            "code": "'0021",
          },
          {
            "name": "仁愛里",
            "code": "'0022",
          },
          {
            "name": "忠義里",
            "code": "'0023",
          },
          {
            "name": "九芎里",
            "code": "'0024",
          },
          {
            "name": "中華里",
            "code": "'0025",
          },
          {
            "name": "得仁里",
            "code": "'0026",
          },
          {
            "name": "仁德里",
            "code": "'0027",
          },
          {
            "name": "仁義里",
            "code": "'0028",
          },
          {
            "name": "光明里",
            "code": "'0029",
          },
          {
            "name": "保新里",
            "code": "'0030",
          },
          {
            "name": "長安里",
            "code": "'0031",
          },
          {
            "name": "民和里",
            "code": "'0032",
          },
          {
            "name": "忠孝里",
            "code": "'0033",
          },
          {
            "name": "福安里",
            "code": "'0034",
          },
          {
            "name": "永安里",
            "code": "'0035",
          },
          {
            "name": "延平里",
            "code": "'0036",
          },
          {
            "name": "成功里",
            "code": "'0037",
          },
          {
            "name": "永德里",
            "code": "'0038",
          }
        ]
      },
      {
        "name": "板橋區",
        "code": "'010",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "留侯里",
            "code": "'0001",
          },
          {
            "name": "流芳里",
            "code": "'0002",
          },
          {
            "name": "赤松里",
            "code": "'0003",
          },
          {
            "name": "黃石里",
            "code": "'0004",
          },
          {
            "name": "挹秀里",
            "code": "'0005",
          },
          {
            "name": "湳興里",
            "code": "'0006",
          },
          {
            "name": "新興里",
            "code": "'0007",
          },
          {
            "name": "社後里",
            "code": "'0008",
          },
          {
            "name": "香社里",
            "code": "'0009",
          },
          {
            "name": "中正里",
            "code": "'0010",
          },
          {
            "name": "自強里",
            "code": "'0011",
          },
          {
            "name": "自立里",
            "code": "'0012",
          },
          {
            "name": "光華里",
            "code": "'0013",
          },
          {
            "name": "國光里",
            "code": "'0014",
          },
          {
            "name": "港尾里",
            "code": "'0015",
          },
          {
            "name": "金華里",
            "code": "'0016",
          },
          {
            "name": "港德里",
            "code": "'0017",
          },
          {
            "name": "民權里",
            "code": "'0018",
          },
          {
            "name": "建國里",
            "code": "'0019",
          },
          {
            "name": "漢生里",
            "code": "'0020",
          },
          {
            "name": "公舘里",
            "code": "'0021",
          },
          {
            "name": "新民里",
            "code": "'0022",
          },
          {
            "name": "幸福里",
            "code": "'0023",
          },
          {
            "name": "忠誠里",
            "code": "'0024",
          },
          {
            "name": "百壽里",
            "code": "'0025",
          },
          {
            "name": "介壽里",
            "code": "'0026",
          },
          {
            "name": "新埔里",
            "code": "'0027",
          },
          {
            "name": "華江里",
            "code": "'0028",
          },
          {
            "name": "江翠里",
            "code": "'0029",
          },
          {
            "name": "純翠里",
            "code": "'0030",
          },
          {
            "name": "溪頭里",
            "code": "'0031",
          },
          {
            "name": "聯翠里",
            "code": "'0032",
          },
          {
            "name": "宏翠里",
            "code": "'0033",
          },
          {
            "name": "仁翠里",
            "code": "'0034",
          },
          {
            "name": "新翠里",
            "code": "'0035",
          },
          {
            "name": "吉翠里",
            "code": "'0036",
          },
          {
            "name": "德翠里",
            "code": "'0037",
          },
          {
            "name": "滿翠里",
            "code": "'0038",
          },
          {
            "name": "明翠里",
            "code": "'0039",
          },
          {
            "name": "松翠里",
            "code": "'0040",
          },
          {
            "name": "柏翠里",
            "code": "'0041",
          },
          {
            "name": "龍翠里",
            "code": "'0042",
          },
          {
            "name": "華翠里",
            "code": "'0043",
          },
          {
            "name": "忠翠里",
            "code": "'0044",
          },
          {
            "name": "嵐翠里",
            "code": "'0045",
          },
          {
            "name": "文翠里",
            "code": "'0046",
          },
          {
            "name": "青翠里",
            "code": "'0047",
          },
          {
            "name": "懷翠里",
            "code": "'0048",
          },
          {
            "name": "福翠里",
            "code": "'0049",
          },
          {
            "name": "港嘴里",
            "code": "'0050",
          },
          {
            "name": "振興里",
            "code": "'0051",
          },
          {
            "name": "振義里",
            "code": "'0052",
          },
          {
            "name": "光復里",
            "code": "'0053",
          },
          {
            "name": "埔墘里",
            "code": "'0054",
          },
          {
            "name": "長壽里",
            "code": "'0055",
          },
          {
            "name": "福壽里",
            "code": "'0056",
          },
          {
            "name": "海山里",
            "code": "'0057",
          },
          {
            "name": "九如里",
            "code": "'0058",
          },
          {
            "name": "玉光里",
            "code": "'0059",
          },
          {
            "name": "光仁里",
            "code": "'0060",
          },
          {
            "name": "埤墘里",
            "code": "'0061",
          },
          {
            "name": "永安里",
            "code": "'0062",
          },
          {
            "name": "雙玉里",
            "code": "'0063",
          },
          {
            "name": "廣新里",
            "code": "'0064",
          },
          {
            "name": "深丘里",
            "code": "'0065",
          },
          {
            "name": "香丘里",
            "code": "'0066",
          },
          {
            "name": "西安里",
            "code": "'0067",
          },
          {
            "name": "東丘里",
            "code": "'0068",
          },
          {
            "name": "長安里",
            "code": "'0069",
          },
          {
            "name": "福丘里",
            "code": "'0070",
          },
          {
            "name": "福祿里",
            "code": "'0071",
          },
          {
            "name": "民族里",
            "code": "'0072",
          },
          {
            "name": "國泰里",
            "code": "'0073",
          },
          {
            "name": "福德里",
            "code": "'0074",
          },
          {
            "name": "景星里",
            "code": "'0075",
          },
          {
            "name": "福星里",
            "code": "'0076",
          },
          {
            "name": "鄉雲里",
            "code": "'0077",
          },
          {
            "name": "廣德里",
            "code": "'0078",
          },
          {
            "name": "大豐里",
            "code": "'0079",
          },
          {
            "name": "重慶里",
            "code": "'0080",
          },
          {
            "name": "仁愛里",
            "code": "'0081",
          },
          {
            "name": "和平里",
            "code": "'0082",
          },
          {
            "name": "廣福里",
            "code": "'0083",
          },
          {
            "name": "華興里",
            "code": "'0084",
          },
          {
            "name": "華貴里",
            "code": "'0085",
          },
          {
            "name": "華德里",
            "code": "'0086",
          },
          {
            "name": "華東里",
            "code": "'0087",
          },
          {
            "name": "信義里",
            "code": "'0088",
          },
          {
            "name": "浮洲里",
            "code": "'0089",
          },
          {
            "name": "華中里",
            "code": "'0090",
          },
          {
            "name": "僑中里",
            "code": "'0091",
          },
          {
            "name": "中山里",
            "code": "'0092",
          },
          {
            "name": "復興里",
            "code": "'0093",
          },
          {
            "name": "大安里",
            "code": "'0094",
          },
          {
            "name": "福安里",
            "code": "'0095",
          },
          {
            "name": "聚安里",
            "code": "'0096",
          },
          {
            "name": "龍安里",
            "code": "'0097",
          },
          {
            "name": "崑崙里",
            "code": "'0098",
          },
          {
            "name": "溪洲里",
            "code": "'0099",
          },
          {
            "name": "溪北里",
            "code": "'0100",
          },
          {
            "name": "香雅里",
            "code": "'0101",
          },
          {
            "name": "新生里",
            "code": "'0102",
          },
          {
            "name": "文德里",
            "code": "'0103",
          },
          {
            "name": "文化里",
            "code": "'0104",
          },
          {
            "name": "新海里",
            "code": "'0105",
          },
          {
            "name": "莒光里",
            "code": "'0106",
          },
          {
            "name": "富貴里",
            "code": "'0107",
          },
          {
            "name": "正泰里",
            "code": "'0108",
          },
          {
            "name": "民生里",
            "code": "'0109",
          },
          {
            "name": "後埔里",
            "code": "'0110",
          },
          {
            "name": "華福里",
            "code": "'0111",
          },
          {
            "name": "堂春里",
            "code": "'0112",
          },
          {
            "name": "成和里",
            "code": "'0113",
          },
          {
            "name": "光榮里",
            "code": "'0114",
          },
          {
            "name": "民安里",
            "code": "'0115",
          },
          {
            "name": "陽明里",
            "code": "'0116",
          },
          {
            "name": "朝陽里",
            "code": "'0117",
          },
          {
            "name": "松柏里",
            "code": "'0118",
          },
          {
            "name": "文聖里",
            "code": "'0119",
          },
          {
            "name": "居仁里",
            "code": "'0120",
          },
          {
            "name": "莊敬里",
            "code": "'0121",
          },
          {
            "name": "東安里",
            "code": "'0122",
          },
          {
            "name": "五權里",
            "code": "'0123",
          },
          {
            "name": "溪福里",
            "code": "'0124",
          },
          {
            "name": "大觀里",
            "code": "'0125",
          },
          {
            "name": "歡園里",
            "code": "'0126",
          }
        ]
      },
      {
        "name": "中和區",
        "code": "'030",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "中原里",
            "code": "'0001",
          },
          {
            "name": "平河里",
            "code": "'0002",
          },
          {
            "name": "信和里",
            "code": "'0003",
          },
          {
            "name": "仁和里",
            "code": "'0004",
          },
          {
            "name": "中正里",
            "code": "'0005",
          },
          {
            "name": "建和里",
            "code": "'0006",
          },
          {
            "name": "連和里",
            "code": "'0007",
          },
          {
            "name": "連城里",
            "code": "'0008",
          },
          {
            "name": "力行里",
            "code": "'0009",
          },
          {
            "name": "枋寮里",
            "code": "'0010",
          },
          {
            "name": "漳和里",
            "code": "'0011",
          },
          {
            "name": "廟美里",
            "code": "'0012",
          },
          {
            "name": "福真里",
            "code": "'0013",
          },
          {
            "name": "福善里",
            "code": "'0014",
          },
          {
            "name": "福美里",
            "code": "'0015",
          },
          {
            "name": "福祥里",
            "code": "'0016",
          },
          {
            "name": "瓦磘里",
            "code": "'0017",
          },
          {
            "name": "福和里",
            "code": "'0018",
          },
          {
            "name": "佳和里",
            "code": "'0019",
          },
          {
            "name": "安樂里",
            "code": "'0020",
          },
          {
            "name": "安和里",
            "code": "'0021",
          },
          {
            "name": "安平里",
            "code": "'0022",
          },
          {
            "name": "中安里",
            "code": "'0023",
          },
          {
            "name": "泰安里",
            "code": "'0024",
          },
          {
            "name": "新南里",
            "code": "'0025",
          },
          {
            "name": "南山里",
            "code": "'0026",
          },
          {
            "name": "秀山里",
            "code": "'0027",
          },
          {
            "name": "秀成里",
            "code": "'0028",
          },
          {
            "name": "秀福里",
            "code": "'0029",
          },
          {
            "name": "秀景里",
            "code": "'0030",
          },
          {
            "name": "秀明里",
            "code": "'0031",
          },
          {
            "name": "秀峰里",
            "code": "'0032",
          },
          {
            "name": "秀仁里",
            "code": "'0033",
          },
          {
            "name": "頂南里",
            "code": "'0034",
          },
          {
            "name": "華新里",
            "code": "'0035",
          },
          {
            "name": "東南里",
            "code": "'0036",
          },
          {
            "name": "華南里",
            "code": "'0037",
          },
          {
            "name": "忠孝里",
            "code": "'0038",
          },
          {
            "name": "崇南里",
            "code": "'0039",
          },
          {
            "name": "景南里",
            "code": "'0040",
          },
          {
            "name": "橫路里",
            "code": "'0041",
          },
          {
            "name": "內南里",
            "code": "'0042",
          },
          {
            "name": "壽南里",
            "code": "'0043",
          },
          {
            "name": "外南里",
            "code": "'0044",
          },
          {
            "name": "復興里",
            "code": "'0045",
          },
          {
            "name": "和興里",
            "code": "'0046",
          },
          {
            "name": "景平里",
            "code": "'0047",
          },
          {
            "name": "景新里",
            "code": "'0048",
          },
          {
            "name": "景福里",
            "code": "'0049",
          },
          {
            "name": "景安里",
            "code": "'0050",
          },
          {
            "name": "景文里",
            "code": "'0051",
          },
          {
            "name": "錦和里",
            "code": "'0052",
          },
          {
            "name": "錦昌里",
            "code": "'0053",
          },
          {
            "name": "灰磘里",
            "code": "'0054",
          },
          {
            "name": "積穗里",
            "code": "'0055",
          },
          {
            "name": "民享里",
            "code": "'0056",
          },
          {
            "name": "員山里",
            "code": "'0057",
          },
          {
            "name": "嘉穗里",
            "code": "'0058",
          },
          {
            "name": "文元里",
            "code": "'0059",
          },
          {
            "name": "嘉新里",
            "code": "'0060",
          },
          {
            "name": "民安里",
            "code": "'0061",
          },
          {
            "name": "安穗里",
            "code": "'0062",
          },
          {
            "name": "瑞穗里",
            "code": "'0063",
          },
          {
            "name": "明穗里",
            "code": "'0064",
          },
          {
            "name": "清穗里",
            "code": "'0065",
          },
          {
            "name": "自強里",
            "code": "'0066",
          },
          {
            "name": "德穗里",
            "code": "'0067",
          },
          {
            "name": "民生里",
            "code": "'0068",
          },
          {
            "name": "國光里",
            "code": "'0069",
          },
          {
            "name": "壽德里",
            "code": "'0070",
          },
          {
            "name": "明德里",
            "code": "'0071",
          },
          {
            "name": "秀水里",
            "code": "'0072",
          },
          {
            "name": "德行里",
            "code": "'0073",
          },
          {
            "name": "宜安里",
            "code": "'0074",
          },
          {
            "name": "安順里",
            "code": "'0075",
          },
          {
            "name": "秀義里",
            "code": "'0076",
          },
          {
            "name": "秀士里",
            "code": "'0077",
          },
          {
            "name": "興南里",
            "code": "'0078",
          },
          {
            "name": "景本里",
            "code": "'0079",
          },
          {
            "name": "福南里",
            "code": "'0080",
          },
          {
            "name": "中興里",
            "code": "'0081",
          },
          {
            "name": "吉興里",
            "code": "'0082",
          },
          {
            "name": "中山里",
            "code": "'0083",
          },
          {
            "name": "碧河里",
            "code": "'0084",
          },
          {
            "name": "錦中里",
            "code": "'0085",
          },
          {
            "name": "錦盛里",
            "code": "'0086",
          },
          {
            "name": "民有里",
            "code": "'0087",
          },
          {
            "name": "員富里",
            "code": "'0088",
          },
          {
            "name": "嘉慶里",
            "code": "'0089",
          },
          {
            "name": "冠穗里",
            "code": "'0090",
          },
          {
            "name": "國華里",
            "code": "'0091",
          },
          {
            "name": "正南里",
            "code": "'0092",
          },
          {
            "name": "正行里",
            "code": "'0093",
          }
        ]
      },
      {
        "name": "永和區",
        "code": "'040",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "福林里",
            "code": "'0001",
          },
          {
            "name": "文化里",
            "code": "'0002",
          },
          {
            "name": "豫溪里",
            "code": "'0003",
          },
          {
            "name": "光明里",
            "code": "'0004",
          },
          {
            "name": "仁愛里",
            "code": "'0005",
          },
          {
            "name": "永貞里",
            "code": "'0006",
          },
          {
            "name": "永福里",
            "code": "'0007",
          },
          {
            "name": "民治里",
            "code": "'0008",
          },
          {
            "name": "永成里",
            "code": "'0009",
          },
          {
            "name": "忠義里",
            "code": "'0010",
          },
          {
            "name": "永元里",
            "code": "'0011",
          },
          {
            "name": "秀林里",
            "code": "'0012",
          },
          {
            "name": "保安里",
            "code": "'0013",
          },
          {
            "name": "中溪里",
            "code": "'0014",
          },
          {
            "name": "永安里",
            "code": "'0015",
          },
          {
            "name": "民族里",
            "code": "'0016",
          },
          {
            "name": "民權里",
            "code": "'0017",
          },
          {
            "name": "中興里",
            "code": "'0018",
          },
          {
            "name": "勵行里",
            "code": "'0019",
          },
          {
            "name": "秀朗里",
            "code": "'0020",
          },
          {
            "name": "秀成里",
            "code": "'0021",
          },
          {
            "name": "上溪里",
            "code": "'0022",
          },
          {
            "name": "竹林里",
            "code": "'0023",
          },
          {
            "name": "雙和里",
            "code": "'0024",
          },
          {
            "name": "安和里",
            "code": "'0025",
          },
          {
            "name": "水源里",
            "code": "'0026",
          },
          {
            "name": "協和里",
            "code": "'0027",
          },
          {
            "name": "河堤里",
            "code": "'0028",
          },
          {
            "name": "頂溪里",
            "code": "'0029",
          },
          {
            "name": "潭墘里",
            "code": "'0030",
          },
          {
            "name": "潭安里",
            "code": "'0031",
          },
          {
            "name": "大新里",
            "code": "'0032",
          },
          {
            "name": "保平里",
            "code": "'0033",
          },
          {
            "name": "民本里",
            "code": "'0034",
          },
          {
            "name": "民樂里",
            "code": "'0035",
          },
          {
            "name": "光復里",
            "code": "'0036",
          },
          {
            "name": "復興里",
            "code": "'0037",
          },
          {
            "name": "福和里",
            "code": "'0038",
          },
          {
            "name": "得和里",
            "code": "'0039",
          },
          {
            "name": "智光里",
            "code": "'0040",
          },
          {
            "name": "新生里",
            "code": "'0041",
          },
          {
            "name": "大同里",
            "code": "'0042",
          },
          {
            "name": "前溪里",
            "code": "'0043",
          },
          {
            "name": "和平里",
            "code": "'0044",
          },
          {
            "name": "新廍里",
            "code": "'0045",
          },
          {
            "name": "下溪里",
            "code": "'0046",
          },
          {
            "name": "秀和里",
            "code": "'0047",
          },
          {
            "name": "河濱里",
            "code": "'0048",
          },
          {
            "name": "民生里",
            "code": "'0049",
          },
          {
            "name": "秀得里",
            "code": "'0050",
          },
          {
            "name": "上林里",
            "code": "'0051",
          },
          {
            "name": "網溪里",
            "code": "'0052",
          },
          {
            "name": "正興里",
            "code": "'0053",
          },
          {
            "name": "永興里",
            "code": "'0054",
          },
          {
            "name": "桂林里",
            "code": "'0055",
          },
          {
            "name": "店街里",
            "code": "'0056",
          },
          {
            "name": "信義里",
            "code": "'0057",
          },
          {
            "name": "後溪里",
            "code": "'0058",
          },
          {
            "name": "民富里",
            "code": "'0059",
          },
          {
            "name": "秀元里",
            "code": "'0060",
          },
          {
            "name": "永樂里",
            "code": "'0061",
          },
          {
            "name": "保順里",
            "code": "'0062",
          }
        ]
      },
      {
        "name": "樹林區",
        "code": "'070",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "三多里",
            "code": "'0001",
          },
          {
            "name": "三福里",
            "code": "'0002",
          },
          {
            "name": "三興里",
            "code": "'0003",
          },
          {
            "name": "圳安里",
            "code": "'0004",
          },
          {
            "name": "保安里",
            "code": "'0005",
          },
          {
            "name": "潭底里",
            "code": "'0006",
          },
          {
            "name": "獇寮里",
            "code": "'0007",
          },
          {
            "name": "樹德里",
            "code": "'0008",
          },
          {
            "name": "樹西里",
            "code": "'0009",
          },
          {
            "name": "樹南里",
            "code": "'0010",
          },
          {
            "name": "樹人里",
            "code": "'0011",
          },
          {
            "name": "坡內里",
            "code": "'0012",
          },
          {
            "name": "樹東里",
            "code": "'0013",
          },
          {
            "name": "大同里",
            "code": "'0014",
          },
          {
            "name": "和平里",
            "code": "'0015",
          },
          {
            "name": "彭厝里",
            "code": "'0016",
          },
          {
            "name": "彭福里",
            "code": "'0017",
          },
          {
            "name": "東山里",
            "code": "'0018",
          },
          {
            "name": "東陽里",
            "code": "'0019",
          },
          {
            "name": "東昇里",
            "code": "'0020",
          },
          {
            "name": "樂山里",
            "code": "'0021",
          },
          {
            "name": "東園里",
            "code": "'0022",
          },
          {
            "name": "西園里",
            "code": "'0023",
          },
          {
            "name": "南園里",
            "code": "'0024",
          },
          {
            "name": "北園里",
            "code": "'0025",
          },
          {
            "name": "柑園里",
            "code": "'0026",
          },
          {
            "name": "樹北里",
            "code": "'0027",
          },
          {
            "name": "圳福里",
            "code": "'0028",
          },
          {
            "name": "樹興里",
            "code": "'0029",
          },
          {
            "name": "樹福里",
            "code": "'0030",
          },
          {
            "name": "光興里",
            "code": "'0031",
          },
          {
            "name": "金寮里",
            "code": "'0032",
          },
          {
            "name": "彭興里",
            "code": "'0033",
          },
          {
            "name": "文林里",
            "code": "'0034",
          },
          {
            "name": "山佳里",
            "code": "'0035",
          },
          {
            "name": "中山里",
            "code": "'0036",
          },
          {
            "name": "育英里",
            "code": "'0037",
          },
          {
            "name": "中華里",
            "code": "'0038",
          },
          {
            "name": "太順里",
            "code": "'0039",
          },
          {
            "name": "圳民里",
            "code": "'0040",
          },
          {
            "name": "圳生里",
            "code": "'0041",
          },
          {
            "name": "三龍里",
            "code": "'0042",
          }
        ]
      },
      {
        "name": "鶯歌區",
        "code": "'080",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "東鶯里",
            "code": "'0001",
          },
          {
            "name": "西鶯里",
            "code": "'0002",
          },
          {
            "name": "南鶯里",
            "code": "'0003",
          },
          {
            "name": "北鶯里",
            "code": "'0004",
          },
          {
            "name": "中鶯里",
            "code": "'0005",
          },
          {
            "name": "建德里",
            "code": "'0006",
          },
          {
            "name": "同慶里",
            "code": "'0007",
          },
          {
            "name": "永昌里",
            "code": "'0008",
          },
          {
            "name": "南靖里",
            "code": "'0009",
          },
          {
            "name": "尖山里",
            "code": "'0010",
          },
          {
            "name": "二橋里",
            "code": "'0011",
          },
          {
            "name": "二甲里",
            "code": "'0012",
          },
          {
            "name": "東湖里",
            "code": "'0013",
          },
          {
            "name": "大湖里",
            "code": "'0014",
          },
          {
            "name": "鳳鳴里",
            "code": "'0015",
          },
          {
            "name": "鳳福里",
            "code": "'0016",
          },
          {
            "name": "永吉里",
            "code": "'0017",
          },
          {
            "name": "中湖里",
            "code": "'0018",
          },
          {
            "name": "鳳祥里",
            "code": "'0019",
          },
          {
            "name": "建國里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "三峽區",
        "code": "'090",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "三峽里",
            "code": "'0001",
          },
          {
            "name": "秀川里",
            "code": "'0002",
          },
          {
            "name": "八張里",
            "code": "'0003",
          },
          {
            "name": "中埔里",
            "code": "'0004",
          },
          {
            "name": "弘道里",
            "code": "'0005",
          },
          {
            "name": "永舘里",
            "code": "'0006",
          },
          {
            "name": "礁溪里",
            "code": "'0007",
          },
          {
            "name": "鳶山里",
            "code": "'0008",
          },
          {
            "name": "龍埔里",
            "code": "'0009",
          },
          {
            "name": "大埔里",
            "code": "'0010",
          },
          {
            "name": "嘉添里",
            "code": "'0011",
          },
          {
            "name": "添福里",
            "code": "'0012",
          },
          {
            "name": "金圳里",
            "code": "'0013",
          },
          {
            "name": "五寮里",
            "code": "'0014",
          },
          {
            "name": "插角里",
            "code": "'0015",
          },
          {
            "name": "有木里",
            "code": "'0016",
          },
          {
            "name": "溪北里",
            "code": "'0017",
          },
          {
            "name": "溪南里",
            "code": "'0018",
          },
          {
            "name": "溪東里",
            "code": "'0019",
          },
          {
            "name": "成福里",
            "code": "'0020",
          },
          {
            "name": "安坑里",
            "code": "'0021",
          },
          {
            "name": "竹崙里",
            "code": "'0022",
          },
          {
            "name": "介壽里",
            "code": "'0023",
          },
          {
            "name": "安溪里",
            "code": "'0024",
          },
          {
            "name": "中正里",
            "code": "'0025",
          },
          {
            "name": "二鬮里",
            "code": "'0026",
          },
          {
            "name": "龍學里",
            "code": "'0027",
          },
          {
            "name": "龍恩里",
            "code": "'0028",
          }
        ]
      },
      {
        "name": "土城區",
        "code": "'130",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "埤塘里",
            "code": "'0001",
          },
          {
            "name": "土城里",
            "code": "'0002",
          },
          {
            "name": "員林里",
            "code": "'0003",
          },
          {
            "name": "員仁里",
            "code": "'0004",
          },
          {
            "name": "長風里",
            "code": "'0005",
          },
          {
            "name": "日新里",
            "code": "'0006",
          },
          {
            "name": "日和里",
            "code": "'0007",
          },
          {
            "name": "貨饒里",
            "code": "'0008",
          },
          {
            "name": "柑林里",
            "code": "'0009",
          },
          {
            "name": "埤林里",
            "code": "'0010",
          },
          {
            "name": "瑞興里",
            "code": "'0011",
          },
          {
            "name": "清水里",
            "code": "'0012",
          },
          {
            "name": "清和里",
            "code": "'0013",
          },
          {
            "name": "永豐里",
            "code": "'0014",
          },
          {
            "name": "清化里",
            "code": "'0015",
          },
          {
            "name": "清溪里",
            "code": "'0016",
          },
          {
            "name": "峯廷里",
            "code": "'0017",
          },
          {
            "name": "平和里",
            "code": "'0018",
          },
          {
            "name": "廷寮里",
            "code": "'0019",
          },
          {
            "name": "大安里",
            "code": "'0020",
          },
          {
            "name": "永寧里",
            "code": "'0021",
          },
          {
            "name": "沛陂里",
            "code": "'0022",
          },
          {
            "name": "頂埔里",
            "code": "'0023",
          },
          {
            "name": "頂福里",
            "code": "'0024",
          },
          {
            "name": "頂新里",
            "code": "'0025",
          },
          {
            "name": "祖田里",
            "code": "'0026",
          },
          {
            "name": "樂利里",
            "code": "'0027",
          },
          {
            "name": "廣福里",
            "code": "'0028",
          },
          {
            "name": "學府里",
            "code": "'0029",
          },
          {
            "name": "延壽里",
            "code": "'0030",
          },
          {
            "name": "安和里",
            "code": "'0031",
          },
          {
            "name": "青雲里",
            "code": "'0032",
          },
          {
            "name": "員福里",
            "code": "'0033",
          },
          {
            "name": "延吉里",
            "code": "'0034",
          },
          {
            "name": "復興里",
            "code": "'0035",
          },
          {
            "name": "裕生里",
            "code": "'0036",
          },
          {
            "name": "員信里",
            "code": "'0037",
          },
          {
            "name": "永富里",
            "code": "'0038",
          },
          {
            "name": "學成里",
            "code": "'0039",
          },
          {
            "name": "延和里",
            "code": "'0040",
          },
          {
            "name": "中正里",
            "code": "'0041",
          },
          {
            "name": "學士里",
            "code": "'0042",
          },
          {
            "name": "明德里",
            "code": "'0043",
          },
          {
            "name": "廣興里",
            "code": "'0044",
          },
          {
            "name": "青山里",
            "code": "'0045",
          },
          {
            "name": "金城里",
            "code": "'0046",
          },
          {
            "name": "延祿里",
            "code": "'0047",
          }
        ]
      },
      {
        "name": "新店區",
        "code": "'060",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "國豐里",
            "code": "'0001",
          },
          {
            "name": "明德里",
            "code": "'0002",
          },
          {
            "name": "大豐里",
            "code": "'0003",
          },
          {
            "name": "大同里",
            "code": "'0004",
          },
          {
            "name": "江陵里",
            "code": "'0005",
          },
          {
            "name": "寶興里",
            "code": "'0006",
          },
          {
            "name": "寶安里",
            "code": "'0007",
          },
          {
            "name": "信義里",
            "code": "'0008",
          },
          {
            "name": "忠孝里",
            "code": "'0009",
          },
          {
            "name": "大鵬里",
            "code": "'0010",
          },
          {
            "name": "太平里",
            "code": "'0011",
          },
          {
            "name": "頂城里",
            "code": "'0012",
          },
          {
            "name": "下城里",
            "code": "'0013",
          },
          {
            "name": "柴埕里",
            "code": "'0014",
          },
          {
            "name": "永安里",
            "code": "'0015",
          },
          {
            "name": "公崙里",
            "code": "'0016",
          },
          {
            "name": "德安里",
            "code": "'0017",
          },
          {
            "name": "雙城里",
            "code": "'0018",
          },
          {
            "name": "日興里",
            "code": "'0019",
          },
          {
            "name": "玫瑰里",
            "code": "'0020",
          },
          {
            "name": "塗潭里",
            "code": "'0021",
          },
          {
            "name": "美潭里",
            "code": "'0022",
          },
          {
            "name": "員潭里",
            "code": "'0023",
          },
          {
            "name": "雙坑里",
            "code": "'0024",
          },
          {
            "name": "粗坑里",
            "code": "'0025",
          },
          {
            "name": "屈尺里",
            "code": "'0026",
          },
          {
            "name": "龜山里",
            "code": "'0027",
          },
          {
            "name": "廣興里",
            "code": "'0028",
          },
          {
            "name": "直潭里",
            "code": "'0029",
          },
          {
            "name": "青潭里",
            "code": "'0030",
          },
          {
            "name": "新店里",
            "code": "'0031",
          },
          {
            "name": "張南里",
            "code": "'0032",
          },
          {
            "name": "國校里",
            "code": "'0033",
          },
          {
            "name": "文中里",
            "code": "'0034",
          },
          {
            "name": "廣明里",
            "code": "'0035",
          },
          {
            "name": "文明里",
            "code": "'0036",
          },
          {
            "name": "中興里",
            "code": "'0037",
          },
          {
            "name": "新生里",
            "code": "'0038",
          },
          {
            "name": "新德里",
            "code": "'0039",
          },
          {
            "name": "張北里",
            "code": "'0040",
          },
          {
            "name": "五峯里",
            "code": "'0041",
          },
          {
            "name": "新安里",
            "code": "'0042",
          },
          {
            "name": "忠誠里",
            "code": "'0043",
          },
          {
            "name": "中正里",
            "code": "'0044",
          },
          {
            "name": "中華里",
            "code": "'0045",
          },
          {
            "name": "仁愛里",
            "code": "'0046",
          },
          {
            "name": "百忍里",
            "code": "'0047",
          },
          {
            "name": "百和里",
            "code": "'0048",
          },
          {
            "name": "福德里",
            "code": "'0049",
          },
          {
            "name": "百福里",
            "code": "'0050",
          },
          {
            "name": "福民里",
            "code": "'0051",
          },
          {
            "name": "中山里",
            "code": "'0052",
          },
          {
            "name": "和平里",
            "code": "'0053",
          },
          {
            "name": "中央里",
            "code": "'0054",
          },
          {
            "name": "明城里",
            "code": "'0055",
          },
          {
            "name": "安和里",
            "code": "'0056",
          },
          {
            "name": "吉祥里",
            "code": "'0057",
          },
          {
            "name": "寶福里",
            "code": "'0058",
          },
          {
            "name": "小城里",
            "code": "'0059",
          },
          {
            "name": "香坡里",
            "code": "'0060",
          },
          {
            "name": "建國里",
            "code": "'0061",
          },
          {
            "name": "復興里",
            "code": "'0062",
          },
          {
            "name": "美城里",
            "code": "'0063",
          },
          {
            "name": "安昌里",
            "code": "'0064",
          },
          {
            "name": "長春里",
            "code": "'0065",
          },
          {
            "name": "新和里",
            "code": "'0066",
          },
          {
            "name": "永平里",
            "code": "'0067",
          },
          {
            "name": "達觀里",
            "code": "'0068",
          },
          {
            "name": "華城里",
            "code": "'0069",
          }
        ]
      },
      {
        "name": "深坑區",
        "code": "'180",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "深坑里",
            "code": "'0001",
          },
          {
            "name": "萬順里",
            "code": "'0002",
          },
          {
            "name": "土庫里",
            "code": "'0003",
          },
          {
            "name": "昇高里",
            "code": "'0004",
          },
          {
            "name": "阿柔里",
            "code": "'0005",
          },
          {
            "name": "埔新里",
            "code": "'0006",
          },
          {
            "name": "萬福里",
            "code": "'0007",
          },
          {
            "name": "賴仲里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "石碇區",
        "code": "'190",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "石碇里",
            "code": "'0001",
          },
          {
            "name": "潭邊里",
            "code": "'0002",
          },
          {
            "name": "隆盛里",
            "code": "'0003",
          },
          {
            "name": "烏塗里",
            "code": "'0004",
          },
          {
            "name": "彭山里",
            "code": "'0005",
          },
          {
            "name": "豐田里",
            "code": "'0006",
          },
          {
            "name": "永定里",
            "code": "'0007",
          },
          {
            "name": "中民里",
            "code": "'0008",
          },
          {
            "name": "光明里",
            "code": "'0009",
          },
          {
            "name": "豐林里",
            "code": "'0010",
          },
          {
            "name": "永安里",
            "code": "'0011",
          },
          {
            "name": "格頭里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "坪林區",
        "code": "'200",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "粗窟里",
            "code": "'0001",
          },
          {
            "name": "坪林里",
            "code": "'0002",
          },
          {
            "name": "大林里",
            "code": "'0003",
          },
          {
            "name": "石𥕢里",
            "code": "'0004",
          },
          {
            "name": "上德里",
            "code": "'0005",
          },
          {
            "name": "水德里",
            "code": "'0006",
          },
          {
            "name": "漁光里",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "烏來區",
        "code": "'290",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "烏來里",
            "code": "'0001",
          },
          {
            "name": "忠治里",
            "code": "'0002",
          },
          {
            "name": "福山里",
            "code": "'0003",
          },
          {
            "name": "信賢里",
            "code": "'0004",
          },
          {
            "name": "孝義里",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "瑞芳區",
        "code": "'120",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "龍潭里",
            "code": "'0001",
          },
          {
            "name": "龍興里",
            "code": "'0002",
          },
          {
            "name": "龍鎮里",
            "code": "'0003",
          },
          {
            "name": "龍川里",
            "code": "'0004",
          },
          {
            "name": "龍山里",
            "code": "'0005",
          },
          {
            "name": "龍安里",
            "code": "'0006",
          },
          {
            "name": "爪峯里",
            "code": "'0007",
          },
          {
            "name": "柑坪里",
            "code": "'0008",
          },
          {
            "name": "東和里",
            "code": "'0009",
          },
          {
            "name": "上天里",
            "code": "'0010",
          },
          {
            "name": "吉慶里",
            "code": "'0011",
          },
          {
            "name": "吉安里",
            "code": "'0012",
          },
          {
            "name": "傑魚里",
            "code": "'0013",
          },
          {
            "name": "猴硐里",
            "code": "'0014",
          },
          {
            "name": "光復里",
            "code": "'0015",
          },
          {
            "name": "弓橋里",
            "code": "'0016",
          },
          {
            "name": "碩仁里",
            "code": "'0017",
          },
          {
            "name": "基山里",
            "code": "'0018",
          },
          {
            "name": "永慶里",
            "code": "'0019",
          },
          {
            "name": "崇文里",
            "code": "'0020",
          },
          {
            "name": "福住里",
            "code": "'0021",
          },
          {
            "name": "頌德里",
            "code": "'0022",
          },
          {
            "name": "新山里",
            "code": "'0023",
          },
          {
            "name": "瓜山里",
            "code": "'0024",
          },
          {
            "name": "銅山里",
            "code": "'0025",
          },
          {
            "name": "石山里",
            "code": "'0026",
          },
          {
            "name": "濓新里",
            "code": "'0027",
          },
          {
            "name": "濓洞里",
            "code": "'0028",
          },
          {
            "name": "海濱里",
            "code": "'0029",
          },
          {
            "name": "瑞濱里",
            "code": "'0030",
          },
          {
            "name": "深澳里",
            "code": "'0031",
          },
          {
            "name": "南雅里",
            "code": "'0032",
          },
          {
            "name": "鼻頭里",
            "code": "'0033",
          },
          {
            "name": "新峰里",
            "code": "'0034",
          }
        ]
      },
      {
        "name": "平溪區",
        "code": "'240",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "薯榔里",
            "code": "'0001",
          },
          {
            "name": "菁桐里",
            "code": "'0002",
          },
          {
            "name": "白石里",
            "code": "'0003",
          },
          {
            "name": "石底里",
            "code": "'0004",
          },
          {
            "name": "平溪里",
            "code": "'0005",
          },
          {
            "name": "嶺腳里",
            "code": "'0006",
          },
          {
            "name": "東勢里",
            "code": "'0007",
          },
          {
            "name": "望古里",
            "code": "'0008",
          },
          {
            "name": "南山里",
            "code": "'0009",
          },
          {
            "name": "平湖里",
            "code": "'0010",
          },
          {
            "name": "十分里",
            "code": "'0011",
          },
          {
            "name": "新寮里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "雙溪區",
        "code": "'250",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "雙溪里",
            "code": "'0001",
          },
          {
            "name": "共和里",
            "code": "'0002",
          },
          {
            "name": "新基里",
            "code": "'0003",
          },
          {
            "name": "魚行里",
            "code": "'0004",
          },
          {
            "name": "三港里",
            "code": "'0005",
          },
          {
            "name": "牡丹里",
            "code": "'0006",
          },
          {
            "name": "三貂里",
            "code": "'0007",
          },
          {
            "name": "平林里",
            "code": "'0008",
          },
          {
            "name": "上林里",
            "code": "'0009",
          },
          {
            "name": "外柑里",
            "code": "'0010",
          },
          {
            "name": "長源里",
            "code": "'0011",
          },
          {
            "name": "泰平里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "貢寮區",
        "code": "'260",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "貢寮里",
            "code": "'0001",
          },
          {
            "name": "吉林里",
            "code": "'0002",
          },
          {
            "name": "雙玉里",
            "code": "'0003",
          },
          {
            "name": "龍崗里",
            "code": "'0004",
          },
          {
            "name": "龍門里",
            "code": "'0005",
          },
          {
            "name": "福隆里",
            "code": "'0006",
          },
          {
            "name": "仁里里",
            "code": "'0007",
          },
          {
            "name": "真理里",
            "code": "'0008",
          },
          {
            "name": "福連里",
            "code": "'0009",
          },
          {
            "name": "美豐里",
            "code": "'0010",
          },
          {
            "name": "和美里",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "汐止區",
        "code": "'110",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "義民里",
            "code": "'0001",
          },
          {
            "name": "禮門里",
            "code": "'0002",
          },
          {
            "name": "智慧里",
            "code": "'0003",
          },
          {
            "name": "信望里",
            "code": "'0004",
          },
          {
            "name": "橋東里",
            "code": "'0005",
          },
          {
            "name": "秀峰里",
            "code": "'0006",
          },
          {
            "name": "新昌里",
            "code": "'0007",
          },
          {
            "name": "復興里",
            "code": "'0008",
          },
          {
            "name": "茄苳里",
            "code": "'0009",
          },
          {
            "name": "長安里",
            "code": "'0010",
          },
          {
            "name": "保安里",
            "code": "'0011",
          },
          {
            "name": "保長里",
            "code": "'0012",
          },
          {
            "name": "鄉長里",
            "code": "'0013",
          },
          {
            "name": "江北里",
            "code": "'0014",
          },
          {
            "name": "拱北里",
            "code": "'0015",
          },
          {
            "name": "烘內里",
            "code": "'0016",
          },
          {
            "name": "八連里",
            "code": "'0017",
          },
          {
            "name": "樟樹里",
            "code": "'0018",
          },
          {
            "name": "北峰里",
            "code": "'0019",
          },
          {
            "name": "北山里",
            "code": "'0020",
          },
          {
            "name": "白雲里",
            "code": "'0021",
          },
          {
            "name": "橫科里",
            "code": "'0022",
          },
          {
            "name": "東山里",
            "code": "'0023",
          },
          {
            "name": "福山里",
            "code": "'0024",
          },
          {
            "name": "宜興里",
            "code": "'0025",
          },
          {
            "name": "中興里",
            "code": "'0026",
          },
          {
            "name": "湖光里",
            "code": "'0027",
          },
          {
            "name": "仁德里",
            "code": "'0028",
          },
          {
            "name": "厚德里",
            "code": "'0029",
          },
          {
            "name": "忠孝里",
            "code": "'0030",
          },
          {
            "name": "自強里",
            "code": "'0031",
          },
          {
            "name": "文化里",
            "code": "'0032",
          },
          {
            "name": "金龍里",
            "code": "'0033",
          },
          {
            "name": "福德里",
            "code": "'0034",
          },
          {
            "name": "興福里",
            "code": "'0035",
          },
          {
            "name": "康福里",
            "code": "'0036",
          },
          {
            "name": "忠山里",
            "code": "'0037",
          },
          {
            "name": "環河里",
            "code": "'0038",
          },
          {
            "name": "東勢里",
            "code": "'0039",
          },
          {
            "name": "山光里",
            "code": "'0040",
          },
          {
            "name": "大同里",
            "code": "'0041",
          },
          {
            "name": "崇德里",
            "code": "'0042",
          },
          {
            "name": "湖興里",
            "code": "'0043",
          },
          {
            "name": "長青里",
            "code": "'0044",
          },
          {
            "name": "福安里",
            "code": "'0045",
          },
          {
            "name": "秀山里",
            "code": "'0046",
          },
          {
            "name": "建成里",
            "code": "'0047",
          },
          {
            "name": "城中里",
            "code": "'0048",
          },
          {
            "name": "湖蓮里",
            "code": "'0049",
          },
          {
            "name": "保新里",
            "code": "'0050",
          }
        ]
      },
      {
        "name": "金山區",
        "code": "'270",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "和平里",
            "code": "'0001",
          },
          {
            "name": "大同里",
            "code": "'0002",
          },
          {
            "name": "豐漁里",
            "code": "'0003",
          },
          {
            "name": "磺港里",
            "code": "'0004",
          },
          {
            "name": "美田里",
            "code": "'0005",
          },
          {
            "name": "五湖里",
            "code": "'0006",
          },
          {
            "name": "重和里",
            "code": "'0007",
          },
          {
            "name": "兩湖里",
            "code": "'0008",
          },
          {
            "name": "六股里",
            "code": "'0009",
          },
          {
            "name": "三界里",
            "code": "'0010",
          },
          {
            "name": "清泉里",
            "code": "'0011",
          },
          {
            "name": "萬壽里",
            "code": "'0012",
          },
          {
            "name": "西湖里",
            "code": "'0013",
          },
          {
            "name": "永興里",
            "code": "'0014",
          },
          {
            "name": "金美里",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "萬里區",
        "code": "'280",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "萬里里",
            "code": "'0001",
          },
          {
            "name": "龜吼里",
            "code": "'0002",
          },
          {
            "name": "野柳里",
            "code": "'0003",
          },
          {
            "name": "大鵬里",
            "code": "'0004",
          },
          {
            "name": "磺潭里",
            "code": "'0005",
          },
          {
            "name": "雙興里",
            "code": "'0006",
          },
          {
            "name": "溪底里",
            "code": "'0007",
          },
          {
            "name": "崁脚里",
            "code": "'0008",
          },
          {
            "name": "中幅里",
            "code": "'0009",
          },
          {
            "name": "北基里",
            "code": "'0010",
          }
        ]
      }
    ]
  },
  {
    "name": "桃園市",
    "code": "'68'000",
    "subLocation": [
      {
        "name": "桃園區",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "文化里",
            "code": "'0001",
          },
          {
            "name": "文明里",
            "code": "'0002",
          },
          {
            "name": "武陵里",
            "code": "'0003",
          },
          {
            "name": "大林里",
            "code": "'0004",
          },
          {
            "name": "忠義里",
            "code": "'0005",
          },
          {
            "name": "會稽里",
            "code": "'0006",
          },
          {
            "name": "長美里",
            "code": "'0007",
          },
          {
            "name": "民生里",
            "code": "'0008",
          },
          {
            "name": "永興里",
            "code": "'0009",
          },
          {
            "name": "汴洲里",
            "code": "'0010",
          },
          {
            "name": "永安里",
            "code": "'0011",
          },
          {
            "name": "東埔里",
            "code": "'0012",
          },
          {
            "name": "東門里",
            "code": "'0013",
          },
          {
            "name": "東山里",
            "code": "'0014",
          },
          {
            "name": "萬壽里",
            "code": "'0015",
          },
          {
            "name": "長安里",
            "code": "'0016",
          },
          {
            "name": "中埔里",
            "code": "'0017",
          },
          {
            "name": "西埔里",
            "code": "'0018",
          },
          {
            "name": "西門里",
            "code": "'0019",
          },
          {
            "name": "中和里",
            "code": "'0020",
          },
          {
            "name": "文昌里",
            "code": "'0021",
          },
          {
            "name": "新埔里",
            "code": "'0022",
          },
          {
            "name": "中山里",
            "code": "'0023",
          },
          {
            "name": "慈文里",
            "code": "'0024",
          },
          {
            "name": "西湖里",
            "code": "'0025",
          },
          {
            "name": "南門里",
            "code": "'0026",
          },
          {
            "name": "南華里",
            "code": "'0027",
          },
          {
            "name": "玉山里",
            "code": "'0028",
          },
          {
            "name": "中成里",
            "code": "'0029",
          },
          {
            "name": "中正里",
            "code": "'0030",
          },
          {
            "name": "北門里",
            "code": "'0031",
          },
          {
            "name": "雲林里",
            "code": "'0032",
          },
          {
            "name": "福林里",
            "code": "'0033",
          },
          {
            "name": "光興里",
            "code": "'0034",
          },
          {
            "name": "龍山里",
            "code": "'0035",
          },
          {
            "name": "龍岡里",
            "code": "'0036",
          },
          {
            "name": "成功里",
            "code": "'0037",
          },
          {
            "name": "青溪里",
            "code": "'0038",
          },
          {
            "name": "朝陽里",
            "code": "'0039",
          },
          {
            "name": "中興里",
            "code": "'0040",
          },
          {
            "name": "豐林里",
            "code": "'0041",
          },
          {
            "name": "建國里",
            "code": "'0042",
          },
          {
            "name": "大豐里",
            "code": "'0043",
          },
          {
            "name": "福安里",
            "code": "'0044",
          },
          {
            "name": "三民里",
            "code": "'0045",
          },
          {
            "name": "大興里",
            "code": "'0046",
          },
          {
            "name": "春日里",
            "code": "'0047",
          },
          {
            "name": "信光里",
            "code": "'0048",
          },
          {
            "name": "中寧里",
            "code": "'0049",
          },
          {
            "name": "同安里",
            "code": "'0050",
          },
          {
            "name": "莊敬里",
            "code": "'0051",
          },
          {
            "name": "文中里",
            "code": "'0052",
          },
          {
            "name": "泰山里",
            "code": "'0053",
          },
          {
            "name": "中路里",
            "code": "'0054",
          },
          {
            "name": "中德里",
            "code": "'0055",
          },
          {
            "name": "龍祥里",
            "code": "'0056",
          },
          {
            "name": "大有里",
            "code": "'0057",
          },
          {
            "name": "寶山里",
            "code": "'0058",
          },
          {
            "name": "龍鳳里",
            "code": "'0059",
          },
          {
            "name": "龍安里",
            "code": "'0060",
          },
          {
            "name": "自強里",
            "code": "'0061",
          },
          {
            "name": "中信里",
            "code": "'0062",
          },
          {
            "name": "長德里",
            "code": "'0063",
          },
          {
            "name": "中平里",
            "code": "'0064",
          },
          {
            "name": "中原里",
            "code": "'0065",
          },
          {
            "name": "中泰里",
            "code": "'0066",
          },
          {
            "name": "南埔里",
            "code": "'0067",
          },
          {
            "name": "寶慶里",
            "code": "'0068",
          },
          {
            "name": "北埔里",
            "code": "'0069",
          },
          {
            "name": "中聖里",
            "code": "'0070",
          },
          {
            "name": "龍壽里",
            "code": "'0071",
          },
          {
            "name": "同德里",
            "code": "'0072",
          },
          {
            "name": "明德里",
            "code": "'0073",
          },
          {
            "name": "寶安里",
            "code": "'0074",
          },
          {
            "name": "瑞慶里",
            "code": "'0075",
          },
          {
            "name": "三元里",
            "code": "'0076",
          },
          {
            "name": "大樹里",
            "code": "'0077",
          },
          {
            "name": "大業里",
            "code": "'0078",
          },
          {
            "name": "福元里",
            "code": "'0079",
          }
        ]
      },
      {
        "name": "龜山區",
        "code": "'070",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "楓樹里",
            "code": "'0001",
          },
          {
            "name": "龜山里",
            "code": "'0002",
          },
          {
            "name": "兔坑里",
            "code": "'0003",
          },
          {
            "name": "大同里",
            "code": "'0004",
          },
          {
            "name": "山福里",
            "code": "'0005",
          },
          {
            "name": "山頂里",
            "code": "'0006",
          },
          {
            "name": "陸光里",
            "code": "'0007",
          },
          {
            "name": "精忠里",
            "code": "'0008",
          },
          {
            "name": "公西里",
            "code": "'0009",
          },
          {
            "name": "樂善里",
            "code": "'0010",
          },
          {
            "name": "南上里",
            "code": "'0011",
          },
          {
            "name": "大坑里",
            "code": "'0012",
          },
          {
            "name": "中興里",
            "code": "'0013",
          },
          {
            "name": "山德里",
            "code": "'0014",
          },
          {
            "name": "新路里",
            "code": "'0015",
          },
          {
            "name": "嶺頂里",
            "code": "'0016",
          },
          {
            "name": "龍壽里",
            "code": "'0017",
          },
          {
            "name": "舊路里",
            "code": "'0018",
          },
          {
            "name": "大崗里",
            "code": "'0019",
          },
          {
            "name": "文化里",
            "code": "'0020",
          },
          {
            "name": "南美里",
            "code": "'0021",
          },
          {
            "name": "幸福里",
            "code": "'0022",
          },
          {
            "name": "福源里",
            "code": "'0023",
          },
          {
            "name": "龍華里",
            "code": "'0024",
          },
          {
            "name": "迴龍里",
            "code": "'0025",
          },
          {
            "name": "新嶺里",
            "code": "'0026",
          },
          {
            "name": "大湖里",
            "code": "'0027",
          },
          {
            "name": "大華里",
            "code": "'0028",
          },
          {
            "name": "長庚里",
            "code": "'0029",
          },
          {
            "name": "新興里",
            "code": "'0030",
          },
          {
            "name": "文青里",
            "code": "'0031",
          },
          {
            "name": "楓福里",
            "code": "'0032",
          }
        ]
      },
      {
        "name": "八德區",
        "code": "'080",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "興仁里",
            "code": "'0001",
          },
          {
            "name": "福興里",
            "code": "'0002",
          },
          {
            "name": "瑞豐里",
            "code": "'0003",
          },
          {
            "name": "瑞發里",
            "code": "'0004",
          },
          {
            "name": "瑞德里",
            "code": "'0005",
          },
          {
            "name": "瑞祥里",
            "code": "'0006",
          },
          {
            "name": "廣興里",
            "code": "'0007",
          },
          {
            "name": "廣隆里",
            "code": "'0008",
          },
          {
            "name": "竹園里",
            "code": "'0009",
          },
          {
            "name": "霄裡里",
            "code": "'0010",
          },
          {
            "name": "茄苳里",
            "code": "'0011",
          },
          {
            "name": "高明里",
            "code": "'0012",
          },
          {
            "name": "白鷺里",
            "code": "'0013",
          },
          {
            "name": "大湳里",
            "code": "'0014",
          },
          {
            "name": "大安里",
            "code": "'0015",
          },
          {
            "name": "大興里",
            "code": "'0016",
          },
          {
            "name": "大和里",
            "code": "'0017",
          },
          {
            "name": "大同里",
            "code": "'0018",
          },
          {
            "name": "大成里",
            "code": "'0019",
          },
          {
            "name": "大義里",
            "code": "'0020",
          },
          {
            "name": "大忠里",
            "code": "'0021",
          },
          {
            "name": "大福里",
            "code": "'0022",
          },
          {
            "name": "大勇里",
            "code": "'0023",
          },
          {
            "name": "大信里",
            "code": "'0024",
          },
          {
            "name": "大華里",
            "code": "'0025",
          },
          {
            "name": "大仁里",
            "code": "'0026",
          },
          {
            "name": "大智里",
            "code": "'0027",
          },
          {
            "name": "瑞泰里",
            "code": "'0028",
          },
          {
            "name": "大昌里",
            "code": "'0029",
          },
          {
            "name": "大正里",
            "code": "'0030",
          },
          {
            "name": "大強里",
            "code": "'0031",
          },
          {
            "name": "大發里",
            "code": "'0032",
          },
          {
            "name": "大明里",
            "code": "'0033",
          },
          {
            "name": "大竹里",
            "code": "'0034",
          },
          {
            "name": "大慶里",
            "code": "'0035",
          },
          {
            "name": "大千里",
            "code": "'0036",
          },
          {
            "name": "大榮里",
            "code": "'0037",
          },
          {
            "name": "茄明里",
            "code": "'0038",
          },
          {
            "name": "高城里",
            "code": "'0039",
          },
          {
            "name": "瑞興里",
            "code": "'0040",
          },
          {
            "name": "大愛里",
            "code": "'0041",
          },
          {
            "name": "大宏里",
            "code": "'0042",
          },
          {
            "name": "大漢里",
            "code": "'0043",
          },
          {
            "name": "廣德里",
            "code": "'0044",
          },
          {
            "name": "龍友里",
            "code": "'0045",
          },
          {
            "name": "陸光里",
            "code": "'0046",
          },
          {
            "name": "永豐里",
            "code": "'0047",
          },
          {
            "name": "大順里",
            "code": "'0048",
          }
        ]
      },
      {
        "name": "蘆竹區",
        "code": "'050",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "新興里",
            "code": "'0001",
          },
          {
            "name": "中福里",
            "code": "'0002",
          },
          {
            "name": "新莊里",
            "code": "'0003",
          },
          {
            "name": "大竹里",
            "code": "'0004",
          },
          {
            "name": "蘆竹里",
            "code": "'0006",
          },
          {
            "name": "南崁里",
            "code": "'0007",
          },
          {
            "name": "錦興里",
            "code": "'0008",
          },
          {
            "name": "五福里",
            "code": "'0009",
          },
          {
            "name": "內厝里",
            "code": "'0010",
          },
          {
            "name": "山鼻里",
            "code": "'0011",
          },
          {
            "name": "山腳里",
            "code": "'0012",
          },
          {
            "name": "外社里",
            "code": "'0013",
          },
          {
            "name": "坑子里",
            "code": "'0014",
          },
          {
            "name": "坑口里",
            "code": "'0015",
          },
          {
            "name": "海湖里",
            "code": "'0016",
          },
          {
            "name": "南榮里",
            "code": "'0017",
          },
          {
            "name": "南興里",
            "code": "'0018",
          },
          {
            "name": "長興里",
            "code": "'0019",
          },
          {
            "name": "錦中里",
            "code": "'0020",
          },
          {
            "name": "富竹里",
            "code": "'0021",
          },
          {
            "name": "上竹里",
            "code": "'0022",
          },
          {
            "name": "宏竹里",
            "code": "'0023",
          },
          {
            "name": "瓦窯里",
            "code": "'0024",
          },
          {
            "name": "營盤里",
            "code": "'0025",
          },
          {
            "name": "羊稠里",
            "code": "'0026",
          },
          {
            "name": "順興里",
            "code": "'0027",
          },
          {
            "name": "福昌里",
            "code": "'0028",
          },
          {
            "name": "福興里",
            "code": "'0029",
          },
          {
            "name": "蘆興里",
            "code": "'0030",
          },
          {
            "name": "長壽里",
            "code": "'0031",
          },
          {
            "name": "吉祥里",
            "code": "'0032",
          },
          {
            "name": "中山里",
            "code": "'0033",
          },
          {
            "name": "福祿里",
            "code": "'0034",
          },
          {
            "name": "興榮里",
            "code": "'0035",
          },
          {
            "name": "中興里",
            "code": "'0036",
          },
          {
            "name": "上興里",
            "code": "'0037",
          },
          {
            "name": "營福里",
            "code": "'0038",
          },
          {
            "name": "正興里",
            "code": "'0039",
          },
          {
            "name": "濱海里",
            "code": "'0040",
          }
        ]
      },
      {
        "name": "大園區",
        "code": "'060",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "大園里",
            "code": "'0001",
          },
          {
            "name": "田心里",
            "code": "'0002",
          },
          {
            "name": "溪海里",
            "code": "'0003",
          },
          {
            "name": "和平里",
            "code": "'0004",
          },
          {
            "name": "橫峰里",
            "code": "'0005",
          },
          {
            "name": "五權里",
            "code": "'0006",
          },
          {
            "name": "埔心里",
            "code": "'0007",
          },
          {
            "name": "大海里",
            "code": "'0008",
          },
          {
            "name": "三石里",
            "code": "'0010",
          },
          {
            "name": "菓林里",
            "code": "'0011",
          },
          {
            "name": "竹圍里",
            "code": "'0012",
          },
          {
            "name": "海口里",
            "code": "'0013",
          },
          {
            "name": "沙崙里",
            "code": "'0014",
          },
          {
            "name": "後厝里",
            "code": "'0015",
          },
          {
            "name": "圳頭里",
            "code": "'0016",
          },
          {
            "name": "內海里",
            "code": "'0017",
          },
          {
            "name": "北港里",
            "code": "'0018",
          },
          {
            "name": "南港里",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "大溪區",
        "code": "'030",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "興和里",
            "code": "'0001",
          },
          {
            "name": "福仁里",
            "code": "'0002",
          },
          {
            "name": "一心里",
            "code": "'0003",
          },
          {
            "name": "一德里",
            "code": "'0004",
          },
          {
            "name": "月眉里",
            "code": "'0005",
          },
          {
            "name": "永福里",
            "code": "'0006",
          },
          {
            "name": "美華里",
            "code": "'0007",
          },
          {
            "name": "福安里",
            "code": "'0008",
          },
          {
            "name": "復興里",
            "code": "'0009",
          },
          {
            "name": "新峰里",
            "code": "'0010",
          },
          {
            "name": "康安里",
            "code": "'0011",
          },
          {
            "name": "義和里",
            "code": "'0012",
          },
          {
            "name": "瑞興里",
            "code": "'0013",
          },
          {
            "name": "中新里",
            "code": "'0014",
          },
          {
            "name": "仁義里",
            "code": "'0015",
          },
          {
            "name": "僑愛里",
            "code": "'0016",
          },
          {
            "name": "仁善里",
            "code": "'0017",
          },
          {
            "name": "仁愛里",
            "code": "'0018",
          },
          {
            "name": "仁武里",
            "code": "'0019",
          },
          {
            "name": "員林里",
            "code": "'0020",
          },
          {
            "name": "南興里",
            "code": "'0021",
          },
          {
            "name": "瑞源里",
            "code": "'0022",
          },
          {
            "name": "光明里",
            "code": "'0023",
          },
          {
            "name": "三元里",
            "code": "'0024",
          },
          {
            "name": "田心里",
            "code": "'0025",
          },
          {
            "name": "仁文里",
            "code": "'0026",
          },
          {
            "name": "仁和里",
            "code": "'0027",
          },
          {
            "name": "仁美里",
            "code": "'0028",
          }
        ]
      },
      {
        "name": "復興區",
        "code": "'130",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "三民里",
            "code": "'0001",
          },
          {
            "name": "澤仁里",
            "code": "'0002",
          },
          {
            "name": "義盛里",
            "code": "'0003",
          },
          {
            "name": "霞雲里",
            "code": "'0004",
          },
          {
            "name": "長興里",
            "code": "'0005",
          },
          {
            "name": "三光里",
            "code": "'0006",
          },
          {
            "name": "奎輝里",
            "code": "'0007",
          },
          {
            "name": "華陵里",
            "code": "'0008",
          },
          {
            "name": "羅浮里",
            "code": "'0009",
          },
          {
            "name": "高義里",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "中壢區",
        "code": "'020",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "龍平里",
            "code": "'0001",
          },
          {
            "name": "龍昌里",
            "code": "'0002",
          },
          {
            "name": "仁德里",
            "code": "'0003",
          },
          {
            "name": "永興里",
            "code": "'0004",
          },
          {
            "name": "內壢里",
            "code": "'0005",
          },
          {
            "name": "成功里",
            "code": "'0006",
          },
          {
            "name": "光明里",
            "code": "'0007",
          },
          {
            "name": "自立里",
            "code": "'0008",
          },
          {
            "name": "自強里",
            "code": "'0009",
          },
          {
            "name": "中堅里",
            "code": "'0010",
          },
          {
            "name": "龍興里",
            "code": "'0011",
          },
          {
            "name": "仁美里",
            "code": "'0012",
          },
          {
            "name": "仁愛里",
            "code": "'0013",
          },
          {
            "name": "新街里",
            "code": "'0014",
          },
          {
            "name": "中央里",
            "code": "'0015",
          },
          {
            "name": "普仁里",
            "code": "'0016",
          },
          {
            "name": "普義里",
            "code": "'0017",
          },
          {
            "name": "忠義里",
            "code": "'0018",
          },
          {
            "name": "中正里",
            "code": "'0019",
          },
          {
            "name": "興仁里",
            "code": "'0020",
          },
          {
            "name": "東興里",
            "code": "'0021",
          },
          {
            "name": "洽溪里",
            "code": "'0022",
          },
          {
            "name": "篤行里",
            "code": "'0023",
          },
          {
            "name": "新明里",
            "code": "'0024",
          },
          {
            "name": "新興里",
            "code": "'0025",
          },
          {
            "name": "中原里",
            "code": "'0026",
          },
          {
            "name": "內定里",
            "code": "'0027",
          },
          {
            "name": "中壢里",
            "code": "'0028",
          },
          {
            "name": "龍岡里",
            "code": "'0029",
          },
          {
            "name": "仁祥里",
            "code": "'0030",
          },
          {
            "name": "水尾里",
            "code": "'0031",
          },
          {
            "name": "青埔里",
            "code": "'0032",
          },
          {
            "name": "復興里",
            "code": "'0033",
          },
          {
            "name": "文化里",
            "code": "'0034",
          },
          {
            "name": "興國里",
            "code": "'0035",
          },
          {
            "name": "忠福里",
            "code": "'0036",
          },
          {
            "name": "信義里",
            "code": "'0037",
          },
          {
            "name": "石頭里",
            "code": "'0038",
          },
          {
            "name": "龍東里",
            "code": "'0039",
          },
          {
            "name": "中建里",
            "code": "'0040",
          },
          {
            "name": "興南里",
            "code": "'0041",
          },
          {
            "name": "後寮里",
            "code": "'0042",
          },
          {
            "name": "過嶺里",
            "code": "'0043",
          },
          {
            "name": "五權里",
            "code": "'0044",
          },
          {
            "name": "舊明里",
            "code": "'0045",
          },
          {
            "name": "芝芭里",
            "code": "'0046",
          },
          {
            "name": "月眉里",
            "code": "'0047",
          },
          {
            "name": "三民里",
            "code": "'0048",
          },
          {
            "name": "山東里",
            "code": "'0049",
          },
          {
            "name": "中榮里",
            "code": "'0050",
          },
          {
            "name": "內厝里",
            "code": "'0051",
          },
          {
            "name": "振興里",
            "code": "'0052",
          },
          {
            "name": "普慶里",
            "code": "'0053",
          },
          {
            "name": "興和里",
            "code": "'0054",
          },
          {
            "name": "永光里",
            "code": "'0055",
          },
          {
            "name": "復華里",
            "code": "'0056",
          },
          {
            "name": "忠孝里",
            "code": "'0057",
          },
          {
            "name": "福德里",
            "code": "'0058",
          },
          {
            "name": "和平里",
            "code": "'0059",
          },
          {
            "name": "普忠里",
            "code": "'0060",
          },
          {
            "name": "普強里",
            "code": "'0061",
          },
          {
            "name": "明德里",
            "code": "'0062",
          },
          {
            "name": "至善里",
            "code": "'0063",
          },
          {
            "name": "五福里",
            "code": "'0064",
          },
          {
            "name": "龍德里",
            "code": "'0065",
          },
          {
            "name": "正義里",
            "code": "'0066",
          },
          {
            "name": "龍安里",
            "code": "'0067",
          },
          {
            "name": "中山里",
            "code": "'0068",
          },
          {
            "name": "德義里",
            "code": "'0069",
          },
          {
            "name": "仁義里",
            "code": "'0070",
          },
          {
            "name": "華勛里",
            "code": "'0071",
          },
          {
            "name": "仁福里",
            "code": "'0072",
          },
          {
            "name": "幸福里",
            "code": "'0073",
          },
          {
            "name": "永福里",
            "code": "'0074",
          },
          {
            "name": "仁和里",
            "code": "'0075",
          },
          {
            "name": "華愛里",
            "code": "'0076",
          },
          {
            "name": "莊敬里",
            "code": "'0077",
          },
          {
            "name": "自治里",
            "code": "'0078",
          },
          {
            "name": "健行里",
            "code": "'0079",
          },
          {
            "name": "興平里",
            "code": "'0080",
          },
          {
            "name": "中興里",
            "code": "'0081",
          },
          {
            "name": "龍慈里",
            "code": "'0082",
          },
          {
            "name": "自信里",
            "code": "'0083",
          },
          {
            "name": "林森里",
            "code": "'0084",
          },
          {
            "name": "金華里",
            "code": "'0085",
          }
        ]
      },
      {
        "name": "平鎮區",
        "code": "'100",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "雙連里",
            "code": "'0001",
          },
          {
            "name": "高雙里",
            "code": "'0002",
          },
          {
            "name": "復旦里",
            "code": "'0003",
          },
          {
            "name": "廣興里",
            "code": "'0004",
          },
          {
            "name": "義民里",
            "code": "'0005",
          },
          {
            "name": "宋屋里",
            "code": "'0006",
          },
          {
            "name": "新勢里",
            "code": "'0007",
          },
          {
            "name": "新富里",
            "code": "'0008",
          },
          {
            "name": "北勢里",
            "code": "'0009",
          },
          {
            "name": "北興里",
            "code": "'0010",
          },
          {
            "name": "北貴里",
            "code": "'0011",
          },
          {
            "name": "平鎮里",
            "code": "'0012",
          },
          {
            "name": "南勢里",
            "code": "'0013",
          },
          {
            "name": "莊敬里",
            "code": "'0014",
          },
          {
            "name": "山峰里",
            "code": "'0015",
          },
          {
            "name": "湧光里",
            "code": "'0016",
          },
          {
            "name": "龍恩里",
            "code": "'0017",
          },
          {
            "name": "東社里",
            "code": "'0018",
          },
          {
            "name": "龍興里",
            "code": "'0019",
          },
          {
            "name": "貿易里",
            "code": "'0020",
          },
          {
            "name": "忠貞里",
            "code": "'0021",
          },
          {
            "name": "中正里",
            "code": "'0022",
          },
          {
            "name": "建安里",
            "code": "'0023",
          },
          {
            "name": "東勢里",
            "code": "'0024",
          },
          {
            "name": "東安里",
            "code": "'0025",
          },
          {
            "name": "新安里",
            "code": "'0026",
          },
          {
            "name": "鎮興里",
            "code": "'0027",
          },
          {
            "name": "義興里",
            "code": "'0028",
          },
          {
            "name": "湧豐里",
            "code": "'0029",
          },
          {
            "name": "湧安里",
            "code": "'0030",
          },
          {
            "name": "廣達里",
            "code": "'0031",
          },
          {
            "name": "福林里",
            "code": "'0032",
          },
          {
            "name": "金星里",
            "code": "'0033",
          },
          {
            "name": "廣仁里",
            "code": "'0034",
          },
          {
            "name": "新貴里",
            "code": "'0035",
          },
          {
            "name": "北富里",
            "code": "'0036",
          },
          {
            "name": "新榮里",
            "code": "'0037",
          },
          {
            "name": "華安里",
            "code": "'0038",
          },
          {
            "name": "金陵里",
            "code": "'0039",
          },
          {
            "name": "北安里",
            "code": "'0040",
          },
          {
            "name": "北華里",
            "code": "'0041",
          },
          {
            "name": "復興里",
            "code": "'0042",
          },
          {
            "name": "平興里",
            "code": "'0043",
          },
          {
            "name": "新英里",
            "code": "'0044",
          },
          {
            "name": "平安里",
            "code": "'0045",
          },
          {
            "name": "平南里",
            "code": "'0046",
          }
        ]
      },
      {
        "name": "楊梅區",
        "code": "'040",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "楊梅里",
            "code": "'0001",
          },
          {
            "name": "楊江里",
            "code": "'0002",
          },
          {
            "name": "紅梅里",
            "code": "'0003",
          },
          {
            "name": "永寧里",
            "code": "'0004",
          },
          {
            "name": "大平里",
            "code": "'0005",
          },
          {
            "name": "東流里",
            "code": "'0006",
          },
          {
            "name": "水美里",
            "code": "'0007",
          },
          {
            "name": "上田里",
            "code": "'0008",
          },
          {
            "name": "梅新里",
            "code": "'0009",
          },
          {
            "name": "大同里",
            "code": "'0010",
          },
          {
            "name": "埔心里",
            "code": "'0011",
          },
          {
            "name": "金龍里",
            "code": "'0012",
          },
          {
            "name": "瑞塘里",
            "code": "'0013",
          },
          {
            "name": "四維里",
            "code": "'0014",
          },
          {
            "name": "梅溪里",
            "code": "'0015",
          },
          {
            "name": "高山里",
            "code": "'0016",
          },
          {
            "name": "高榮里",
            "code": "'0017",
          },
          {
            "name": "仁美里",
            "code": "'0018",
          },
          {
            "name": "光華里",
            "code": "'0019",
          },
          {
            "name": "富岡里",
            "code": "'0020",
          },
          {
            "name": "豐野里",
            "code": "'0021",
          },
          {
            "name": "員本里",
            "code": "'0022",
          },
          {
            "name": "瑞原里",
            "code": "'0023",
          },
          {
            "name": "三湖里",
            "code": "'0024",
          },
          {
            "name": "上湖里",
            "code": "'0025",
          },
          {
            "name": "中山里",
            "code": "'0026",
          },
          {
            "name": "楊明里",
            "code": "'0027",
          },
          {
            "name": "金溪里",
            "code": "'0028",
          },
          {
            "name": "裕成里",
            "code": "'0029",
          },
          {
            "name": "秀才里",
            "code": "'0030",
          },
          {
            "name": "青山里",
            "code": "'0031",
          },
          {
            "name": "新榮里",
            "code": "'0032",
          },
          {
            "name": "雙榮里",
            "code": "'0033",
          },
          {
            "name": "瑞坪里",
            "code": "'0034",
          },
          {
            "name": "永平里",
            "code": "'0035",
          },
          {
            "name": "瑞溪里",
            "code": "'0036",
          },
          {
            "name": "高上里",
            "code": "'0037",
          },
          {
            "name": "富豐里",
            "code": "'0038",
          },
          {
            "name": "裕新里",
            "code": "'0039",
          },
          {
            "name": "三民里",
            "code": "'0040",
          },
          {
            "name": "頭湖里",
            "code": "'0041",
          }
        ]
      },
      {
        "name": "龍潭區",
        "code": "'090",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "黃唐里",
            "code": "'0001",
          },
          {
            "name": "中山里",
            "code": "'0002",
          },
          {
            "name": "烏林里",
            "code": "'0003",
          },
          {
            "name": "高平里",
            "code": "'0004",
          },
          {
            "name": "凌雲里",
            "code": "'0006",
          },
          {
            "name": "八德里",
            "code": "'0007",
          },
          {
            "name": "三水里",
            "code": "'0008",
          },
          {
            "name": "上林里",
            "code": "'0009",
          },
          {
            "name": "中正里",
            "code": "'0010",
          },
          {
            "name": "龍潭里",
            "code": "'0011",
          },
          {
            "name": "高原里",
            "code": "'0012",
          },
          {
            "name": "九龍里",
            "code": "'0013",
          },
          {
            "name": "中興里",
            "code": "'0014",
          },
          {
            "name": "佳安里",
            "code": "'0015",
          },
          {
            "name": "三和里",
            "code": "'0016",
          },
          {
            "name": "三坑里",
            "code": "'0017",
          },
          {
            "name": "大平里",
            "code": "'0018",
          },
          {
            "name": "三林里",
            "code": "'0019",
          },
          {
            "name": "永興里",
            "code": "'0020",
          },
          {
            "name": "烏樹林里",
            "code": "'0021",
          },
          {
            "name": "百年里",
            "code": "'0022",
          },
          {
            "name": "龍祥里",
            "code": "'0023",
          },
          {
            "name": "聖德里",
            "code": "'0024",
          },
          {
            "name": "龍星里",
            "code": "'0025",
          },
          {
            "name": "上華里",
            "code": "'0026",
          },
          {
            "name": "武漢里",
            "code": "'0027",
          },
          {
            "name": "東興里",
            "code": "'0028",
          },
          {
            "name": "北興里",
            "code": "'0029",
          },
          {
            "name": "建林里",
            "code": "'0030",
          },
          {
            "name": "富林里",
            "code": "'0031",
          },
          {
            "name": "祥和里",
            "code": "'0032",
          }
        ]
      },
      {
        "name": "新屋區",
        "code": "'110",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "新屋里",
            "code": "'0001",
          },
          {
            "name": "新生里",
            "code": "'0002",
          },
          {
            "name": "後湖里",
            "code": "'0003",
          },
          {
            "name": "清華里",
            "code": "'0004",
          },
          {
            "name": "頭洲里",
            "code": "'0005",
          },
          {
            "name": "埔頂里",
            "code": "'0006",
          },
          {
            "name": "九斗里",
            "code": "'0007",
          },
          {
            "name": "社子里",
            "code": "'0008",
          },
          {
            "name": "東明里",
            "code": "'0009",
          },
          {
            "name": "石磊里",
            "code": "'0010",
          },
          {
            "name": "下埔里",
            "code": "'0011",
          },
          {
            "name": "下田里",
            "code": "'0012",
          },
          {
            "name": "石牌里",
            "code": "'0013",
          },
          {
            "name": "赤欄里",
            "code": "'0014",
          },
          {
            "name": "永興里",
            "code": "'0015",
          },
          {
            "name": "永安里",
            "code": "'0016",
          },
          {
            "name": "笨港里",
            "code": "'0017",
          },
          {
            "name": "深圳里",
            "code": "'0018",
          },
          {
            "name": "蚵間里",
            "code": "'0019",
          },
          {
            "name": "槺榔里",
            "code": "'0020",
          },
          {
            "name": "後庄里",
            "code": "'0021",
          },
          {
            "name": "大坡里",
            "code": "'0022",
          },
          {
            "name": "望間里",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "觀音區",
        "code": "'120",
        "group": [
          "'12"
        ],
        "subLocation": [
          {
            "name": "觀音里",
            "code": "'0001",
          },
          {
            "name": "白玉里",
            "code": "'0002",
          },
          {
            "name": "廣興里",
            "code": "'0003",
          },
          {
            "name": "大潭里",
            "code": "'0004",
          },
          {
            "name": "武威里",
            "code": "'0005",
          },
          {
            "name": "保生里",
            "code": "'0006",
          },
          {
            "name": "三和里",
            "code": "'0007",
          },
          {
            "name": "新興里",
            "code": "'0008",
          },
          {
            "name": "坑尾里",
            "code": "'0009",
          },
          {
            "name": "金湖里",
            "code": "'0010",
          },
          {
            "name": "藍埔里",
            "code": "'0011",
          },
          {
            "name": "大同里",
            "code": "'0012",
          },
          {
            "name": "大堀里",
            "code": "'0013",
          },
          {
            "name": "崙坪里",
            "code": "'0014",
          },
          {
            "name": "富源里",
            "code": "'0015",
          },
          {
            "name": "上大里",
            "code": "'0016",
          },
          {
            "name": "新坡里",
            "code": "'0017",
          },
          {
            "name": "廣福里",
            "code": "'0018",
          },
          {
            "name": "塔腳里",
            "code": "'0019",
          },
          {
            "name": "保障里",
            "code": "'0020",
          },
          {
            "name": "草漯里",
            "code": "'0021",
          },
          {
            "name": "樹林里",
            "code": "'0022",
          },
          {
            "name": "富林里",
            "code": "'0023",
          },
          {
            "name": "草新里",
            "code": "'0024",
          }
        ]
      }
    ]
  },
  {
    "name": "台中市",
    "code": "'66'000",
    "subLocation": [
      {
        "name": "大甲區",
        "code": "'110",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "朝陽里",
            "code": "'0001",
          },
          {
            "name": "大甲里",
            "code": "'0002",
          },
          {
            "name": "順天里",
            "code": "'0003",
          },
          {
            "name": "孔門里",
            "code": "'0004",
          },
          {
            "name": "平安里",
            "code": "'0005",
          },
          {
            "name": "庄美里",
            "code": "'0006",
          },
          {
            "name": "新美里",
            "code": "'0007",
          },
          {
            "name": "岷山里",
            "code": "'0008",
          },
          {
            "name": "中山里",
            "code": "'0009",
          },
          {
            "name": "南陽里",
            "code": "'0010",
          },
          {
            "name": "薰風里",
            "code": "'0011",
          },
          {
            "name": "義和里",
            "code": "'0012",
          },
          {
            "name": "武陵里",
            "code": "'0013",
          },
          {
            "name": "文曲里",
            "code": "'0014",
          },
          {
            "name": "武曲里",
            "code": "'0015",
          },
          {
            "name": "文武里",
            "code": "'0016",
          },
          {
            "name": "奉化里",
            "code": "'0017",
          },
          {
            "name": "德化里",
            "code": "'0018",
          },
          {
            "name": "江南里",
            "code": "'0019",
          },
          {
            "name": "頂店里",
            "code": "'0020",
          },
          {
            "name": "太白里",
            "code": "'0021",
          },
          {
            "name": "孟春里",
            "code": "'0022",
          },
          {
            "name": "幸福里",
            "code": "'0023",
          },
          {
            "name": "日南里",
            "code": "'0024",
          },
          {
            "name": "龍泉里",
            "code": "'0025",
          },
          {
            "name": "西岐里",
            "code": "'0026",
          },
          {
            "name": "銅安里",
            "code": "'0027",
          },
          {
            "name": "福德里",
            "code": "'0028",
          },
          {
            "name": "建興里",
            "code": "'0029",
          }
        ]
      },
      {
        "name": "外埔區",
        "code": "'210",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "永豐里",
            "code": "'0001",
          },
          {
            "name": "六分里",
            "code": "'0002",
          },
          {
            "name": "三崁里",
            "code": "'0003",
          },
          {
            "name": "大同里",
            "code": "'0004",
          },
          {
            "name": "水美里",
            "code": "'0005",
          },
          {
            "name": "大東里",
            "code": "'0006",
          },
          {
            "name": "馬鳴里",
            "code": "'0007",
          },
          {
            "name": "中山里",
            "code": "'0008",
          },
          {
            "name": "鐵山里",
            "code": "'0009",
          },
          {
            "name": "土城里",
            "code": "'0010",
          },
          {
            "name": "廍子里",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "大安區",
        "code": "'220",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "南埔里",
            "code": "'0001",
          },
          {
            "name": "南庄里",
            "code": "'0002",
          },
          {
            "name": "中庄里",
            "code": "'0003",
          },
          {
            "name": "龜壳里",
            "code": "'0004",
          },
          {
            "name": "福興里",
            "code": "'0005",
          },
          {
            "name": "東安里",
            "code": "'0006",
          },
          {
            "name": "頂安里",
            "code": "'0007",
          },
          {
            "name": "永安里",
            "code": "'0008",
          },
          {
            "name": "福住里",
            "code": "'0009",
          },
          {
            "name": "海墘里",
            "code": "'0010",
          },
          {
            "name": "西安里",
            "code": "'0011",
          },
          {
            "name": "松雅里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "清水區",
        "code": "'120",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "鰲峰里",
            "code": "'0001",
          },
          {
            "name": "靈泉里",
            "code": "'0002",
          },
          {
            "name": "清水里",
            "code": "'0003",
          },
          {
            "name": "文昌里",
            "code": "'0004",
          },
          {
            "name": "南寧里",
            "code": "'0005",
          },
          {
            "name": "西寧里",
            "code": "'0006",
          },
          {
            "name": "北寧里",
            "code": "'0007",
          },
          {
            "name": "中興里",
            "code": "'0008",
          },
          {
            "name": "西社里",
            "code": "'0009",
          },
          {
            "name": "南社里",
            "code": "'0010",
          },
          {
            "name": "裕嘉里",
            "code": "'0011",
          },
          {
            "name": "臨江里",
            "code": "'0012",
          },
          {
            "name": "秀水里",
            "code": "'0013",
          },
          {
            "name": "武鹿里",
            "code": "'0014",
          },
          {
            "name": "海濱里",
            "code": "'0015",
          },
          {
            "name": "槺榔里",
            "code": "'0016",
          },
          {
            "name": "中社里",
            "code": "'0017",
          },
          {
            "name": "高西里",
            "code": "'0018",
          },
          {
            "name": "高南里",
            "code": "'0019",
          },
          {
            "name": "高東里",
            "code": "'0020",
          },
          {
            "name": "高北里",
            "code": "'0021",
          },
          {
            "name": "高美里",
            "code": "'0022",
          },
          {
            "name": "國姓里",
            "code": "'0023",
          },
          {
            "name": "菁埔里",
            "code": "'0024",
          },
          {
            "name": "頂湳里",
            "code": "'0025",
          },
          {
            "name": "田寮里",
            "code": "'0026",
          },
          {
            "name": "橋頭里",
            "code": "'0027",
          },
          {
            "name": "下湳里",
            "code": "'0028",
          },
          {
            "name": "東山里",
            "code": "'0029",
          },
          {
            "name": "楊厝里",
            "code": "'0030",
          },
          {
            "name": "海風里",
            "code": "'0031",
          },
          {
            "name": "吳厝里",
            "code": "'0032",
          }
        ]
      },
      {
        "name": "沙鹿區",
        "code": "'130",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "居仁里",
            "code": "'0001",
          },
          {
            "name": "洛泉里",
            "code": "'0002",
          },
          {
            "name": "沙鹿里",
            "code": "'0003",
          },
          {
            "name": "美仁里",
            "code": "'0004",
          },
          {
            "name": "興仁里",
            "code": "'0005",
          },
          {
            "name": "斗抵里",
            "code": "'0006",
          },
          {
            "name": "鹿峰里",
            "code": "'0007",
          },
          {
            "name": "鹿寮里",
            "code": "'0008",
          },
          {
            "name": "竹林里",
            "code": "'0009",
          },
          {
            "name": "犁分里",
            "code": "'0010",
          },
          {
            "name": "北勢里",
            "code": "'0011",
          },
          {
            "name": "福興里",
            "code": "'0012",
          },
          {
            "name": "晉江里",
            "code": "'0013",
          },
          {
            "name": "六路里",
            "code": "'0014",
          },
          {
            "name": "南勢里",
            "code": "'0015",
          },
          {
            "name": "埔子里",
            "code": "'0016",
          },
          {
            "name": "公明里",
            "code": "'0017",
          },
          {
            "name": "清泉里",
            "code": "'0018",
          },
          {
            "name": "西勢里",
            "code": "'0019",
          },
          {
            "name": "興安里",
            "code": "'0020",
          },
          {
            "name": "三鹿里",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "梧棲區",
        "code": "'140",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "頂寮里",
            "code": "'0001",
          },
          {
            "name": "下寮里",
            "code": "'0002",
          },
          {
            "name": "中正里",
            "code": "'0003",
          },
          {
            "name": "中和里",
            "code": "'0004",
          },
          {
            "name": "文化里",
            "code": "'0005",
          },
          {
            "name": "安仁里",
            "code": "'0006",
          },
          {
            "name": "草湳里",
            "code": "'0007",
          },
          {
            "name": "南簡里",
            "code": "'0008",
          },
          {
            "name": "福德里",
            "code": "'0009",
          },
          {
            "name": "大庄里",
            "code": "'0010",
          },
          {
            "name": "大村里",
            "code": "'0011",
          },
          {
            "name": "興農里",
            "code": "'0012",
          },
          {
            "name": "永寧里",
            "code": "'0013",
          },
          {
            "name": "永安里",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "烏日區",
        "code": "'230",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "烏日里",
            "code": "'0001",
          },
          {
            "name": "湖日里",
            "code": "'0002",
          },
          {
            "name": "三和里",
            "code": "'0003",
          },
          {
            "name": "榮泉里",
            "code": "'0004",
          },
          {
            "name": "學田里",
            "code": "'0005",
          },
          {
            "name": "九德里",
            "code": "'0006",
          },
          {
            "name": "仁德里",
            "code": "'0007",
          },
          {
            "name": "前竹里",
            "code": "'0008",
          },
          {
            "name": "五光里",
            "code": "'0009",
          },
          {
            "name": "光明里",
            "code": "'0010",
          },
          {
            "name": "東園里",
            "code": "'0011",
          },
          {
            "name": "溪埧里",
            "code": "'0012",
          },
          {
            "name": "螺潭里",
            "code": "'0013",
          },
          {
            "name": "北里里",
            "code": "'0014",
          },
          {
            "name": "南里里",
            "code": "'0015",
          },
          {
            "name": "溪尾里",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "大肚區",
        "code": "'240",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "王田里",
            "code": "'0001",
          },
          {
            "name": "中和里",
            "code": "'0002",
          },
          {
            "name": "福山里",
            "code": "'0003",
          },
          {
            "name": "營埔里",
            "code": "'0004",
          },
          {
            "name": "社腳里",
            "code": "'0005",
          },
          {
            "name": "新興里",
            "code": "'0006",
          },
          {
            "name": "大東里",
            "code": "'0007",
          },
          {
            "name": "大肚里",
            "code": "'0008",
          },
          {
            "name": "永和里",
            "code": "'0009",
          },
          {
            "name": "磺溪里",
            "code": "'0010",
          },
          {
            "name": "永順里",
            "code": "'0011",
          },
          {
            "name": "成功里",
            "code": "'0012",
          },
          {
            "name": "山陽里",
            "code": "'0013",
          },
          {
            "name": "頂街里",
            "code": "'0014",
          },
          {
            "name": "瑞井里",
            "code": "'0015",
          },
          {
            "name": "蔗廍里",
            "code": "'0016",
          },
          {
            "name": "自強里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "龍井區",
        "code": "'250",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "竹坑里",
            "code": "'0001",
          },
          {
            "name": "龍東里",
            "code": "'0002",
          },
          {
            "name": "龍西里",
            "code": "'0003",
          },
          {
            "name": "田中里",
            "code": "'0004",
          },
          {
            "name": "福田里",
            "code": "'0005",
          },
          {
            "name": "麗水里",
            "code": "'0006",
          },
          {
            "name": "龍津里",
            "code": "'0007",
          },
          {
            "name": "三德里",
            "code": "'0008",
          },
          {
            "name": "忠和里",
            "code": "'0009",
          },
          {
            "name": "山腳里",
            "code": "'0010",
          },
          {
            "name": "龍泉里",
            "code": "'0011",
          },
          {
            "name": "龍崗里",
            "code": "'0012",
          },
          {
            "name": "新庄里",
            "code": "'0013",
          },
          {
            "name": "南寮里",
            "code": "'0014",
          },
          {
            "name": "新東里",
            "code": "'0015",
          },
          {
            "name": "東海里",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "豐原區",
        "code": "'090",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "豐原里",
            "code": "'0001",
          },
          {
            "name": "頂街里",
            "code": "'0002",
          },
          {
            "name": "中山里",
            "code": "'0003",
          },
          {
            "name": "豐榮里",
            "code": "'0004",
          },
          {
            "name": "下街里",
            "code": "'0005",
          },
          {
            "name": "富春里",
            "code": "'0006",
          },
          {
            "name": "葫蘆里",
            "code": "'0007",
          },
          {
            "name": "豐西里",
            "code": "'0008",
          },
          {
            "name": "西安里",
            "code": "'0009",
          },
          {
            "name": "西勢里",
            "code": "'0010",
          },
          {
            "name": "東勢里",
            "code": "'0011",
          },
          {
            "name": "民生里",
            "code": "'0012",
          },
          {
            "name": "大湳里",
            "code": "'0013",
          },
          {
            "name": "北湳里",
            "code": "'0014",
          },
          {
            "name": "東湳里",
            "code": "'0015",
          },
          {
            "name": "西湳里",
            "code": "'0016",
          },
          {
            "name": "圳寮里",
            "code": "'0017",
          },
          {
            "name": "豐圳里",
            "code": "'0018",
          },
          {
            "name": "社皮里",
            "code": "'0019",
          },
          {
            "name": "三村里",
            "code": "'0020",
          },
          {
            "name": "中興里",
            "code": "'0021",
          },
          {
            "name": "田心里",
            "code": "'0022",
          },
          {
            "name": "豐田里",
            "code": "'0023",
          },
          {
            "name": "鎌村里",
            "code": "'0024",
          },
          {
            "name": "南陽里",
            "code": "'0025",
          },
          {
            "name": "陽明里",
            "code": "'0026",
          },
          {
            "name": "北陽里",
            "code": "'0027",
          },
          {
            "name": "中陽里",
            "code": "'0028",
          },
          {
            "name": "東陽里",
            "code": "'0029",
          },
          {
            "name": "南村里",
            "code": "'0030",
          },
          {
            "name": "南田里",
            "code": "'0031",
          },
          {
            "name": "南嵩里",
            "code": "'0032",
          },
          {
            "name": "翁明里",
            "code": "'0033",
          },
          {
            "name": "翁子里",
            "code": "'0034",
          },
          {
            "name": "翁社里",
            "code": "'0035",
          },
          {
            "name": "朴子里",
            "code": "'0036",
          }
        ]
      },
      {
        "name": "后里區",
        "code": "'150",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "廣福里",
            "code": "'0001",
          },
          {
            "name": "仁里里",
            "code": "'0002",
          },
          {
            "name": "義里里",
            "code": "'0003",
          },
          {
            "name": "后里里",
            "code": "'0004",
          },
          {
            "name": "厚里里",
            "code": "'0005",
          },
          {
            "name": "墩東里",
            "code": "'0006",
          },
          {
            "name": "墩西里",
            "code": "'0007",
          },
          {
            "name": "墩南里",
            "code": "'0008",
          },
          {
            "name": "墩北里",
            "code": "'0009",
          },
          {
            "name": "中和里",
            "code": "'0010",
          },
          {
            "name": "舊社里",
            "code": "'0011",
          },
          {
            "name": "聯合里",
            "code": "'0012",
          },
          {
            "name": "太平里",
            "code": "'0013",
          },
          {
            "name": "眉山里",
            "code": "'0014",
          },
          {
            "name": "泰安里",
            "code": "'0015",
          },
          {
            "name": "公館里",
            "code": "'0016",
          },
          {
            "name": "月眉里",
            "code": "'0017",
          },
          {
            "name": "義德里",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "神岡區",
        "code": "'160",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "神岡里",
            "code": "'0001",
          },
          {
            "name": "庄前里",
            "code": "'0002",
          },
          {
            "name": "北庄里",
            "code": "'0003",
          },
          {
            "name": "庄後里",
            "code": "'0004",
          },
          {
            "name": "社口里",
            "code": "'0005",
          },
          {
            "name": "社南里",
            "code": "'0006",
          },
          {
            "name": "三角里",
            "code": "'0007",
          },
          {
            "name": "岸裡里",
            "code": "'0008",
          },
          {
            "name": "大社里",
            "code": "'0009",
          },
          {
            "name": "豐洲里",
            "code": "'0010",
          },
          {
            "name": "神洲里",
            "code": "'0011",
          },
          {
            "name": "溪洲里",
            "code": "'0012",
          },
          {
            "name": "圳前里",
            "code": "'0013",
          },
          {
            "name": "新庄里",
            "code": "'0014",
          },
          {
            "name": "圳堵里",
            "code": "'0015",
          },
          {
            "name": "山皮里",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "潭子區",
        "code": "'170",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "潭陽里",
            "code": "'0001",
          },
          {
            "name": "潭秀里",
            "code": "'0002",
          },
          {
            "name": "潭北里",
            "code": "'0003",
          },
          {
            "name": "福仁里",
            "code": "'0004",
          },
          {
            "name": "頭家里",
            "code": "'0005",
          },
          {
            "name": "甘蔗里",
            "code": "'0006",
          },
          {
            "name": "東寶里",
            "code": "'0007",
          },
          {
            "name": "大富里",
            "code": "'0008",
          },
          {
            "name": "大豐里",
            "code": "'0009",
          },
          {
            "name": "栗林里",
            "code": "'0010",
          },
          {
            "name": "嘉仁里",
            "code": "'0011",
          },
          {
            "name": "新田里",
            "code": "'0012",
          },
          {
            "name": "聚興里",
            "code": "'0013",
          },
          {
            "name": "頭家東里",
            "code": "'0014",
          },
          {
            "name": "家興里",
            "code": "'0015",
          },
          {
            "name": "家福里",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "大雅區",
        "code": "'180",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "大雅里",
            "code": "'0001",
          },
          {
            "name": "員林里",
            "code": "'0002",
          },
          {
            "name": "六寶里",
            "code": "'0003",
          },
          {
            "name": "秀山里",
            "code": "'0004",
          },
          {
            "name": "忠義里",
            "code": "'0005",
          },
          {
            "name": "橫山里",
            "code": "'0006",
          },
          {
            "name": "三和里",
            "code": "'0007",
          },
          {
            "name": "西寶里",
            "code": "'0008",
          },
          {
            "name": "上楓里",
            "code": "'0009",
          },
          {
            "name": "二和里",
            "code": "'0010",
          },
          {
            "name": "四德里",
            "code": "'0011",
          },
          {
            "name": "上雅里",
            "code": "'0012",
          },
          {
            "name": "文雅里",
            "code": "'0013",
          },
          {
            "name": "大楓里",
            "code": "'0014",
          },
          {
            "name": "雅楓里",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "西屯區",
        "code": "'060",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "西安里",
            "code": "'0001",
          },
          {
            "name": "西墩里",
            "code": "'0002",
          },
          {
            "name": "潮洋里",
            "code": "'0003",
          },
          {
            "name": "龍潭里",
            "code": "'0004",
          },
          {
            "name": "惠來里",
            "code": "'0005",
          },
          {
            "name": "上石里",
            "code": "'0006",
          },
          {
            "name": "逢甲里",
            "code": "'0007",
          },
          {
            "name": "鵬程里",
            "code": "'0008",
          },
          {
            "name": "何安里",
            "code": "'0009",
          },
          {
            "name": "何厝里",
            "code": "'0010",
          },
          {
            "name": "大河里",
            "code": "'0011",
          },
          {
            "name": "大石里",
            "code": "'0012",
          },
          {
            "name": "大福里",
            "code": "'0013",
          },
          {
            "name": "大鵬里",
            "code": "'0014",
          },
          {
            "name": "港尾里",
            "code": "'0015",
          },
          {
            "name": "廣福里",
            "code": "'0016",
          },
          {
            "name": "林厝里",
            "code": "'0017",
          },
          {
            "name": "永安里",
            "code": "'0018",
          },
          {
            "name": "福安里",
            "code": "'0019",
          },
          {
            "name": "協和里",
            "code": "'0020",
          },
          {
            "name": "何仁里",
            "code": "'0021",
          },
          {
            "name": "何德里",
            "code": "'0022",
          },
          {
            "name": "何源里",
            "code": "'0023",
          },
          {
            "name": "西平里",
            "code": "'0024",
          },
          {
            "name": "何南里",
            "code": "'0025",
          },
          {
            "name": "至善里",
            "code": "'0026",
          },
          {
            "name": "上安里",
            "code": "'0027",
          },
          {
            "name": "上德里",
            "code": "'0028",
          },
          {
            "name": "逢福里",
            "code": "'0029",
          },
          {
            "name": "何明里",
            "code": "'0030",
          },
          {
            "name": "何成里",
            "code": "'0031",
          },
          {
            "name": "何福里",
            "code": "'0032",
          },
          {
            "name": "福和里",
            "code": "'0033",
          },
          {
            "name": "福中里",
            "code": "'0034",
          },
          {
            "name": "福恩里",
            "code": "'0035",
          },
          {
            "name": "福聯里",
            "code": "'0036",
          },
          {
            "name": "福瑞里",
            "code": "'0037",
          },
          {
            "name": "福林里",
            "code": "'0038",
          },
          {
            "name": "福雅里",
            "code": "'0039",
          }
        ]
      },
      {
        "name": "南屯區",
        "code": "'070",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "南屯里",
            "code": "'0001",
          },
          {
            "name": "豐樂里",
            "code": "'0002",
          },
          {
            "name": "楓樹里",
            "code": "'0003",
          },
          {
            "name": "中和里",
            "code": "'0004",
          },
          {
            "name": "春社里",
            "code": "'0005",
          },
          {
            "name": "春安里",
            "code": "'0006",
          },
          {
            "name": "文山里",
            "code": "'0007",
          },
          {
            "name": "新生里",
            "code": "'0008",
          },
          {
            "name": "永定里",
            "code": "'0009",
          },
          {
            "name": "三厝里",
            "code": "'0010",
          },
          {
            "name": "溝墘里",
            "code": "'0011",
          },
          {
            "name": "田心里",
            "code": "'0012",
          },
          {
            "name": "黎明里",
            "code": "'0013",
          },
          {
            "name": "鎮平里",
            "code": "'0014",
          },
          {
            "name": "大同里",
            "code": "'0015",
          },
          {
            "name": "文心里",
            "code": "'0016",
          },
          {
            "name": "同心里",
            "code": "'0017",
          },
          {
            "name": "向心里",
            "code": "'0018",
          },
          {
            "name": "大誠里",
            "code": "'0019",
          },
          {
            "name": "大興里",
            "code": "'0020",
          },
          {
            "name": "大業里",
            "code": "'0021",
          },
          {
            "name": "惠中里",
            "code": "'0022",
          },
          {
            "name": "寶山里",
            "code": "'0023",
          },
          {
            "name": "三和里",
            "code": "'0024",
          },
          {
            "name": "三義里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "北屯區",
        "code": "'080",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "北興里",
            "code": "'0001",
          },
          {
            "name": "北屯里",
            "code": "'0002",
          },
          {
            "name": "三光里",
            "code": "'0003",
          },
          {
            "name": "平田里",
            "code": "'0004",
          },
          {
            "name": "平和里",
            "code": "'0005",
          },
          {
            "name": "東光里",
            "code": "'0006",
          },
          {
            "name": "舊社里",
            "code": "'0007",
          },
          {
            "name": "水景里",
            "code": "'0008",
          },
          {
            "name": "軍功里",
            "code": "'0009",
          },
          {
            "name": "和平里",
            "code": "'0010",
          },
          {
            "name": "東山里",
            "code": "'0011",
          },
          {
            "name": "民政里",
            "code": "'0012",
          },
          {
            "name": "廍子里",
            "code": "'0013",
          },
          {
            "name": "大坑里",
            "code": "'0014",
          },
          {
            "name": "民德里",
            "code": "'0015",
          },
          {
            "name": "松竹里",
            "code": "'0016",
          },
          {
            "name": "仁和里",
            "code": "'0017",
          },
          {
            "name": "仁美里",
            "code": "'0018",
          },
          {
            "name": "四民里",
            "code": "'0019",
          },
          {
            "name": "后庄里",
            "code": "'0020",
          },
          {
            "name": "同榮里",
            "code": "'0021",
          },
          {
            "name": "仁愛里",
            "code": "'0022",
          },
          {
            "name": "水湳里",
            "code": "'0023",
          },
          {
            "name": "陳平里",
            "code": "'0024",
          },
          {
            "name": "新平里",
            "code": "'0025",
          },
          {
            "name": "平安里",
            "code": "'0026",
          },
          {
            "name": "平順里",
            "code": "'0027",
          },
          {
            "name": "平德里",
            "code": "'0028",
          },
          {
            "name": "松安里",
            "code": "'0029",
          },
          {
            "name": "松茂里",
            "code": "'0030",
          },
          {
            "name": "大德里",
            "code": "'0031",
          },
          {
            "name": "松強里",
            "code": "'0032",
          },
          {
            "name": "松勇里",
            "code": "'0033",
          },
          {
            "name": "北京里",
            "code": "'0034",
          },
          {
            "name": "平心里",
            "code": "'0035",
          },
          {
            "name": "平福里",
            "code": "'0036",
          },
          {
            "name": "松和里",
            "code": "'0037",
          },
          {
            "name": "平陽里",
            "code": "'0038",
          },
          {
            "name": "平興里",
            "code": "'0039",
          },
          {
            "name": "平昌里",
            "code": "'0040",
          },
          {
            "name": "三和里",
            "code": "'0041",
          },
          {
            "name": "忠平里",
            "code": "'0042",
          }
        ]
      },
      {
        "name": "北區",
        "code": "'050",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "光大里",
            "code": "'0001",
          },
          {
            "name": "文莊里",
            "code": "'0002",
          },
          {
            "name": "五常里",
            "code": "'0003",
          },
          {
            "name": "新興里",
            "code": "'0004",
          },
          {
            "name": "新北里",
            "code": "'0005",
          },
          {
            "name": "樂英里",
            "code": "'0006",
          },
          {
            "name": "錦村里",
            "code": "'0007",
          },
          {
            "name": "建成里",
            "code": "'0008",
          },
          {
            "name": "錦洲里",
            "code": "'0009",
          },
          {
            "name": "金華里",
            "code": "'0010",
          },
          {
            "name": "金龍里",
            "code": "'0011",
          },
          {
            "name": "邱厝里",
            "code": "'0012",
          },
          {
            "name": "建興里",
            "code": "'0013",
          },
          {
            "name": "頂厝里",
            "code": "'0014",
          },
          {
            "name": "賴厝里",
            "code": "'0015",
          },
          {
            "name": "賴村里",
            "code": "'0016",
          },
          {
            "name": "育德里",
            "code": "'0017",
          },
          {
            "name": "淡溝里",
            "code": "'0018",
          },
          {
            "name": "中達里",
            "code": "'0019",
          },
          {
            "name": "錦祥里",
            "code": "'0020",
          },
          {
            "name": "健行里",
            "code": "'0021",
          },
          {
            "name": "明德里",
            "code": "'0022",
          },
          {
            "name": "長青里",
            "code": "'0023",
          },
          {
            "name": "賴明里",
            "code": "'0024",
          },
          {
            "name": "賴興里",
            "code": "'0025",
          },
          {
            "name": "中正里",
            "code": "'0026",
          },
          {
            "name": "六合里",
            "code": "'0027",
          },
          {
            "name": "大湖里",
            "code": "'0028",
          },
          {
            "name": "錦平里",
            "code": "'0029",
          },
          {
            "name": "建德里",
            "code": "'0030",
          },
          {
            "name": "崇德里",
            "code": "'0031",
          },
          {
            "name": "賴旺里",
            "code": "'0032",
          },
          {
            "name": "明新里",
            "code": "'0033",
          },
          {
            "name": "立人里",
            "code": "'0034",
          },
          {
            "name": "梅川里",
            "code": "'0035",
          },
          {
            "name": "賴福里",
            "code": "'0036",
          }
        ]
      },
      {
        "name": "中區",
        "code": "'010",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "繼光里",
            "code": "'0001",
          },
          {
            "name": "大墩里",
            "code": "'0002",
          },
          {
            "name": "綠川里",
            "code": "'0003",
          },
          {
            "name": "公園里",
            "code": "'0004",
          },
          {
            "name": "光復里",
            "code": "'0005",
          },
          {
            "name": "柳川里",
            "code": "'0006",
          },
          {
            "name": "大誠里",
            "code": "'0007",
          },
          {
            "name": "中華里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "西區",
        "code": "'040",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "民生里",
            "code": "'0001",
          },
          {
            "name": "利民里",
            "code": "'0002",
          },
          {
            "name": "三民里",
            "code": "'0003",
          },
          {
            "name": "東昇里",
            "code": "'0004",
          },
          {
            "name": "廣民里",
            "code": "'0005",
          },
          {
            "name": "平和里",
            "code": "'0006",
          },
          {
            "name": "公民里",
            "code": "'0007",
          },
          {
            "name": "藍興里",
            "code": "'0008",
          },
          {
            "name": "公舘里",
            "code": "'0009",
          },
          {
            "name": "和龍里",
            "code": "'0010",
          },
          {
            "name": "民龍里",
            "code": "'0011",
          },
          {
            "name": "後龍里",
            "code": "'0012",
          },
          {
            "name": "安龍里",
            "code": "'0013",
          },
          {
            "name": "吉龍里",
            "code": "'0014",
          },
          {
            "name": "昇平里",
            "code": "'0015",
          },
          {
            "name": "忠明里",
            "code": "'0016",
          },
          {
            "name": "公正里",
            "code": "'0017",
          },
          {
            "name": "土庫里",
            "code": "'0018",
          },
          {
            "name": "忠誠里",
            "code": "'0019",
          },
          {
            "name": "公益里",
            "code": "'0020",
          },
          {
            "name": "中興里",
            "code": "'0021",
          },
          {
            "name": "公平里",
            "code": "'0022",
          },
          {
            "name": "公德里",
            "code": "'0023",
          },
          {
            "name": "双龍里",
            "code": "'0024",
          },
          {
            "name": "大忠里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "東區",
        "code": "'020",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "新庄里",
            "code": "'0001",
          },
          {
            "name": "振興里",
            "code": "'0002",
          },
          {
            "name": "東橋里",
            "code": "'0003",
          },
          {
            "name": "東興里",
            "code": "'0004",
          },
          {
            "name": "干城里",
            "code": "'0005",
          },
          {
            "name": "泉源里",
            "code": "'0006",
          },
          {
            "name": "旱溪里",
            "code": "'0007",
          },
          {
            "name": "樂成里",
            "code": "'0008",
          },
          {
            "name": "東門里",
            "code": "'0009",
          },
          {
            "name": "十甲里",
            "code": "'0010",
          },
          {
            "name": "東南里",
            "code": "'0011",
          },
          {
            "name": "東勢里",
            "code": "'0012",
          },
          {
            "name": "合作里",
            "code": "'0013",
          },
          {
            "name": "富台里",
            "code": "'0014",
          },
          {
            "name": "富仁里",
            "code": "'0015",
          },
          {
            "name": "東信里",
            "code": "'0016",
          },
          {
            "name": "東英里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "南區",
        "code": "'030",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "城隍里",
            "code": "'0001",
          },
          {
            "name": "國光里",
            "code": "'0002",
          },
          {
            "name": "南門里",
            "code": "'0003",
          },
          {
            "name": "德義里",
            "code": "'0004",
          },
          {
            "name": "積善里",
            "code": "'0005",
          },
          {
            "name": "江川里",
            "code": "'0006",
          },
          {
            "name": "新榮里",
            "code": "'0007",
          },
          {
            "name": "福興里",
            "code": "'0008",
          },
          {
            "name": "福平里",
            "code": "'0009",
          },
          {
            "name": "和平里",
            "code": "'0010",
          },
          {
            "name": "永興里",
            "code": "'0011",
          },
          {
            "name": "樹義里",
            "code": "'0012",
          },
          {
            "name": "樹德里",
            "code": "'0013",
          },
          {
            "name": "西川里",
            "code": "'0014",
          },
          {
            "name": "長春里",
            "code": "'0015",
          },
          {
            "name": "長榮里",
            "code": "'0016",
          },
          {
            "name": "福順里",
            "code": "'0017",
          },
          {
            "name": "平和里",
            "code": "'0018",
          },
          {
            "name": "南和里",
            "code": "'0019",
          },
          {
            "name": "永和里",
            "code": "'0020",
          },
          {
            "name": "工學里",
            "code": "'0021",
          },
          {
            "name": "崇倫里",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "太平區",
        "code": "'270",
        "group": [
          "'12"
        ],
        "subLocation": [
          {
            "name": "太平里",
            "code": "'0001",
          },
          {
            "name": "長億里",
            "code": "'0002",
          },
          {
            "name": "中平里",
            "code": "'0003",
          },
          {
            "name": "中興里",
            "code": "'0004",
          },
          {
            "name": "建國里",
            "code": "'0005",
          },
          {
            "name": "東平里",
            "code": "'0006",
          },
          {
            "name": "宜欣里",
            "code": "'0007",
          },
          {
            "name": "中山里",
            "code": "'0008",
          },
          {
            "name": "坪林里",
            "code": "'0009",
          },
          {
            "name": "光華里",
            "code": "'0010",
          },
          {
            "name": "新坪里",
            "code": "'0011",
          },
          {
            "name": "新光里",
            "code": "'0012",
          },
          {
            "name": "新福里",
            "code": "'0013",
          },
          {
            "name": "頭汴里",
            "code": "'0014",
          },
          {
            "name": "東汴里",
            "code": "'0015",
          },
          {
            "name": "興隆里",
            "code": "'0016",
          },
          {
            "name": "光隆里",
            "code": "'0017",
          },
          {
            "name": "德隆里",
            "code": "'0018",
          },
          {
            "name": "黃竹里",
            "code": "'0019",
          },
          {
            "name": "永成里",
            "code": "'0020",
          },
          {
            "name": "永平里",
            "code": "'0021",
          },
          {
            "name": "成功里",
            "code": "'0022",
          },
          {
            "name": "東和里",
            "code": "'0023",
          },
          {
            "name": "中政里",
            "code": "'0024",
          },
          {
            "name": "平安里",
            "code": "'0025",
          },
          {
            "name": "建興里",
            "code": "'0026",
          },
          {
            "name": "新興里",
            "code": "'0027",
          },
          {
            "name": "新高里",
            "code": "'0028",
          },
          {
            "name": "新吉里",
            "code": "'0029",
          },
          {
            "name": "新城里",
            "code": "'0030",
          },
          {
            "name": "宜佳里",
            "code": "'0031",
          },
          {
            "name": "宜昌里",
            "code": "'0032",
          },
          {
            "name": "光明里",
            "code": "'0033",
          },
          {
            "name": "豐年里",
            "code": "'0034",
          },
          {
            "name": "大興里",
            "code": "'0035",
          },
          {
            "name": "勤益里",
            "code": "'0036",
          },
          {
            "name": "聖和里",
            "code": "'0037",
          },
          {
            "name": "福隆里",
            "code": "'0038",
          },
          {
            "name": "永隆里",
            "code": "'0039",
          }
        ]
      },
      {
        "name": "霧峰區",
        "code": "'260",
        "group": [
          "'13"
        ],
        "subLocation": [
          {
            "name": "桐林里",
            "code": "'0001",
          },
          {
            "name": "吉峰里",
            "code": "'0002",
          },
          {
            "name": "甲寅里",
            "code": "'0003",
          },
          {
            "name": "本鄉里",
            "code": "'0004",
          },
          {
            "name": "中正里",
            "code": "'0005",
          },
          {
            "name": "錦榮里",
            "code": "'0006",
          },
          {
            "name": "萊園里",
            "code": "'0007",
          },
          {
            "name": "本堂里",
            "code": "'0008",
          },
          {
            "name": "坑口里",
            "code": "'0009",
          },
          {
            "name": "北柳里",
            "code": "'0010",
          },
          {
            "name": "南柳里",
            "code": "'0011",
          },
          {
            "name": "四德里",
            "code": "'0012",
          },
          {
            "name": "五福里",
            "code": "'0013",
          },
          {
            "name": "萬豐里",
            "code": "'0014",
          },
          {
            "name": "舊正里",
            "code": "'0015",
          },
          {
            "name": "峰谷里",
            "code": "'0016",
          },
          {
            "name": "六股里",
            "code": "'0017",
          },
          {
            "name": "丁台里",
            "code": "'0018",
          },
          {
            "name": "北勢里",
            "code": "'0019",
          },
          {
            "name": "南勢里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "大里區",
        "code": "'280",
        "group": [
          "'13"
        ],
        "subLocation": [
          {
            "name": "大里里",
            "code": "'0001",
          },
          {
            "name": "新里里",
            "code": "'0002",
          },
          {
            "name": "樹王里",
            "code": "'0003",
          },
          {
            "name": "東興里",
            "code": "'0004",
          },
          {
            "name": "祥興里",
            "code": "'0005",
          },
          {
            "name": "日新里",
            "code": "'0006",
          },
          {
            "name": "西榮里",
            "code": "'0007",
          },
          {
            "name": "內新里",
            "code": "'0008",
          },
          {
            "name": "東昇里",
            "code": "'0009",
          },
          {
            "name": "立仁里",
            "code": "'0010",
          },
          {
            "name": "新仁里",
            "code": "'0011",
          },
          {
            "name": "大元里",
            "code": "'0012",
          },
          {
            "name": "夏田里",
            "code": "'0013",
          },
          {
            "name": "仁化里",
            "code": "'0014",
          },
          {
            "name": "健民里",
            "code": "'0015",
          },
          {
            "name": "塗城里",
            "code": "'0016",
          },
          {
            "name": "瑞城里",
            "code": "'0017",
          },
          {
            "name": "金城里",
            "code": "'0018",
          },
          {
            "name": "東湖里",
            "code": "'0019",
          },
          {
            "name": "西湖里",
            "code": "'0020",
          },
          {
            "name": "國光里",
            "code": "'0021",
          },
          {
            "name": "大明里",
            "code": "'0022",
          },
          {
            "name": "永隆里",
            "code": "'0023",
          },
          {
            "name": "長榮里",
            "code": "'0024",
          },
          {
            "name": "中新里",
            "code": "'0025",
          },
          {
            "name": "立德里",
            "code": "'0026",
          },
          {
            "name": "仁德里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "東勢區",
        "code": "'100",
        "group": [
          "'14"
        ],
        "subLocation": [
          {
            "name": "北興里",
            "code": "'0001",
          },
          {
            "name": "中寧里",
            "code": "'0002",
          },
          {
            "name": "東安里",
            "code": "'0003",
          },
          {
            "name": "南平里",
            "code": "'0004",
          },
          {
            "name": "延平里",
            "code": "'0005",
          },
          {
            "name": "上新里",
            "code": "'0006",
          },
          {
            "name": "廣興里",
            "code": "'0007",
          },
          {
            "name": "泰昌里",
            "code": "'0008",
          },
          {
            "name": "中嵙里",
            "code": "'0009",
          },
          {
            "name": "福隆里",
            "code": "'0010",
          },
          {
            "name": "隆興里",
            "code": "'0011",
          },
          {
            "name": "新盛里",
            "code": "'0012",
          },
          {
            "name": "詒福里",
            "code": "'0013",
          },
          {
            "name": "上城里",
            "code": "'0014",
          },
          {
            "name": "下城里",
            "code": "'0015",
          },
          {
            "name": "慶東里",
            "code": "'0016",
          },
          {
            "name": "慶福里",
            "code": "'0017",
          },
          {
            "name": "東新里",
            "code": "'0018",
          },
          {
            "name": "粵寧里",
            "code": "'0019",
          },
          {
            "name": "下新里",
            "code": "'0020",
          },
          {
            "name": "興隆里",
            "code": "'0021",
          },
          {
            "name": "茂興里",
            "code": "'0022",
          },
          {
            "name": "泰興里",
            "code": "'0023",
          },
          {
            "name": "埤頭里",
            "code": "'0024",
          },
          {
            "name": "明正里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "新社區",
        "code": "'190",
        "group": [
          "'14"
        ],
        "subLocation": [
          {
            "name": "新社里",
            "code": "'0001",
          },
          {
            "name": "復盛里",
            "code": "'0002",
          },
          {
            "name": "中正里",
            "code": "'0003",
          },
          {
            "name": "月湖里",
            "code": "'0004",
          },
          {
            "name": "崑山里",
            "code": "'0005",
          },
          {
            "name": "大南里",
            "code": "'0006",
          },
          {
            "name": "永源里",
            "code": "'0007",
          },
          {
            "name": "中興里",
            "code": "'0008",
          },
          {
            "name": "協成里",
            "code": "'0009",
          },
          {
            "name": "東興里",
            "code": "'0010",
          },
          {
            "name": "慶西里",
            "code": "'0011",
          },
          {
            "name": "中和里",
            "code": "'0012",
          },
          {
            "name": "福興里",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "石岡區",
        "code": "'200",
        "group": [
          "'14"
        ],
        "subLocation": [
          {
            "name": "石岡里",
            "code": "'0001",
          },
          {
            "name": "萬安里",
            "code": "'0002",
          },
          {
            "name": "九房里",
            "code": "'0003",
          },
          {
            "name": "金星里",
            "code": "'0004",
          },
          {
            "name": "龍興里",
            "code": "'0005",
          },
          {
            "name": "萬興里",
            "code": "'0006",
          },
          {
            "name": "梅子里",
            "code": "'0007",
          },
          {
            "name": "土牛里",
            "code": "'0008",
          },
          {
            "name": "德興里",
            "code": "'0009",
          },
          {
            "name": "和盛里",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "和平區",
        "code": "'290",
        "group": [
          "'14"
        ],
        "subLocation": [
          {
            "name": "南勢里",
            "code": "'0001",
          },
          {
            "name": "天輪里",
            "code": "'0002",
          },
          {
            "name": "博愛里",
            "code": "'0003",
          },
          {
            "name": "中坑里",
            "code": "'0004",
          },
          {
            "name": "自由里",
            "code": "'0005",
          },
          {
            "name": "達觀里",
            "code": "'0006",
          },
          {
            "name": "梨山里",
            "code": "'0007",
          },
          {
            "name": "平等里",
            "code": "'0008",
          }
        ]
      }
    ]
  },
  {
    "name": "台南市",
    "code": "'67'000",
    "subLocation": [
      {
        "name": "新營區",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "忠政里",
            "code": "'0001",
          },
          {
            "name": "民權里",
            "code": "'0002",
          },
          {
            "name": "三仙里",
            "code": "'0003",
          },
          {
            "name": "大宏里",
            "code": "'0007",
          },
          {
            "name": "王公里",
            "code": "'0008",
          },
          {
            "name": "中營里",
            "code": "'0009",
          },
          {
            "name": "土庫里",
            "code": "'0010",
          },
          {
            "name": "南興里",
            "code": "'0012",
          },
          {
            "name": "興業里",
            "code": "'0013",
          },
          {
            "name": "民生里",
            "code": "'0014",
          },
          {
            "name": "民榮里",
            "code": "'0015",
          },
          {
            "name": "埤寮里",
            "code": "'0016",
          },
          {
            "name": "護鎮里",
            "code": "'0017",
          },
          {
            "name": "太南里",
            "code": "'0018",
          },
          {
            "name": "太北里",
            "code": "'0019",
          },
          {
            "name": "南紙里",
            "code": "'0020",
          },
          {
            "name": "嘉芳里",
            "code": "'0021",
          },
          {
            "name": "五興里",
            "code": "'0025",
          },
          {
            "name": "姑爺里",
            "code": "'0026",
          },
          {
            "name": "新北里",
            "code": "'0027",
          },
          {
            "name": "新南里",
            "code": "'0028",
          },
          {
            "name": "新東里",
            "code": "'0029",
          },
          {
            "name": "永平里",
            "code": "'0030",
          }
        ]
      },
      {
        "name": "鹽水區",
        "code": "'020",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "水秀里",
            "code": "'0001",
          },
          {
            "name": "橋南里",
            "code": "'0008",
          },
          {
            "name": "汫水里",
            "code": "'0010",
          },
          {
            "name": "岸內里",
            "code": "'0011",
          },
          {
            "name": "歡雅里",
            "code": "'0014",
          },
          {
            "name": "津城里",
            "code": "'0026",
          },
          {
            "name": "月港里",
            "code": "'0027",
          },
          {
            "name": "三明里",
            "code": "'0028",
          },
          {
            "name": "義中里",
            "code": "'0029",
          },
          {
            "name": "文昌里",
            "code": "'0030",
          },
          {
            "name": "竹林里",
            "code": "'0031",
          },
          {
            "name": "三和里",
            "code": "'0032",
          },
          {
            "name": "坔頭港里",
            "code": "'0033",
          }
        ]
      },
      {
        "name": "白河區",
        "code": "'030",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "白河里",
            "code": "'0001",
          },
          {
            "name": "永安里",
            "code": "'0002",
          },
          {
            "name": "外角里",
            "code": "'0003",
          },
          {
            "name": "庄內里",
            "code": "'0004",
          },
          {
            "name": "秀祐里",
            "code": "'0005",
          },
          {
            "name": "河東里",
            "code": "'0006",
          },
          {
            "name": "虎山里",
            "code": "'0007",
          },
          {
            "name": "大林里",
            "code": "'0008",
          },
          {
            "name": "崁頭里",
            "code": "'0009",
          },
          {
            "name": "六溪里",
            "code": "'0010",
          },
          {
            "name": "仙草里",
            "code": "'0011",
          },
          {
            "name": "關嶺里",
            "code": "'0012",
          },
          {
            "name": "大竹里",
            "code": "'0014",
          },
          {
            "name": "竹門里",
            "code": "'0018",
          },
          {
            "name": "崎內里",
            "code": "'0019",
          },
          {
            "name": "昇安里",
            "code": "'0020",
          },
          {
            "name": "汴頭里",
            "code": "'0021",
          },
          {
            "name": "內角里",
            "code": "'0022",
          },
          {
            "name": "詔豐里",
            "code": "'0025",
          },
          {
            "name": "廣蓮里",
            "code": "'0026",
          },
          {
            "name": "馬稠後里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "柳營區",
        "code": "'040",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "士林里",
            "code": "'0001",
          },
          {
            "name": "光福里",
            "code": "'0002",
          },
          {
            "name": "中埕里",
            "code": "'0003",
          },
          {
            "name": "東昇里",
            "code": "'0004",
          },
          {
            "name": "八翁里",
            "code": "'0005",
          },
          {
            "name": "人和里",
            "code": "'0006",
          },
          {
            "name": "太康里",
            "code": "'0007",
          },
          {
            "name": "重溪里",
            "code": "'0008",
          },
          {
            "name": "篤農里",
            "code": "'0009",
          },
          {
            "name": "大農里",
            "code": "'0010",
          },
          {
            "name": "神農里",
            "code": "'0011",
          },
          {
            "name": "果毅里",
            "code": "'0012",
          },
          {
            "name": "旭山里",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "後壁區",
        "code": "'050",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "後壁里",
            "code": "'0001",
          },
          {
            "name": "侯伯里",
            "code": "'0002",
          },
          {
            "name": "嘉苳里",
            "code": "'0003",
          },
          {
            "name": "土溝里",
            "code": "'0004",
          },
          {
            "name": "竹新里",
            "code": "'0010",
          },
          {
            "name": "新東里",
            "code": "'0011",
          },
          {
            "name": "頂安里",
            "code": "'0012",
          },
          {
            "name": "長安里",
            "code": "'0013",
          },
          {
            "name": "福安里",
            "code": "'0014",
          },
          {
            "name": "菁寮里",
            "code": "'0018",
          },
          {
            "name": "新嘉里",
            "code": "'0019",
          },
          {
            "name": "長短樹里",
            "code": "'0022",
          },
          {
            "name": "上茄苳里",
            "code": "'0023",
          },
          {
            "name": "菁豐里",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "東山區",
        "code": "'060",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "東山里",
            "code": "'0001",
          },
          {
            "name": "東中里",
            "code": "'0002",
          },
          {
            "name": "東正里",
            "code": "'0003",
          },
          {
            "name": "三榮里",
            "code": "'0004",
          },
          {
            "name": "大客里",
            "code": "'0005",
          },
          {
            "name": "科里里",
            "code": "'0006",
          },
          {
            "name": "東河里",
            "code": "'0007",
          },
          {
            "name": "南溪里",
            "code": "'0008",
          },
          {
            "name": "水雲里",
            "code": "'0009",
          },
          {
            "name": "林安里",
            "code": "'0010",
          },
          {
            "name": "東原里",
            "code": "'0011",
          },
          {
            "name": "嶺南里",
            "code": "'0012",
          },
          {
            "name": "南勢里",
            "code": "'0013",
          },
          {
            "name": "青山里",
            "code": "'0014",
          },
          {
            "name": "高原里",
            "code": "'0015",
          },
          {
            "name": "聖賢里",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "佳里區",
        "code": "'120",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "東寧里",
            "code": "'0001",
          },
          {
            "name": "忠仁里",
            "code": "'0002",
          },
          {
            "name": "鎮山里",
            "code": "'0003",
          },
          {
            "name": "建南里",
            "code": "'0004",
          },
          {
            "name": "安西里",
            "code": "'0005",
          },
          {
            "name": "六安里",
            "code": "'0006",
          },
          {
            "name": "佳化里",
            "code": "'0007",
          },
          {
            "name": "海澄里",
            "code": "'0012",
          },
          {
            "name": "民安里",
            "code": "'0019",
          },
          {
            "name": "子龍里",
            "code": "'0020",
          },
          {
            "name": "營溪里",
            "code": "'0022",
          },
          {
            "name": "文新里",
            "code": "'0023",
          },
          {
            "name": "佳興里",
            "code": "'0024",
          },
          {
            "name": "下營里",
            "code": "'0025",
          },
          {
            "name": "塭內里",
            "code": "'0026",
          },
          {
            "name": "南勢里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "學甲區",
        "code": "'130",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "仁得里",
            "code": "'0001",
          },
          {
            "name": "新榮里",
            "code": "'0002",
          },
          {
            "name": "光華里",
            "code": "'0003",
          },
          {
            "name": "宅港里",
            "code": "'0004",
          },
          {
            "name": "慈福里",
            "code": "'0005",
          },
          {
            "name": "秀昌里",
            "code": "'0006",
          },
          {
            "name": "明宜里",
            "code": "'0007",
          },
          {
            "name": "豐和里",
            "code": "'0008",
          },
          {
            "name": "平和里",
            "code": "'0009",
          },
          {
            "name": "新達里",
            "code": "'0010",
          },
          {
            "name": "大灣里",
            "code": "'0011",
          },
          {
            "name": "三慶里",
            "code": "'0012",
          },
          {
            "name": "中洲里",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "西港區",
        "code": "'140",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "西港里",
            "code": "'0001",
          },
          {
            "name": "南海里",
            "code": "'0002",
          },
          {
            "name": "港東里",
            "code": "'0003",
          },
          {
            "name": "檨林里",
            "code": "'0004",
          },
          {
            "name": "後營里",
            "code": "'0005",
          },
          {
            "name": "營西里",
            "code": "'0006",
          },
          {
            "name": "金砂里",
            "code": "'0007",
          },
          {
            "name": "劉厝里",
            "code": "'0008",
          },
          {
            "name": "竹林里",
            "code": "'0009",
          },
          {
            "name": "永樂里",
            "code": "'0010",
          },
          {
            "name": "新復里",
            "code": "'0011",
          },
          {
            "name": "慶安里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "七股區",
        "code": "'150",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "後港里",
            "code": "'0001",
          },
          {
            "name": "大潭里",
            "code": "'0002",
          },
          {
            "name": "篤加里",
            "code": "'0004",
          },
          {
            "name": "頂山里",
            "code": "'0005",
          },
          {
            "name": "西寮里",
            "code": "'0006",
          },
          {
            "name": "塩埕里",
            "code": "'0007",
          },
          {
            "name": "龍山里",
            "code": "'0008",
          },
          {
            "name": "溪南里",
            "code": "'0009",
          },
          {
            "name": "七股里",
            "code": "'0010",
          },
          {
            "name": "玉成里",
            "code": "'0011",
          },
          {
            "name": "大埕里",
            "code": "'0012",
          },
          {
            "name": "樹林里",
            "code": "'0015",
          },
          {
            "name": "中寮里",
            "code": "'0017",
          },
          {
            "name": "竹橋里",
            "code": "'0018",
          },
          {
            "name": "義合里",
            "code": "'0019",
          },
          {
            "name": "永吉里",
            "code": "'0021",
          },
          {
            "name": "三股里",
            "code": "'0022",
          },
          {
            "name": "十份里",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "將軍區",
        "code": "'160",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "長榮里",
            "code": "'0001",
          },
          {
            "name": "玉山里",
            "code": "'0013",
          },
          {
            "name": "廣山里",
            "code": "'0014",
          },
          {
            "name": "長沙里",
            "code": "'0015",
          },
          {
            "name": "平沙里",
            "code": "'0016",
          },
          {
            "name": "鯤鯓里",
            "code": "'0017",
          },
          {
            "name": "鯤溟里",
            "code": "'0018",
          },
          {
            "name": "巷埔里",
            "code": "'0019",
          },
          {
            "name": "西甲里",
            "code": "'0020",
          },
          {
            "name": "忠嘉里",
            "code": "'0021",
          },
          {
            "name": "苓仔寮里",
            "code": "'0022",
          },
          {
            "name": "將軍里",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "北門區",
        "code": "'170",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "保吉里",
            "code": "'0001",
          },
          {
            "name": "雙春里",
            "code": "'0002",
          },
          {
            "name": "北門里",
            "code": "'0005",
          },
          {
            "name": "錦湖里",
            "code": "'0006",
          },
          {
            "name": "三光里",
            "code": "'0007",
          },
          {
            "name": "玉港里",
            "code": "'0009",
          },
          {
            "name": "慈安里",
            "code": "'0011",
          },
          {
            "name": "永隆里",
            "code": "'0013",
          },
          {
            "name": "蚵寮里",
            "code": "'0014",
          },
          {
            "name": "文山里",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "麻豆區",
        "code": "'070",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "東角里",
            "code": "'0003",
          },
          {
            "name": "晋江里",
            "code": "'0004",
          },
          {
            "name": "巷口里",
            "code": "'0005",
          },
          {
            "name": "中興里",
            "code": "'0006",
          },
          {
            "name": "興農里",
            "code": "'0007",
          },
          {
            "name": "油車里",
            "code": "'0009",
          },
          {
            "name": "北勢里",
            "code": "'0010",
          },
          {
            "name": "大埕里",
            "code": "'0011",
          },
          {
            "name": "南勢里",
            "code": "'0014",
          },
          {
            "name": "寮廍里",
            "code": "'0015",
          },
          {
            "name": "埤頭里",
            "code": "'0017",
          },
          {
            "name": "海埔里",
            "code": "'0019",
          },
          {
            "name": "港尾里",
            "code": "'0021",
          },
          {
            "name": "麻口里",
            "code": "'0022",
          },
          {
            "name": "安業里",
            "code": "'0024",
          },
          {
            "name": "安正里",
            "code": "'0028",
          },
          {
            "name": "新興里",
            "code": "'0030",
          },
          {
            "name": "謝厝寮里",
            "code": "'0031",
          },
          {
            "name": "井東里",
            "code": "'0032",
          },
          {
            "name": "清水里",
            "code": "'0033",
          }
        ]
      },
      {
        "name": "下營區",
        "code": "'080",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "下營里",
            "code": "'0001",
          },
          {
            "name": "仁里里",
            "code": "'0002",
          },
          {
            "name": "宅內里",
            "code": "'0003",
          },
          {
            "name": "後街里",
            "code": "'0004",
          },
          {
            "name": "新興里",
            "code": "'0005",
          },
          {
            "name": "營前里",
            "code": "'0006",
          },
          {
            "name": "開化里",
            "code": "'0011",
          },
          {
            "name": "西連里",
            "code": "'0012",
          },
          {
            "name": "賀建里",
            "code": "'0013",
          },
          {
            "name": "茅營里",
            "code": "'0016",
          },
          {
            "name": "大吉里",
            "code": "'0017",
          },
          {
            "name": "紅甲里",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "六甲區",
        "code": "'090",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "六甲里",
            "code": "'0001",
          },
          {
            "name": "甲東里",
            "code": "'0002",
          },
          {
            "name": "甲南里",
            "code": "'0003",
          },
          {
            "name": "七甲里",
            "code": "'0005",
          },
          {
            "name": "二甲里",
            "code": "'0006",
          },
          {
            "name": "水林里",
            "code": "'0007",
          },
          {
            "name": "中社里",
            "code": "'0008",
          },
          {
            "name": "龜港里",
            "code": "'0009",
          },
          {
            "name": "菁埔里",
            "code": "'0010",
          },
          {
            "name": "王爺里",
            "code": "'0011",
          },
          {
            "name": "大丘里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "官田區",
        "code": "'100",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "官田里",
            "code": "'0001",
          },
          {
            "name": "二鎮里",
            "code": "'0002",
          },
          {
            "name": "大崎里",
            "code": "'0005",
          },
          {
            "name": "隆田里",
            "code": "'0006",
          },
          {
            "name": "隆本里",
            "code": "'0007",
          },
          {
            "name": "南廍里",
            "code": "'0008",
          },
          {
            "name": "社子里",
            "code": "'0013",
          },
          {
            "name": "烏山頭里",
            "code": "'0014",
          },
          {
            "name": "渡拔里",
            "code": "'0015",
          },
          {
            "name": "東西庄里",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "大內區",
        "code": "'110",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "石湖里",
            "code": "'0001",
          },
          {
            "name": "石林里",
            "code": "'0002",
          },
          {
            "name": "石城里",
            "code": "'0003",
          },
          {
            "name": "內江里",
            "code": "'0004",
          },
          {
            "name": "大內里",
            "code": "'0005",
          },
          {
            "name": "內郭里",
            "code": "'0006",
          },
          {
            "name": "頭社里",
            "code": "'0007",
          },
          {
            "name": "環湖里",
            "code": "'0008",
          },
          {
            "name": "二溪里",
            "code": "'0009",
          },
          {
            "name": "曲溪里",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "玉井區",
        "code": "'230",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "玉井里",
            "code": "'0001",
          },
          {
            "name": "玉田里",
            "code": "'0002",
          },
          {
            "name": "中正里",
            "code": "'0003",
          },
          {
            "name": "竹圍里",
            "code": "'0004",
          },
          {
            "name": "沙田里",
            "code": "'0005",
          },
          {
            "name": "三埔里",
            "code": "'0006",
          },
          {
            "name": "三和里",
            "code": "'0007",
          },
          {
            "name": "望明里",
            "code": "'0008",
          },
          {
            "name": "層林里",
            "code": "'0009",
          },
          {
            "name": "豐里里",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "楠西區",
        "code": "'240",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "楠西里",
            "code": "'0001",
          },
          {
            "name": "灣丘里",
            "code": "'0002",
          },
          {
            "name": "密枝里",
            "code": "'0003",
          },
          {
            "name": "照興里",
            "code": "'0004",
          },
          {
            "name": "鹿田里",
            "code": "'0005",
          },
          {
            "name": "龜丹里",
            "code": "'0006",
          },
          {
            "name": "東勢里",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "南化區",
        "code": "'250",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "南化里",
            "code": "'0001",
          },
          {
            "name": "小崙里",
            "code": "'0002",
          },
          {
            "name": "中坑里",
            "code": "'0003",
          },
          {
            "name": "北平里",
            "code": "'0004",
          },
          {
            "name": "東和里",
            "code": "'0005",
          },
          {
            "name": "西埔里",
            "code": "'0006",
          },
          {
            "name": "北寮里",
            "code": "'0007",
          },
          {
            "name": "玉山里",
            "code": "'0008",
          },
          {
            "name": "關山里",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "左鎮區",
        "code": "'260",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "光和里",
            "code": "'0001",
          },
          {
            "name": "榮和里",
            "code": "'0002",
          },
          {
            "name": "左鎮里",
            "code": "'0003",
          },
          {
            "name": "中正里",
            "code": "'0004",
          },
          {
            "name": "睦光里",
            "code": "'0005",
          },
          {
            "name": "內庄里",
            "code": "'0006",
          },
          {
            "name": "澄山里",
            "code": "'0007",
          },
          {
            "name": "岡林里",
            "code": "'0008",
          },
          {
            "name": "草山里",
            "code": "'0009",
          },
          {
            "name": "二寮里",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "新化區",
        "code": "'180",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "武安里",
            "code": "'0001",
          },
          {
            "name": "東榮里",
            "code": "'0002",
          },
          {
            "name": "護國里",
            "code": "'0003",
          },
          {
            "name": "太平里",
            "code": "'0004",
          },
          {
            "name": "協興里",
            "code": "'0009",
          },
          {
            "name": "唪口里",
            "code": "'0010",
          },
          {
            "name": "北勢里",
            "code": "'0011",
          },
          {
            "name": "豐榮里",
            "code": "'0012",
          },
          {
            "name": "全興里",
            "code": "'0013",
          },
          {
            "name": "崙頂里",
            "code": "'0014",
          },
          {
            "name": "知義里",
            "code": "'0015",
          },
          {
            "name": "山脚里",
            "code": "'0016",
          },
          {
            "name": "大坑里",
            "code": "'0017",
          },
          {
            "name": "𦰡拔里",
            "code": "'0018",
          },
          {
            "name": "羊林里",
            "code": "'0019",
          },
          {
            "name": "礁坑里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "善化區",
        "code": "'190",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "東關里",
            "code": "'0001",
          },
          {
            "name": "坐駕里",
            "code": "'0002",
          },
          {
            "name": "光文里",
            "code": "'0003",
          },
          {
            "name": "文昌里",
            "code": "'0004",
          },
          {
            "name": "南關里",
            "code": "'0005",
          },
          {
            "name": "文正里",
            "code": "'0008",
          },
          {
            "name": "胡家里",
            "code": "'0009",
          },
          {
            "name": "胡厝里",
            "code": "'0010",
          },
          {
            "name": "什乃里",
            "code": "'0011",
          },
          {
            "name": "溪美里",
            "code": "'0012",
          },
          {
            "name": "六分里",
            "code": "'0013",
          },
          {
            "name": "六德里",
            "code": "'0014",
          },
          {
            "name": "田寮里",
            "code": "'0015",
          },
          {
            "name": "牛庄里",
            "code": "'0018",
          },
          {
            "name": "嘉北里",
            "code": "'0019",
          },
          {
            "name": "嘉南里",
            "code": "'0020",
          },
          {
            "name": "小新里",
            "code": "'0021",
          },
          {
            "name": "頂街里",
            "code": "'0022",
          },
          {
            "name": "昌隆里",
            "code": "'0023",
          },
          {
            "name": "蓮潭里",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "新市區",
        "code": "'200",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "新市里",
            "code": "'0001",
          },
          {
            "name": "新和里",
            "code": "'0002",
          },
          {
            "name": "社內里",
            "code": "'0003",
          },
          {
            "name": "大洲里",
            "code": "'0004",
          },
          {
            "name": "豐華里",
            "code": "'0005",
          },
          {
            "name": "三舍里",
            "code": "'0006",
          },
          {
            "name": "大營里",
            "code": "'0007",
          },
          {
            "name": "大社里",
            "code": "'0008",
          },
          {
            "name": "潭頂里",
            "code": "'0009",
          },
          {
            "name": "港墘里",
            "code": "'0010",
          },
          {
            "name": "永就里",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "安定區",
        "code": "'210",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "蘇林里",
            "code": "'0001",
          },
          {
            "name": "蘇厝里",
            "code": "'0002",
          },
          {
            "name": "安定里",
            "code": "'0003",
          },
          {
            "name": "安加里",
            "code": "'0004",
          },
          {
            "name": "港尾里",
            "code": "'0006",
          },
          {
            "name": "中榮里",
            "code": "'0007",
          },
          {
            "name": "港口里",
            "code": "'0008",
          },
          {
            "name": "港南里",
            "code": "'0009",
          },
          {
            "name": "中沙里",
            "code": "'0012",
          },
          {
            "name": "新吉里",
            "code": "'0013",
          },
          {
            "name": "海寮里",
            "code": "'0014",
          },
          {
            "name": "文科里",
            "code": "'0017",
          },
          {
            "name": "嘉同里",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "山上區",
        "code": "'220",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "明和里",
            "code": "'0001",
          },
          {
            "name": "南洲里",
            "code": "'0002",
          },
          {
            "name": "山上里",
            "code": "'0003",
          },
          {
            "name": "新莊里",
            "code": "'0004",
          },
          {
            "name": "玉峯里",
            "code": "'0005",
          },
          {
            "name": "平陽里",
            "code": "'0006",
          },
          {
            "name": "豐德里",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "安南區",
        "code": "'350",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "東和里",
            "code": "'0001",
          },
          {
            "name": "安順里",
            "code": "'0002",
          },
          {
            "name": "塭南里",
            "code": "'0003",
          },
          {
            "name": "州南里",
            "code": "'0004",
          },
          {
            "name": "州北里",
            "code": "'0005",
          },
          {
            "name": "安東里",
            "code": "'0006",
          },
          {
            "name": "安西里",
            "code": "'0007",
          },
          {
            "name": "頂安里",
            "code": "'0008",
          },
          {
            "name": "安慶里",
            "code": "'0009",
          },
          {
            "name": "新順里",
            "code": "'0010",
          },
          {
            "name": "原佃里",
            "code": "'0011",
          },
          {
            "name": "總頭里",
            "code": "'0012",
          },
          {
            "name": "長安里",
            "code": "'0013",
          },
          {
            "name": "公親里",
            "code": "'0014",
          },
          {
            "name": "海東里",
            "code": "'0015",
          },
          {
            "name": "海西里",
            "code": "'0016",
          },
          {
            "name": "海南里",
            "code": "'0017",
          },
          {
            "name": "溪心里",
            "code": "'0018",
          },
          {
            "name": "公塭里",
            "code": "'0024",
          },
          {
            "name": "淵東里",
            "code": "'0020",
          },
          {
            "name": "淵西里",
            "code": "'0021",
          },
          {
            "name": "佃東里",
            "code": "'0022",
          },
          {
            "name": "佃西里",
            "code": "'0023",
          },
          {
            "name": "塩田里",
            "code": "'0019",
          },
          {
            "name": "南興里",
            "code": "'0025",
          },
          {
            "name": "學東里",
            "code": "'0026",
          },
          {
            "name": "城東里",
            "code": "'0027",
          },
          {
            "name": "城北里",
            "code": "'0028",
          },
          {
            "name": "城中里",
            "code": "'0029",
          },
          {
            "name": "城南里",
            "code": "'0030",
          },
          {
            "name": "城西里",
            "code": "'0031",
          },
          {
            "name": "青草里",
            "code": "'0032",
          },
          {
            "name": "砂崙里",
            "code": "'0033",
          },
          {
            "name": "顯宮里",
            "code": "'0034",
          },
          {
            "name": "鹿耳里",
            "code": "'0035",
          },
          {
            "name": "四草里",
            "code": "'0036",
          },
          {
            "name": "安和里",
            "code": "'0037",
          },
          {
            "name": "溪北里",
            "code": "'0038",
          },
          {
            "name": "溪頂里",
            "code": "'0039",
          },
          {
            "name": "溪墘里",
            "code": "'0040",
          },
          {
            "name": "海佃里",
            "code": "'0041",
          },
          {
            "name": "幸福里",
            "code": "'0042",
          },
          {
            "name": "鳳凰里",
            "code": "'0043",
          },
          {
            "name": "梅花里",
            "code": "'0044",
          },
          {
            "name": "理想里",
            "code": "'0045",
          },
          {
            "name": "溪東里",
            "code": "'0046",
          },
          {
            "name": "淵中里",
            "code": "'0047",
          },
          {
            "name": "布袋里",
            "code": "'0048",
          },
          {
            "name": "國安里",
            "code": "'0049",
          },
          {
            "name": "安富里",
            "code": "'0050",
          },
          {
            "name": "大安里",
            "code": "'0051",
          }
        ]
      },
      {
        "name": "永康區",
        "code": "'310",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "五王里",
            "code": "'0001",
          },
          {
            "name": "網寮里",
            "code": "'0002",
          },
          {
            "name": "永康里",
            "code": "'0003",
          },
          {
            "name": "埔園里",
            "code": "'0004",
          },
          {
            "name": "大橋里",
            "code": "'0005",
          },
          {
            "name": "王行里",
            "code": "'0006",
          },
          {
            "name": "烏竹里",
            "code": "'0007",
          },
          {
            "name": "蔦松里",
            "code": "'0008",
          },
          {
            "name": "三民里",
            "code": "'0009",
          },
          {
            "name": "塩行里",
            "code": "'0010",
          },
          {
            "name": "甲頂里",
            "code": "'0011",
          },
          {
            "name": "大灣里",
            "code": "'0012",
          },
          {
            "name": "東灣里",
            "code": "'0013",
          },
          {
            "name": "西灣里",
            "code": "'0014",
          },
          {
            "name": "南灣里",
            "code": "'0015",
          },
          {
            "name": "崑山里",
            "code": "'0016",
          },
          {
            "name": "北灣里",
            "code": "'0017",
          },
          {
            "name": "新樹里",
            "code": "'0018",
          },
          {
            "name": "復興里",
            "code": "'0019",
          },
          {
            "name": "復國里",
            "code": "'0020",
          },
          {
            "name": "建國里",
            "code": "'0021",
          },
          {
            "name": "神洲里",
            "code": "'0022",
          },
          {
            "name": "西勢里",
            "code": "'0023",
          },
          {
            "name": "成功里",
            "code": "'0024",
          },
          {
            "name": "中興里",
            "code": "'0025",
          },
          {
            "name": "勝利里",
            "code": "'0026",
          },
          {
            "name": "龍潭里",
            "code": "'0027",
          },
          {
            "name": "光復里",
            "code": "'0028",
          },
          {
            "name": "塩洲里",
            "code": "'0029",
          },
          {
            "name": "二王里",
            "code": "'0030",
          },
          {
            "name": "六合里",
            "code": "'0031",
          },
          {
            "name": "三合里",
            "code": "'0032",
          },
          {
            "name": "東橋里",
            "code": "'0033",
          },
          {
            "name": "安康里",
            "code": "'0034",
          },
          {
            "name": "西橋里",
            "code": "'0035",
          },
          {
            "name": "尚頂里",
            "code": "'0036",
          },
          {
            "name": "復華里",
            "code": "'0037",
          },
          {
            "name": "正強里",
            "code": "'0038",
          },
          {
            "name": "中華里",
            "code": "'0039",
          },
          {
            "name": "北興里",
            "code": "'0040",
          },
          {
            "name": "龍埔里",
            "code": "'0041",
          },
          {
            "name": "永明里",
            "code": "'0042",
          },
          {
            "name": "塩興里",
            "code": "'0043",
          }
        ]
      },
      {
        "name": "北區",
        "code": "'340",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "開元里",
            "code": "'0001",
          },
          {
            "name": "東興里",
            "code": "'0004",
          },
          {
            "name": "力行里",
            "code": "'0005",
          },
          {
            "name": "振興里",
            "code": "'0007",
          },
          {
            "name": "仁愛里",
            "code": "'0008",
          },
          {
            "name": "大豐里",
            "code": "'0018",
          },
          {
            "name": "重興里",
            "code": "'0019",
          },
          {
            "name": "和順里",
            "code": "'0020",
          },
          {
            "name": "正覺里",
            "code": "'0023",
          },
          {
            "name": "成功里",
            "code": "'0024",
          },
          {
            "name": "文元里",
            "code": "'0025",
          },
          {
            "name": "大港里",
            "code": "'0026",
          },
          {
            "name": "中樓里",
            "code": "'0032",
          },
          {
            "name": "公園里",
            "code": "'0033",
          },
          {
            "name": "元寶里",
            "code": "'0038",
          },
          {
            "name": "永祥里",
            "code": "'0039",
          },
          {
            "name": "成德里",
            "code": "'0040",
          },
          {
            "name": "文成里",
            "code": "'0041",
          },
          {
            "name": "大和里",
            "code": "'0042",
          },
          {
            "name": "賢北里",
            "code": "'0043",
          },
          {
            "name": "長勝里",
            "code": "'0044",
          },
          {
            "name": "合興里",
            "code": "'0045",
          },
          {
            "name": "北門里",
            "code": "'0046",
          },
          {
            "name": "小北里",
            "code": "'0047",
          },
          {
            "name": "大光里",
            "code": "'0048",
          },
          {
            "name": "大興里",
            "code": "'0049",
          },
          {
            "name": "長興里",
            "code": "'0050",
          },
          {
            "name": "北華里",
            "code": "'0051",
          },
          {
            "name": "華德里",
            "code": "'0052",
          },
          {
            "name": "福德里",
            "code": "'0053",
          },
          {
            "name": "立人里",
            "code": "'0054",
          },
          {
            "name": "雙安里",
            "code": "'0055",
          },
          {
            "name": "元美里",
            "code": "'0056",
          }
        ]
      },
      {
        "name": "中西區",
        "code": "'370",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "赤嵌里",
            "code": "'0005",
          },
          {
            "name": "郡王里",
            "code": "'0007",
          },
          {
            "name": "法華里",
            "code": "'0009",
          },
          {
            "name": "開山里",
            "code": "'0011",
          },
          {
            "name": "永華里",
            "code": "'0016",
          },
          {
            "name": "藥王里",
            "code": "'0022",
          },
          {
            "name": "光賢里",
            "code": "'0025",
          },
          {
            "name": "大涼里",
            "code": "'0031",
          },
          {
            "name": "西湖里",
            "code": "'0033",
          },
          {
            "name": "西賢里",
            "code": "'0034",
          },
          {
            "name": "城隍里",
            "code": "'0039",
          },
          {
            "name": "南美里",
            "code": "'0040",
          },
          {
            "name": "南門里",
            "code": "'0041",
          },
          {
            "name": "小西門里",
            "code": "'0042",
          },
          {
            "name": "五條港里",
            "code": "'0043",
          },
          {
            "name": "兌悅里",
            "code": "'0044",
          },
          {
            "name": "淺草里",
            "code": "'0045",
          },
          {
            "name": "府前里",
            "code": "'0046",
          },
          {
            "name": "南廠里",
            "code": "'0047",
          },
          {
            "name": "西和里",
            "code": "'0048",
          }
        ]
      },
      {
        "name": "南區",
        "code": "'330",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "竹溪里",
            "code": "'0001",
          },
          {
            "name": "明德里",
            "code": "'0003",
          },
          {
            "name": "大成里",
            "code": "'0004",
          },
          {
            "name": "廣州里",
            "code": "'0005",
          },
          {
            "name": "新昌里",
            "code": "'0006",
          },
          {
            "name": "新興里",
            "code": "'0007",
          },
          {
            "name": "田寮里",
            "code": "'0008",
          },
          {
            "name": "國宅里",
            "code": "'0009",
          },
          {
            "name": "光明里",
            "code": "'0011",
          },
          {
            "name": "明亮里",
            "code": "'0013",
          },
          {
            "name": "喜東里",
            "code": "'0014",
          },
          {
            "name": "喜北里",
            "code": "'0015",
          },
          {
            "name": "喜南里",
            "code": "'0016",
          },
          {
            "name": "省躬里",
            "code": "'0017",
          },
          {
            "name": "興農里",
            "code": "'0018",
          },
          {
            "name": "同安里",
            "code": "'0019",
          },
          {
            "name": "佛壇里",
            "code": "'0020",
          },
          {
            "name": "大林里",
            "code": "'0021",
          },
          {
            "name": "大忠里",
            "code": "'0022",
          },
          {
            "name": "大恩里",
            "code": "'0023",
          },
          {
            "name": "新生里",
            "code": "'0024",
          },
          {
            "name": "再興里",
            "code": "'0025",
          },
          {
            "name": "明興里",
            "code": "'0026",
          },
          {
            "name": "文華里",
            "code": "'0027",
          },
          {
            "name": "金華里",
            "code": "'0028",
          },
          {
            "name": "南都里",
            "code": "'0029",
          },
          {
            "name": "開南里",
            "code": "'0030",
          },
          {
            "name": "彰南里",
            "code": "'0031",
          },
          {
            "name": "建南里",
            "code": "'0032",
          },
          {
            "name": "郡南里",
            "code": "'0033",
          },
          {
            "name": "府南里",
            "code": "'0034",
          },
          {
            "name": "文南里",
            "code": "'0035",
          },
          {
            "name": "鯤鯓里",
            "code": "'0036",
          },
          {
            "name": "松安里",
            "code": "'0037",
          },
          {
            "name": "永寧里",
            "code": "'0038",
          },
          {
            "name": "南華里",
            "code": "'0039",
          },
          {
            "name": "鹽埕里",
            "code": "'0040",
          }
        ]
      },
      {
        "name": "安平區",
        "code": "'360",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "金城里",
            "code": "'0002",
          },
          {
            "name": "漁光里",
            "code": "'0004",
          },
          {
            "name": "建平里",
            "code": "'0005",
          },
          {
            "name": "怡平里",
            "code": "'0006",
          },
          {
            "name": "華平里",
            "code": "'0007",
          },
          {
            "name": "平通里",
            "code": "'0008",
          },
          {
            "name": "文平里",
            "code": "'0009",
          },
          {
            "name": "國平里",
            "code": "'0010",
          },
          {
            "name": "育平里",
            "code": "'0011",
          },
          {
            "name": "億載里",
            "code": "'0012",
          },
          {
            "name": "平安里",
            "code": "'0015",
          },
          {
            "name": "天妃里",
            "code": "'0016",
          },
          {
            "name": "王城里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "東區",
        "code": "'320",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "富裕里",
            "code": "'0001",
          },
          {
            "name": "裕農里",
            "code": "'0002",
          },
          {
            "name": "大智里",
            "code": "'0003",
          },
          {
            "name": "崇學里",
            "code": "'0004",
          },
          {
            "name": "泉南里",
            "code": "'0005",
          },
          {
            "name": "仁和里",
            "code": "'0006",
          },
          {
            "name": "後甲里",
            "code": "'0007",
          },
          {
            "name": "東光里",
            "code": "'0008",
          },
          {
            "name": "新東里",
            "code": "'0009",
          },
          {
            "name": "中西里",
            "code": "'0010",
          },
          {
            "name": "東安里",
            "code": "'0011",
          },
          {
            "name": "崇明里",
            "code": "'0012",
          },
          {
            "name": "自強里",
            "code": "'0013",
          },
          {
            "name": "和平里",
            "code": "'0014",
          },
          {
            "name": "路東里",
            "code": "'0015",
          },
          {
            "name": "大德里",
            "code": "'0016",
          },
          {
            "name": "關聖里",
            "code": "'0017",
          },
          {
            "name": "衛國里",
            "code": "'0018",
          },
          {
            "name": "崇善里",
            "code": "'0019",
          },
          {
            "name": "富強里",
            "code": "'0020",
          },
          {
            "name": "圍下里",
            "code": "'0021",
          },
          {
            "name": "小東里",
            "code": "'0022",
          },
          {
            "name": "大學里",
            "code": "'0023",
          },
          {
            "name": "龍山里",
            "code": "'0024",
          },
          {
            "name": "虎尾里",
            "code": "'0025",
          },
          {
            "name": "德高里",
            "code": "'0026",
          },
          {
            "name": "莊敬里",
            "code": "'0027",
          },
          {
            "name": "大福里",
            "code": "'0028",
          },
          {
            "name": "忠孝里",
            "code": "'0029",
          },
          {
            "name": "崇誨里",
            "code": "'0030",
          },
          {
            "name": "東明里",
            "code": "'0031",
          },
          {
            "name": "崇德里",
            "code": "'0032",
          },
          {
            "name": "東智里",
            "code": "'0033",
          },
          {
            "name": "東聖里",
            "code": "'0034",
          },
          {
            "name": "崇成里",
            "code": "'0035",
          },
          {
            "name": "東門里",
            "code": "'0036",
          },
          {
            "name": "成大里",
            "code": "'0037",
          },
          {
            "name": "大同里",
            "code": "'0038",
          },
          {
            "name": "德光里",
            "code": "'0039",
          },
          {
            "name": "崇信里",
            "code": "'0040",
          },
          {
            "name": "崇文里",
            "code": "'0041",
          },
          {
            "name": "復興里",
            "code": "'0042",
          },
          {
            "name": "裕聖里",
            "code": "'0043",
          },
          {
            "name": "南聖里",
            "code": "'0044",
          },
          {
            "name": "文聖里",
            "code": "'0045",
          }
        ]
      },
      {
        "name": "仁德區",
        "code": "'270",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "太子里",
            "code": "'0001",
          },
          {
            "name": "土庫里",
            "code": "'0002",
          },
          {
            "name": "一甲里",
            "code": "'0003",
          },
          {
            "name": "仁德里",
            "code": "'0004",
          },
          {
            "name": "仁義里",
            "code": "'0005",
          },
          {
            "name": "新田里",
            "code": "'0006",
          },
          {
            "name": "後壁里",
            "code": "'0007",
          },
          {
            "name": "上崙里",
            "code": "'0008",
          },
          {
            "name": "保安里",
            "code": "'0011",
          },
          {
            "name": "成功里",
            "code": "'0012",
          },
          {
            "name": "仁和里",
            "code": "'0013",
          },
          {
            "name": "仁愛里",
            "code": "'0014",
          },
          {
            "name": "二行里",
            "code": "'0015",
          },
          {
            "name": "大甲里",
            "code": "'0016",
          },
          {
            "name": "中洲里",
            "code": "'0017",
          },
          {
            "name": "文賢里",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "歸仁區",
        "code": "'280",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "南保里",
            "code": "'0001",
          },
          {
            "name": "六甲里",
            "code": "'0002",
          },
          {
            "name": "歸仁里",
            "code": "'0003",
          },
          {
            "name": "後市里",
            "code": "'0004",
          },
          {
            "name": "辜厝里",
            "code": "'0005",
          },
          {
            "name": "許厝里",
            "code": "'0006",
          },
          {
            "name": "看西里",
            "code": "'0007",
          },
          {
            "name": "看東里",
            "code": "'0008",
          },
          {
            "name": "崙頂里",
            "code": "'0009",
          },
          {
            "name": "沙崙里",
            "code": "'0010",
          },
          {
            "name": "大潭里",
            "code": "'0011",
          },
          {
            "name": "武東里",
            "code": "'0012",
          },
          {
            "name": "八甲里",
            "code": "'0013",
          },
          {
            "name": "七甲里",
            "code": "'0014",
          },
          {
            "name": "媽廟里",
            "code": "'0015",
          },
          {
            "name": "西埔里",
            "code": "'0016",
          },
          {
            "name": "大廟里",
            "code": "'0017",
          },
          {
            "name": "南興里",
            "code": "'0018",
          },
          {
            "name": "新厝里",
            "code": "'0019",
          },
          {
            "name": "歸南里",
            "code": "'0020",
          },
          {
            "name": "文化里",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "關廟區",
        "code": "'290",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "關廟里",
            "code": "'0001",
          },
          {
            "name": "山西里",
            "code": "'0002",
          },
          {
            "name": "香洋里",
            "code": "'0003",
          },
          {
            "name": "北勢里",
            "code": "'0004",
          },
          {
            "name": "新埔里",
            "code": "'0005",
          },
          {
            "name": "新光里",
            "code": "'0006",
          },
          {
            "name": "五甲里",
            "code": "'0007",
          },
          {
            "name": "東勢里",
            "code": "'0008",
          },
          {
            "name": "松腳里",
            "code": "'0009",
          },
          {
            "name": "深坑里",
            "code": "'0012",
          },
          {
            "name": "布袋里",
            "code": "'0013",
          },
          {
            "name": "埤頭里",
            "code": "'0016",
          },
          {
            "name": "下湖里",
            "code": "'0017",
          },
          {
            "name": "花園里",
            "code": "'0018",
          },
          {
            "name": "南雄里",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "龍崎區",
        "code": "'300",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "崎頂里",
            "code": "'0001",
          },
          {
            "name": "土崎里",
            "code": "'0002",
          },
          {
            "name": "中坑里",
            "code": "'0003",
          },
          {
            "name": "楠坑里",
            "code": "'0004",
          },
          {
            "name": "牛埔里",
            "code": "'0005",
          },
          {
            "name": "大坪里",
            "code": "'0006",
          },
          {
            "name": "龍船里",
            "code": "'0007",
          },
          {
            "name": "石𥕢里",
            "code": "'0008",
          },
          {
            "name": "旗山區",
            "code": "'0000",
          },
          {
            "name": "太平里",
            "code": "'0001",
          },
          {
            "name": "大德里",
            "code": "'0002",
          },
          {
            "name": "湄洲里",
            "code": "'0003",
          },
          {
            "name": "圓富里",
            "code": "'0004",
          },
          {
            "name": "中正里",
            "code": "'0005",
          },
          {
            "name": "大林里",
            "code": "'0006",
          },
          {
            "name": "上洲里",
            "code": "'0007",
          },
          {
            "name": "新光里",
            "code": "'0008",
          },
          {
            "name": "南勝里",
            "code": "'0009",
          },
          {
            "name": "中寮里",
            "code": "'0010",
          },
          {
            "name": "東平里",
            "code": "'0011",
          },
          {
            "name": "東昌里",
            "code": "'0012",
          },
          {
            "name": "竹峰里",
            "code": "'0013",
          },
          {
            "name": "瑞吉里",
            "code": "'0014",
          },
          {
            "name": "永和里",
            "code": "'0015",
          },
          {
            "name": "三協里",
            "code": "'0016",
          },
          {
            "name": "鯤洲里",
            "code": "'0017",
          },
          {
            "name": "大山里",
            "code": "'0018",
          },
          {
            "name": "中洲里",
            "code": "'0019",
          },
          {
            "name": "南洲里",
            "code": "'0020",
          },
          {
            "name": "廣福里",
            "code": "'0021",
          }
        ]
      }
    ]
  },
  {
    "name": "高雄市",
    "code": "'64'000",
    "subLocation": [
      {
        "name": "美濃區",
        "code": "'310",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "福安里",
            "code": "'0001",
          },
          {
            "name": "合和里",
            "code": "'0002",
          },
          {
            "name": "祿興里",
            "code": "'0003",
          },
          {
            "name": "中壇里",
            "code": "'0004",
          },
          {
            "name": "德興里",
            "code": "'0005",
          },
          {
            "name": "龍山里",
            "code": "'0006",
          },
          {
            "name": "獅山里",
            "code": "'0007",
          },
          {
            "name": "龍肚里",
            "code": "'0008",
          },
          {
            "name": "廣德里",
            "code": "'0009",
          },
          {
            "name": "興隆里",
            "code": "'0010",
          },
          {
            "name": "中圳里",
            "code": "'0011",
          },
          {
            "name": "東門里",
            "code": "'0012",
          },
          {
            "name": "泰安里",
            "code": "'0013",
          },
          {
            "name": "瀰濃里",
            "code": "'0014",
          },
          {
            "name": "清水里",
            "code": "'0015",
          },
          {
            "name": "吉洋里",
            "code": "'0016",
          },
          {
            "name": "吉和里",
            "code": "'0017",
          },
          {
            "name": "吉東里",
            "code": "'0018",
          },
          {
            "name": "廣林里",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "六龜區",
        "code": "'320",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "新威里",
            "code": "'0001",
          },
          {
            "name": "新興里",
            "code": "'0002",
          },
          {
            "name": "新寮里",
            "code": "'0003",
          },
          {
            "name": "新發里",
            "code": "'0004",
          },
          {
            "name": "荖濃里",
            "code": "'0005",
          },
          {
            "name": "六龜里",
            "code": "'0006",
          },
          {
            "name": "義寶里",
            "code": "'0007",
          },
          {
            "name": "興龍里",
            "code": "'0008",
          },
          {
            "name": "中興里",
            "code": "'0009",
          },
          {
            "name": "寶來里",
            "code": "'0010",
          },
          {
            "name": "文武里",
            "code": "'0011",
          },
          {
            "name": "大津里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "甲仙區",
        "code": "'330",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "東安里",
            "code": "'0001",
          },
          {
            "name": "西安里",
            "code": "'0002",
          },
          {
            "name": "和安里",
            "code": "'0003",
          },
          {
            "name": "大田里",
            "code": "'0004",
          },
          {
            "name": "寶隆里",
            "code": "'0005",
          },
          {
            "name": "關山里",
            "code": "'0006",
          },
          {
            "name": "小林里",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "杉林區",
        "code": "'340",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "杉林里",
            "code": "'0001",
          },
          {
            "name": "木梓里",
            "code": "'0002",
          },
          {
            "name": "集來里",
            "code": "'0003",
          },
          {
            "name": "新庄里",
            "code": "'0004",
          },
          {
            "name": "上平里",
            "code": "'0005",
          },
          {
            "name": "月眉里",
            "code": "'0006",
          },
          {
            "name": "月美里",
            "code": "'0007",
          },
          {
            "name": "大愛里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "內門區",
        "code": "'350",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "溝坪里",
            "code": "'0001",
          },
          {
            "name": "金竹里",
            "code": "'0002",
          },
          {
            "name": "永富里",
            "code": "'0003",
          },
          {
            "name": "永吉里",
            "code": "'0004",
          },
          {
            "name": "永興里",
            "code": "'0005",
          },
          {
            "name": "石坑里",
            "code": "'0006",
          },
          {
            "name": "內門里",
            "code": "'0007",
          },
          {
            "name": "內豊里",
            "code": "'0008",
          },
          {
            "name": "觀亭里",
            "code": "'0009",
          },
          {
            "name": "中埔里",
            "code": "'0010",
          },
          {
            "name": "內東里",
            "code": "'0011",
          },
          {
            "name": "內南里",
            "code": "'0012",
          },
          {
            "name": "東埔里",
            "code": "'0013",
          },
          {
            "name": "三平里",
            "code": "'0014",
          },
          {
            "name": "木柵里",
            "code": "'0015",
          },
          {
            "name": "內興里",
            "code": "'0016",
          },
          {
            "name": "瑞山里",
            "code": "'0017",
          },
          {
            "name": "光興里",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "茂林區",
        "code": "'360",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "茂林里",
            "code": "'0001",
          },
          {
            "name": "萬山里",
            "code": "'0002",
          },
          {
            "name": "多納里",
            "code": "'0003",
          }
        ]
      },
      {
        "name": "桃源區",
        "code": "'370",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "桃源里",
            "code": "'0001",
          },
          {
            "name": "寶山里",
            "code": "'0002",
          },
          {
            "name": "建山里",
            "code": "'0003",
          },
          {
            "name": "高中里",
            "code": "'0004",
          },
          {
            "name": "勤和里",
            "code": "'0005",
          },
          {
            "name": "復興里",
            "code": "'0006",
          },
          {
            "name": "拉芙蘭里",
            "code": "'0007",
          },
          {
            "name": "梅山里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "那瑪夏區",
        "code": "'380",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "達卡努瓦里",
            "code": "'0001",
          },
          {
            "name": "瑪雅里",
            "code": "'0002",
          },
          {
            "name": "南沙魯里",
            "code": "'0003",
          }
        ]
      },
      {
        "name": "田寮區",
        "code": "'220",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "鹿埔里",
            "code": "'0001",
          },
          {
            "name": "南安里",
            "code": "'0002",
          },
          {
            "name": "大同里",
            "code": "'0003",
          },
          {
            "name": "田寮里",
            "code": "'0004",
          },
          {
            "name": "七星里",
            "code": "'0005",
          },
          {
            "name": "崇德里",
            "code": "'0006",
          },
          {
            "name": "西德里",
            "code": "'0007",
          },
          {
            "name": "三和里",
            "code": "'0008",
          },
          {
            "name": "古亭里",
            "code": "'0009",
          },
          {
            "name": "新興里",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "阿蓮區",
        "code": "'230",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "石安里",
            "code": "'0001",
          },
          {
            "name": "中路里",
            "code": "'0002",
          },
          {
            "name": "峯山里",
            "code": "'0003",
          },
          {
            "name": "港後里",
            "code": "'0004",
          },
          {
            "name": "崗山里",
            "code": "'0005",
          },
          {
            "name": "阿蓮里",
            "code": "'0006",
          },
          {
            "name": "清蓮里",
            "code": "'0007",
          },
          {
            "name": "和蓮里",
            "code": "'0008",
          },
          {
            "name": "青旗里",
            "code": "'0009",
          },
          {
            "name": "復安里",
            "code": "'0010",
          },
          {
            "name": "玉庫里",
            "code": "'0011",
          },
          {
            "name": "南蓮里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "路竹區",
        "code": "'240",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "竹滬里",
            "code": "'0001",
          },
          {
            "name": "頂寮里",
            "code": "'0002",
          },
          {
            "name": "新達里",
            "code": "'0003",
          },
          {
            "name": "後鄉里",
            "code": "'0004",
          },
          {
            "name": "北嶺里",
            "code": "'0005",
          },
          {
            "name": "社西里",
            "code": "'0006",
          },
          {
            "name": "甲北里",
            "code": "'0007",
          },
          {
            "name": "甲南里",
            "code": "'0008",
          },
          {
            "name": "下坑里",
            "code": "'0009",
          },
          {
            "name": "竹園里",
            "code": "'0010",
          },
          {
            "name": "竹東里",
            "code": "'0011",
          },
          {
            "name": "竹西里",
            "code": "'0012",
          },
          {
            "name": "文北里",
            "code": "'0013",
          },
          {
            "name": "文南里",
            "code": "'0014",
          },
          {
            "name": "三爺里",
            "code": "'0015",
          },
          {
            "name": "鴨寮里",
            "code": "'0016",
          },
          {
            "name": "社東里",
            "code": "'0017",
          },
          {
            "name": "社中里",
            "code": "'0018",
          },
          {
            "name": "竹南里",
            "code": "'0019",
          },
          {
            "name": "社南里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "湖內區",
        "code": "'250",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "海山里",
            "code": "'0001",
          },
          {
            "name": "劉家里",
            "code": "'0002",
          },
          {
            "name": "太爺里",
            "code": "'0003",
          },
          {
            "name": "公舘里",
            "code": "'0004",
          },
          {
            "name": "葉厝里",
            "code": "'0005",
          },
          {
            "name": "大湖里",
            "code": "'0006",
          },
          {
            "name": "田尾里",
            "code": "'0007",
          },
          {
            "name": "湖內里",
            "code": "'0008",
          },
          {
            "name": "海埔里",
            "code": "'0009",
          },
          {
            "name": "文賢里",
            "code": "'0010",
          },
          {
            "name": "中賢里",
            "code": "'0011",
          },
          {
            "name": "逸賢里",
            "code": "'0012",
          },
          {
            "name": "忠興里",
            "code": "'0013",
          },
          {
            "name": "湖東里",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "茄萣區",
        "code": "'260",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "福德里",
            "code": "'0001",
          },
          {
            "name": "萬福里",
            "code": "'0002",
          },
          {
            "name": "白雲里",
            "code": "'0003",
          },
          {
            "name": "嘉賜里",
            "code": "'0004",
          },
          {
            "name": "嘉安里",
            "code": "'0005",
          },
          {
            "name": "嘉定里",
            "code": "'0006",
          },
          {
            "name": "保定里",
            "code": "'0007",
          },
          {
            "name": "光定里",
            "code": "'0008",
          },
          {
            "name": "大定里",
            "code": "'0009",
          },
          {
            "name": "吉定里",
            "code": "'0010",
          },
          {
            "name": "嘉樂里",
            "code": "'0011",
          },
          {
            "name": "嘉泰里",
            "code": "'0012",
          },
          {
            "name": "嘉福里",
            "code": "'0013",
          },
          {
            "name": "和協里",
            "code": "'0014",
          },
          {
            "name": "崎漏里",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "岡山區",
        "code": "'190",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "平安里",
            "code": "'0001",
          },
          {
            "name": "岡山里",
            "code": "'0002",
          },
          {
            "name": "壽天里",
            "code": "'0003",
          },
          {
            "name": "維仁里",
            "code": "'0004",
          },
          {
            "name": "後紅里",
            "code": "'0005",
          },
          {
            "name": "大遼里",
            "code": "'0006",
          },
          {
            "name": "忠孝里",
            "code": "'0007",
          },
          {
            "name": "和平里",
            "code": "'0008",
          },
          {
            "name": "前峰里",
            "code": "'0009",
          },
          {
            "name": "劉厝里",
            "code": "'0010",
          },
          {
            "name": "協和里",
            "code": "'0011",
          },
          {
            "name": "後協里",
            "code": "'0012",
          },
          {
            "name": "信義里",
            "code": "'0013",
          },
          {
            "name": "潭底里",
            "code": "'0014",
          },
          {
            "name": "三和里",
            "code": "'0015",
          },
          {
            "name": "仁壽里",
            "code": "'0016",
          },
          {
            "name": "碧紅里",
            "code": "'0017",
          },
          {
            "name": "程香里",
            "code": "'0018",
          },
          {
            "name": "竹圍里",
            "code": "'0019",
          },
          {
            "name": "台上里",
            "code": "'0020",
          },
          {
            "name": "灣裡里",
            "code": "'0021",
          },
          {
            "name": "白米里",
            "code": "'0022",
          },
          {
            "name": "石潭里",
            "code": "'0023",
          },
          {
            "name": "福興里",
            "code": "'0024",
          },
          {
            "name": "本洲里",
            "code": "'0025",
          },
          {
            "name": "嘉興里",
            "code": "'0026",
          },
          {
            "name": "嘉峰里",
            "code": "'0027",
          },
          {
            "name": "華崗里",
            "code": "'0028",
          },
          {
            "name": "大莊里",
            "code": "'0029",
          },
          {
            "name": "協榮里",
            "code": "'0030",
          },
          {
            "name": "為隨里",
            "code": "'0031",
          },
          {
            "name": "壽峰里",
            "code": "'0032",
          },
          {
            "name": "仁義里",
            "code": "'0033",
          }
        ]
      },
      {
        "name": "橋頭區",
        "code": "'200",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "橋頭里",
            "code": "'0001",
          },
          {
            "name": "橋南里",
            "code": "'0002",
          },
          {
            "name": "仕隆里",
            "code": "'0003",
          },
          {
            "name": "仕豐里",
            "code": "'0004",
          },
          {
            "name": "芋寮里",
            "code": "'0005",
          },
          {
            "name": "東林里",
            "code": "'0006",
          },
          {
            "name": "西林里",
            "code": "'0007",
          },
          {
            "name": "白樹里",
            "code": "'0008",
          },
          {
            "name": "筆秀里",
            "code": "'0009",
          },
          {
            "name": "新莊里",
            "code": "'0010",
          },
          {
            "name": "甲北里",
            "code": "'0011",
          },
          {
            "name": "甲南里",
            "code": "'0012",
          },
          {
            "name": "頂鹽里",
            "code": "'0013",
          },
          {
            "name": "中崎里",
            "code": "'0014",
          },
          {
            "name": "仕和里",
            "code": "'0015",
          },
          {
            "name": "德松里",
            "code": "'0016",
          },
          {
            "name": "三德里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "燕巢區",
        "code": "'210",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "尖山里",
            "code": "'0001",
          },
          {
            "name": "瓊林里",
            "code": "'0002",
          },
          {
            "name": "安招里",
            "code": "'0003",
          },
          {
            "name": "角宿里",
            "code": "'0004",
          },
          {
            "name": "鳳雄里",
            "code": "'0005",
          },
          {
            "name": "金山里",
            "code": "'0006",
          },
          {
            "name": "東燕里",
            "code": "'0007",
          },
          {
            "name": "南燕里",
            "code": "'0008",
          },
          {
            "name": "西燕里",
            "code": "'0009",
          },
          {
            "name": "橫山里",
            "code": "'0010",
          },
          {
            "name": "深水里",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "永安區",
        "code": "'270",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "永安里",
            "code": "'0001",
          },
          {
            "name": "永華里",
            "code": "'0002",
          },
          {
            "name": "新港里",
            "code": "'0003",
          },
          {
            "name": "鹽田里",
            "code": "'0004",
          },
          {
            "name": "保寧里",
            "code": "'0005",
          },
          {
            "name": "維新里",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "彌陀區",
        "code": "'280",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "光和里",
            "code": "'0001",
          },
          {
            "name": "彌靖里",
            "code": "'0002",
          },
          {
            "name": "彌仁里",
            "code": "'0003",
          },
          {
            "name": "彌壽里",
            "code": "'0004",
          },
          {
            "name": "彌陀里",
            "code": "'0005",
          },
          {
            "name": "舊港里",
            "code": "'0006",
          },
          {
            "name": "文安里",
            "code": "'0007",
          },
          {
            "name": "鹽埕里",
            "code": "'0008",
          },
          {
            "name": "過港里",
            "code": "'0009",
          },
          {
            "name": "海尾里",
            "code": "'0010",
          },
          {
            "name": "漯底里",
            "code": "'0011",
          },
          {
            "name": "南寮里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "梓官區",
        "code": "'290",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "梓信里",
            "code": "'0001",
          },
          {
            "name": "梓義里",
            "code": "'0002",
          },
          {
            "name": "梓和里",
            "code": "'0003",
          },
          {
            "name": "梓平里",
            "code": "'0004",
          },
          {
            "name": "中崙里",
            "code": "'0005",
          },
          {
            "name": "赤崁里",
            "code": "'0006",
          },
          {
            "name": "禮蚵里",
            "code": "'0007",
          },
          {
            "name": "智蚵里",
            "code": "'0008",
          },
          {
            "name": "信蚵里",
            "code": "'0009",
          },
          {
            "name": "同安里",
            "code": "'0010",
          },
          {
            "name": "大舍里",
            "code": "'0011",
          },
          {
            "name": "赤東里",
            "code": "'0012",
          },
          {
            "name": "赤西里",
            "code": "'0013",
          },
          {
            "name": "茄苳里",
            "code": "'0014",
          },
          {
            "name": "典寶里",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "左營區",
        "code": "'030",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "進學里",
            "code": "'0001",
          },
          {
            "name": "尾西里",
            "code": "'0002",
          },
          {
            "name": "頂北里",
            "code": "'0003",
          },
          {
            "name": "中北里",
            "code": "'0004",
          },
          {
            "name": "中南里",
            "code": "'0005",
          },
          {
            "name": "廟東里",
            "code": "'0006",
          },
          {
            "name": "廟北里",
            "code": "'0007",
          },
          {
            "name": "尾南里",
            "code": "'0008",
          },
          {
            "name": "尾北里",
            "code": "'0009",
          },
          {
            "name": "屏山里",
            "code": "'0011",
          },
          {
            "name": "祥和里",
            "code": "'0012",
          },
          {
            "name": "永清里",
            "code": "'0013",
          },
          {
            "name": "莒光里",
            "code": "'0015",
          },
          {
            "name": "光輝里",
            "code": "'0016",
          },
          {
            "name": "合群里",
            "code": "'0017",
          },
          {
            "name": "明建里",
            "code": "'0019",
          },
          {
            "name": "頂西里",
            "code": "'0021",
          },
          {
            "name": "聖后里",
            "code": "'0022",
          },
          {
            "name": "聖西里",
            "code": "'0023",
          },
          {
            "name": "聖南里",
            "code": "'0024",
          },
          {
            "name": "城南里",
            "code": "'0025",
          },
          {
            "name": "路東里",
            "code": "'0026",
          },
          {
            "name": "廍北里",
            "code": "'0027",
          },
          {
            "name": "廍南里",
            "code": "'0028",
          },
          {
            "name": "埤西里",
            "code": "'0029",
          },
          {
            "name": "埤北里",
            "code": "'0030",
          },
          {
            "name": "埤東里",
            "code": "'0031",
          },
          {
            "name": "海勝里",
            "code": "'0032",
          },
          {
            "name": "崇實里",
            "code": "'0034",
          },
          {
            "name": "自助里",
            "code": "'0035",
          },
          {
            "name": "果貿里",
            "code": "'0036",
          },
          {
            "name": "果惠里",
            "code": "'0037",
          },
          {
            "name": "果峰里",
            "code": "'0038",
          },
          {
            "name": "新下里",
            "code": "'0039",
          },
          {
            "name": "新上里",
            "code": "'0040",
          },
          {
            "name": "新中里",
            "code": "'0041",
          },
          {
            "name": "新光里",
            "code": "'0042",
          },
          {
            "name": "菜公里",
            "code": "'0043",
          },
          {
            "name": "福山里",
            "code": "'0044",
          }
        ]
      },
      {
        "name": "楠梓區",
        "code": "'040",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "清豐里",
            "code": "'0001",
          },
          {
            "name": "東寧里",
            "code": "'0002",
          },
          {
            "name": "五常里",
            "code": "'0003",
          },
          {
            "name": "享平里",
            "code": "'0004",
          },
          {
            "name": "中陽里",
            "code": "'0005",
          },
          {
            "name": "惠楠里",
            "code": "'0006",
          },
          {
            "name": "惠民里",
            "code": "'0007",
          },
          {
            "name": "惠豐里",
            "code": "'0008",
          },
          {
            "name": "錦屏里",
            "code": "'0009",
          },
          {
            "name": "玉屏里",
            "code": "'0010",
          },
          {
            "name": "金田里",
            "code": "'0011",
          },
          {
            "name": "稔田里",
            "code": "'0012",
          },
          {
            "name": "瑞屏里",
            "code": "'0013",
          },
          {
            "name": "翠屏里",
            "code": "'0014",
          },
          {
            "name": "宏南里",
            "code": "'0015",
          },
          {
            "name": "宏毅里",
            "code": "'0016",
          },
          {
            "name": "宏榮里",
            "code": "'0017",
          },
          {
            "name": "廣昌里",
            "code": "'0018",
          },
          {
            "name": "久昌里",
            "code": "'0019",
          },
          {
            "name": "大昌里",
            "code": "'0020",
          },
          {
            "name": "福昌里",
            "code": "'0021",
          },
          {
            "name": "盛昌里",
            "code": "'0022",
          },
          {
            "name": "泰昌里",
            "code": "'0023",
          },
          {
            "name": "興昌里",
            "code": "'0024",
          },
          {
            "name": "建昌里",
            "code": "'0025",
          },
          {
            "name": "宏昌里",
            "code": "'0026",
          },
          {
            "name": "和昌里",
            "code": "'0027",
          },
          {
            "name": "慶昌里",
            "code": "'0028",
          },
          {
            "name": "隆昌里",
            "code": "'0029",
          },
          {
            "name": "秀昌里",
            "code": "'0030",
          },
          {
            "name": "裕昌里",
            "code": "'0031",
          },
          {
            "name": "國昌里",
            "code": "'0032",
          },
          {
            "name": "加昌里",
            "code": "'0033",
          },
          {
            "name": "仁昌里",
            "code": "'0034",
          },
          {
            "name": "藍田里",
            "code": "'0035",
          },
          {
            "name": "中興里",
            "code": "'0036",
          },
          {
            "name": "中和里",
            "code": "'0037",
          }
        ]
      },
      {
        "name": "大樹區",
        "code": "'150",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "竹寮里",
            "code": "'0001",
          },
          {
            "name": "九曲里",
            "code": "'0002",
          },
          {
            "name": "久堂里",
            "code": "'0003",
          },
          {
            "name": "水安里",
            "code": "'0004",
          },
          {
            "name": "水寮里",
            "code": "'0005",
          },
          {
            "name": "檨腳里",
            "code": "'0006",
          },
          {
            "name": "興山里",
            "code": "'0007",
          },
          {
            "name": "和山里",
            "code": "'0008",
          },
          {
            "name": "姑山里",
            "code": "'0009",
          },
          {
            "name": "大坑里",
            "code": "'0010",
          },
          {
            "name": "井腳里",
            "code": "'0011",
          },
          {
            "name": "小坪里",
            "code": "'0012",
          },
          {
            "name": "龍目里",
            "code": "'0013",
          },
          {
            "name": "大樹里",
            "code": "'0014",
          },
          {
            "name": "三和里",
            "code": "'0015",
          },
          {
            "name": "溪埔里",
            "code": "'0016",
          },
          {
            "name": "興田里",
            "code": "'0017",
          },
          {
            "name": "統嶺里",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "大社區",
        "code": "'160",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "嘉誠里",
            "code": "'0001",
          },
          {
            "name": "保社里",
            "code": "'0002",
          },
          {
            "name": "大社里",
            "code": "'0003",
          },
          {
            "name": "翠屏里",
            "code": "'0004",
          },
          {
            "name": "三奶里",
            "code": "'0005",
          },
          {
            "name": "觀音里",
            "code": "'0006",
          },
          {
            "name": "神農里",
            "code": "'0007",
          },
          {
            "name": "中里里",
            "code": "'0008",
          },
          {
            "name": "保安里",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "仁武區",
        "code": "'170",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "大灣里",
            "code": "'0001",
          },
          {
            "name": "灣內里",
            "code": "'0002",
          },
          {
            "name": "考潭里",
            "code": "'0003",
          },
          {
            "name": "烏林里",
            "code": "'0004",
          },
          {
            "name": "仁福里",
            "code": "'0005",
          },
          {
            "name": "仁武里",
            "code": "'0006",
          },
          {
            "name": "文武里",
            "code": "'0007",
          },
          {
            "name": "竹後里",
            "code": "'0008",
          },
          {
            "name": "八卦里",
            "code": "'0009",
          },
          {
            "name": "高楠里",
            "code": "'0010",
          },
          {
            "name": "後安里",
            "code": "'0011",
          },
          {
            "name": "中華里",
            "code": "'0012",
          },
          {
            "name": "五和里",
            "code": "'0013",
          },
          {
            "name": "仁和里",
            "code": "'0014",
          },
          {
            "name": "赤山里",
            "code": "'0015",
          },
          {
            "name": "仁慈里",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "鳥松區",
        "code": "'180",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "鳥松里",
            "code": "'0001",
          },
          {
            "name": "夢裡里",
            "code": "'0002",
          },
          {
            "name": "大華里",
            "code": "'0003",
          },
          {
            "name": "坔埔里",
            "code": "'0004",
          },
          {
            "name": "仁美里",
            "code": "'0005",
          },
          {
            "name": "大竹里",
            "code": "'0006",
          },
          {
            "name": "華美里",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "鹽埕區",
        "code": "'010",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "藍橋里",
            "code": "'0001",
          },
          {
            "name": "慈愛里",
            "code": "'0002",
          },
          {
            "name": "博愛里",
            "code": "'0003",
          },
          {
            "name": "壽星里",
            "code": "'0004",
          },
          {
            "name": "中山里",
            "code": "'0005",
          },
          {
            "name": "教仁里",
            "code": "'0006",
          },
          {
            "name": "新樂里",
            "code": "'0007",
          },
          {
            "name": "中原里",
            "code": "'0008",
          },
          {
            "name": "光明里",
            "code": "'0009",
          },
          {
            "name": "育仁里",
            "code": "'0010",
          },
          {
            "name": "河濱里",
            "code": "'0011",
          },
          {
            "name": "沙地里",
            "code": "'0012",
          },
          {
            "name": "南端里",
            "code": "'0013",
          },
          {
            "name": "港都里",
            "code": "'0014",
          },
          {
            "name": "江西里",
            "code": "'0015",
          },
          {
            "name": "新豐里",
            "code": "'0016",
          },
          {
            "name": "府北里",
            "code": "'0017",
          },
          {
            "name": "陸橋里",
            "code": "'0018",
          },
          {
            "name": "瀨南里",
            "code": "'0019",
          },
          {
            "name": "新化里",
            "code": "'0020",
          },
          {
            "name": "江南里",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "鼓山區",
        "code": "'020",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "鼓峰里",
            "code": "'0001",
          },
          {
            "name": "雄峰里",
            "code": "'0002",
          },
          {
            "name": "前峰里",
            "code": "'0003",
          },
          {
            "name": "光榮里",
            "code": "'0004",
          },
          {
            "name": "民族里",
            "code": "'0005",
          },
          {
            "name": "內惟里",
            "code": "'0006",
          },
          {
            "name": "建國里",
            "code": "'0007",
          },
          {
            "name": "忠正里",
            "code": "'0008",
          },
          {
            "name": "自強里",
            "code": "'0009",
          },
          {
            "name": "龍井里",
            "code": "'0010",
          },
          {
            "name": "正德里",
            "code": "'0011",
          },
          {
            "name": "平和里",
            "code": "'0012",
          },
          {
            "name": "民強里",
            "code": "'0013",
          },
          {
            "name": "厚生里",
            "code": "'0014",
          },
          {
            "name": "龍子里",
            "code": "'0015",
          },
          {
            "name": "龍水里",
            "code": "'0016",
          },
          {
            "name": "明誠里",
            "code": "'0017",
          },
          {
            "name": "華豐里",
            "code": "'0018",
          },
          {
            "name": "裕興里",
            "code": "'0019",
          },
          {
            "name": "裕豐里",
            "code": "'0020",
          },
          {
            "name": "鼓岩里",
            "code": "'0021",
          },
          {
            "name": "樹德里",
            "code": "'0022",
          },
          {
            "name": "寶樹里",
            "code": "'0023",
          },
          {
            "name": "興宗里",
            "code": "'0024",
          },
          {
            "name": "光化里",
            "code": "'0025",
          },
          {
            "name": "山下里",
            "code": "'0026",
          },
          {
            "name": "河邊里",
            "code": "'0027",
          },
          {
            "name": "綠川里",
            "code": "'0028",
          },
          {
            "name": "登山里",
            "code": "'0029",
          },
          {
            "name": "峰南里",
            "code": "'0030",
          },
          {
            "name": "麗興里",
            "code": "'0031",
          },
          {
            "name": "新民里",
            "code": "'0032",
          },
          {
            "name": "延平里",
            "code": "'0033",
          },
          {
            "name": "維生里",
            "code": "'0034",
          },
          {
            "name": "惠安里",
            "code": "'0035",
          },
          {
            "name": "壽山里",
            "code": "'0036",
          },
          {
            "name": "哨船頭里",
            "code": "'0037",
          },
          {
            "name": "桃源里",
            "code": "'0038",
          }
        ]
      },
      {
        "name": "旗津區",
        "code": "'100",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "旗下里",
            "code": "'0001",
          },
          {
            "name": "永安里",
            "code": "'0002",
          },
          {
            "name": "振興里",
            "code": "'0003",
          },
          {
            "name": "慈愛里",
            "code": "'0004",
          },
          {
            "name": "復興里",
            "code": "'0005",
          },
          {
            "name": "中華里",
            "code": "'0006",
          },
          {
            "name": "實踐里",
            "code": "'0007",
          },
          {
            "name": "北汕里",
            "code": "'0008",
          },
          {
            "name": "南汕里",
            "code": "'0009",
          },
          {
            "name": "上竹里",
            "code": "'0010",
          },
          {
            "name": "中洲里",
            "code": "'0011",
          },
          {
            "name": "安順里",
            "code": "'0012",
          },
          {
            "name": "中興里",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "三民區",
        "code": "'050",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "鼎金里",
            "code": "'0001",
          },
          {
            "name": "鼎盛里",
            "code": "'0002",
          },
          {
            "name": "鼎強里",
            "code": "'0003",
          },
          {
            "name": "鼎力里",
            "code": "'0004",
          },
          {
            "name": "鼎西里",
            "code": "'0005",
          },
          {
            "name": "鼎中里",
            "code": "'0006",
          },
          {
            "name": "鼎泰里",
            "code": "'0007",
          },
          {
            "name": "本館里",
            "code": "'0008",
          },
          {
            "name": "本和里",
            "code": "'0009",
          },
          {
            "name": "本文里",
            "code": "'0010",
          },
          {
            "name": "本武里",
            "code": "'0011",
          },
          {
            "name": "本元里",
            "code": "'0012",
          },
          {
            "name": "本安里",
            "code": "'0013",
          },
          {
            "name": "本上里",
            "code": "'0014",
          },
          {
            "name": "本揚里",
            "code": "'0015",
          },
          {
            "name": "寶獅里",
            "code": "'0016",
          },
          {
            "name": "寶德里",
            "code": "'0017",
          },
          {
            "name": "寶泰里",
            "code": "'0018",
          },
          {
            "name": "寶興里",
            "code": "'0019",
          },
          {
            "name": "寶中里",
            "code": "'0020",
          },
          {
            "name": "寶華里",
            "code": "'0021",
          },
          {
            "name": "寶國里",
            "code": "'0022",
          },
          {
            "name": "寶民里",
            "code": "'0023",
          },
          {
            "name": "寶慶里",
            "code": "'0024",
          },
          {
            "name": "寶業里",
            "code": "'0025",
          },
          {
            "name": "寶盛里",
            "code": "'0026",
          },
          {
            "name": "寶安里",
            "code": "'0027",
          },
          {
            "name": "寶龍里",
            "code": "'0028",
          },
          {
            "name": "寶珠里",
            "code": "'0029",
          },
          {
            "name": "寶玉里",
            "code": "'0030",
          },
          {
            "name": "灣子里",
            "code": "'0031",
          },
          {
            "name": "灣愛里",
            "code": "'0032",
          },
          {
            "name": "灣中里",
            "code": "'0033",
          },
          {
            "name": "灣華里",
            "code": "'0034",
          },
          {
            "name": "灣勝里",
            "code": "'0035",
          },
          {
            "name": "灣利里",
            "code": "'0036",
          },
          {
            "name": "灣復里",
            "code": "'0037",
          },
          {
            "name": "正興里",
            "code": "'0038",
          },
          {
            "name": "正順里",
            "code": "'0039",
          },
          {
            "name": "灣興里",
            "code": "'0040",
          },
          {
            "name": "灣成里",
            "code": "'0041",
          },
          {
            "name": "安康里",
            "code": "'0042",
          },
          {
            "name": "安寧里",
            "code": "'0043",
          },
          {
            "name": "安吉里",
            "code": "'0044",
          },
          {
            "name": "安發里",
            "code": "'0045",
          },
          {
            "name": "安東里",
            "code": "'0046",
          },
          {
            "name": "安和里",
            "code": "'0047",
          },
          {
            "name": "達德里",
            "code": "'0048",
          },
          {
            "name": "達明里",
            "code": "'0049",
          },
          {
            "name": "達仁里",
            "code": "'0050",
          },
          {
            "name": "達勇里",
            "code": "'0051",
          },
          {
            "name": "同德里",
            "code": "'0052",
          },
          {
            "name": "德智里",
            "code": "'0053",
          },
          {
            "name": "德仁里",
            "code": "'0054",
          },
          {
            "name": "安生里",
            "code": "'0055",
          },
          {
            "name": "德東里",
            "code": "'0056",
          },
          {
            "name": "德行里",
            "code": "'0057",
          },
          {
            "name": "精華里",
            "code": "'0058",
          },
          {
            "name": "民享里",
            "code": "'0059",
          },
          {
            "name": "安宜里",
            "code": "'0060",
          },
          {
            "name": "安泰里",
            "code": "'0061",
          },
          {
            "name": "安邦里",
            "code": "'0062",
          },
          {
            "name": "十全里",
            "code": "'0063",
          },
          {
            "name": "十美里",
            "code": "'0064",
          },
          {
            "name": "德北里",
            "code": "'0065",
          },
          {
            "name": "立誠里",
            "code": "'0066",
          },
          {
            "name": "立業里",
            "code": "'0067",
          },
          {
            "name": "港東里",
            "code": "'0068",
          },
          {
            "name": "港新里",
            "code": "'0069",
          },
          {
            "name": "港西里",
            "code": "'0070",
          },
          {
            "name": "博愛里",
            "code": "'0072",
          },
          {
            "name": "博惠里",
            "code": "'0073",
          },
          {
            "name": "長明里",
            "code": "'0074",
          },
          {
            "name": "建東里",
            "code": "'0075",
          },
          {
            "name": "興德里",
            "code": "'0076",
          },
          {
            "name": "鳳南里",
            "code": "'0077",
          },
          {
            "name": "鳳北里",
            "code": "'0078",
          },
          {
            "name": "德西里",
            "code": "'0079",
          },
          {
            "name": "豐裕里",
            "code": "'0081",
          },
          {
            "name": "川東里",
            "code": "'0082",
          },
          {
            "name": "裕民里",
            "code": "'0083",
          },
          {
            "name": "力行里",
            "code": "'0084",
          },
          {
            "name": "千歲里",
            "code": "'0085",
          },
          {
            "name": "立德里",
            "code": "'0086",
          },
          {
            "name": "千北里",
            "code": "'0087",
          },
          {
            "name": "千秋里",
            "code": "'0088",
          }
        ]
      },
      {
        "name": "新興區",
        "code": "'060",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "浩然里",
            "code": "'0001",
          },
          {
            "name": "振成里",
            "code": "'0002",
          },
          {
            "name": "德生里",
            "code": "'0003",
          },
          {
            "name": "振華里",
            "code": "'0004",
          },
          {
            "name": "正氣里",
            "code": "'0005",
          },
          {
            "name": "德政里",
            "code": "'0006",
          },
          {
            "name": "仁聲里",
            "code": "'0007",
          },
          {
            "name": "德望里",
            "code": "'0008",
          },
          {
            "name": "華聲里",
            "code": "'0009",
          },
          {
            "name": "蕉園里",
            "code": "'0010",
          },
          {
            "name": "永寧里",
            "code": "'0011",
          },
          {
            "name": "玉衡里",
            "code": "'0012",
          },
          {
            "name": "順昌里",
            "code": "'0013",
          },
          {
            "name": "文昌里",
            "code": "'0014",
          },
          {
            "name": "光耀里",
            "code": "'0015",
          },
          {
            "name": "興昌里",
            "code": "'0016",
          },
          {
            "name": "開平里",
            "code": "'0017",
          },
          {
            "name": "成功里",
            "code": "'0018",
          },
          {
            "name": "新江里",
            "code": "'0019",
          },
          {
            "name": "黎明里",
            "code": "'0020",
          },
          {
            "name": "愛平里",
            "code": "'0021",
          },
          {
            "name": "南港里",
            "code": "'0022",
          },
          {
            "name": "中東里",
            "code": "'0023",
          },
          {
            "name": "明莊里",
            "code": "'0024",
          },
          {
            "name": "大明里",
            "code": "'0025",
          },
          {
            "name": "秋山里",
            "code": "'0026",
          },
          {
            "name": "長驛里",
            "code": "'0027",
          },
          {
            "name": "建興里",
            "code": "'0028",
          },
          {
            "name": "建華里",
            "code": "'0029",
          },
          {
            "name": "漢民里",
            "code": "'0030",
          },
          {
            "name": "榮治里",
            "code": "'0031",
          },
          {
            "name": "東坡里",
            "code": "'0032",
          }
        ]
      },
      {
        "name": "前金區",
        "code": "'070",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "三川里",
            "code": "'0001",
          },
          {
            "name": "草江里",
            "code": "'0002",
          },
          {
            "name": "長城里",
            "code": "'0003",
          },
          {
            "name": "北金里",
            "code": "'0004",
          },
          {
            "name": "東金里",
            "code": "'0005",
          },
          {
            "name": "新生里",
            "code": "'0006",
          },
          {
            "name": "後金里",
            "code": "'0007",
          },
          {
            "name": "長興里",
            "code": "'0008",
          },
          {
            "name": "青山里",
            "code": "'0009",
          },
          {
            "name": "民生里",
            "code": "'0010",
          },
          {
            "name": "復元里",
            "code": "'0011",
          },
          {
            "name": "林投里",
            "code": "'0012",
          },
          {
            "name": "國民里",
            "code": "'0013",
          },
          {
            "name": "社東里",
            "code": "'0014",
          },
          {
            "name": "社西里",
            "code": "'0015",
          },
          {
            "name": "博孝里",
            "code": "'0016",
          },
          {
            "name": "長生里",
            "code": "'0017",
          },
          {
            "name": "榮復里",
            "code": "'0018",
          },
          {
            "name": "文西里",
            "code": "'0019",
          },
          {
            "name": "文東里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "苓雅區",
        "code": "'080",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "博仁里",
            "code": "'0001",
          },
          {
            "name": "苓洲里",
            "code": "'0002",
          },
          {
            "name": "苓昇里",
            "code": "'0003",
          },
          {
            "name": "苓中里",
            "code": "'0004",
          },
          {
            "name": "苓雅里",
            "code": "'0005",
          },
          {
            "name": "苓東里",
            "code": "'0006",
          },
          {
            "name": "城北里",
            "code": "'0007",
          },
          {
            "name": "城西里",
            "code": "'0008",
          },
          {
            "name": "城東里",
            "code": "'0009",
          },
          {
            "name": "意誠里",
            "code": "'0010",
          },
          {
            "name": "鼓中里",
            "code": "'0011",
          },
          {
            "name": "田西里",
            "code": "'0012",
          },
          {
            "name": "人和里",
            "code": "'0013",
          },
          {
            "name": "仁政里",
            "code": "'0014",
          },
          {
            "name": "廣澤里",
            "code": "'0015",
          },
          {
            "name": "美田里",
            "code": "'0016",
          },
          {
            "name": "華堂里",
            "code": "'0017",
          },
          {
            "name": "日中里",
            "code": "'0018",
          },
          {
            "name": "普照里",
            "code": "'0019",
          },
          {
            "name": "和煦里",
            "code": "'0020",
          },
          {
            "name": "晴朗里",
            "code": "'0021",
          },
          {
            "name": "普天里",
            "code": "'0022",
          },
          {
            "name": "林富里",
            "code": "'0023",
          },
          {
            "name": "林圍里",
            "code": "'0024",
          },
          {
            "name": "林安里",
            "code": "'0025",
          },
          {
            "name": "光華里",
            "code": "'0026",
          },
          {
            "name": "林興里",
            "code": "'0027",
          },
          {
            "name": "林華里",
            "code": "'0028",
          },
          {
            "name": "林西里",
            "code": "'0029",
          },
          {
            "name": "林中里",
            "code": "'0030",
          },
          {
            "name": "林泉里",
            "code": "'0031",
          },
          {
            "name": "林南里",
            "code": "'0032",
          },
          {
            "name": "中正里",
            "code": "'0033",
          },
          {
            "name": "尚義里",
            "code": "'0034",
          },
          {
            "name": "同慶里",
            "code": "'0035",
          },
          {
            "name": "凱旋里",
            "code": "'0036",
          },
          {
            "name": "安祥里",
            "code": "'0037",
          },
          {
            "name": "奏捷里",
            "code": "'0038",
          },
          {
            "name": "福壽里",
            "code": "'0039",
          },
          {
            "name": "福南里",
            "code": "'0040",
          },
          {
            "name": "五權里",
            "code": "'0041",
          },
          {
            "name": "民主里",
            "code": "'0042",
          },
          {
            "name": "林德里",
            "code": "'0043",
          },
          {
            "name": "林貴里",
            "code": "'0044",
          },
          {
            "name": "林榮里",
            "code": "'0045",
          },
          {
            "name": "英明里",
            "code": "'0046",
          },
          {
            "name": "林靖里",
            "code": "'0047",
          },
          {
            "name": "朝陽里",
            "code": "'0048",
          },
          {
            "name": "福隆里",
            "code": "'0049",
          },
          {
            "name": "福祥里",
            "code": "'0050",
          },
          {
            "name": "福海里",
            "code": "'0051",
          },
          {
            "name": "福康里",
            "code": "'0052",
          },
          {
            "name": "福人里",
            "code": "'0053",
          },
          {
            "name": "福地里",
            "code": "'0054",
          },
          {
            "name": "福居里",
            "code": "'0055",
          },
          {
            "name": "福東里",
            "code": "'0056",
          },
          {
            "name": "福西里",
            "code": "'0057",
          },
          {
            "name": "永康里",
            "code": "'0058",
          },
          {
            "name": "正文里",
            "code": "'0059",
          },
          {
            "name": "正言里",
            "code": "'0060",
          },
          {
            "name": "正大里",
            "code": "'0061",
          },
          {
            "name": "五福里",
            "code": "'0062",
          },
          {
            "name": "正心里",
            "code": "'0063",
          },
          {
            "name": "正道里",
            "code": "'0064",
          },
          {
            "name": "正義里",
            "code": "'0065",
          },
          {
            "name": "正仁里",
            "code": "'0066",
          },
          {
            "name": "文昌里",
            "code": "'0067",
          },
          {
            "name": "建軍里",
            "code": "'0068",
          },
          {
            "name": "衛武里",
            "code": "'0069",
          }
        ]
      },
      {
        "name": "鳳山區",
        "code": "'120",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "縣口里",
            "code": "'0001",
          },
          {
            "name": "成功里",
            "code": "'0002",
          },
          {
            "name": "光明里",
            "code": "'0003",
          },
          {
            "name": "興中里",
            "code": "'0004",
          },
          {
            "name": "南興里",
            "code": "'0005",
          },
          {
            "name": "和德里",
            "code": "'0006",
          },
          {
            "name": "鳳崗里",
            "code": "'0007",
          },
          {
            "name": "中和里",
            "code": "'0008",
          },
          {
            "name": "鎮北里",
            "code": "'0009",
          },
          {
            "name": "縣衙里",
            "code": "'0010",
          },
          {
            "name": "文英里",
            "code": "'0011",
          },
          {
            "name": "鎮西里",
            "code": "'0012",
          },
          {
            "name": "鎮東里",
            "code": "'0013",
          },
          {
            "name": "埤頂里",
            "code": "'0014",
          },
          {
            "name": "中正里",
            "code": "'0015",
          },
          {
            "name": "鎮南里",
            "code": "'0016",
          },
          {
            "name": "老爺里",
            "code": "'0017",
          },
          {
            "name": "瑞竹里",
            "code": "'0018",
          },
          {
            "name": "忠義里",
            "code": "'0019",
          },
          {
            "name": "誠義里",
            "code": "'0020",
          },
          {
            "name": "新興里",
            "code": "'0021",
          },
          {
            "name": "海光里",
            "code": "'0022",
          },
          {
            "name": "忠誠里",
            "code": "'0024",
          },
          {
            "name": "新甲里",
            "code": "'0025",
          },
          {
            "name": "武漢里",
            "code": "'0026",
          },
          {
            "name": "正義里",
            "code": "'0027",
          },
          {
            "name": "一甲里",
            "code": "'0028",
          },
          {
            "name": "福興里",
            "code": "'0029",
          },
          {
            "name": "東門里",
            "code": "'0030",
          },
          {
            "name": "瑞興里",
            "code": "'0031",
          },
          {
            "name": "天興里",
            "code": "'0032",
          },
          {
            "name": "新強里",
            "code": "'0033",
          },
          {
            "name": "國泰里",
            "code": "'0034",
          },
          {
            "name": "鳳東里",
            "code": "'0035",
          },
          {
            "name": "新富里",
            "code": "'0036",
          },
          {
            "name": "國光里",
            "code": "'0037",
          },
          {
            "name": "國隆里",
            "code": "'0038",
          },
          {
            "name": "文德里",
            "code": "'0039",
          },
          {
            "name": "過埤里",
            "code": "'0040",
          },
          {
            "name": "曹公里",
            "code": "'0041",
          },
          {
            "name": "興仁里",
            "code": "'0042",
          },
          {
            "name": "五福里",
            "code": "'0043",
          },
          {
            "name": "忠孝里",
            "code": "'0044",
          },
          {
            "name": "生明里",
            "code": "'0045",
          },
          {
            "name": "和興里",
            "code": "'0046",
          },
          {
            "name": "協和里",
            "code": "'0047",
          },
          {
            "name": "文山里",
            "code": "'0048",
          },
          {
            "name": "福誠里",
            "code": "'0049",
          },
          {
            "name": "誠德里",
            "code": "'0050",
          },
          {
            "name": "三民里",
            "code": "'0051",
          },
          {
            "name": "北門里",
            "code": "'0053",
          },
          {
            "name": "富甲里",
            "code": "'0054",
          },
          {
            "name": "南成里",
            "code": "'0055",
          },
          {
            "name": "文華里",
            "code": "'0056",
          },
          {
            "name": "大德里",
            "code": "'0057",
          },
          {
            "name": "武松里",
            "code": "'0058",
          },
          {
            "name": "文衡里",
            "code": "'0059",
          },
          {
            "name": "文福里",
            "code": "'0060",
          },
          {
            "name": "誠信里",
            "code": "'0061",
          },
          {
            "name": "誠智里",
            "code": "'0062",
          },
          {
            "name": "國富里",
            "code": "'0063",
          },
          {
            "name": "武慶里",
            "code": "'0064",
          },
          {
            "name": "海洋里",
            "code": "'0065",
          },
          {
            "name": "新武里",
            "code": "'0066",
          },
          {
            "name": "新樂里",
            "code": "'0067",
          },
          {
            "name": "新泰里",
            "code": "'0068",
          },
          {
            "name": "中崙里",
            "code": "'0069",
          },
          {
            "name": "中榮里",
            "code": "'0070",
          },
          {
            "name": "中民里",
            "code": "'0071",
          },
          {
            "name": "二甲里",
            "code": "'0072",
          },
          {
            "name": "龍成里",
            "code": "'0073",
          },
          {
            "name": "富榮里",
            "code": "'0074",
          },
          {
            "name": "善美里",
            "code": "'0075",
          },
          {
            "name": "南和里",
            "code": "'0076",
          },
          {
            "name": "福祥里",
            "code": "'0077",
          },
          {
            "name": "保安里",
            "code": "'0078",
          }
        ]
      },
      {
        "name": "前鎮區",
        "code": "'090",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "草衙里",
            "code": "'0001",
          },
          {
            "name": "明孝里",
            "code": "'0002",
          },
          {
            "name": "明正里",
            "code": "'0003",
          },
          {
            "name": "明義里",
            "code": "'0004",
          },
          {
            "name": "仁愛里",
            "code": "'0005",
          },
          {
            "name": "德昌里",
            "code": "'0006",
          },
          {
            "name": "平等里",
            "code": "'0007",
          },
          {
            "name": "平昌里",
            "code": "'0008",
          },
          {
            "name": "明禮里",
            "code": "'0009",
          },
          {
            "name": "信義里",
            "code": "'0010",
          },
          {
            "name": "信德里",
            "code": "'0011",
          },
          {
            "name": "明道里",
            "code": "'0012",
          },
          {
            "name": "興化里",
            "code": "'0013",
          },
          {
            "name": "興仁里",
            "code": "'0014",
          },
          {
            "name": "前鎮里",
            "code": "'0015",
          },
          {
            "name": "鎮東里",
            "code": "'0016",
          },
          {
            "name": "鎮榮里",
            "code": "'0017",
          },
          {
            "name": "鎮昌里",
            "code": "'0018",
          },
          {
            "name": "鎮海里",
            "code": "'0019",
          },
          {
            "name": "鎮陽里",
            "code": "'0020",
          },
          {
            "name": "興邦里",
            "code": "'0021",
          },
          {
            "name": "鎮中里",
            "code": "'0022",
          },
          {
            "name": "鎮北里",
            "code": "'0023",
          },
          {
            "name": "忠純里",
            "code": "'0024",
          },
          {
            "name": "忠誠里",
            "code": "'0025",
          },
          {
            "name": "西山里",
            "code": "'0028",
          },
          {
            "name": "民權里",
            "code": "'0029",
          },
          {
            "name": "建隆里",
            "code": "'0030",
          },
          {
            "name": "振興里",
            "code": "'0031",
          },
          {
            "name": "良和里",
            "code": "'0032",
          },
          {
            "name": "西甲里",
            "code": "'0033",
          },
          {
            "name": "盛興里",
            "code": "'0034",
          },
          {
            "name": "盛豐里",
            "code": "'0035",
          },
          {
            "name": "興中里",
            "code": "'0036",
          },
          {
            "name": "興東里",
            "code": "'0037",
          },
          {
            "name": "忠孝里",
            "code": "'0038",
          },
          {
            "name": "復國里",
            "code": "'0039",
          },
          {
            "name": "竹內里",
            "code": "'0040",
          },
          {
            "name": "竹東里",
            "code": "'0041",
          },
          {
            "name": "竹南里",
            "code": "'0042",
          },
          {
            "name": "竹北里",
            "code": "'0043",
          },
          {
            "name": "竹西里",
            "code": "'0044",
          },
          {
            "name": "竹中里",
            "code": "'0045",
          },
          {
            "name": "瑞竹里",
            "code": "'0046",
          },
          {
            "name": "瑞南里",
            "code": "'0047",
          },
          {
            "name": "瑞豐里",
            "code": "'0048",
          },
          {
            "name": "瑞祥里",
            "code": "'0049",
          },
          {
            "name": "瑞東里",
            "code": "'0050",
          },
          {
            "name": "瑞和里",
            "code": "'0051",
          },
          {
            "name": "瑞平里",
            "code": "'0052",
          },
          {
            "name": "瑞隆里",
            "code": "'0053",
          },
          {
            "name": "瑞北里",
            "code": "'0054",
          },
          {
            "name": "瑞西里",
            "code": "'0055",
          },
          {
            "name": "瑞崗里",
            "code": "'0056",
          },
          {
            "name": "瑞興里",
            "code": "'0057",
          },
          {
            "name": "瑞誠里",
            "code": "'0058",
          },
          {
            "name": "瑞文里",
            "code": "'0059",
          },
          {
            "name": "瑞華里",
            "code": "'0060",
          },
          {
            "name": "瑞昌里",
            "code": "'0061",
          }
        ]
      },
      {
        "name": "小港區",
        "code": "'110",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "小港里",
            "code": "'0001",
          },
          {
            "name": "港口里",
            "code": "'0002",
          },
          {
            "name": "港正里",
            "code": "'0003",
          },
          {
            "name": "港墘里",
            "code": "'0004",
          },
          {
            "name": "港明里",
            "code": "'0005",
          },
          {
            "name": "港后里",
            "code": "'0006",
          },
          {
            "name": "港南里",
            "code": "'0007",
          },
          {
            "name": "港興里",
            "code": "'0008",
          },
          {
            "name": "鳳宮里",
            "code": "'0009",
          },
          {
            "name": "店鎮里",
            "code": "'0010",
          },
          {
            "name": "大苓里",
            "code": "'0011",
          },
          {
            "name": "二苓里",
            "code": "'0012",
          },
          {
            "name": "三苓里",
            "code": "'0013",
          },
          {
            "name": "正苓里",
            "code": "'0014",
          },
          {
            "name": "順苓里",
            "code": "'0015",
          },
          {
            "name": "六苓里",
            "code": "'0016",
          },
          {
            "name": "宏亮里",
            "code": "'0017",
          },
          {
            "name": "山東里",
            "code": "'0018",
          },
          {
            "name": "青島里",
            "code": "'0019",
          },
          {
            "name": "濟南里",
            "code": "'0020",
          },
          {
            "name": "泰山里",
            "code": "'0021",
          },
          {
            "name": "山明里",
            "code": "'0022",
          },
          {
            "name": "高松里",
            "code": "'0023",
          },
          {
            "name": "松金里",
            "code": "'0024",
          },
          {
            "name": "松山里",
            "code": "'0025",
          },
          {
            "name": "大坪里",
            "code": "'0026",
          },
          {
            "name": "坪頂里",
            "code": "'0027",
          },
          {
            "name": "孔宅里",
            "code": "'0028",
          },
          {
            "name": "廈莊里",
            "code": "'0029",
          },
          {
            "name": "合作里",
            "code": "'0030",
          },
          {
            "name": "桂林里",
            "code": "'0031",
          },
          {
            "name": "中厝里",
            "code": "'0032",
          },
          {
            "name": "鳳鳴里",
            "code": "'0033",
          },
          {
            "name": "龍鳳里",
            "code": "'0034",
          },
          {
            "name": "鳳森里",
            "code": "'0035",
          },
          {
            "name": "鳳林里",
            "code": "'0036",
          },
          {
            "name": "鳳興里",
            "code": "'0037",
          },
          {
            "name": "鳳源里",
            "code": "'0038",
          }
        ]
      },
      {
        "name": "林園區",
        "code": "'130",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "東林里",
            "code": "'0001",
          },
          {
            "name": "林園里",
            "code": "'0002",
          },
          {
            "name": "溪州里",
            "code": "'0003",
          },
          {
            "name": "潭頭里",
            "code": "'0004",
          },
          {
            "name": "中厝里",
            "code": "'0005",
          },
          {
            "name": "中門里",
            "code": "'0006",
          },
          {
            "name": "頂厝里",
            "code": "'0007",
          },
          {
            "name": "港埔里",
            "code": "'0008",
          },
          {
            "name": "西溪里",
            "code": "'0009",
          },
          {
            "name": "港嘴里",
            "code": "'0010",
          },
          {
            "name": "北汕里",
            "code": "'0011",
          },
          {
            "name": "林內里",
            "code": "'0012",
          },
          {
            "name": "王公里",
            "code": "'0013",
          },
          {
            "name": "林家里",
            "code": "'0014",
          },
          {
            "name": "龔厝里",
            "code": "'0015",
          },
          {
            "name": "鳳芸里",
            "code": "'0016",
          },
          {
            "name": "中芸里",
            "code": "'0017",
          },
          {
            "name": "東汕里",
            "code": "'0018",
          },
          {
            "name": "西汕里",
            "code": "'0019",
          },
          {
            "name": "仁愛里",
            "code": "'0020",
          },
          {
            "name": "文賢里",
            "code": "'0021",
          },
          {
            "name": "廣應里",
            "code": "'0022",
          },
          {
            "name": "五福里",
            "code": "'0023",
          },
          {
            "name": "中汕里",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "大寮區",
        "code": "'140",
        "group": [
          "'11"
        ],
        "subLocation": [
          {
            "name": "拷潭里",
            "code": "'0001",
          },
          {
            "name": "內坑里",
            "code": "'0002",
          },
          {
            "name": "大寮里",
            "code": "'0003",
          },
          {
            "name": "上寮里",
            "code": "'0004",
          },
          {
            "name": "三隆里",
            "code": "'0005",
          },
          {
            "name": "琉球里",
            "code": "'0006",
          },
          {
            "name": "翁園里",
            "code": "'0007",
          },
          {
            "name": "前庄里",
            "code": "'0008",
          },
          {
            "name": "中庄里",
            "code": "'0009",
          },
          {
            "name": "後庄里",
            "code": "'0010",
          },
          {
            "name": "義仁里",
            "code": "'0011",
          },
          {
            "name": "新厝里",
            "code": "'0012",
          },
          {
            "name": "過溪里",
            "code": "'0013",
          },
          {
            "name": "潮寮里",
            "code": "'0014",
          },
          {
            "name": "會結里",
            "code": "'0015",
          },
          {
            "name": "會社里",
            "code": "'0016",
          },
          {
            "name": "山頂里",
            "code": "'0017",
          },
          {
            "name": "忠義里",
            "code": "'0018",
          },
          {
            "name": "永芳里",
            "code": "'0019",
          },
          {
            "name": "義和里",
            "code": "'0020",
          },
          {
            "name": "溪寮里",
            "code": "'0021",
          },
          {
            "name": "江山里",
            "code": "'0022",
          },
          {
            "name": "昭明里",
            "code": "'0023",
          },
          {
            "name": "光武里",
            "code": "'0024",
          },
          {
            "name": "中興里",
            "code": "'0025",
          }
        ]
      }
    ]
  },
  {
    "name": "新竹縣",
    "code": "'10'004",
    "subLocation": [
      {
        "name": "竹北市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "斗崙里",
            "code": "'0001",
          },
          {
            "name": "鹿場里",
            "code": "'0002",
          },
          {
            "name": "東平里",
            "code": "'0003",
          },
          {
            "name": "中興里",
            "code": "'0004",
          },
          {
            "name": "隘口里",
            "code": "'0005",
          },
          {
            "name": "東海里",
            "code": "'0006",
          },
          {
            "name": "竹北里",
            "code": "'0007",
          },
          {
            "name": "竹仁里",
            "code": "'0008",
          },
          {
            "name": "竹義里",
            "code": "'0009",
          },
          {
            "name": "泰和里",
            "code": "'0010",
          },
          {
            "name": "新社里",
            "code": "'0011",
          },
          {
            "name": "聯興里",
            "code": "'0012",
          },
          {
            "name": "麻園里",
            "code": "'0013",
          },
          {
            "name": "溪州里",
            "code": "'0014",
          },
          {
            "name": "白地里",
            "code": "'0015",
          },
          {
            "name": "新港里",
            "code": "'0016",
          },
          {
            "name": "大義里",
            "code": "'0017",
          },
          {
            "name": "崇義里",
            "code": "'0018",
          },
          {
            "name": "大眉里",
            "code": "'0019",
          },
          {
            "name": "尚義里",
            "code": "'0020",
          },
          {
            "name": "十興里",
            "code": "'0021",
          },
          {
            "name": "新國里",
            "code": "'0022",
          },
          {
            "name": "新庄里",
            "code": "'0023",
          },
          {
            "name": "北崙里",
            "code": "'0024",
          },
          {
            "name": "新崙里",
            "code": "'0025",
          },
          {
            "name": "福德里",
            "code": "'0026",
          },
          {
            "name": "東興里",
            "code": "'0027",
          },
          {
            "name": "北興里",
            "code": "'0028",
          },
          {
            "name": "興安里",
            "code": "'0029",
          },
          {
            "name": "文化里",
            "code": "'0030",
          },
          {
            "name": "中崙里",
            "code": "'0031",
          }
        ]
      },
      {
        "name": "湖口鄉",
        "code": "'050",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "孝勢村",
            "code": "'0001",
          },
          {
            "name": "仁勢村",
            "code": "'0002",
          },
          {
            "name": "愛勢村",
            "code": "'0003",
          },
          {
            "name": "信勢村",
            "code": "'0004",
          },
          {
            "name": "中勢村",
            "code": "'0005",
          },
          {
            "name": "德盛村",
            "code": "'0006",
          },
          {
            "name": "和興村",
            "code": "'0007",
          },
          {
            "name": "長嶺村",
            "code": "'0008",
          },
          {
            "name": "長安村",
            "code": "'0009",
          },
          {
            "name": "湖口村",
            "code": "'0010",
          },
          {
            "name": "湖鏡村",
            "code": "'0011",
          },
          {
            "name": "湖南村",
            "code": "'0012",
          },
          {
            "name": "波羅村",
            "code": "'0013",
          },
          {
            "name": "鳳凰村",
            "code": "'0014",
          },
          {
            "name": "鳳山村",
            "code": "'0015",
          },
          {
            "name": "中興村",
            "code": "'0016",
          },
          {
            "name": "信義村",
            "code": "'0017",
          },
          {
            "name": "中正村",
            "code": "'0018",
          },
          {
            "name": "東興村",
            "code": "'0019",
          },
          {
            "name": "勝利村",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "新豐鄉",
        "code": "'060",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "福興村",
            "code": "'0001",
          },
          {
            "name": "後湖村",
            "code": "'0002",
          },
          {
            "name": "青埔村",
            "code": "'0003",
          },
          {
            "name": "埔和村",
            "code": "'0004",
          },
          {
            "name": "瑞興村",
            "code": "'0005",
          },
          {
            "name": "坡頭村",
            "code": "'0006",
          },
          {
            "name": "中崙村",
            "code": "'0007",
          },
          {
            "name": "重興村",
            "code": "'0008",
          },
          {
            "name": "新豐村",
            "code": "'0009",
          },
          {
            "name": "員山村",
            "code": "'0010",
          },
          {
            "name": "松林村",
            "code": "'0011",
          },
          {
            "name": "上坑村",
            "code": "'0012",
          },
          {
            "name": "鳳坑村",
            "code": "'0013",
          },
          {
            "name": "忠孝村",
            "code": "'0014",
          },
          {
            "name": "山崎村",
            "code": "'0015",
          },
          {
            "name": "松柏村",
            "code": "'0016",
          },
          {
            "name": "崎頂村",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "關西鎮",
        "code": "'040",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "東興里",
            "code": "'0001",
          },
          {
            "name": "西安里",
            "code": "'0002",
          },
          {
            "name": "南雄里",
            "code": "'0003",
          },
          {
            "name": "北斗里",
            "code": "'0004",
          },
          {
            "name": "仁安里",
            "code": "'0005",
          },
          {
            "name": "北山里",
            "code": "'0006",
          },
          {
            "name": "南山里",
            "code": "'0007",
          },
          {
            "name": "東安里",
            "code": "'0008",
          },
          {
            "name": "東光里",
            "code": "'0009",
          },
          {
            "name": "新富里",
            "code": "'0010",
          },
          {
            "name": "金山里",
            "code": "'0011",
          },
          {
            "name": "錦山里",
            "code": "'0012",
          },
          {
            "name": "玉山里",
            "code": "'0013",
          },
          {
            "name": "大同里",
            "code": "'0014",
          },
          {
            "name": "石光里",
            "code": "'0015",
          },
          {
            "name": "上林里",
            "code": "'0016",
          },
          {
            "name": "新力里",
            "code": "'0017",
          },
          {
            "name": "南和里",
            "code": "'0018",
          },
          {
            "name": "東山里",
            "code": "'0019",
          },
          {
            "name": "南新里",
            "code": "'0020",
          },
          {
            "name": "東平里",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "新埔鎮",
        "code": "'030",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "內立里",
            "code": "'0001",
          },
          {
            "name": "寶石里",
            "code": "'0002",
          },
          {
            "name": "文山里",
            "code": "'0003",
          },
          {
            "name": "五埔里",
            "code": "'0004",
          },
          {
            "name": "四座里",
            "code": "'0005",
          },
          {
            "name": "旱坑里",
            "code": "'0006",
          },
          {
            "name": "田新里",
            "code": "'0007",
          },
          {
            "name": "新埔里",
            "code": "'0008",
          },
          {
            "name": "新生里",
            "code": "'0009",
          },
          {
            "name": "新民里",
            "code": "'0010",
          },
          {
            "name": "上寮里",
            "code": "'0011",
          },
          {
            "name": "下寮里",
            "code": "'0012",
          },
          {
            "name": "南平里",
            "code": "'0013",
          },
          {
            "name": "北平里",
            "code": "'0014",
          },
          {
            "name": "巨埔里",
            "code": "'0015",
          },
          {
            "name": "照門里",
            "code": "'0016",
          },
          {
            "name": "清水里",
            "code": "'0017",
          },
          {
            "name": "鹿鳴里",
            "code": "'0018",
          },
          {
            "name": "新北里",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "橫山鄉",
        "code": "'080",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "橫山村",
            "code": "'0001",
          },
          {
            "name": "田寮村",
            "code": "'0002",
          },
          {
            "name": "南昌村",
            "code": "'0003",
          },
          {
            "name": "豐鄉村",
            "code": "'0004",
          },
          {
            "name": "豐田村",
            "code": "'0005",
          },
          {
            "name": "內灣村",
            "code": "'0006",
          },
          {
            "name": "福興村",
            "code": "'0007",
          },
          {
            "name": "沙坑村",
            "code": "'0008",
          },
          {
            "name": "新興村",
            "code": "'0009",
          },
          {
            "name": "大肚村",
            "code": "'0010",
          },
          {
            "name": "力行村",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "尖石鄉",
        "code": "'120",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "義興村",
            "code": "'0001",
          },
          {
            "name": "嘉樂村",
            "code": "'0002",
          },
          {
            "name": "新樂村",
            "code": "'0003",
          },
          {
            "name": "梅花村",
            "code": "'0004",
          },
          {
            "name": "錦屏村",
            "code": "'0005",
          },
          {
            "name": "玉峰村",
            "code": "'0006",
          },
          {
            "name": "秀巒村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "芎林鄉",
        "code": "'070",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "五龍村",
            "code": "'0001",
          },
          {
            "name": "華龍村",
            "code": "'0002",
          },
          {
            "name": "秀湖村",
            "code": "'0003",
          },
          {
            "name": "永興村",
            "code": "'0004",
          },
          {
            "name": "石潭村",
            "code": "'0005",
          },
          {
            "name": "新鳳村",
            "code": "'0006",
          },
          {
            "name": "中坑村",
            "code": "'0007",
          },
          {
            "name": "水坑村",
            "code": "'0008",
          },
          {
            "name": "文林村",
            "code": "'0009",
          },
          {
            "name": "芎林村",
            "code": "'0010",
          },
          {
            "name": "上山村",
            "code": "'0011",
          },
          {
            "name": "下山村",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "竹東鎮",
        "code": "'020",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "上坪里",
            "code": "'0001",
          },
          {
            "name": "瑞峰里",
            "code": "'0002",
          },
          {
            "name": "軟橋里",
            "code": "'0003",
          },
          {
            "name": "員崠里",
            "code": "'0004",
          },
          {
            "name": "東寧里",
            "code": "'0005",
          },
          {
            "name": "上舘里",
            "code": "'0006",
          },
          {
            "name": "大鄉里",
            "code": "'0007",
          },
          {
            "name": "東華里",
            "code": "'0008",
          },
          {
            "name": "商華里",
            "code": "'0009",
          },
          {
            "name": "竹東里",
            "code": "'0010",
          },
          {
            "name": "榮樂里",
            "code": "'0011",
          },
          {
            "name": "鷄林里",
            "code": "'0012",
          },
          {
            "name": "仁愛里",
            "code": "'0013",
          },
          {
            "name": "五豐里",
            "code": "'0014",
          },
          {
            "name": "三重里",
            "code": "'0015",
          },
          {
            "name": "頭重里",
            "code": "'0016",
          },
          {
            "name": "員山里",
            "code": "'0017",
          },
          {
            "name": "柯湖里",
            "code": "'0018",
          },
          {
            "name": "二重里",
            "code": "'0019",
          },
          {
            "name": "中山里",
            "code": "'0020",
          },
          {
            "name": "中正里",
            "code": "'0021",
          },
          {
            "name": "南華里",
            "code": "'0022",
          },
          {
            "name": "榮華里",
            "code": "'0023",
          },
          {
            "name": "忠孝里",
            "code": "'0024",
          },
          {
            "name": "陸豐里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "五峰鄉",
        "code": "'130",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "大隘村",
            "code": "'0001",
          },
          {
            "name": "花園村",
            "code": "'0002",
          },
          {
            "name": "竹林村",
            "code": "'0003",
          },
          {
            "name": "桃山村",
            "code": "'0004",
          }
        ]
      },
      {
        "name": "寶山鄉",
        "code": "'100",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "雙溪村",
            "code": "'0001",
          },
          {
            "name": "大崎村",
            "code": "'0002",
          },
          {
            "name": "三峰村",
            "code": "'0003",
          },
          {
            "name": "新城村",
            "code": "'0004",
          },
          {
            "name": "深井村",
            "code": "'0005",
          },
          {
            "name": "寶斗村",
            "code": "'0006",
          },
          {
            "name": "山湖村",
            "code": "'0007",
          },
          {
            "name": "寶山村",
            "code": "'0008",
          },
          {
            "name": "油田村",
            "code": "'0009",
          },
          {
            "name": "雙新村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "北埔鄉",
        "code": "'090",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "北埔村",
            "code": "'0001",
          },
          {
            "name": "南興村",
            "code": "'0002",
          },
          {
            "name": "埔尾村",
            "code": "'0003",
          },
          {
            "name": "水磜村",
            "code": "'0004",
          },
          {
            "name": "南埔村",
            "code": "'0005",
          },
          {
            "name": "大林村",
            "code": "'0006",
          },
          {
            "name": "大湖村",
            "code": "'0007",
          },
          {
            "name": "南坑村",
            "code": "'0008",
          },
          {
            "name": "外坪村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "峨眉鄉",
        "code": "'110",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "峨眉村",
            "code": "'0001",
          },
          {
            "name": "中盛村",
            "code": "'0002",
          },
          {
            "name": "石井村",
            "code": "'0003",
          },
          {
            "name": "富興村",
            "code": "'0004",
          },
          {
            "name": "七星村",
            "code": "'0005",
          },
          {
            "name": "湖光村",
            "code": "'0006",
          }
        ]
      }
    ]
  },
  {
    "name": "苗栗縣",
    "code": "'10'005",
    "subLocation": [
      {
        "name": "苗栗市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "中苗里",
            "code": "'0001",
          },
          {
            "name": "建功里",
            "code": "'0002",
          },
          {
            "name": "青苗里",
            "code": "'0003",
          },
          {
            "name": "玉清里",
            "code": "'0004",
          },
          {
            "name": "玉華里",
            "code": "'0005",
          },
          {
            "name": "維新里",
            "code": "'0006",
          },
          {
            "name": "維祥里",
            "code": "'0007",
          },
          {
            "name": "綠苗里",
            "code": "'0008",
          },
          {
            "name": "高苗里",
            "code": "'0009",
          },
          {
            "name": "玉苗里",
            "code": "'0010",
          },
          {
            "name": "大同里",
            "code": "'0011",
          },
          {
            "name": "新苗里",
            "code": "'0012",
          },
          {
            "name": "勝利里",
            "code": "'0013",
          },
          {
            "name": "恭敬里",
            "code": "'0014",
          },
          {
            "name": "上苗里",
            "code": "'0015",
          },
          {
            "name": "北苗里",
            "code": "'0016",
          },
          {
            "name": "清華里",
            "code": "'0017",
          },
          {
            "name": "嘉盛里",
            "code": "'0018",
          },
          {
            "name": "福星里",
            "code": "'0019",
          },
          {
            "name": "新川里",
            "code": "'0020",
          },
          {
            "name": "文山里",
            "code": "'0021",
          },
          {
            "name": "福麗里",
            "code": "'0022",
          },
          {
            "name": "水源里",
            "code": "'0023",
          },
          {
            "name": "新英里",
            "code": "'0024",
          },
          {
            "name": "福安里",
            "code": "'0025",
          },
          {
            "name": "嘉新里",
            "code": "'0026",
          },
          {
            "name": "南勢里",
            "code": "'0027",
          },
          {
            "name": "文聖里",
            "code": "'0028",
          }
        ]
      },
      {
        "name": "公館鄉",
        "code": "'090",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "館中村",
            "code": "'0001",
          },
          {
            "name": "館東村",
            "code": "'0002",
          },
          {
            "name": "館南村",
            "code": "'0003",
          },
          {
            "name": "大坑村",
            "code": "'0004",
          },
          {
            "name": "中義村",
            "code": "'0005",
          },
          {
            "name": "玉泉村",
            "code": "'0006",
          },
          {
            "name": "石墻村",
            "code": "'0007",
          },
          {
            "name": "福基村",
            "code": "'0008",
          },
          {
            "name": "福星村",
            "code": "'0009",
          },
          {
            "name": "開礦村",
            "code": "'0010",
          },
          {
            "name": "福德村",
            "code": "'0011",
          },
          {
            "name": "五谷村",
            "code": "'0012",
          },
          {
            "name": "玉谷村",
            "code": "'0013",
          },
          {
            "name": "鶴岡村",
            "code": "'0014",
          },
          {
            "name": "鶴山村",
            "code": "'0015",
          },
          {
            "name": "仁安村",
            "code": "'0016",
          },
          {
            "name": "尖山村",
            "code": "'0017",
          },
          {
            "name": "南河村",
            "code": "'0018",
          },
          {
            "name": "北河村",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "頭屋鄉",
        "code": "'120",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "頭屋村",
            "code": "'0001",
          },
          {
            "name": "象山村",
            "code": "'0002",
          },
          {
            "name": "北坑村",
            "code": "'0003",
          },
          {
            "name": "獅潭村",
            "code": "'0004",
          },
          {
            "name": "曲洞村",
            "code": "'0005",
          },
          {
            "name": "飛鳳村",
            "code": "'0006",
          },
          {
            "name": "明德村",
            "code": "'0007",
          },
          {
            "name": "鳴鳳村",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "銅鑼鄉",
        "code": "'100",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "福興村",
            "code": "'0001",
          },
          {
            "name": "銅鑼村",
            "code": "'0002",
          },
          {
            "name": "朝陽村",
            "code": "'0003",
          },
          {
            "name": "樟樹村",
            "code": "'0004",
          },
          {
            "name": "九湖村",
            "code": "'0005",
          },
          {
            "name": "竹森村",
            "code": "'0006",
          },
          {
            "name": "中平村",
            "code": "'0007",
          },
          {
            "name": "興隆村",
            "code": "'0008",
          },
          {
            "name": "盛隆村",
            "code": "'0009",
          },
          {
            "name": "新隆村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "三義鄉",
        "code": "'130",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "廣盛村",
            "code": "'0001",
          },
          {
            "name": "双湖村",
            "code": "'0002",
          },
          {
            "name": "双潭村",
            "code": "'0003",
          },
          {
            "name": "勝興村",
            "code": "'0004",
          },
          {
            "name": "西湖村",
            "code": "'0005",
          },
          {
            "name": "龍騰村",
            "code": "'0006",
          },
          {
            "name": "鯉魚潭村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "西湖鄉",
        "code": "'140",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "高埔村",
            "code": "'0001",
          },
          {
            "name": "下埔村",
            "code": "'0002",
          },
          {
            "name": "五湖村",
            "code": "'0003",
          },
          {
            "name": "四湖村",
            "code": "'0004",
          },
          {
            "name": "金獅村",
            "code": "'0005",
          },
          {
            "name": "龍洞村",
            "code": "'0006",
          },
          {
            "name": "三湖村",
            "code": "'0007",
          },
          {
            "name": "湖東村",
            "code": "'0008",
          },
          {
            "name": "二湖村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "苑裡鎮",
        "code": "'020",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "苑東里",
            "code": "'0001",
          },
          {
            "name": "苑南里",
            "code": "'0002",
          },
          {
            "name": "苑北里",
            "code": "'0003",
          },
          {
            "name": "西平里",
            "code": "'0004",
          },
          {
            "name": "苑港里",
            "code": "'0005",
          },
          {
            "name": "西勢里",
            "code": "'0006",
          },
          {
            "name": "海岸里",
            "code": "'0007",
          },
          {
            "name": "房裡里",
            "code": "'0008",
          },
          {
            "name": "客庄里",
            "code": "'0009",
          },
          {
            "name": "中正里",
            "code": "'0010",
          },
          {
            "name": "苑坑里",
            "code": "'0011",
          },
          {
            "name": "水坡里",
            "code": "'0012",
          },
          {
            "name": "新復里",
            "code": "'0013",
          },
          {
            "name": "山脚里",
            "code": "'0014",
          },
          {
            "name": "舊社里",
            "code": "'0015",
          },
          {
            "name": "福田里",
            "code": "'0016",
          },
          {
            "name": "石鎮里",
            "code": "'0017",
          },
          {
            "name": "蕉埔里",
            "code": "'0018",
          },
          {
            "name": "社苓里",
            "code": "'0019",
          },
          {
            "name": "泰田里",
            "code": "'0020",
          },
          {
            "name": "上舘里",
            "code": "'0021",
          },
          {
            "name": "玉田里",
            "code": "'0022",
          },
          {
            "name": "南勢里",
            "code": "'0023",
          },
          {
            "name": "山柑里",
            "code": "'0024",
          },
          {
            "name": "田心里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "通霄鎮",
        "code": "'030",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "通東里",
            "code": "'0001",
          },
          {
            "name": "通西里",
            "code": "'0002",
          },
          {
            "name": "平元里",
            "code": "'0003",
          },
          {
            "name": "梅南里",
            "code": "'0004",
          },
          {
            "name": "圳頭里",
            "code": "'0005",
          },
          {
            "name": "通南里",
            "code": "'0006",
          },
          {
            "name": "城北里",
            "code": "'0007",
          },
          {
            "name": "城南里",
            "code": "'0008",
          },
          {
            "name": "南和里",
            "code": "'0009",
          },
          {
            "name": "福興里",
            "code": "'0010",
          },
          {
            "name": "坪頂里",
            "code": "'0011",
          },
          {
            "name": "五南里",
            "code": "'0012",
          },
          {
            "name": "五北里",
            "code": "'0013",
          },
          {
            "name": "內湖里",
            "code": "'0014",
          },
          {
            "name": "楓樹里",
            "code": "'0015",
          },
          {
            "name": "烏眉里",
            "code": "'0016",
          },
          {
            "name": "福龍里",
            "code": "'0017",
          },
          {
            "name": "福源里",
            "code": "'0018",
          },
          {
            "name": "通灣里",
            "code": "'0019",
          },
          {
            "name": "新埔里",
            "code": "'0020",
          },
          {
            "name": "內島里",
            "code": "'0021",
          },
          {
            "name": "白東里",
            "code": "'0022",
          },
          {
            "name": "白西里",
            "code": "'0023",
          },
          {
            "name": "平安里",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "竹南鎮",
        "code": "'040",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "竹南里",
            "code": "'0001",
          },
          {
            "name": "照南里",
            "code": "'0002",
          },
          {
            "name": "正南里",
            "code": "'0003",
          },
          {
            "name": "新南里",
            "code": "'0004",
          },
          {
            "name": "大厝里",
            "code": "'0005",
          },
          {
            "name": "營盤里",
            "code": "'0006",
          },
          {
            "name": "聖福里",
            "code": "'0007",
          },
          {
            "name": "竹興里",
            "code": "'0008",
          },
          {
            "name": "龍鳳里",
            "code": "'0009",
          },
          {
            "name": "山佳里",
            "code": "'0010",
          },
          {
            "name": "中港里",
            "code": "'0011",
          },
          {
            "name": "中華里",
            "code": "'0012",
          },
          {
            "name": "中英里",
            "code": "'0013",
          },
          {
            "name": "中美里",
            "code": "'0014",
          },
          {
            "name": "開元里",
            "code": "'0015",
          },
          {
            "name": "海口里",
            "code": "'0016",
          },
          {
            "name": "港墘里",
            "code": "'0017",
          },
          {
            "name": "公舘里",
            "code": "'0018",
          },
          {
            "name": "大埔里",
            "code": "'0019",
          },
          {
            "name": "頂埔里",
            "code": "'0020",
          },
          {
            "name": "崎頂里",
            "code": "'0021",
          },
          {
            "name": "公義里",
            "code": "'0022",
          },
          {
            "name": "佳興里",
            "code": "'0023",
          },
          {
            "name": "天文里",
            "code": "'0024",
          },
          {
            "name": "龍山里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "後龍鎮",
        "code": "'060",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "南龍里",
            "code": "'0001",
          },
          {
            "name": "中龍里",
            "code": "'0002",
          },
          {
            "name": "北龍里",
            "code": "'0003",
          },
          {
            "name": "大庄里",
            "code": "'0004",
          },
          {
            "name": "溪洲里",
            "code": "'0005",
          },
          {
            "name": "豐富里",
            "code": "'0006",
          },
          {
            "name": "校椅里",
            "code": "'0007",
          },
          {
            "name": "埔頂里",
            "code": "'0008",
          },
          {
            "name": "新民里",
            "code": "'0009",
          },
          {
            "name": "復興里",
            "code": "'0010",
          },
          {
            "name": "東明里",
            "code": "'0011",
          },
          {
            "name": "大山里",
            "code": "'0012",
          },
          {
            "name": "灣寶里",
            "code": "'0013",
          },
          {
            "name": "海寶里",
            "code": "'0014",
          },
          {
            "name": "外埔里",
            "code": "'0015",
          },
          {
            "name": "海埔里",
            "code": "'0016",
          },
          {
            "name": "秀水里",
            "code": "'0017",
          },
          {
            "name": "水尾里",
            "code": "'0018",
          },
          {
            "name": "龍津里",
            "code": "'0019",
          },
          {
            "name": "龍坑里",
            "code": "'0020",
          },
          {
            "name": "福寧里",
            "code": "'0021",
          },
          {
            "name": "中和里",
            "code": "'0022",
          },
          {
            "name": "南港里",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "造橋鄉",
        "code": "'150",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "造橋村",
            "code": "'0001",
          },
          {
            "name": "平興村",
            "code": "'0002",
          },
          {
            "name": "豐湖村",
            "code": "'0003",
          },
          {
            "name": "大西村",
            "code": "'0004",
          },
          {
            "name": "錦水村",
            "code": "'0005",
          },
          {
            "name": "大龍村",
            "code": "'0006",
          },
          {
            "name": "朝陽村",
            "code": "'0007",
          },
          {
            "name": "談文村",
            "code": "'0008",
          },
          {
            "name": "龍昇村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "頭份市",
        "code": "'050",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "流東里",
            "code": "'0001",
          },
          {
            "name": "珊湖里",
            "code": "'0002",
          },
          {
            "name": "斗煥里",
            "code": "'0003",
          },
          {
            "name": "新華里",
            "code": "'0004",
          },
          {
            "name": "興隆里",
            "code": "'0005",
          },
          {
            "name": "上埔里",
            "code": "'0006",
          },
          {
            "name": "土牛里",
            "code": "'0007",
          },
          {
            "name": "頭份里",
            "code": "'0008",
          },
          {
            "name": "忠孝里",
            "code": "'0009",
          },
          {
            "name": "仁愛里",
            "code": "'0010",
          },
          {
            "name": "信義里",
            "code": "'0011",
          },
          {
            "name": "和平里",
            "code": "'0012",
          },
          {
            "name": "民族里",
            "code": "'0013",
          },
          {
            "name": "東庄里",
            "code": "'0014",
          },
          {
            "name": "後庄里",
            "code": "'0015",
          },
          {
            "name": "山下里",
            "code": "'0016",
          },
          {
            "name": "蟠桃里",
            "code": "'0017",
          },
          {
            "name": "田寮里",
            "code": "'0018",
          },
          {
            "name": "蘆竹里",
            "code": "'0019",
          },
          {
            "name": "上興里",
            "code": "'0020",
          },
          {
            "name": "下興里",
            "code": "'0021",
          },
          {
            "name": "尖山里",
            "code": "'0022",
          },
          {
            "name": "尖下里",
            "code": "'0023",
          },
          {
            "name": "廣興里",
            "code": "'0024",
          },
          {
            "name": "濫坑里",
            "code": "'0025",
          },
          {
            "name": "民權里",
            "code": "'0026",
          },
          {
            "name": "民生里",
            "code": "'0027",
          },
          {
            "name": "建國里",
            "code": "'0028",
          },
          {
            "name": "成功里",
            "code": "'0029",
          },
          {
            "name": "自強里",
            "code": "'0030",
          },
          {
            "name": "合興里",
            "code": "'0031",
          },
          {
            "name": "文化里",
            "code": "'0032",
          },
          {
            "name": "中興里",
            "code": "'0033",
          }
        ]
      },
      {
        "name": "南庄鄉",
        "code": "'110",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "東村",
            "code": "'0001",
          },
          {
            "name": "西村",
            "code": "'0002",
          },
          {
            "name": "南江村",
            "code": "'0003",
          },
          {
            "name": "田美村",
            "code": "'0004",
          },
          {
            "name": "獅山村",
            "code": "'0005",
          },
          {
            "name": "南富村",
            "code": "'0006",
          },
          {
            "name": "員林村",
            "code": "'0007",
          },
          {
            "name": "蓬萊村",
            "code": "'0008",
          },
          {
            "name": "東河村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "三灣鄉",
        "code": "'160",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "三灣村",
            "code": "'0001",
          },
          {
            "name": "內灣村",
            "code": "'0002",
          },
          {
            "name": "銅鏡村",
            "code": "'0003",
          },
          {
            "name": "頂寮村",
            "code": "'0004",
          },
          {
            "name": "北埔村",
            "code": "'0005",
          },
          {
            "name": "大河村",
            "code": "'0006",
          },
          {
            "name": "大坪村",
            "code": "'0007",
          },
          {
            "name": "永和村",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "卓蘭鎮",
        "code": "'070",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "中街里",
            "code": "'0001",
          },
          {
            "name": "新厝里",
            "code": "'0002",
          },
          {
            "name": "上新里",
            "code": "'0003",
          },
          {
            "name": "豐田里",
            "code": "'0004",
          },
          {
            "name": "西坪里",
            "code": "'0005",
          },
          {
            "name": "景山里",
            "code": "'0006",
          },
          {
            "name": "老庄里",
            "code": "'0007",
          },
          {
            "name": "新榮里",
            "code": "'0008",
          },
          {
            "name": "內灣里",
            "code": "'0009",
          },
          {
            "name": "苗豐里",
            "code": "'0010",
          },
          {
            "name": "坪林里",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "大湖鄉",
        "code": "'080",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "大湖村",
            "code": "'0001",
          },
          {
            "name": "明湖村",
            "code": "'0002",
          },
          {
            "name": "靜湖村",
            "code": "'0003",
          },
          {
            "name": "富興村",
            "code": "'0004",
          },
          {
            "name": "大南村",
            "code": "'0005",
          },
          {
            "name": "大寮村",
            "code": "'0006",
          },
          {
            "name": "南湖村",
            "code": "'0007",
          },
          {
            "name": "義和村",
            "code": "'0008",
          },
          {
            "name": "東興村",
            "code": "'0009",
          },
          {
            "name": "武榮村",
            "code": "'0010",
          },
          {
            "name": "栗林村",
            "code": "'0011",
          },
          {
            "name": "新開村",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "獅潭鄉",
        "code": "'170",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "百壽村",
            "code": "'0001",
          },
          {
            "name": "永興村",
            "code": "'0002",
          },
          {
            "name": "新店村",
            "code": "'0003",
          },
          {
            "name": "和興村",
            "code": "'0004",
          },
          {
            "name": "豐林村",
            "code": "'0005",
          },
          {
            "name": "新豐村",
            "code": "'0006",
          },
          {
            "name": "竹木村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "泰安鄉",
        "code": "'180",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "八卦村",
            "code": "'0001",
          },
          {
            "name": "錦水村",
            "code": "'0002",
          },
          {
            "name": "清安村",
            "code": "'0003",
          },
          {
            "name": "大興村",
            "code": "'0004",
          },
          {
            "name": "中興村",
            "code": "'0005",
          },
          {
            "name": "梅園村",
            "code": "'0006",
          },
          {
            "name": "象鼻村",
            "code": "'0007",
          },
          {
            "name": "士林村",
            "code": "'0008",
          }
        ]
      }
    ]
  },
  {
    "name": "彰化縣",
    "code": "'10'007",
    "subLocation": [
      {
        "name": "彰化市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "文化里",
            "code": "'0001",
          },
          {
            "name": "下廍里",
            "code": "'0002",
          },
          {
            "name": "興北里",
            "code": "'0003",
          },
          {
            "name": "信義里",
            "code": "'0004",
          },
          {
            "name": "民生里",
            "code": "'0005",
          },
          {
            "name": "陽明里",
            "code": "'0006",
          },
          {
            "name": "萬安里",
            "code": "'0007",
          },
          {
            "name": "新興里",
            "code": "'0008",
          },
          {
            "name": "新華里",
            "code": "'0009",
          },
          {
            "name": "龍山里",
            "code": "'0010",
          },
          {
            "name": "中山里",
            "code": "'0011",
          },
          {
            "name": "中正里",
            "code": "'0012",
          },
          {
            "name": "永福里",
            "code": "'0013",
          },
          {
            "name": "光復里",
            "code": "'0014",
          },
          {
            "name": "光華里",
            "code": "'0015",
          },
          {
            "name": "萬壽里",
            "code": "'0016",
          },
          {
            "name": "中央里",
            "code": "'0017",
          },
          {
            "name": "富貴里",
            "code": "'0018",
          },
          {
            "name": "民權里",
            "code": "'0019",
          },
          {
            "name": "西興里",
            "code": "'0020",
          },
          {
            "name": "西勢里",
            "code": "'0021",
          },
          {
            "name": "忠孝里",
            "code": "'0022",
          },
          {
            "name": "忠權里",
            "code": "'0023",
          },
          {
            "name": "五權里",
            "code": "'0024",
          },
          {
            "name": "長樂里",
            "code": "'0025",
          },
          {
            "name": "東芳里",
            "code": "'0026",
          },
          {
            "name": "南安里",
            "code": "'0027",
          },
          {
            "name": "南美里",
            "code": "'0028",
          },
          {
            "name": "莿桐里",
            "code": "'0029",
          },
          {
            "name": "磚磘里",
            "code": "'0030",
          },
          {
            "name": "平和里",
            "code": "'0031",
          },
          {
            "name": "桃源里",
            "code": "'0032",
          },
          {
            "name": "卦山里",
            "code": "'0033",
          },
          {
            "name": "東興里",
            "code": "'0034",
          },
          {
            "name": "大同里",
            "code": "'0035",
          },
          {
            "name": "永生里",
            "code": "'0036",
          },
          {
            "name": "華陽里",
            "code": "'0037",
          },
          {
            "name": "華北里",
            "code": "'0038",
          },
          {
            "name": "南瑤里",
            "code": "'0039",
          },
          {
            "name": "成功里",
            "code": "'0040",
          },
          {
            "name": "延和里",
            "code": "'0041",
          },
          {
            "name": "南興里",
            "code": "'0042",
          },
          {
            "name": "西安里",
            "code": "'0043",
          },
          {
            "name": "彰安里",
            "code": "'0044",
          },
          {
            "name": "光南里",
            "code": "'0045",
          },
          {
            "name": "介壽里",
            "code": "'0046",
          },
          {
            "name": "延平里",
            "code": "'0047",
          },
          {
            "name": "建寶里",
            "code": "'0048",
          },
          {
            "name": "福安里",
            "code": "'0049",
          },
          {
            "name": "大竹里",
            "code": "'0050",
          },
          {
            "name": "竹中里",
            "code": "'0051",
          },
          {
            "name": "安溪里",
            "code": "'0052",
          },
          {
            "name": "香山里",
            "code": "'0053",
          },
          {
            "name": "牛埔里",
            "code": "'0054",
          },
          {
            "name": "福山里",
            "code": "'0055",
          },
          {
            "name": "中庄里",
            "code": "'0056",
          },
          {
            "name": "三村里",
            "code": "'0057",
          },
          {
            "name": "國聖里",
            "code": "'0058",
          },
          {
            "name": "寶廍里",
            "code": "'0059",
          },
          {
            "name": "茄苳里",
            "code": "'0060",
          },
          {
            "name": "茄南里",
            "code": "'0061",
          },
          {
            "name": "阿夷里",
            "code": "'0062",
          },
          {
            "name": "古夷里",
            "code": "'0063",
          },
          {
            "name": "復興里",
            "code": "'0064",
          },
          {
            "name": "和調里",
            "code": "'0065",
          },
          {
            "name": "石牌里",
            "code": "'0066",
          },
          {
            "name": "福田里",
            "code": "'0067",
          },
          {
            "name": "竹巷里",
            "code": "'0068",
          },
          {
            "name": "田中里",
            "code": "'0069",
          },
          {
            "name": "快官里",
            "code": "'0070",
          },
          {
            "name": "台鳳里",
            "code": "'0071",
          },
          {
            "name": "崙平里",
            "code": "'0072",
          },
          {
            "name": "向陽里",
            "code": "'0073",
          }
        ]
      },
      {
        "name": "花壇鄉",
        "code": "'080",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "花壇村",
            "code": "'0001",
          },
          {
            "name": "金墩村",
            "code": "'0002",
          },
          {
            "name": "中庄村",
            "code": "'0003",
          },
          {
            "name": "劉厝村",
            "code": "'0004",
          },
          {
            "name": "崙雅村",
            "code": "'0005",
          },
          {
            "name": "南口村",
            "code": "'0006",
          },
          {
            "name": "中口村",
            "code": "'0007",
          },
          {
            "name": "北口村",
            "code": "'0008",
          },
          {
            "name": "長沙村",
            "code": "'0009",
          },
          {
            "name": "文德村",
            "code": "'0010",
          },
          {
            "name": "白沙村",
            "code": "'0011",
          },
          {
            "name": "岩竹村",
            "code": "'0012",
          },
          {
            "name": "橋頭村",
            "code": "'0013",
          },
          {
            "name": "灣雅村",
            "code": "'0014",
          },
          {
            "name": "灣東村",
            "code": "'0015",
          },
          {
            "name": "三春村",
            "code": "'0016",
          },
          {
            "name": "永春村",
            "code": "'0017",
          },
          {
            "name": "長春村",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "芬園鄉",
        "code": "'090",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "舊社村",
            "code": "'0001",
          },
          {
            "name": "大埔村",
            "code": "'0002",
          },
          {
            "name": "竹林村",
            "code": "'0003",
          },
          {
            "name": "社口村",
            "code": "'0004",
          },
          {
            "name": "茄荖村",
            "code": "'0005",
          },
          {
            "name": "嘉興村",
            "code": "'0006",
          },
          {
            "name": "芬園村",
            "code": "'0007",
          },
          {
            "name": "縣庄村",
            "code": "'0008",
          },
          {
            "name": "圳墘村",
            "code": "'0009",
          },
          {
            "name": "溪頭村",
            "code": "'0010",
          },
          {
            "name": "進芬村",
            "code": "'0011",
          },
          {
            "name": "同安村",
            "code": "'0012",
          },
          {
            "name": "中崙村",
            "code": "'0013",
          },
          {
            "name": "大竹村",
            "code": "'0014",
          },
          {
            "name": "楓坑村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "鹿港鎮",
        "code": "'020",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "大有里",
            "code": "'0001",
          },
          {
            "name": "中興里",
            "code": "'0002",
          },
          {
            "name": "洛津里",
            "code": "'0003",
          },
          {
            "name": "順興里",
            "code": "'0004",
          },
          {
            "name": "頂厝里",
            "code": "'0005",
          },
          {
            "name": "東崎里",
            "code": "'0006",
          },
          {
            "name": "埔崙里",
            "code": "'0007",
          },
          {
            "name": "東石里",
            "code": "'0008",
          },
          {
            "name": "郭厝里",
            "code": "'0009",
          },
          {
            "name": "新宮里",
            "code": "'0010",
          },
          {
            "name": "玉順里",
            "code": "'0011",
          },
          {
            "name": "景福里",
            "code": "'0012",
          },
          {
            "name": "泰興里",
            "code": "'0013",
          },
          {
            "name": "長興里",
            "code": "'0014",
          },
          {
            "name": "龍山里",
            "code": "'0015",
          },
          {
            "name": "興化里",
            "code": "'0016",
          },
          {
            "name": "菜園里",
            "code": "'0017",
          },
          {
            "name": "街尾里",
            "code": "'0018",
          },
          {
            "name": "頂番里",
            "code": "'0019",
          },
          {
            "name": "頭崙里",
            "code": "'0020",
          },
          {
            "name": "廖厝里",
            "code": "'0021",
          },
          {
            "name": "溝墘里",
            "code": "'0022",
          },
          {
            "name": "草中里",
            "code": "'0023",
          },
          {
            "name": "頭南里",
            "code": "'0024",
          },
          {
            "name": "山崙里",
            "code": "'0025",
          },
          {
            "name": "海埔里",
            "code": "'0026",
          },
          {
            "name": "詔安里",
            "code": "'0027",
          },
          {
            "name": "洋厝里",
            "code": "'0028",
          },
          {
            "name": "永安里",
            "code": "'0029",
          }
        ]
      },
      {
        "name": "福興鄉",
        "code": "'060",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "番社村",
            "code": "'0001",
          },
          {
            "name": "社尾村",
            "code": "'0002",
          },
          {
            "name": "大崙村",
            "code": "'0003",
          },
          {
            "name": "外埔村",
            "code": "'0004",
          },
          {
            "name": "外中村",
            "code": "'0005",
          },
          {
            "name": "元中村",
            "code": "'0006",
          },
          {
            "name": "三汴村",
            "code": "'0007",
          },
          {
            "name": "萬豐村",
            "code": "'0008",
          },
          {
            "name": "番婆村",
            "code": "'0009",
          },
          {
            "name": "橋頭村",
            "code": "'0010",
          },
          {
            "name": "秀厝村",
            "code": "'0011",
          },
          {
            "name": "鎮平村",
            "code": "'0012",
          },
          {
            "name": "三和村",
            "code": "'0013",
          },
          {
            "name": "麥厝村",
            "code": "'0014",
          },
          {
            "name": "廈粘村",
            "code": "'0015",
          },
          {
            "name": "頂粘村",
            "code": "'0016",
          },
          {
            "name": "福寶村",
            "code": "'0017",
          },
          {
            "name": "二港村",
            "code": "'0018",
          },
          {
            "name": "福興村",
            "code": "'0019",
          },
          {
            "name": "福南村",
            "code": "'0020",
          },
          {
            "name": "同安村",
            "code": "'0021",
          },
          {
            "name": "西勢村",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "秀水鄉",
        "code": "'070",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "秀水村",
            "code": "'0001",
          },
          {
            "name": "安溪村",
            "code": "'0002",
          },
          {
            "name": "莊雅村",
            "code": "'0003",
          },
          {
            "name": "金興村",
            "code": "'0004",
          },
          {
            "name": "陝西村",
            "code": "'0005",
          },
          {
            "name": "安東村",
            "code": "'0006",
          },
          {
            "name": "鶴鳴村",
            "code": "'0007",
          },
          {
            "name": "馬興村",
            "code": "'0008",
          },
          {
            "name": "義興村",
            "code": "'0009",
          },
          {
            "name": "福安村",
            "code": "'0010",
          },
          {
            "name": "埔崙村",
            "code": "'0011",
          },
          {
            "name": "曾厝村",
            "code": "'0012",
          },
          {
            "name": "金陵村",
            "code": "'0013",
          },
          {
            "name": "下崙村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "和美鎮",
        "code": "'030",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "竹營里",
            "code": "'0001",
          },
          {
            "name": "和東里",
            "code": "'0002",
          },
          {
            "name": "和南里",
            "code": "'0003",
          },
          {
            "name": "和北里",
            "code": "'0004",
          },
          {
            "name": "和西里",
            "code": "'0005",
          },
          {
            "name": "四張里",
            "code": "'0006",
          },
          {
            "name": "頭前里",
            "code": "'0007",
          },
          {
            "name": "還社里",
            "code": "'0008",
          },
          {
            "name": "面前里",
            "code": "'0009",
          },
          {
            "name": "鎮平里",
            "code": "'0010",
          },
          {
            "name": "大霞里",
            "code": "'0011",
          },
          {
            "name": "源埤里",
            "code": "'0012",
          },
          {
            "name": "南佃里",
            "code": "'0013",
          },
          {
            "name": "雅溝里",
            "code": "'0014",
          },
          {
            "name": "山犁里",
            "code": "'0015",
          },
          {
            "name": "嘉犁里",
            "code": "'0016",
          },
          {
            "name": "鐵山里",
            "code": "'0017",
          },
          {
            "name": "詔安里",
            "code": "'0018",
          },
          {
            "name": "柑井里",
            "code": "'0019",
          },
          {
            "name": "竹園里",
            "code": "'0020",
          },
          {
            "name": "新庄里",
            "code": "'0021",
          },
          {
            "name": "犁盛里",
            "code": "'0022",
          },
          {
            "name": "中寮里",
            "code": "'0023",
          },
          {
            "name": "中圍里",
            "code": "'0024",
          },
          {
            "name": "糖友里",
            "code": "'0025",
          },
          {
            "name": "塗厝里",
            "code": "'0026",
          },
          {
            "name": "地潭里",
            "code": "'0027",
          },
          {
            "name": "湖內里",
            "code": "'0028",
          },
          {
            "name": "嘉寶里",
            "code": "'0029",
          },
          {
            "name": "月眉里",
            "code": "'0030",
          },
          {
            "name": "好修里",
            "code": "'0031",
          },
          {
            "name": "仁愛里",
            "code": "'0032",
          }
        ]
      },
      {
        "name": "線西鄉",
        "code": "'040",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "線西村",
            "code": "'0001",
          },
          {
            "name": "頂庄村",
            "code": "'0002",
          },
          {
            "name": "寓埔村",
            "code": "'0003",
          },
          {
            "name": "塭仔村",
            "code": "'0004",
          },
          {
            "name": "溝內村",
            "code": "'0005",
          },
          {
            "name": "下犁村",
            "code": "'0006",
          },
          {
            "name": "頂犁村",
            "code": "'0007",
          },
          {
            "name": "德興村",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "伸港鄉",
        "code": "'050",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "曾家村",
            "code": "'0001",
          },
          {
            "name": "蚵寮村",
            "code": "'0002",
          },
          {
            "name": "埤墘村",
            "code": "'0003",
          },
          {
            "name": "溪底村",
            "code": "'0004",
          },
          {
            "name": "泉州村",
            "code": "'0005",
          },
          {
            "name": "泉厝村",
            "code": "'0006",
          },
          {
            "name": "海尾村",
            "code": "'0007",
          },
          {
            "name": "全興村",
            "code": "'0008",
          },
          {
            "name": "定興村",
            "code": "'0009",
          },
          {
            "name": "新港村",
            "code": "'0010",
          },
          {
            "name": "什股村",
            "code": "'0011",
          },
          {
            "name": "大同村",
            "code": "'0012",
          },
          {
            "name": "七嘉村",
            "code": "'0013",
          },
          {
            "name": "汴頭村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "員林市",
        "code": "'100",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "東和里",
            "code": "'0001",
          },
          {
            "name": "民生里",
            "code": "'0002",
          },
          {
            "name": "中正里",
            "code": "'0003",
          },
          {
            "name": "仁美里",
            "code": "'0004",
          },
          {
            "name": "新興里",
            "code": "'0005",
          },
          {
            "name": "和平里",
            "code": "'0006",
          },
          {
            "name": "光明里",
            "code": "'0007",
          },
          {
            "name": "中山里",
            "code": "'0008",
          },
          {
            "name": "黎明里",
            "code": "'0009",
          },
          {
            "name": "惠來里",
            "code": "'0010",
          },
          {
            "name": "三義里",
            "code": "'0011",
          },
          {
            "name": "新生里",
            "code": "'0012",
          },
          {
            "name": "南平里",
            "code": "'0013",
          },
          {
            "name": "南興里",
            "code": "'0014",
          },
          {
            "name": "源潭里",
            "code": "'0015",
          },
          {
            "name": "大埔里",
            "code": "'0016",
          },
          {
            "name": "三條里",
            "code": "'0017",
          },
          {
            "name": "三和里",
            "code": "'0018",
          },
          {
            "name": "三橋里",
            "code": "'0019",
          },
          {
            "name": "中央里",
            "code": "'0020",
          },
          {
            "name": "溝皂里",
            "code": "'0021",
          },
          {
            "name": "大饒里",
            "code": "'0022",
          },
          {
            "name": "大明里",
            "code": "'0023",
          },
          {
            "name": "萬年里",
            "code": "'0024",
          },
          {
            "name": "崙雅里",
            "code": "'0025",
          },
          {
            "name": "振興里",
            "code": "'0026",
          },
          {
            "name": "林厝里",
            "code": "'0027",
          },
          {
            "name": "出水里",
            "code": "'0028",
          },
          {
            "name": "湖水里",
            "code": "'0029",
          },
          {
            "name": "大峯里",
            "code": "'0030",
          },
          {
            "name": "鎮興里",
            "code": "'0031",
          },
          {
            "name": "浮圳里",
            "code": "'0032",
          },
          {
            "name": "西東里",
            "code": "'0033",
          },
          {
            "name": "南東里",
            "code": "'0034",
          },
          {
            "name": "中東里",
            "code": "'0035",
          },
          {
            "name": "東北里",
            "code": "'0036",
          },
          {
            "name": "三愛里",
            "code": "'0037",
          },
          {
            "name": "三信里",
            "code": "'0038",
          },
          {
            "name": "三多里",
            "code": "'0039",
          },
          {
            "name": "仁愛里",
            "code": "'0040",
          },
          {
            "name": "忠孝里",
            "code": "'0041",
          }
        ]
      },
      {
        "name": "大村鄉",
        "code": "'130",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "大村村",
            "code": "'0001",
          },
          {
            "name": "茄苳村",
            "code": "'0002",
          },
          {
            "name": "南勢村",
            "code": "'0003",
          },
          {
            "name": "田洋村",
            "code": "'0004",
          },
          {
            "name": "新興村",
            "code": "'0005",
          },
          {
            "name": "過溝村",
            "code": "'0006",
          },
          {
            "name": "美港村",
            "code": "'0007",
          },
          {
            "name": "平和村",
            "code": "'0008",
          },
          {
            "name": "貢旗村",
            "code": "'0009",
          },
          {
            "name": "加錫村",
            "code": "'0010",
          },
          {
            "name": "村上村",
            "code": "'0011",
          },
          {
            "name": "大橋村",
            "code": "'0012",
          },
          {
            "name": "大崙村",
            "code": "'0013",
          },
          {
            "name": "擺塘村",
            "code": "'0014",
          },
          {
            "name": "黃厝村",
            "code": "'0015",
          },
          {
            "name": "福興村",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "永靖鄉",
        "code": "'160",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "永東村",
            "code": "'0001",
          },
          {
            "name": "永西村",
            "code": "'0002",
          },
          {
            "name": "永南村",
            "code": "'0003",
          },
          {
            "name": "永北村",
            "code": "'0004",
          },
          {
            "name": "港西村",
            "code": "'0005",
          },
          {
            "name": "五福村",
            "code": "'0006",
          },
          {
            "name": "湳港村",
            "code": "'0007",
          },
          {
            "name": "新莊村",
            "code": "'0008",
          },
          {
            "name": "瑚璉村",
            "code": "'0009",
          },
          {
            "name": "五汴村",
            "code": "'0010",
          },
          {
            "name": "浮圳村",
            "code": "'0011",
          },
          {
            "name": "光雲村",
            "code": "'0012",
          },
          {
            "name": "東寧村",
            "code": "'0013",
          },
          {
            "name": "永興村",
            "code": "'0014",
          },
          {
            "name": "崙子村",
            "code": "'0015",
          },
          {
            "name": "獨鰲村",
            "code": "'0016",
          },
          {
            "name": "敦厚村",
            "code": "'0017",
          },
          {
            "name": "崙美村",
            "code": "'0018",
          },
          {
            "name": "同安村",
            "code": "'0019",
          },
          {
            "name": "同仁村",
            "code": "'0020",
          },
          {
            "name": "湳墘村",
            "code": "'0021",
          },
          {
            "name": "四芳村",
            "code": "'0022",
          },
          {
            "name": "福興村",
            "code": "'0023",
          },
          {
            "name": "竹子村",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "溪湖鎮",
        "code": "'110",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "光平里",
            "code": "'0001",
          },
          {
            "name": "平和里",
            "code": "'0002",
          },
          {
            "name": "太平里",
            "code": "'0003",
          },
          {
            "name": "光華里",
            "code": "'0004",
          },
          {
            "name": "東寮里",
            "code": "'0005",
          },
          {
            "name": "西寮里",
            "code": "'0006",
          },
          {
            "name": "大突里",
            "code": "'0007",
          },
          {
            "name": "北勢里",
            "code": "'0008",
          },
          {
            "name": "汴頭里",
            "code": "'0009",
          },
          {
            "name": "大竹里",
            "code": "'0010",
          },
          {
            "name": "湖西里",
            "code": "'0011",
          },
          {
            "name": "田中里",
            "code": "'0012",
          },
          {
            "name": "河東里",
            "code": "'0013",
          },
          {
            "name": "中山里",
            "code": "'0014",
          },
          {
            "name": "中竹里",
            "code": "'0015",
          },
          {
            "name": "東溪里",
            "code": "'0016",
          },
          {
            "name": "西溪里",
            "code": "'0017",
          },
          {
            "name": "湖東里",
            "code": "'0018",
          },
          {
            "name": "頂庄里",
            "code": "'0019",
          },
          {
            "name": "忠覺里",
            "code": "'0020",
          },
          {
            "name": "湳底里",
            "code": "'0021",
          },
          {
            "name": "大庭里",
            "code": "'0022",
          },
          {
            "name": "媽厝里",
            "code": "'0023",
          },
          {
            "name": "西勢里",
            "code": "'0024",
          },
          {
            "name": "番婆里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "埔鹽鄉",
        "code": "'140",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "埔鹽村",
            "code": "'0001",
          },
          {
            "name": "埔南村",
            "code": "'0002",
          },
          {
            "name": "廍子村",
            "code": "'0003",
          },
          {
            "name": "出水村",
            "code": "'0004",
          },
          {
            "name": "南港村",
            "code": "'0005",
          },
          {
            "name": "打廉村",
            "code": "'0006",
          },
          {
            "name": "豐澤村",
            "code": "'0007",
          },
          {
            "name": "崑崙村",
            "code": "'0008",
          },
          {
            "name": "角樹村",
            "code": "'0009",
          },
          {
            "name": "瓦磘村",
            "code": "'0010",
          },
          {
            "name": "好修村",
            "code": "'0011",
          },
          {
            "name": "西湖村",
            "code": "'0012",
          },
          {
            "name": "大有村",
            "code": "'0013",
          },
          {
            "name": "新興村",
            "code": "'0014",
          },
          {
            "name": "永平村",
            "code": "'0015",
          },
          {
            "name": "永樂村",
            "code": "'0016",
          },
          {
            "name": "石埤村",
            "code": "'0017",
          },
          {
            "name": "天盛村",
            "code": "'0018",
          },
          {
            "name": "太平村",
            "code": "'0019",
          },
          {
            "name": "新水村",
            "code": "'0020",
          },
          {
            "name": "三省村",
            "code": "'0021",
          },
          {
            "name": "南新村",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "埔心鄉",
        "code": "'150",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "東門村",
            "code": "'0001",
          },
          {
            "name": "埔心村",
            "code": "'0002",
          },
          {
            "name": "義民村",
            "code": "'0003",
          },
          {
            "name": "油車村",
            "code": "'0004",
          },
          {
            "name": "瓦北村",
            "code": "'0005",
          },
          {
            "name": "瓦中村",
            "code": "'0006",
          },
          {
            "name": "瓦南村",
            "code": "'0007",
          },
          {
            "name": "太平村",
            "code": "'0008",
          },
          {
            "name": "經口村",
            "code": "'0009",
          },
          {
            "name": "大華村",
            "code": "'0010",
          },
          {
            "name": "仁里村",
            "code": "'0011",
          },
          {
            "name": "舊舘村",
            "code": "'0012",
          },
          {
            "name": "南舘村",
            "code": "'0013",
          },
          {
            "name": "新舘村",
            "code": "'0014",
          },
          {
            "name": "羅厝村",
            "code": "'0015",
          },
          {
            "name": "芎蕉村",
            "code": "'0016",
          },
          {
            "name": "二重村",
            "code": "'0017",
          },
          {
            "name": "梧鳳村",
            "code": "'0018",
          },
          {
            "name": "埤霞村",
            "code": "'0019",
          },
          {
            "name": "埤脚村",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "田中鎮",
        "code": "'120",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "香山里",
            "code": "'0001",
          },
          {
            "name": "碧峰里",
            "code": "'0002",
          },
          {
            "name": "東源里",
            "code": "'0003",
          },
          {
            "name": "復興里",
            "code": "'0004",
          },
          {
            "name": "平和里",
            "code": "'0005",
          },
          {
            "name": "頂潭里",
            "code": "'0006",
          },
          {
            "name": "中潭里",
            "code": "'0007",
          },
          {
            "name": "龍潭里",
            "code": "'0008",
          },
          {
            "name": "北路里",
            "code": "'0009",
          },
          {
            "name": "西路里",
            "code": "'0010",
          },
          {
            "name": "東路里",
            "code": "'0011",
          },
          {
            "name": "南路里",
            "code": "'0012",
          },
          {
            "name": "中路里",
            "code": "'0013",
          },
          {
            "name": "新庄里",
            "code": "'0014",
          },
          {
            "name": "三安里",
            "code": "'0015",
          },
          {
            "name": "大崙里",
            "code": "'0016",
          },
          {
            "name": "沙崙里",
            "code": "'0017",
          },
          {
            "name": "新民里",
            "code": "'0018",
          },
          {
            "name": "梅州里",
            "code": "'0019",
          },
          {
            "name": "三民里",
            "code": "'0020",
          },
          {
            "name": "三光里",
            "code": "'0021",
          },
          {
            "name": "大社里",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "社頭鄉",
        "code": "'170",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "社頭村",
            "code": "'0001",
          },
          {
            "name": "東興村",
            "code": "'0002",
          },
          {
            "name": "山湖村",
            "code": "'0003",
          },
          {
            "name": "廣興村",
            "code": "'0004",
          },
          {
            "name": "廣福村",
            "code": "'0005",
          },
          {
            "name": "朝興村",
            "code": "'0006",
          },
          {
            "name": "仁雅村",
            "code": "'0007",
          },
          {
            "name": "橋頭村",
            "code": "'0008",
          },
          {
            "name": "仁和村",
            "code": "'0009",
          },
          {
            "name": "崙雅村",
            "code": "'0010",
          },
          {
            "name": "湳底村",
            "code": "'0011",
          },
          {
            "name": "泰安村",
            "code": "'0012",
          },
          {
            "name": "美雅村",
            "code": "'0013",
          },
          {
            "name": "新厝村",
            "code": "'0014",
          },
          {
            "name": "平和村",
            "code": "'0015",
          },
          {
            "name": "里仁村",
            "code": "'0016",
          },
          {
            "name": "張厝村",
            "code": "'0017",
          },
          {
            "name": "龍井村",
            "code": "'0018",
          },
          {
            "name": "舊社村",
            "code": "'0019",
          },
          {
            "name": "埤斗村",
            "code": "'0020",
          },
          {
            "name": "湳雅村",
            "code": "'0021",
          },
          {
            "name": "松竹村",
            "code": "'0022",
          },
          {
            "name": "清水村",
            "code": "'0023",
          },
          {
            "name": "協和村",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "二水鄉",
        "code": "'180",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "合和村",
            "code": "'0001",
          },
          {
            "name": "上豊村",
            "code": "'0002",
          },
          {
            "name": "過圳村",
            "code": "'0003",
          },
          {
            "name": "五伯村",
            "code": "'0004",
          },
          {
            "name": "光化村",
            "code": "'0005",
          },
          {
            "name": "文化村",
            "code": "'0006",
          },
          {
            "name": "二水村",
            "code": "'0007",
          },
          {
            "name": "聖化村",
            "code": "'0008",
          },
          {
            "name": "裕民村",
            "code": "'0009",
          },
          {
            "name": "惠民村",
            "code": "'0010",
          },
          {
            "name": "大園村",
            "code": "'0011",
          },
          {
            "name": "修仁村",
            "code": "'0012",
          },
          {
            "name": "合興村",
            "code": "'0013",
          },
          {
            "name": "源泉村",
            "code": "'0014",
          },
          {
            "name": "倡和村",
            "code": "'0015",
          },
          {
            "name": "復興村",
            "code": "'0016",
          },
          {
            "name": "十五村",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "北斗鎮",
        "code": "'190",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "光復里",
            "code": "'0001",
          },
          {
            "name": "新政里",
            "code": "'0002",
          },
          {
            "name": "重慶里",
            "code": "'0003",
          },
          {
            "name": "五權里",
            "code": "'0004",
          },
          {
            "name": "七星里",
            "code": "'0005",
          },
          {
            "name": "居仁里",
            "code": "'0006",
          },
          {
            "name": "東光里",
            "code": "'0007",
          },
          {
            "name": "文昌里",
            "code": "'0008",
          },
          {
            "name": "中和里",
            "code": "'0009",
          },
          {
            "name": "西德里",
            "code": "'0010",
          },
          {
            "name": "西安里",
            "code": "'0011",
          },
          {
            "name": "中寮里",
            "code": "'0012",
          },
          {
            "name": "大道里",
            "code": "'0013",
          },
          {
            "name": "新生里",
            "code": "'0014",
          },
          {
            "name": "大新里",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "田尾鄉",
        "code": "'210",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "田尾村",
            "code": "'0001",
          },
          {
            "name": "豐田村",
            "code": "'0002",
          },
          {
            "name": "饒平村",
            "code": "'0003",
          },
          {
            "name": "睦宜村",
            "code": "'0004",
          },
          {
            "name": "福田村",
            "code": "'0005",
          },
          {
            "name": "新興村",
            "code": "'0006",
          },
          {
            "name": "北鎮村",
            "code": "'0007",
          },
          {
            "name": "南鎮村",
            "code": "'0008",
          },
          {
            "name": "新生村",
            "code": "'0009",
          },
          {
            "name": "南曾村",
            "code": "'0010",
          },
          {
            "name": "北曾村",
            "code": "'0011",
          },
          {
            "name": "溪畔村",
            "code": "'0012",
          },
          {
            "name": "打簾村",
            "code": "'0013",
          },
          {
            "name": "柳鳳村",
            "code": "'0014",
          },
          {
            "name": "陸豐村",
            "code": "'0015",
          },
          {
            "name": "海豐村",
            "code": "'0016",
          },
          {
            "name": "仁里村",
            "code": "'0017",
          },
          {
            "name": "新厝村",
            "code": "'0018",
          },
          {
            "name": "溪頂村",
            "code": "'0019",
          },
          {
            "name": "正義村",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "埤頭鄉",
        "code": "'220",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "和豊村",
            "code": "'0001",
          },
          {
            "name": "興農村",
            "code": "'0002",
          },
          {
            "name": "埤頭村",
            "code": "'0003",
          },
          {
            "name": "豊崙村",
            "code": "'0004",
          },
          {
            "name": "崙子村",
            "code": "'0005",
          },
          {
            "name": "永豊村",
            "code": "'0006",
          },
          {
            "name": "合興村",
            "code": "'0007",
          },
          {
            "name": "平原村",
            "code": "'0008",
          },
          {
            "name": "崙腳村",
            "code": "'0009",
          },
          {
            "name": "元埔村",
            "code": "'0010",
          },
          {
            "name": "芙朝村",
            "code": "'0011",
          },
          {
            "name": "新庄村",
            "code": "'0012",
          },
          {
            "name": "陸嘉村",
            "code": "'0013",
          },
          {
            "name": "竹圍村",
            "code": "'0014",
          },
          {
            "name": "中和村",
            "code": "'0015",
          },
          {
            "name": "庄內村",
            "code": "'0016",
          },
          {
            "name": "大湖村",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "溪州鄉",
        "code": "'260",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "溪厝村",
            "code": "'0001",
          },
          {
            "name": "舊眉村",
            "code": "'0002",
          },
          {
            "name": "柑園村",
            "code": "'0003",
          },
          {
            "name": "水尾村",
            "code": "'0004",
          },
          {
            "name": "坑厝村",
            "code": "'0005",
          },
          {
            "name": "圳寮村",
            "code": "'0006",
          },
          {
            "name": "潮洋村",
            "code": "'0007",
          },
          {
            "name": "瓦厝村",
            "code": "'0008",
          },
          {
            "name": "西畔村",
            "code": "'0009",
          },
          {
            "name": "張厝村",
            "code": "'0010",
          },
          {
            "name": "溪州村",
            "code": "'0011",
          },
          {
            "name": "成功村",
            "code": "'0012",
          },
          {
            "name": "菜公村",
            "code": "'0013",
          },
          {
            "name": "尾厝村",
            "code": "'0014",
          },
          {
            "name": "大庄村",
            "code": "'0015",
          },
          {
            "name": "三圳村",
            "code": "'0016",
          },
          {
            "name": "東州村",
            "code": "'0017",
          },
          {
            "name": "榮光村",
            "code": "'0018",
          },
          {
            "name": "三條村",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "二林鎮",
        "code": "'200",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "東和里",
            "code": "'0001",
          },
          {
            "name": "南光里",
            "code": "'0002",
          },
          {
            "name": "西平里",
            "code": "'0003",
          },
          {
            "name": "北平里",
            "code": "'0004",
          },
          {
            "name": "中西里",
            "code": "'0005",
          },
          {
            "name": "廣興里",
            "code": "'0006",
          },
          {
            "name": "香田里",
            "code": "'0007",
          },
          {
            "name": "外竹里",
            "code": "'0008",
          },
          {
            "name": "興華里",
            "code": "'0009",
          },
          {
            "name": "東興里",
            "code": "'0010",
          },
          {
            "name": "後厝里",
            "code": "'0011",
          },
          {
            "name": "豐田里",
            "code": "'0012",
          },
          {
            "name": "頂厝里",
            "code": "'0013",
          },
          {
            "name": "趙甲里",
            "code": "'0014",
          },
          {
            "name": "振興里",
            "code": "'0015",
          },
          {
            "name": "萬興里",
            "code": "'0016",
          },
          {
            "name": "永興里",
            "code": "'0017",
          },
          {
            "name": "西庄里",
            "code": "'0018",
          },
          {
            "name": "梅芳里",
            "code": "'0019",
          },
          {
            "name": "華崙里",
            "code": "'0020",
          },
          {
            "name": "東勢里",
            "code": "'0021",
          },
          {
            "name": "萬合里",
            "code": "'0022",
          },
          {
            "name": "大永里",
            "code": "'0023",
          },
          {
            "name": "原斗里",
            "code": "'0024",
          },
          {
            "name": "西斗里",
            "code": "'0025",
          },
          {
            "name": "東華里",
            "code": "'0026",
          },
          {
            "name": "復豐里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "芳苑鄉",
        "code": "'230",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "芳苑村",
            "code": "'0001",
          },
          {
            "name": "芳中村",
            "code": "'0002",
          },
          {
            "name": "仁愛村",
            "code": "'0003",
          },
          {
            "name": "信義村",
            "code": "'0004",
          },
          {
            "name": "後寮村",
            "code": "'0005",
          },
          {
            "name": "三合村",
            "code": "'0006",
          },
          {
            "name": "永興村",
            "code": "'0007",
          },
          {
            "name": "五俊村",
            "code": "'0008",
          },
          {
            "name": "路上村",
            "code": "'0009",
          },
          {
            "name": "路平村",
            "code": "'0010",
          },
          {
            "name": "三成村",
            "code": "'0011",
          },
          {
            "name": "福榮村",
            "code": "'0012",
          },
          {
            "name": "頂廍村",
            "code": "'0013",
          },
          {
            "name": "新街村",
            "code": "'0014",
          },
          {
            "name": "王功村",
            "code": "'0015",
          },
          {
            "name": "博愛村",
            "code": "'0016",
          },
          {
            "name": "和平村",
            "code": "'0017",
          },
          {
            "name": "民生村",
            "code": "'0018",
          },
          {
            "name": "興仁村",
            "code": "'0019",
          },
          {
            "name": "新寶村",
            "code": "'0020",
          },
          {
            "name": "草湖村",
            "code": "'0021",
          },
          {
            "name": "文津村",
            "code": "'0022",
          },
          {
            "name": "建平村",
            "code": "'0023",
          },
          {
            "name": "崙腳村",
            "code": "'0024",
          },
          {
            "name": "新生村",
            "code": "'0025",
          },
          {
            "name": "漢寶村",
            "code": "'0026",
          }
        ]
      },
      {
        "name": "大城鄉",
        "code": "'240",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "潭墘村",
            "code": "'0001",
          },
          {
            "name": "上山村",
            "code": "'0002",
          },
          {
            "name": "東城村",
            "code": "'0003",
          },
          {
            "name": "西城村",
            "code": "'0004",
          },
          {
            "name": "大城村",
            "code": "'0005",
          },
          {
            "name": "菜寮村",
            "code": "'0006",
          },
          {
            "name": "山腳村",
            "code": "'0007",
          },
          {
            "name": "永和村",
            "code": "'0008",
          },
          {
            "name": "頂庄村",
            "code": "'0009",
          },
          {
            "name": "公館村",
            "code": "'0010",
          },
          {
            "name": "台西村",
            "code": "'0011",
          },
          {
            "name": "東港村",
            "code": "'0012",
          },
          {
            "name": "西港村",
            "code": "'0013",
          },
          {
            "name": "豐美村",
            "code": "'0014",
          },
          {
            "name": "三豐村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "竹塘鄉",
        "code": "'250",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "竹元村",
            "code": "'0001",
          },
          {
            "name": "竹塘村",
            "code": "'0002",
          },
          {
            "name": "小西村",
            "code": "'0003",
          },
          {
            "name": "民靖村",
            "code": "'0004",
          },
          {
            "name": "五庄村",
            "code": "'0005",
          },
          {
            "name": "樹腳村",
            "code": "'0006",
          },
          {
            "name": "田頭村",
            "code": "'0007",
          },
          {
            "name": "新廣村",
            "code": "'0008",
          },
          {
            "name": "溪墘村",
            "code": "'0009",
          },
          {
            "name": "竹林村",
            "code": "'0010",
          },
          {
            "name": "土庫村",
            "code": "'0011",
          },
          {
            "name": "內新村",
            "code": "'0012",
          },
          {
            "name": "長安村",
            "code": "'0013",
          },
          {
            "name": "永安村",
            "code": "'0014",
          }
        ]
      }
    ]
  },
  {
    "name": "南投縣",
    "code": "'10'008",
    "subLocation": [
      {
        "name": "南投市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "龍泉里",
            "code": "'0001",
          },
          {
            "name": "康壽里",
            "code": "'0002",
          },
          {
            "name": "三民里",
            "code": "'0003",
          },
          {
            "name": "仁和里",
            "code": "'0004",
          },
          {
            "name": "南投里",
            "code": "'0005",
          },
          {
            "name": "彰仁里",
            "code": "'0006",
          },
          {
            "name": "崇文里",
            "code": "'0007",
          },
          {
            "name": "漳興里",
            "code": "'0008",
          },
          {
            "name": "漳和里",
            "code": "'0009",
          },
          {
            "name": "軍功里",
            "code": "'0010",
          },
          {
            "name": "東山里",
            "code": "'0011",
          },
          {
            "name": "平和里",
            "code": "'0012",
          },
          {
            "name": "振興里",
            "code": "'0013",
          },
          {
            "name": "千秋里",
            "code": "'0014",
          },
          {
            "name": "三興里",
            "code": "'0015",
          },
          {
            "name": "三和里",
            "code": "'0016",
          },
          {
            "name": "嘉興里",
            "code": "'0017",
          },
          {
            "name": "嘉和里",
            "code": "'0018",
          },
          {
            "name": "福山里",
            "code": "'0019",
          },
          {
            "name": "永興里",
            "code": "'0020",
          },
          {
            "name": "鳳鳴里",
            "code": "'0021",
          },
          {
            "name": "鳳山里",
            "code": "'0022",
          },
          {
            "name": "平山里",
            "code": "'0023",
          },
          {
            "name": "新興里",
            "code": "'0024",
          },
          {
            "name": "永豐里",
            "code": "'0025",
          },
          {
            "name": "福興里",
            "code": "'0026",
          },
          {
            "name": "營北里",
            "code": "'0027",
          },
          {
            "name": "營南里",
            "code": "'0028",
          },
          {
            "name": "內興里",
            "code": "'0029",
          },
          {
            "name": "內新里",
            "code": "'0030",
          },
          {
            "name": "光華里",
            "code": "'0031",
          },
          {
            "name": "光榮里",
            "code": "'0032",
          },
          {
            "name": "光明里",
            "code": "'0033",
          },
          {
            "name": "光輝里",
            "code": "'0034",
          }
        ]
      },
      {
        "name": "名間鄉",
        "code": "'060",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "南雅村",
            "code": "'0001",
          },
          {
            "name": "中正村",
            "code": "'0002",
          },
          {
            "name": "中山村",
            "code": "'0003",
          },
          {
            "name": "濁水村",
            "code": "'0004",
          },
          {
            "name": "炭寮村",
            "code": "'0005",
          },
          {
            "name": "新民村",
            "code": "'0006",
          },
          {
            "name": "新街村",
            "code": "'0007",
          },
          {
            "name": "東湖村",
            "code": "'0008",
          },
          {
            "name": "萬丹村",
            "code": "'0009",
          },
          {
            "name": "田仔村",
            "code": "'0010",
          },
          {
            "name": "大庄村",
            "code": "'0011",
          },
          {
            "name": "廍下村",
            "code": "'0012",
          },
          {
            "name": "大坑村",
            "code": "'0013",
          },
          {
            "name": "竹圍村",
            "code": "'0014",
          },
          {
            "name": "赤水村",
            "code": "'0015",
          },
          {
            "name": "三崙村",
            "code": "'0016",
          },
          {
            "name": "松山村",
            "code": "'0017",
          },
          {
            "name": "松柏村",
            "code": "'0018",
          },
          {
            "name": "埔中村",
            "code": "'0019",
          },
          {
            "name": "崁腳村",
            "code": "'0020",
          },
          {
            "name": "新厝村",
            "code": "'0021",
          },
          {
            "name": "新光村",
            "code": "'0022",
          },
          {
            "name": "仁和村",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "草屯鎮",
        "code": "'030",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "玉峰里",
            "code": "'0001",
          },
          {
            "name": "炎峰里",
            "code": "'0002",
          },
          {
            "name": "和平里",
            "code": "'0003",
          },
          {
            "name": "敦和里",
            "code": "'0004",
          },
          {
            "name": "山腳里",
            "code": "'0005",
          },
          {
            "name": "富寮里",
            "code": "'0006",
          },
          {
            "name": "御史里",
            "code": "'0007",
          },
          {
            "name": "新豐里",
            "code": "'0008",
          },
          {
            "name": "碧峰里",
            "code": "'0009",
          },
          {
            "name": "碧洲里",
            "code": "'0010",
          },
          {
            "name": "復興里",
            "code": "'0011",
          },
          {
            "name": "上林里",
            "code": "'0012",
          },
          {
            "name": "新庄里",
            "code": "'0013",
          },
          {
            "name": "北投里",
            "code": "'0014",
          },
          {
            "name": "石川里",
            "code": "'0015",
          },
          {
            "name": "加老里",
            "code": "'0016",
          },
          {
            "name": "北勢里",
            "code": "'0017",
          },
          {
            "name": "中原里",
            "code": "'0018",
          },
          {
            "name": "南埔里",
            "code": "'0019",
          },
          {
            "name": "土城里",
            "code": "'0020",
          },
          {
            "name": "坪頂里",
            "code": "'0021",
          },
          {
            "name": "雙冬里",
            "code": "'0022",
          },
          {
            "name": "平林里",
            "code": "'0023",
          },
          {
            "name": "中正里",
            "code": "'0024",
          },
          {
            "name": "中山里",
            "code": "'0025",
          },
          {
            "name": "明正里",
            "code": "'0026",
          },
          {
            "name": "新厝里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "中寮鄉",
        "code": "'080",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "廣興村",
            "code": "'0001",
          },
          {
            "name": "崁頂村",
            "code": "'0002",
          },
          {
            "name": "八仙村",
            "code": "'0003",
          },
          {
            "name": "和興村",
            "code": "'0004",
          },
          {
            "name": "福盛村",
            "code": "'0005",
          },
          {
            "name": "永平村",
            "code": "'0006",
          },
          {
            "name": "復興村",
            "code": "'0007",
          },
          {
            "name": "中寮村",
            "code": "'0008",
          },
          {
            "name": "廣福村",
            "code": "'0009",
          },
          {
            "name": "永福村",
            "code": "'0010",
          },
          {
            "name": "義和村",
            "code": "'0011",
          },
          {
            "name": "清水村",
            "code": "'0012",
          },
          {
            "name": "龍安村",
            "code": "'0013",
          },
          {
            "name": "龍岩村",
            "code": "'0014",
          },
          {
            "name": "永和村",
            "code": "'0015",
          },
          {
            "name": "內城村",
            "code": "'0016",
          },
          {
            "name": "永芳村",
            "code": "'0017",
          },
          {
            "name": "爽文村",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "集集鎮",
        "code": "'050",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "集集里",
            "code": "'0001",
          },
          {
            "name": "和平里",
            "code": "'0002",
          },
          {
            "name": "林尾里",
            "code": "'0003",
          },
          {
            "name": "田寮里",
            "code": "'0004",
          },
          {
            "name": "隘寮里",
            "code": "'0005",
          },
          {
            "name": "玉映里",
            "code": "'0006",
          },
          {
            "name": "吳厝里",
            "code": "'0007",
          },
          {
            "name": "八張里",
            "code": "'0008",
          },
          {
            "name": "永昌里",
            "code": "'0009",
          },
          {
            "name": "富山里",
            "code": "'0010",
          },
          {
            "name": "廣明里",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "魚池鄉",
        "code": "'090",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "東池村",
            "code": "'0001",
          },
          {
            "name": "魚池村",
            "code": "'0002",
          },
          {
            "name": "中明村",
            "code": "'0003",
          },
          {
            "name": "新城村",
            "code": "'0004",
          },
          {
            "name": "大林村",
            "code": "'0005",
          },
          {
            "name": "東光村",
            "code": "'0006",
          },
          {
            "name": "共和村",
            "code": "'0007",
          },
          {
            "name": "大雁村",
            "code": "'0008",
          },
          {
            "name": "五城村",
            "code": "'0009",
          },
          {
            "name": "水社村",
            "code": "'0010",
          },
          {
            "name": "頭社村",
            "code": "'0011",
          },
          {
            "name": "武登村",
            "code": "'0012",
          },
          {
            "name": "日月村",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "水里鄉",
        "code": "'110",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "水里村",
            "code": "'0001",
          },
          {
            "name": "農富村",
            "code": "'0002",
          },
          {
            "name": "新城村",
            "code": "'0003",
          },
          {
            "name": "中央村",
            "code": "'0004",
          },
          {
            "name": "城中村",
            "code": "'0005",
          },
          {
            "name": "北埔村",
            "code": "'0006",
          },
          {
            "name": "鉅工村",
            "code": "'0007",
          },
          {
            "name": "永豐村",
            "code": "'0008",
          },
          {
            "name": "頂崁村",
            "code": "'0009",
          },
          {
            "name": "車埕村",
            "code": "'0010",
          },
          {
            "name": "新興村",
            "code": "'0011",
          },
          {
            "name": "新山村",
            "code": "'0012",
          },
          {
            "name": "郡坑村",
            "code": "'0013",
          },
          {
            "name": "上安村",
            "code": "'0014",
          },
          {
            "name": "民和村",
            "code": "'0015",
          },
          {
            "name": "永興村",
            "code": "'0016",
          },
          {
            "name": "玉峰村",
            "code": "'0017",
          },
          {
            "name": "興隆村",
            "code": "'0018",
          },
          {
            "name": "南光村",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "信義鄉",
        "code": "'120",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "人和村",
            "code": "'0001",
          },
          {
            "name": "地利村",
            "code": "'0002",
          },
          {
            "name": "潭南村",
            "code": "'0003",
          },
          {
            "name": "明德村",
            "code": "'0004",
          },
          {
            "name": "愛國村",
            "code": "'0005",
          },
          {
            "name": "自強村",
            "code": "'0006",
          },
          {
            "name": "羅娜村",
            "code": "'0007",
          },
          {
            "name": "望美村",
            "code": "'0008",
          },
          {
            "name": "同富村",
            "code": "'0009",
          },
          {
            "name": "神木村",
            "code": "'0010",
          },
          {
            "name": "東埔村",
            "code": "'0011",
          },
          {
            "name": "雙龍村",
            "code": "'0012",
          },
          {
            "name": "新鄉村",
            "code": "'0013",
          },
          {
            "name": "豐丘村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "竹山鎮",
        "code": "'040",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "竹山里",
            "code": "'0001",
          },
          {
            "name": "中正里",
            "code": "'0002",
          },
          {
            "name": "中山里",
            "code": "'0003",
          },
          {
            "name": "雲林里",
            "code": "'0004",
          },
          {
            "name": "硘磘里",
            "code": "'0005",
          },
          {
            "name": "下坪里",
            "code": "'0006",
          },
          {
            "name": "桂林里",
            "code": "'0007",
          },
          {
            "name": "中和里",
            "code": "'0008",
          },
          {
            "name": "中崎里",
            "code": "'0009",
          },
          {
            "name": "德興里",
            "code": "'0010",
          },
          {
            "name": "延平里",
            "code": "'0011",
          },
          {
            "name": "延正里",
            "code": "'0012",
          },
          {
            "name": "延山里",
            "code": "'0013",
          },
          {
            "name": "延和里",
            "code": "'0014",
          },
          {
            "name": "山崇里",
            "code": "'0015",
          },
          {
            "name": "社寮里",
            "code": "'0016",
          },
          {
            "name": "中央里",
            "code": "'0017",
          },
          {
            "name": "富州里",
            "code": "'0018",
          },
          {
            "name": "大鞍里",
            "code": "'0019",
          },
          {
            "name": "田子里",
            "code": "'0020",
          },
          {
            "name": "福興里",
            "code": "'0021",
          },
          {
            "name": "鯉魚里",
            "code": "'0022",
          },
          {
            "name": "瑞竹里",
            "code": "'0023",
          },
          {
            "name": "坪頂里",
            "code": "'0024",
          },
          {
            "name": "桶頭里",
            "code": "'0025",
          },
          {
            "name": "秀林里",
            "code": "'0026",
          },
          {
            "name": "延祥里",
            "code": "'0027",
          },
          {
            "name": "竹圍里",
            "code": "'0028",
          }
        ]
      },
      {
        "name": "鹿谷鄉",
        "code": "'070",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "鹿谷村",
            "code": "'0001",
          },
          {
            "name": "初鄉村",
            "code": "'0002",
          },
          {
            "name": "彰雅村",
            "code": "'0003",
          },
          {
            "name": "廣興村",
            "code": "'0004",
          },
          {
            "name": "內湖村",
            "code": "'0005",
          },
          {
            "name": "竹林村",
            "code": "'0006",
          },
          {
            "name": "竹豐村",
            "code": "'0007",
          },
          {
            "name": "和雅村",
            "code": "'0008",
          },
          {
            "name": "鳳凰村",
            "code": "'0009",
          },
          {
            "name": "永隆村",
            "code": "'0010",
          },
          {
            "name": "秀峰村",
            "code": "'0011",
          },
          {
            "name": "清水村",
            "code": "'0012",
          },
          {
            "name": "瑞田村",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "埔里鎮",
        "code": "'020",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "東門里",
            "code": "'0001",
          },
          {
            "name": "南門里",
            "code": "'0002",
          },
          {
            "name": "西門里",
            "code": "'0003",
          },
          {
            "name": "北門里",
            "code": "'0004",
          },
          {
            "name": "同聲里",
            "code": "'0005",
          },
          {
            "name": "薰化里",
            "code": "'0006",
          },
          {
            "name": "清新里",
            "code": "'0007",
          },
          {
            "name": "大城里",
            "code": "'0008",
          },
          {
            "name": "籃城里",
            "code": "'0009",
          },
          {
            "name": "枇杷里",
            "code": "'0010",
          },
          {
            "name": "水頭里",
            "code": "'0011",
          },
          {
            "name": "麒麟里",
            "code": "'0012",
          },
          {
            "name": "珠格里",
            "code": "'0013",
          },
          {
            "name": "溪南里",
            "code": "'0014",
          },
          {
            "name": "桃米里",
            "code": "'0015",
          },
          {
            "name": "成功里",
            "code": "'0016",
          },
          {
            "name": "南村里",
            "code": "'0017",
          },
          {
            "name": "愛蘭里",
            "code": "'0018",
          },
          {
            "name": "鐵山里",
            "code": "'0019",
          },
          {
            "name": "房里里",
            "code": "'0020",
          },
          {
            "name": "向善里",
            "code": "'0021",
          },
          {
            "name": "一新里",
            "code": "'0022",
          },
          {
            "name": "廣成里",
            "code": "'0023",
          },
          {
            "name": "史港里",
            "code": "'0024",
          },
          {
            "name": "福興里",
            "code": "'0025",
          },
          {
            "name": "牛眠里",
            "code": "'0026",
          },
          {
            "name": "大湳里",
            "code": "'0027",
          },
          {
            "name": "蜈蚣里",
            "code": "'0028",
          },
          {
            "name": "合成里",
            "code": "'0029",
          },
          {
            "name": "北安里",
            "code": "'0030",
          },
          {
            "name": "杷城里",
            "code": "'0031",
          },
          {
            "name": "北梅里",
            "code": "'0032",
          },
          {
            "name": "泰安里",
            "code": "'0033",
          }
        ]
      },
      {
        "name": "國姓鄉",
        "code": "'100",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "國姓村",
            "code": "'0001",
          },
          {
            "name": "石門村",
            "code": "'0002",
          },
          {
            "name": "大旗村",
            "code": "'0003",
          },
          {
            "name": "北港村",
            "code": "'0004",
          },
          {
            "name": "長流村",
            "code": "'0005",
          },
          {
            "name": "長福村",
            "code": "'0006",
          },
          {
            "name": "福龜村",
            "code": "'0007",
          },
          {
            "name": "乾溝村",
            "code": "'0008",
          },
          {
            "name": "柑林村",
            "code": "'0009",
          },
          {
            "name": "北山村",
            "code": "'0010",
          },
          {
            "name": "南港村",
            "code": "'0011",
          },
          {
            "name": "大石村",
            "code": "'0012",
          },
          {
            "name": "長豐村",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "仁愛鄉",
        "code": "'130",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "法治村",
            "code": "'0001",
          },
          {
            "name": "中正村",
            "code": "'0002",
          },
          {
            "name": "互助村",
            "code": "'0003",
          },
          {
            "name": "新生村",
            "code": "'0004",
          },
          {
            "name": "力行村",
            "code": "'0005",
          },
          {
            "name": "發祥村",
            "code": "'0006",
          },
          {
            "name": "合作村",
            "code": "'0007",
          },
          {
            "name": "親愛村",
            "code": "'0008",
          },
          {
            "name": "大同村",
            "code": "'0009",
          },
          {
            "name": "南豐村",
            "code": "'0010",
          },
          {
            "name": "春陽村",
            "code": "'0011",
          },
          {
            "name": "精英村",
            "code": "'0012",
          },
          {
            "name": "萬豐村",
            "code": "'0013",
          },
          {
            "name": "榮興村",
            "code": "'0014",
          },
          {
            "name": "翠華村",
            "code": "'0015",
          },
          {
            "name": "都達村",
            "code": "'0016",
          }
        ]
      }
    ]
  },
  {
    "name": "雲林縣",
    "code": "'10'009",
    "subLocation": [
      {
        "name": "斗六市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "忠孝里",
            "code": "'0001",
          },
          {
            "name": "仁愛里",
            "code": "'0002",
          },
          {
            "name": "信義里",
            "code": "'0003",
          },
          {
            "name": "四維里",
            "code": "'0004",
          },
          {
            "name": "太平里",
            "code": "'0005",
          },
          {
            "name": "中和里",
            "code": "'0006",
          },
          {
            "name": "光興里",
            "code": "'0007",
          },
          {
            "name": "三平里",
            "code": "'0008",
          },
          {
            "name": "八德里",
            "code": "'0009",
          },
          {
            "name": "鎮北里",
            "code": "'0010",
          },
          {
            "name": "龍潭里",
            "code": "'0011",
          },
          {
            "name": "鎮南里",
            "code": "'0012",
          },
          {
            "name": "林頭里",
            "code": "'0013",
          },
          {
            "name": "重光里",
            "code": "'0014",
          },
          {
            "name": "溝埧里",
            "code": "'0015",
          },
          {
            "name": "江厝里",
            "code": "'0016",
          },
          {
            "name": "三光里",
            "code": "'0017",
          },
          {
            "name": "崙峰里",
            "code": "'0018",
          },
          {
            "name": "嘉東里",
            "code": "'0019",
          },
          {
            "name": "久安里",
            "code": "'0020",
          },
          {
            "name": "長平里",
            "code": "'0021",
          },
          {
            "name": "保庄里",
            "code": "'0022",
          },
          {
            "name": "虎溪里",
            "code": "'0023",
          },
          {
            "name": "梅林里",
            "code": "'0024",
          },
          {
            "name": "湖山里",
            "code": "'0025",
          },
          {
            "name": "榴中里",
            "code": "'0026",
          },
          {
            "name": "榴北里",
            "code": "'0027",
          },
          {
            "name": "榴南里",
            "code": "'0028",
          },
          {
            "name": "溪洲里",
            "code": "'0029",
          },
          {
            "name": "長安里",
            "code": "'0030",
          },
          {
            "name": "十三里",
            "code": "'0031",
          },
          {
            "name": "鎮東里",
            "code": "'0032",
          },
          {
            "name": "鎮西里",
            "code": "'0033",
          },
          {
            "name": "公正里",
            "code": "'0034",
          },
          {
            "name": "社口里",
            "code": "'0035",
          },
          {
            "name": "公誠里",
            "code": "'0036",
          },
          {
            "name": "明德里",
            "code": "'0037",
          },
          {
            "name": "成功里",
            "code": "'0038",
          },
          {
            "name": "文化里",
            "code": "'0039",
          }
        ]
      },
      {
        "name": "莿桐鄉",
        "code": "'090",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "莿桐村",
            "code": "'0001",
          },
          {
            "name": "甘厝村",
            "code": "'0002",
          },
          {
            "name": "甘西村",
            "code": "'0003",
          },
          {
            "name": "興桐村",
            "code": "'0004",
          },
          {
            "name": "義和村",
            "code": "'0005",
          },
          {
            "name": "埔子村",
            "code": "'0006",
          },
          {
            "name": "大美村",
            "code": "'0007",
          },
          {
            "name": "埔尾村",
            "code": "'0008",
          },
          {
            "name": "饒平村",
            "code": "'0009",
          },
          {
            "name": "興貴村",
            "code": "'0010",
          },
          {
            "name": "四合村",
            "code": "'0011",
          },
          {
            "name": "麻園村",
            "code": "'0012",
          },
          {
            "name": "五華村",
            "code": "'0013",
          },
          {
            "name": "六合村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "林內鄉",
        "code": "'100",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "林南村",
            "code": "'0001",
          },
          {
            "name": "林中村",
            "code": "'0002",
          },
          {
            "name": "林北村",
            "code": "'0003",
          },
          {
            "name": "坪頂村",
            "code": "'0004",
          },
          {
            "name": "林茂村",
            "code": "'0005",
          },
          {
            "name": "九芎村",
            "code": "'0006",
          },
          {
            "name": "湖本村",
            "code": "'0007",
          },
          {
            "name": "烏塗村",
            "code": "'0008",
          },
          {
            "name": "烏麻村",
            "code": "'0009",
          },
          {
            "name": "重興村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "斗南鎮",
        "code": "'020",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "東仁里",
            "code": "'0001",
          },
          {
            "name": "西岐里",
            "code": "'0002",
          },
          {
            "name": "南昌里",
            "code": "'0003",
          },
          {
            "name": "北銘里",
            "code": "'0004",
          },
          {
            "name": "中天里",
            "code": "'0005",
          },
          {
            "name": "舊社里",
            "code": "'0006",
          },
          {
            "name": "林子里",
            "code": "'0007",
          },
          {
            "name": "石龜里",
            "code": "'0008",
          },
          {
            "name": "靖興里",
            "code": "'0009",
          },
          {
            "name": "新南里",
            "code": "'0010",
          },
          {
            "name": "阿丹里",
            "code": "'0011",
          },
          {
            "name": "將軍里",
            "code": "'0012",
          },
          {
            "name": "東明里",
            "code": "'0013",
          },
          {
            "name": "新光里",
            "code": "'0014",
          },
          {
            "name": "田頭里",
            "code": "'0015",
          },
          {
            "name": "明昌里",
            "code": "'0016",
          },
          {
            "name": "大東里",
            "code": "'0017",
          },
          {
            "name": "埤麻里",
            "code": "'0018",
          },
          {
            "name": "西伯里",
            "code": "'0019",
          },
          {
            "name": "新崙里",
            "code": "'0020",
          },
          {
            "name": "小東里",
            "code": "'0021",
          },
          {
            "name": "石溪里",
            "code": "'0022",
          },
          {
            "name": "大同里",
            "code": "'0023",
          },
          {
            "name": "僑真里",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "古坑鄉",
        "code": "'070",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "水碓村",
            "code": "'0001",
          },
          {
            "name": "田心村",
            "code": "'0002",
          },
          {
            "name": "高林村",
            "code": "'0003",
          },
          {
            "name": "荷苞村",
            "code": "'0004",
          },
          {
            "name": "東和村",
            "code": "'0005",
          },
          {
            "name": "新庄村",
            "code": "'0006",
          },
          {
            "name": "棋盤村",
            "code": "'0007",
          },
          {
            "name": "華山村",
            "code": "'0008",
          },
          {
            "name": "華南村",
            "code": "'0009",
          },
          {
            "name": "永光村",
            "code": "'0010",
          },
          {
            "name": "桂林村",
            "code": "'0011",
          },
          {
            "name": "崁腳村",
            "code": "'0012",
          },
          {
            "name": "樟湖村",
            "code": "'0013",
          },
          {
            "name": "草嶺村",
            "code": "'0014",
          },
          {
            "name": "永昌村",
            "code": "'0015",
          },
          {
            "name": "麻園村",
            "code": "'0016",
          },
          {
            "name": "古坑村",
            "code": "'0017",
          },
          {
            "name": "朝陽村",
            "code": "'0018",
          },
          {
            "name": "西平村",
            "code": "'0019",
          },
          {
            "name": "湳仔村",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "大埤鄉",
        "code": "'080",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "北和村",
            "code": "'0001",
          },
          {
            "name": "南和村",
            "code": "'0002",
          },
          {
            "name": "大德村",
            "code": "'0003",
          },
          {
            "name": "松竹村",
            "code": "'0004",
          },
          {
            "name": "尚義村",
            "code": "'0005",
          },
          {
            "name": "嘉興村",
            "code": "'0006",
          },
          {
            "name": "豐田村",
            "code": "'0007",
          },
          {
            "name": "三結村",
            "code": "'0008",
          },
          {
            "name": "吉田村",
            "code": "'0009",
          },
          {
            "name": "豐岡村",
            "code": "'0010",
          },
          {
            "name": "聯美村",
            "code": "'0011",
          },
          {
            "name": "怡然村",
            "code": "'0012",
          },
          {
            "name": "北鎮村",
            "code": "'0013",
          },
          {
            "name": "興安村",
            "code": "'0014",
          },
          {
            "name": "西鎮村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "虎尾鎮",
        "code": "'030",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "中山里",
            "code": "'0001",
          },
          {
            "name": "新興里",
            "code": "'0002",
          },
          {
            "name": "東仁里",
            "code": "'0003",
          },
          {
            "name": "公安里",
            "code": "'0004",
          },
          {
            "name": "德興里",
            "code": "'0005",
          },
          {
            "name": "西安里",
            "code": "'0006",
          },
          {
            "name": "安慶里",
            "code": "'0007",
          },
          {
            "name": "平和里",
            "code": "'0008",
          },
          {
            "name": "興南里",
            "code": "'0009",
          },
          {
            "name": "新吉里",
            "code": "'0010",
          },
          {
            "name": "穎川里",
            "code": "'0011",
          },
          {
            "name": "延平里",
            "code": "'0012",
          },
          {
            "name": "三合里",
            "code": "'0013",
          },
          {
            "name": "北溪里",
            "code": "'0014",
          },
          {
            "name": "墾地里",
            "code": "'0015",
          },
          {
            "name": "西屯里",
            "code": "'0016",
          },
          {
            "name": "芳草里",
            "code": "'0017",
          },
          {
            "name": "埒內里",
            "code": "'0018",
          },
          {
            "name": "堀頭里",
            "code": "'0019",
          },
          {
            "name": "安溪里",
            "code": "'0020",
          },
          {
            "name": "興中里",
            "code": "'0021",
          },
          {
            "name": "廉使里",
            "code": "'0022",
          },
          {
            "name": "惠來里",
            "code": "'0023",
          },
          {
            "name": "頂溪里",
            "code": "'0024",
          },
          {
            "name": "中溪里",
            "code": "'0025",
          },
          {
            "name": "下溪里",
            "code": "'0026",
          },
          {
            "name": "建國里",
            "code": "'0027",
          },
          {
            "name": "立仁里",
            "code": "'0028",
          },
          {
            "name": "東屯里",
            "code": "'0029",
          }
        ]
      },
      {
        "name": "土庫鎮",
        "code": "'050",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "忠正里",
            "code": "'0001",
          },
          {
            "name": "順天里",
            "code": "'0002",
          },
          {
            "name": "宮北里",
            "code": "'0003",
          },
          {
            "name": "越港里",
            "code": "'0004",
          },
          {
            "name": "溪邊里",
            "code": "'0005",
          },
          {
            "name": "興新里",
            "code": "'0006",
          },
          {
            "name": "奮起里",
            "code": "'0007",
          },
          {
            "name": "埤腳里",
            "code": "'0008",
          },
          {
            "name": "大荖里",
            "code": "'0009",
          },
          {
            "name": "石廟里",
            "code": "'0010",
          },
          {
            "name": "東平里",
            "code": "'0011",
          },
          {
            "name": "南平里",
            "code": "'0012",
          },
          {
            "name": "北平里",
            "code": "'0013",
          },
          {
            "name": "西平里",
            "code": "'0014",
          },
          {
            "name": "後埔里",
            "code": "'0015",
          },
          {
            "name": "崙內里",
            "code": "'0016",
          },
          {
            "name": "新庄里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "褒忠鄉",
        "code": "'150",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "中民村",
            "code": "'0001",
          },
          {
            "name": "埔姜村",
            "code": "'0002",
          },
          {
            "name": "中勝村",
            "code": "'0003",
          },
          {
            "name": "新湖村",
            "code": "'0004",
          },
          {
            "name": "馬鳴村",
            "code": "'0005",
          },
          {
            "name": "有才村",
            "code": "'0006",
          },
          {
            "name": "田洋村",
            "code": "'0007",
          },
          {
            "name": "龍岩村",
            "code": "'0008",
          },
          {
            "name": "潮厝村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "元長鄉",
        "code": "'170",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "長南村",
            "code": "'0001",
          },
          {
            "name": "長北村",
            "code": "'0002",
          },
          {
            "name": "子茂村",
            "code": "'0003",
          },
          {
            "name": "後湖村",
            "code": "'0004",
          },
          {
            "name": "山內村",
            "code": "'0005",
          },
          {
            "name": "合和村",
            "code": "'0006",
          },
          {
            "name": "五塊村",
            "code": "'0007",
          },
          {
            "name": "潭西村",
            "code": "'0008",
          },
          {
            "name": "潭東村",
            "code": "'0009",
          },
          {
            "name": "客厝村",
            "code": "'0010",
          },
          {
            "name": "卓運村",
            "code": "'0011",
          },
          {
            "name": "頂寮村",
            "code": "'0012",
          },
          {
            "name": "下寮村",
            "code": "'0013",
          },
          {
            "name": "龍岩村",
            "code": "'0014",
          },
          {
            "name": "西莊村",
            "code": "'0015",
          },
          {
            "name": "鹿北村",
            "code": "'0016",
          },
          {
            "name": "鹿南村",
            "code": "'0017",
          },
          {
            "name": "瓦磘村",
            "code": "'0018",
          },
          {
            "name": "內寮村",
            "code": "'0019",
          },
          {
            "name": "崙仔村",
            "code": "'0020",
          },
          {
            "name": "新吉村",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "西螺鎮",
        "code": "'040",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "振興里",
            "code": "'0001",
          },
          {
            "name": "大園里",
            "code": "'0002",
          },
          {
            "name": "廣福里",
            "code": "'0003",
          },
          {
            "name": "正興里",
            "code": "'0004",
          },
          {
            "name": "永安里",
            "code": "'0005",
          },
          {
            "name": "中和里",
            "code": "'0006",
          },
          {
            "name": "福興里",
            "code": "'0007",
          },
          {
            "name": "光華里",
            "code": "'0008",
          },
          {
            "name": "中興里",
            "code": "'0009",
          },
          {
            "name": "漢光里",
            "code": "'0010",
          },
          {
            "name": "新安里",
            "code": "'0011",
          },
          {
            "name": "新豐里",
            "code": "'0012",
          },
          {
            "name": "詔安里",
            "code": "'0013",
          },
          {
            "name": "河南里",
            "code": "'0014",
          },
          {
            "name": "安定里",
            "code": "'0015",
          },
          {
            "name": "福田里",
            "code": "'0016",
          },
          {
            "name": "大新里",
            "code": "'0017",
          },
          {
            "name": "埤頭里",
            "code": "'0018",
          },
          {
            "name": "東興里",
            "code": "'0019",
          },
          {
            "name": "廣興里",
            "code": "'0020",
          },
          {
            "name": "鹿場里",
            "code": "'0021",
          },
          {
            "name": "頂湳里",
            "code": "'0022",
          },
          {
            "name": "下湳里",
            "code": "'0023",
          },
          {
            "name": "九隆里",
            "code": "'0024",
          },
          {
            "name": "吳厝里",
            "code": "'0025",
          },
          {
            "name": "七座里",
            "code": "'0026",
          },
          {
            "name": "公舘里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "二崙鄉",
        "code": "'110",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "崙東村",
            "code": "'0001",
          },
          {
            "name": "崙西村",
            "code": "'0002",
          },
          {
            "name": "來惠村",
            "code": "'0003",
          },
          {
            "name": "三和村",
            "code": "'0004",
          },
          {
            "name": "湳仔村",
            "code": "'0005",
          },
          {
            "name": "田尾村",
            "code": "'0006",
          },
          {
            "name": "定安村",
            "code": "'0007",
          },
          {
            "name": "大華村",
            "code": "'0008",
          },
          {
            "name": "義庄村",
            "code": "'0009",
          },
          {
            "name": "楊賢村",
            "code": "'0010",
          },
          {
            "name": "港後村",
            "code": "'0011",
          },
          {
            "name": "大義村",
            "code": "'0012",
          },
          {
            "name": "油車村",
            "code": "'0013",
          },
          {
            "name": "大庄村",
            "code": "'0014",
          },
          {
            "name": "庄西村",
            "code": "'0015",
          },
          {
            "name": "大同村",
            "code": "'0016",
          },
          {
            "name": "復興村",
            "code": "'0017",
          },
          {
            "name": "永定村",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "崙背鄉",
        "code": "'120",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "東明村",
            "code": "'0001",
          },
          {
            "name": "西榮村",
            "code": "'0002",
          },
          {
            "name": "南陽村",
            "code": "'0003",
          },
          {
            "name": "崙前村",
            "code": "'0004",
          },
          {
            "name": "羅厝村",
            "code": "'0005",
          },
          {
            "name": "港尾村",
            "code": "'0006",
          },
          {
            "name": "阿勸村",
            "code": "'0007",
          },
          {
            "name": "五魁村",
            "code": "'0008",
          },
          {
            "name": "大有村",
            "code": "'0009",
          },
          {
            "name": "豐榮村",
            "code": "'0010",
          },
          {
            "name": "草湖村",
            "code": "'0011",
          },
          {
            "name": "舊庄村",
            "code": "'0012",
          },
          {
            "name": "水尾村",
            "code": "'0013",
          },
          {
            "name": "枋南村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "麥寮鄉",
        "code": "'130",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "麥津村",
            "code": "'0001",
          },
          {
            "name": "麥豐村",
            "code": "'0002",
          },
          {
            "name": "瓦磘村",
            "code": "'0003",
          },
          {
            "name": "興華村",
            "code": "'0004",
          },
          {
            "name": "崙後村",
            "code": "'0005",
          },
          {
            "name": "後安村",
            "code": "'0006",
          },
          {
            "name": "海豐村",
            "code": "'0007",
          },
          {
            "name": "三盛村",
            "code": "'0008",
          },
          {
            "name": "橋頭村",
            "code": "'0009",
          },
          {
            "name": "新吉村",
            "code": "'0010",
          },
          {
            "name": "施厝村",
            "code": "'0011",
          },
          {
            "name": "雷厝村",
            "code": "'0012",
          },
          {
            "name": "中興村",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "東勢鄉",
        "code": "'140",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "東南村",
            "code": "'0001",
          },
          {
            "name": "東北村",
            "code": "'0002",
          },
          {
            "name": "嘉隆村",
            "code": "'0003",
          },
          {
            "name": "復興村",
            "code": "'0004",
          },
          {
            "name": "月眉村",
            "code": "'0005",
          },
          {
            "name": "程海村",
            "code": "'0006",
          },
          {
            "name": "龍潭村",
            "code": "'0007",
          },
          {
            "name": "安南村",
            "code": "'0008",
          },
          {
            "name": "昌南村",
            "code": "'0009",
          },
          {
            "name": "同安村",
            "code": "'0010",
          },
          {
            "name": "新坤村",
            "code": "'0011",
          },
          {
            "name": "四美村",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "台西鄉",
        "code": "'160",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "台西村",
            "code": "'0001",
          },
          {
            "name": "海北村",
            "code": "'0002",
          },
          {
            "name": "海南村",
            "code": "'0003",
          },
          {
            "name": "海口村",
            "code": "'0004",
          },
          {
            "name": "溪頂村",
            "code": "'0005",
          },
          {
            "name": "牛厝村",
            "code": "'0006",
          },
          {
            "name": "泉州村",
            "code": "'0007",
          },
          {
            "name": "光華村",
            "code": "'0008",
          },
          {
            "name": "五榔村",
            "code": "'0009",
          },
          {
            "name": "山寮村",
            "code": "'0010",
          },
          {
            "name": "富琦村",
            "code": "'0011",
          },
          {
            "name": "永豐村",
            "code": "'0012",
          },
          {
            "name": "和豐村",
            "code": "'0013",
          },
          {
            "name": "蚊港村",
            "code": "'0014",
          },
          {
            "name": "五港村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "四湖鄉",
        "code": "'180",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "四湖村",
            "code": "'0001",
          },
          {
            "name": "新庄村",
            "code": "'0002",
          },
          {
            "name": "溪底村",
            "code": "'0003",
          },
          {
            "name": "鹿場村",
            "code": "'0004",
          },
          {
            "name": "湖西村",
            "code": "'0005",
          },
          {
            "name": "施湖村",
            "code": "'0006",
          },
          {
            "name": "湖寮村",
            "code": "'0007",
          },
          {
            "name": "蔡厝村",
            "code": "'0008",
          },
          {
            "name": "飛沙村",
            "code": "'0009",
          },
          {
            "name": "三姓村",
            "code": "'0010",
          },
          {
            "name": "內湖村",
            "code": "'0011",
          },
          {
            "name": "羊調村",
            "code": "'0012",
          },
          {
            "name": "崙北村",
            "code": "'0013",
          },
          {
            "name": "崙南村",
            "code": "'0014",
          },
          {
            "name": "廣溝村",
            "code": "'0015",
          },
          {
            "name": "箔子村",
            "code": "'0016",
          },
          {
            "name": "林厝村",
            "code": "'0017",
          },
          {
            "name": "林東村",
            "code": "'0018",
          },
          {
            "name": "溪尾村",
            "code": "'0019",
          },
          {
            "name": "飛東村",
            "code": "'0020",
          },
          {
            "name": "箔東村",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "北港鎮",
        "code": "'060",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "東陽里",
            "code": "'0001",
          },
          {
            "name": "光民里",
            "code": "'0002",
          },
          {
            "name": "東華里",
            "code": "'0003",
          },
          {
            "name": "南安里",
            "code": "'0004",
          },
          {
            "name": "中和里",
            "code": "'0005",
          },
          {
            "name": "義民里",
            "code": "'0006",
          },
          {
            "name": "共榮里",
            "code": "'0007",
          },
          {
            "name": "西勢里",
            "code": "'0008",
          },
          {
            "name": "仁和里",
            "code": "'0009",
          },
          {
            "name": "賜福里",
            "code": "'0010",
          },
          {
            "name": "公舘里",
            "code": "'0011",
          },
          {
            "name": "大同里",
            "code": "'0012",
          },
          {
            "name": "仁安里",
            "code": "'0013",
          },
          {
            "name": "華勝里",
            "code": "'0014",
          },
          {
            "name": "光復里",
            "code": "'0015",
          },
          {
            "name": "新街里",
            "code": "'0016",
          },
          {
            "name": "劉厝里",
            "code": "'0017",
          },
          {
            "name": "府番里",
            "code": "'0018",
          },
          {
            "name": "草湖里",
            "code": "'0019",
          },
          {
            "name": "溝皂里",
            "code": "'0020",
          },
          {
            "name": "後溝里",
            "code": "'0021",
          },
          {
            "name": "番溝里",
            "code": "'0022",
          },
          {
            "name": "大北里",
            "code": "'0023",
          },
          {
            "name": "好收里",
            "code": "'0024",
          },
          {
            "name": "樹腳里",
            "code": "'0025",
          },
          {
            "name": "扶朝里",
            "code": "'0026",
          },
          {
            "name": "水埔里",
            "code": "'0027",
          },
          {
            "name": "新厝里",
            "code": "'0028",
          }
        ]
      },
      {
        "name": "口湖鄉",
        "code": "'190",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "湖東村",
            "code": "'0001",
          },
          {
            "name": "口湖村",
            "code": "'0002",
          },
          {
            "name": "頂湖村",
            "code": "'0003",
          },
          {
            "name": "埔北村",
            "code": "'0004",
          },
          {
            "name": "埔南村",
            "code": "'0005",
          },
          {
            "name": "謝厝村",
            "code": "'0006",
          },
          {
            "name": "蚵寮村",
            "code": "'0007",
          },
          {
            "name": "下崙村",
            "code": "'0008",
          },
          {
            "name": "崙中村",
            "code": "'0009",
          },
          {
            "name": "崙東村",
            "code": "'0010",
          },
          {
            "name": "青蚶村",
            "code": "'0011",
          },
          {
            "name": "港東村",
            "code": "'0012",
          },
          {
            "name": "港西村",
            "code": "'0013",
          },
          {
            "name": "台子村",
            "code": "'0014",
          },
          {
            "name": "成龍村",
            "code": "'0015",
          },
          {
            "name": "湖口村",
            "code": "'0016",
          },
          {
            "name": "水井村",
            "code": "'0017",
          },
          {
            "name": "後厝村",
            "code": "'0018",
          },
          {
            "name": "梧南村",
            "code": "'0019",
          },
          {
            "name": "梧北村",
            "code": "'0020",
          },
          {
            "name": "過港村",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "水林鄉",
        "code": "'200",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "水北村",
            "code": "'0001",
          },
          {
            "name": "水南村",
            "code": "'0002",
          },
          {
            "name": "海埔村",
            "code": "'0003",
          },
          {
            "name": "土厝村",
            "code": "'0004",
          },
          {
            "name": "春埔村",
            "code": "'0005",
          },
          {
            "name": "西井村",
            "code": "'0006",
          },
          {
            "name": "車港村",
            "code": "'0007",
          },
          {
            "name": "蘇秦村",
            "code": "'0008",
          },
          {
            "name": "灣東村",
            "code": "'0009",
          },
          {
            "name": "灣西村",
            "code": "'0010",
          },
          {
            "name": "萬興村",
            "code": "'0011",
          },
          {
            "name": "尖山村",
            "code": "'0012",
          },
          {
            "name": "大山村",
            "code": "'0013",
          },
          {
            "name": "大溝村",
            "code": "'0014",
          },
          {
            "name": "順興村",
            "code": "'0015",
          },
          {
            "name": "蕃薯村",
            "code": "'0016",
          },
          {
            "name": "山腳村",
            "code": "'0017",
          },
          {
            "name": "松北村",
            "code": "'0018",
          },
          {
            "name": "松中村",
            "code": "'0019",
          },
          {
            "name": "松西村",
            "code": "'0020",
          },
          {
            "name": "欍埔村",
            "code": "'0021",
          },
          {
            "name": "塭底村",
            "code": "'0022",
          },
          {
            "name": "後寮村",
            "code": "'0023",
          },
          {
            "name": "溪墘村",
            "code": "'0024",
          }
        ]
      }
    ]
  },
  {
    "name": "嘉義縣",
    "code": "'10'010",
    "subLocation": [
      {
        "name": "太保市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "太保里",
            "code": "'0001",
          },
          {
            "name": "後庄里",
            "code": "'0002",
          },
          {
            "name": "前潭里",
            "code": "'0003",
          },
          {
            "name": "後潭里",
            "code": "'0004",
          },
          {
            "name": "梅埔里",
            "code": "'0005",
          },
          {
            "name": "春珠里",
            "code": "'0006",
          },
          {
            "name": "崙頂里",
            "code": "'0007",
          },
          {
            "name": "東勢里",
            "code": "'0008",
          },
          {
            "name": "新埤里",
            "code": "'0009",
          },
          {
            "name": "舊埤里",
            "code": "'0010",
          },
          {
            "name": "田尾里",
            "code": "'0011",
          },
          {
            "name": "港尾里",
            "code": "'0012",
          },
          {
            "name": "過溝里",
            "code": "'0013",
          },
          {
            "name": "麻寮里",
            "code": "'0014",
          },
          {
            "name": "南新里",
            "code": "'0015",
          },
          {
            "name": "北新里",
            "code": "'0016",
          },
          {
            "name": "埤鄉里",
            "code": "'0017",
          },
          {
            "name": "安仁里",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "鹿草鄉",
        "code": "'110",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "後寮村",
            "code": "'0001",
          },
          {
            "name": "鹿東村",
            "code": "'0002",
          },
          {
            "name": "鹿草村",
            "code": "'0003",
          },
          {
            "name": "西井村",
            "code": "'0004",
          },
          {
            "name": "豊稠村",
            "code": "'0005",
          },
          {
            "name": "重寮村",
            "code": "'0006",
          },
          {
            "name": "施家村",
            "code": "'0007",
          },
          {
            "name": "下潭村",
            "code": "'0008",
          },
          {
            "name": "光潭村",
            "code": "'0009",
          },
          {
            "name": "碧潭村",
            "code": "'0010",
          },
          {
            "name": "松竹村",
            "code": "'0011",
          },
          {
            "name": "竹山村",
            "code": "'0012",
          },
          {
            "name": "後堀村",
            "code": "'0013",
          },
          {
            "name": "三角村",
            "code": "'0014",
          },
          {
            "name": "下麻村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "水上鄉",
        "code": "'120",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "水上村",
            "code": "'0001",
          },
          {
            "name": "水頭村",
            "code": "'0002",
          },
          {
            "name": "大堀村",
            "code": "'0003",
          },
          {
            "name": "大崙村",
            "code": "'0004",
          },
          {
            "name": "塗溝村",
            "code": "'0005",
          },
          {
            "name": "粗溪村",
            "code": "'0006",
          },
          {
            "name": "下寮村",
            "code": "'0007",
          },
          {
            "name": "三和村",
            "code": "'0008",
          },
          {
            "name": "回歸村",
            "code": "'0009",
          },
          {
            "name": "三鎮村",
            "code": "'0010",
          },
          {
            "name": "靖和村",
            "code": "'0011",
          },
          {
            "name": "南和村",
            "code": "'0012",
          },
          {
            "name": "龍德村",
            "code": "'0013",
          },
          {
            "name": "溪洲村",
            "code": "'0014",
          },
          {
            "name": "柳林村",
            "code": "'0015",
          },
          {
            "name": "柳鄉村",
            "code": "'0016",
          },
          {
            "name": "柳新村",
            "code": "'0017",
          },
          {
            "name": "寬士村",
            "code": "'0018",
          },
          {
            "name": "民生村",
            "code": "'0019",
          },
          {
            "name": "忠和村",
            "code": "'0020",
          },
          {
            "name": "中庄村",
            "code": "'0021",
          },
          {
            "name": "南鄉村",
            "code": "'0022",
          },
          {
            "name": "三界村",
            "code": "'0023",
          },
          {
            "name": "國姓村",
            "code": "'0024",
          },
          {
            "name": "內溪村",
            "code": "'0025",
          },
          {
            "name": "義興村",
            "code": "'0026",
          }
        ]
      },
      {
        "name": "民雄鄉",
        "code": "'050",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "東榮村",
            "code": "'0001",
          },
          {
            "name": "中樂村",
            "code": "'0002",
          },
          {
            "name": "西安村",
            "code": "'0003",
          },
          {
            "name": "寮頂村",
            "code": "'0004",
          },
          {
            "name": "福權村",
            "code": "'0005",
          },
          {
            "name": "東湖村",
            "code": "'0006",
          },
          {
            "name": "頂崙村",
            "code": "'0007",
          },
          {
            "name": "菁埔村",
            "code": "'0008",
          },
          {
            "name": "中和村",
            "code": "'0009",
          },
          {
            "name": "平和村",
            "code": "'0010",
          },
          {
            "name": "西昌村",
            "code": "'0011",
          },
          {
            "name": "豊收村",
            "code": "'0012",
          },
          {
            "name": "三興村",
            "code": "'0013",
          },
          {
            "name": "東興村",
            "code": "'0014",
          },
          {
            "name": "鎮北村",
            "code": "'0015",
          },
          {
            "name": "興中村",
            "code": "'0016",
          },
          {
            "name": "興南村",
            "code": "'0017",
          },
          {
            "name": "福興村",
            "code": "'0018",
          },
          {
            "name": "文隆村",
            "code": "'0019",
          },
          {
            "name": "山中村",
            "code": "'0020",
          },
          {
            "name": "中央村",
            "code": "'0021",
          },
          {
            "name": "北斗村",
            "code": "'0022",
          },
          {
            "name": "双福村",
            "code": "'0023",
          },
          {
            "name": "大崎村",
            "code": "'0024",
          },
          {
            "name": "秀林村",
            "code": "'0025",
          },
          {
            "name": "松山村",
            "code": "'0026",
          },
          {
            "name": "金興村",
            "code": "'0027",
          },
          {
            "name": "福樂村",
            "code": "'0028",
          }
        ]
      },
      {
        "name": "新港鄉",
        "code": "'070",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "宮前村",
            "code": "'0001",
          },
          {
            "name": "宮後村",
            "code": "'0002",
          },
          {
            "name": "大興村",
            "code": "'0003",
          },
          {
            "name": "福德村",
            "code": "'0004",
          },
          {
            "name": "古民村",
            "code": "'0005",
          },
          {
            "name": "中庄村",
            "code": "'0006",
          },
          {
            "name": "大潭村",
            "code": "'0007",
          },
          {
            "name": "西庄村",
            "code": "'0008",
          },
          {
            "name": "埤子村",
            "code": "'0009",
          },
          {
            "name": "南崙村",
            "code": "'0010",
          },
          {
            "name": "北崙村",
            "code": "'0011",
          },
          {
            "name": "海瀛村",
            "code": "'0012",
          },
          {
            "name": "月眉村",
            "code": "'0013",
          },
          {
            "name": "月潭村",
            "code": "'0014",
          },
          {
            "name": "溪北村",
            "code": "'0015",
          },
          {
            "name": "三間村",
            "code": "'0016",
          },
          {
            "name": "中洋村",
            "code": "'0017",
          },
          {
            "name": "菜公村",
            "code": "'0018",
          },
          {
            "name": "安和村",
            "code": "'0019",
          },
          {
            "name": "潭大村",
            "code": "'0020",
          },
          {
            "name": "南港村",
            "code": "'0021",
          },
          {
            "name": "板頭村",
            "code": "'0022",
          },
          {
            "name": "共和村",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "大林鎮",
        "code": "'040",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "東林里",
            "code": "'0001",
          },
          {
            "name": "西林里",
            "code": "'0002",
          },
          {
            "name": "吉林里",
            "code": "'0003",
          },
          {
            "name": "平林里",
            "code": "'0004",
          },
          {
            "name": "明華里",
            "code": "'0005",
          },
          {
            "name": "明和里",
            "code": "'0006",
          },
          {
            "name": "排路里",
            "code": "'0007",
          },
          {
            "name": "西結里",
            "code": "'0008",
          },
          {
            "name": "湖北里",
            "code": "'0009",
          },
          {
            "name": "大糖里",
            "code": "'0010",
          },
          {
            "name": "大美里",
            "code": "'0011",
          },
          {
            "name": "過溪里",
            "code": "'0012",
          },
          {
            "name": "中坑里",
            "code": "'0013",
          },
          {
            "name": "上林里",
            "code": "'0014",
          },
          {
            "name": "中林里",
            "code": "'0015",
          },
          {
            "name": "三和里",
            "code": "'0016",
          },
          {
            "name": "溝背里",
            "code": "'0017",
          },
          {
            "name": "義和里",
            "code": "'0018",
          },
          {
            "name": "內林里",
            "code": "'0019",
          },
          {
            "name": "三角里",
            "code": "'0020",
          },
          {
            "name": "三村里",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "溪口鄉",
        "code": "'060",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "溪東村",
            "code": "'0001",
          },
          {
            "name": "溪西村",
            "code": "'0002",
          },
          {
            "name": "溪北村",
            "code": "'0003",
          },
          {
            "name": "游東村",
            "code": "'0004",
          },
          {
            "name": "游西村",
            "code": "'0005",
          },
          {
            "name": "柴林村",
            "code": "'0006",
          },
          {
            "name": "林腳村",
            "code": "'0007",
          },
          {
            "name": "美南村",
            "code": "'0008",
          },
          {
            "name": "美北村",
            "code": "'0009",
          },
          {
            "name": "本厝村",
            "code": "'0010",
          },
          {
            "name": "柳溝村",
            "code": "'0011",
          },
          {
            "name": "疊溪村",
            "code": "'0012",
          },
          {
            "name": "妙崙村",
            "code": "'0013",
          },
          {
            "name": "坪頂村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "梅山鄉",
        "code": "'150",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "梅東村",
            "code": "'0001",
          },
          {
            "name": "梅南村",
            "code": "'0002",
          },
          {
            "name": "過山村",
            "code": "'0003",
          },
          {
            "name": "梅北村",
            "code": "'0004",
          },
          {
            "name": "圳南村",
            "code": "'0005",
          },
          {
            "name": "永興村",
            "code": "'0006",
          },
          {
            "name": "半天村",
            "code": "'0007",
          },
          {
            "name": "圳北村",
            "code": "'0008",
          },
          {
            "name": "大南村",
            "code": "'0009",
          },
          {
            "name": "双溪村",
            "code": "'0010",
          },
          {
            "name": "安靖村",
            "code": "'0011",
          },
          {
            "name": "太平村",
            "code": "'0012",
          },
          {
            "name": "碧湖村",
            "code": "'0013",
          },
          {
            "name": "太興村",
            "code": "'0014",
          },
          {
            "name": "瑞峯村",
            "code": "'0015",
          },
          {
            "name": "瑞里村",
            "code": "'0016",
          },
          {
            "name": "太和村",
            "code": "'0017",
          },
          {
            "name": "龍眼村",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "朴子市",
        "code": "'020",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "安福里",
            "code": "'0001",
          },
          {
            "name": "平和里",
            "code": "'0002",
          },
          {
            "name": "內厝里",
            "code": "'0003",
          },
          {
            "name": "開元里",
            "code": "'0004",
          },
          {
            "name": "順天里",
            "code": "'0005",
          },
          {
            "name": "中正里",
            "code": "'0006",
          },
          {
            "name": "博厚里",
            "code": "'0007",
          },
          {
            "name": "文化里",
            "code": "'0008",
          },
          {
            "name": "永和里",
            "code": "'0009",
          },
          {
            "name": "竹圍里",
            "code": "'0010",
          },
          {
            "name": "新寮里",
            "code": "'0011",
          },
          {
            "name": "双溪里",
            "code": "'0012",
          },
          {
            "name": "溪口里",
            "code": "'0013",
          },
          {
            "name": "德興里",
            "code": "'0014",
          },
          {
            "name": "仁和里",
            "code": "'0015",
          },
          {
            "name": "大鄉里",
            "code": "'0016",
          },
          {
            "name": "大葛里",
            "code": "'0017",
          },
          {
            "name": "佳禾里",
            "code": "'0018",
          },
          {
            "name": "竹村里",
            "code": "'0019",
          },
          {
            "name": "崁前里",
            "code": "'0020",
          },
          {
            "name": "順安里",
            "code": "'0021",
          },
          {
            "name": "崁後里",
            "code": "'0022",
          },
          {
            "name": "德家里",
            "code": "'0023",
          },
          {
            "name": "新庄里",
            "code": "'0024",
          },
          {
            "name": "梅華里",
            "code": "'0025",
          },
          {
            "name": "松華里",
            "code": "'0026",
          },
          {
            "name": "南竹里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "六腳鄉",
        "code": "'080",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "蒜頭村",
            "code": "'0001",
          },
          {
            "name": "蒜東村",
            "code": "'0002",
          },
          {
            "name": "蒜南村",
            "code": "'0003",
          },
          {
            "name": "工廠村",
            "code": "'0004",
          },
          {
            "name": "灣南村",
            "code": "'0005",
          },
          {
            "name": "灣北村",
            "code": "'0006",
          },
          {
            "name": "雙涵村",
            "code": "'0007",
          },
          {
            "name": "塗師村",
            "code": "'0008",
          },
          {
            "name": "三義村",
            "code": "'0009",
          },
          {
            "name": "港美村",
            "code": "'0010",
          },
          {
            "name": "豐美村",
            "code": "'0011",
          },
          {
            "name": "六斗村",
            "code": "'0012",
          },
          {
            "name": "崙陽村",
            "code": "'0013",
          },
          {
            "name": "蘇厝村",
            "code": "'0014",
          },
          {
            "name": "竹本村",
            "code": "'0015",
          },
          {
            "name": "永賢村",
            "code": "'0016",
          },
          {
            "name": "魚寮村",
            "code": "'0017",
          },
          {
            "name": "潭墘村",
            "code": "'0018",
          },
          {
            "name": "正義村",
            "code": "'0019",
          },
          {
            "name": "溪厝村",
            "code": "'0020",
          },
          {
            "name": "六腳村",
            "code": "'0021",
          },
          {
            "name": "六南村",
            "code": "'0022",
          },
          {
            "name": "崩山村",
            "code": "'0023",
          },
          {
            "name": "古林村",
            "code": "'0024",
          },
          {
            "name": "更寮村",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "東石鄉",
        "code": "'090",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "東石村",
            "code": "'0001",
          },
          {
            "name": "猿樹村",
            "code": "'0002",
          },
          {
            "name": "型厝村",
            "code": "'0003",
          },
          {
            "name": "塭港村",
            "code": "'0004",
          },
          {
            "name": "三家村",
            "code": "'0005",
          },
          {
            "name": "永屯村",
            "code": "'0006",
          },
          {
            "name": "海埔村",
            "code": "'0007",
          },
          {
            "name": "龍港村",
            "code": "'0008",
          },
          {
            "name": "副瀨村",
            "code": "'0009",
          },
          {
            "name": "下揖村",
            "code": "'0010",
          },
          {
            "name": "頂揖村",
            "code": "'0011",
          },
          {
            "name": "溪下村",
            "code": "'0012",
          },
          {
            "name": "港墘村",
            "code": "'0013",
          },
          {
            "name": "蔦松村",
            "code": "'0014",
          },
          {
            "name": "圍潭村",
            "code": "'0015",
          },
          {
            "name": "洲仔村",
            "code": "'0016",
          },
          {
            "name": "東崙村",
            "code": "'0017",
          },
          {
            "name": "西崙村",
            "code": "'0018",
          },
          {
            "name": "塭仔村",
            "code": "'0019",
          },
          {
            "name": "網寮村",
            "code": "'0020",
          },
          {
            "name": "掌潭村",
            "code": "'0021",
          },
          {
            "name": "港口村",
            "code": "'0022",
          },
          {
            "name": "鰲鼓村",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "布袋鎮",
        "code": "'030",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "岱江里",
            "code": "'0001",
          },
          {
            "name": "九龍里",
            "code": "'0002",
          },
          {
            "name": "岑海里",
            "code": "'0003",
          },
          {
            "name": "興中里",
            "code": "'0004",
          },
          {
            "name": "光復里",
            "code": "'0005",
          },
          {
            "name": "見龍里",
            "code": "'0006",
          },
          {
            "name": "新厝里",
            "code": "'0007",
          },
          {
            "name": "龍江里",
            "code": "'0008",
          },
          {
            "name": "新岑里",
            "code": "'0009",
          },
          {
            "name": "新民里",
            "code": "'0010",
          },
          {
            "name": "復興里",
            "code": "'0011",
          },
          {
            "name": "好美里",
            "code": "'0012",
          },
          {
            "name": "永安里",
            "code": "'0013",
          },
          {
            "name": "東港里",
            "code": "'0014",
          },
          {
            "name": "江山里",
            "code": "'0015",
          },
          {
            "name": "菜舖里",
            "code": "'0016",
          },
          {
            "name": "考試里",
            "code": "'0017",
          },
          {
            "name": "振寮里",
            "code": "'0018",
          },
          {
            "name": "西安里",
            "code": "'0019",
          },
          {
            "name": "東安里",
            "code": "'0020",
          },
          {
            "name": "中安里",
            "code": "'0021",
          },
          {
            "name": "樹林里",
            "code": "'0022",
          },
          {
            "name": "貴舍里",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "義竹鄉",
        "code": "'100",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "岸腳村",
            "code": "'0001",
          },
          {
            "name": "義竹村",
            "code": "'0002",
          },
          {
            "name": "六桂村",
            "code": "'0003",
          },
          {
            "name": "仁里村",
            "code": "'0004",
          },
          {
            "name": "傳芳村",
            "code": "'0005",
          },
          {
            "name": "頭竹村",
            "code": "'0006",
          },
          {
            "name": "五厝村",
            "code": "'0007",
          },
          {
            "name": "中平村",
            "code": "'0008",
          },
          {
            "name": "埤前村",
            "code": "'0009",
          },
          {
            "name": "平溪村",
            "code": "'0010",
          },
          {
            "name": "後鎮村",
            "code": "'0011",
          },
          {
            "name": "北華村",
            "code": "'0012",
          },
          {
            "name": "新店村",
            "code": "'0013",
          },
          {
            "name": "東過村",
            "code": "'0014",
          },
          {
            "name": "西過村",
            "code": "'0015",
          },
          {
            "name": "官和村",
            "code": "'0016",
          },
          {
            "name": "官順村",
            "code": "'0017",
          },
          {
            "name": "新富村",
            "code": "'0018",
          },
          {
            "name": "東光村",
            "code": "'0019",
          },
          {
            "name": "東榮村",
            "code": "'0020",
          },
          {
            "name": "龍蛟村",
            "code": "'0021",
          },
          {
            "name": "溪洲村",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "中埔鄉",
        "code": "'130",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "中埔村",
            "code": "'0001",
          },
          {
            "name": "塩舘村",
            "code": "'0002",
          },
          {
            "name": "龍門村",
            "code": "'0003",
          },
          {
            "name": "裕民村",
            "code": "'0004",
          },
          {
            "name": "金蘭村",
            "code": "'0005",
          },
          {
            "name": "富收村",
            "code": "'0006",
          },
          {
            "name": "和睦村",
            "code": "'0007",
          },
          {
            "name": "和美村",
            "code": "'0008",
          },
          {
            "name": "和興村",
            "code": "'0009",
          },
          {
            "name": "隆興村",
            "code": "'0010",
          },
          {
            "name": "義仁村",
            "code": "'0011",
          },
          {
            "name": "社口村",
            "code": "'0012",
          },
          {
            "name": "灣潭村",
            "code": "'0013",
          },
          {
            "name": "石硦村",
            "code": "'0014",
          },
          {
            "name": "頂埔村",
            "code": "'0015",
          },
          {
            "name": "同仁村",
            "code": "'0016",
          },
          {
            "name": "瑞豐村",
            "code": "'0017",
          },
          {
            "name": "沄水村",
            "code": "'0018",
          },
          {
            "name": "三層村",
            "code": "'0019",
          },
          {
            "name": "深坑村",
            "code": "'0020",
          },
          {
            "name": "東興村",
            "code": "'0021",
          },
          {
            "name": "中崙村",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "竹崎鄉",
        "code": "'140",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "竹崎村",
            "code": "'0001",
          },
          {
            "name": "和平村",
            "code": "'0002",
          },
          {
            "name": "坑頭村",
            "code": "'0003",
          },
          {
            "name": "沙坑村",
            "code": "'0004",
          },
          {
            "name": "獅埜村",
            "code": "'0005",
          },
          {
            "name": "義和村",
            "code": "'0006",
          },
          {
            "name": "義仁村",
            "code": "'0007",
          },
          {
            "name": "灣橋村",
            "code": "'0008",
          },
          {
            "name": "鹿滿村",
            "code": "'0009",
          },
          {
            "name": "紫雲村",
            "code": "'0010",
          },
          {
            "name": "內埔村",
            "code": "'0011",
          },
          {
            "name": "昇平村",
            "code": "'0012",
          },
          {
            "name": "白杞村",
            "code": "'0013",
          },
          {
            "name": "桃源村",
            "code": "'0014",
          },
          {
            "name": "塘興村",
            "code": "'0015",
          },
          {
            "name": "義隆村",
            "code": "'0016",
          },
          {
            "name": "龍山村",
            "code": "'0017",
          },
          {
            "name": "文峯村",
            "code": "'0018",
          },
          {
            "name": "金獅村",
            "code": "'0019",
          },
          {
            "name": "復金村",
            "code": "'0020",
          },
          {
            "name": "緞繻村",
            "code": "'0021",
          },
          {
            "name": "仁壽村",
            "code": "'0022",
          },
          {
            "name": "中和村",
            "code": "'0023",
          },
          {
            "name": "光華村",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "番路鄉",
        "code": "'160",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "江西村",
            "code": "'0001",
          },
          {
            "name": "內甕村",
            "code": "'0002",
          },
          {
            "name": "新福村",
            "code": "'0003",
          },
          {
            "name": "下坑村",
            "code": "'0004",
          },
          {
            "name": "番路村",
            "code": "'0005",
          },
          {
            "name": "民和村",
            "code": "'0006",
          },
          {
            "name": "觸口村",
            "code": "'0007",
          },
          {
            "name": "大湖村",
            "code": "'0008",
          },
          {
            "name": "公田村",
            "code": "'0009",
          },
          {
            "name": "公興村",
            "code": "'0010",
          },
          {
            "name": "草山村",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "大埔鄉",
        "code": "'170",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "大埔村",
            "code": "'0001",
          },
          {
            "name": "和平村",
            "code": "'0002",
          },
          {
            "name": "永樂村",
            "code": "'0003",
          },
          {
            "name": "茄苳村",
            "code": "'0004",
          },
          {
            "name": "西興村",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "阿里山鄉",
        "code": "'180",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "中山村",
            "code": "'0001",
          },
          {
            "name": "中正村",
            "code": "'0002",
          },
          {
            "name": "香林村",
            "code": "'0003",
          },
          {
            "name": "十字村",
            "code": "'0004",
          },
          {
            "name": "來吉村",
            "code": "'0005",
          },
          {
            "name": "豐山村",
            "code": "'0006",
          },
          {
            "name": "達邦村",
            "code": "'0007",
          },
          {
            "name": "樂野村",
            "code": "'0008",
          },
          {
            "name": "里佳村",
            "code": "'0009",
          },
          {
            "name": "山美村",
            "code": "'0010",
          },
          {
            "name": "新美村",
            "code": "'0011",
          },
          {
            "name": "茶山村",
            "code": "'0012",
          }
        ]
      }
    ]
  },
  {
    "name": "屏東縣",
    "code": "'10'013",
    "subLocation": [
      {
        "name": "屏東市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "光榮里",
            "code": "'0001",
          },
          {
            "name": "文明里",
            "code": "'0002",
          },
          {
            "name": "武廟里",
            "code": "'0003",
          },
          {
            "name": "大同里",
            "code": "'0004",
          },
          {
            "name": "泰安里",
            "code": "'0005",
          },
          {
            "name": "端正里",
            "code": "'0006",
          },
          {
            "name": "民權里",
            "code": "'0007",
          },
          {
            "name": "大埔里",
            "code": "'0008",
          },
          {
            "name": "必信里",
            "code": "'0009",
          },
          {
            "name": "崇智里",
            "code": "'0010",
          },
          {
            "name": "崇禮里",
            "code": "'0011",
          },
          {
            "name": "維新里",
            "code": "'0012",
          },
          {
            "name": "安樂里",
            "code": "'0013",
          },
          {
            "name": "平和里",
            "code": "'0014",
          },
          {
            "name": "楠樹里",
            "code": "'0015",
          },
          {
            "name": "扶風里",
            "code": "'0016",
          },
          {
            "name": "慶春里",
            "code": "'0017",
          },
          {
            "name": "金泉里",
            "code": "'0018",
          },
          {
            "name": "勝豐里",
            "code": "'0019",
          },
          {
            "name": "橋南里",
            "code": "'0020",
          },
          {
            "name": "橋北里",
            "code": "'0021",
          },
          {
            "name": "長春里",
            "code": "'0022",
          },
          {
            "name": "豐榮里",
            "code": "'0023",
          },
          {
            "name": "豐源里",
            "code": "'0024",
          },
          {
            "name": "豐田里",
            "code": "'0025",
          },
          {
            "name": "大連里",
            "code": "'0026",
          },
          {
            "name": "湖西里",
            "code": "'0027",
          },
          {
            "name": "湖南里",
            "code": "'0028",
          },
          {
            "name": "歸心里",
            "code": "'0029",
          },
          {
            "name": "瑞光里",
            "code": "'0030",
          },
          {
            "name": "新生里",
            "code": "'0031",
          },
          {
            "name": "頂宅里",
            "code": "'0032",
          },
          {
            "name": "厚生里",
            "code": "'0033",
          },
          {
            "name": "擇仁里",
            "code": "'0034",
          },
          {
            "name": "建國里",
            "code": "'0035",
          },
          {
            "name": "一心里",
            "code": "'0036",
          },
          {
            "name": "萬年里",
            "code": "'0037",
          },
          {
            "name": "義勇里",
            "code": "'0038",
          },
          {
            "name": "光華里",
            "code": "'0039",
          },
          {
            "name": "永安里",
            "code": "'0040",
          },
          {
            "name": "新興里",
            "code": "'0041",
          },
          {
            "name": "公館里",
            "code": "'0042",
          },
          {
            "name": "龍華里",
            "code": "'0043",
          },
          {
            "name": "玉成里",
            "code": "'0044",
          },
          {
            "name": "頂柳里",
            "code": "'0045",
          },
          {
            "name": "大湖里",
            "code": "'0046",
          },
          {
            "name": "凌雲里",
            "code": "'0047",
          },
          {
            "name": "鵬程里",
            "code": "'0048",
          },
          {
            "name": "安鎮里",
            "code": "'0049",
          },
          {
            "name": "永光里",
            "code": "'0050",
          },
          {
            "name": "大洲里",
            "code": "'0051",
          },
          {
            "name": "大武里",
            "code": "'0053",
          },
          {
            "name": "前進里",
            "code": "'0054",
          },
          {
            "name": "清溪里",
            "code": "'0055",
          },
          {
            "name": "長安里",
            "code": "'0056",
          },
          {
            "name": "永順里",
            "code": "'0057",
          },
          {
            "name": "永昌里",
            "code": "'0058",
          },
          {
            "name": "斯文里",
            "code": "'0059",
          },
          {
            "name": "華山里",
            "code": "'0060",
          },
          {
            "name": "興樂里",
            "code": "'0061",
          },
          {
            "name": "明正里",
            "code": "'0062",
          },
          {
            "name": "太平里",
            "code": "'0063",
          },
          {
            "name": "中正里",
            "code": "'0064",
          },
          {
            "name": "勝利里",
            "code": "'0065",
          },
          {
            "name": "永城里",
            "code": "'0066",
          },
          {
            "name": "溝美里",
            "code": "'0067",
          },
          {
            "name": "北勢里",
            "code": "'0068",
          },
          {
            "name": "北興里",
            "code": "'0069",
          },
          {
            "name": "仁愛里",
            "code": "'0070",
          },
          {
            "name": "廣興里",
            "code": "'0071",
          },
          {
            "name": "崇蘭里",
            "code": "'0073",
          },
          {
            "name": "空翔里",
            "code": "'0074",
          },
          {
            "name": "潭墘里",
            "code": "'0075",
          },
          {
            "name": "三山里",
            "code": "'0076",
          },
          {
            "name": "海豐里",
            "code": "'0077",
          },
          {
            "name": "仁義里",
            "code": "'0078",
          },
          {
            "name": "和興里",
            "code": "'0079",
          },
          {
            "name": "信和里",
            "code": "'0080",
          },
          {
            "name": "崇武里",
            "code": "'0081",
          }
        ]
      },
      {
        "name": "長治鄉",
        "code": "'060",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "進興村",
            "code": "'0001",
          },
          {
            "name": "新潭村",
            "code": "'0002",
          },
          {
            "name": "潭頭村",
            "code": "'0003",
          },
          {
            "name": "香楊村",
            "code": "'0004",
          },
          {
            "name": "長興村",
            "code": "'0005",
          },
          {
            "name": "德成村",
            "code": "'0006",
          },
          {
            "name": "德協村",
            "code": "'0007",
          },
          {
            "name": "復興村",
            "code": "'0008",
          },
          {
            "name": "崙上村",
            "code": "'0009",
          },
          {
            "name": "德榮村",
            "code": "'0010",
          },
          {
            "name": "德和村",
            "code": "'0011",
          },
          {
            "name": "繁華村",
            "code": "'0012",
          },
          {
            "name": "繁昌村",
            "code": "'0013",
          },
          {
            "name": "繁榮村",
            "code": "'0014",
          },
          {
            "name": "繁隆村",
            "code": "'0015",
          },
          {
            "name": "榮華村",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "麟洛鄉",
        "code": "'070",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "新田村",
            "code": "'0001",
          },
          {
            "name": "麟頂村",
            "code": "'0002",
          },
          {
            "name": "麟蹄村",
            "code": "'0003",
          },
          {
            "name": "麟趾村",
            "code": "'0004",
          },
          {
            "name": "田中村",
            "code": "'0005",
          },
          {
            "name": "田心村",
            "code": "'0006",
          },
          {
            "name": "田道村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "九如鄉",
        "code": "'080",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "九清村",
            "code": "'0001",
          },
          {
            "name": "九塊村",
            "code": "'0002",
          },
          {
            "name": "九明村",
            "code": "'0003",
          },
          {
            "name": "玉水村",
            "code": "'0004",
          },
          {
            "name": "大坵村",
            "code": "'0005",
          },
          {
            "name": "三塊村",
            "code": "'0006",
          },
          {
            "name": "後庄村",
            "code": "'0007",
          },
          {
            "name": "耆老村",
            "code": "'0008",
          },
          {
            "name": "洽興村",
            "code": "'0009",
          },
          {
            "name": "東寧村",
            "code": "'0010",
          },
          {
            "name": "玉泉村",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "里港鄉",
        "code": "'090",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "玉田村",
            "code": "'0001",
          },
          {
            "name": "大平村",
            "code": "'0002",
          },
          {
            "name": "永春村",
            "code": "'0003",
          },
          {
            "name": "春林村",
            "code": "'0004",
          },
          {
            "name": "過江村",
            "code": "'0005",
          },
          {
            "name": "鐵店村",
            "code": "'0006",
          },
          {
            "name": "塔樓村",
            "code": "'0007",
          },
          {
            "name": "潮厝村",
            "code": "'0008",
          },
          {
            "name": "茄苳村",
            "code": "'0009",
          },
          {
            "name": "載興村",
            "code": "'0010",
          },
          {
            "name": "土庫村",
            "code": "'0011",
          },
          {
            "name": "三廍村",
            "code": "'0012",
          },
          {
            "name": "中和村",
            "code": "'0013",
          },
          {
            "name": "瀰力村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "鹽埔鄉",
        "code": "'100",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "新圍村",
            "code": "'0001",
          },
          {
            "name": "高朗村",
            "code": "'0002",
          },
          {
            "name": "鹽北村",
            "code": "'0003",
          },
          {
            "name": "鹽中村",
            "code": "'0004",
          },
          {
            "name": "鹽南村",
            "code": "'0005",
          },
          {
            "name": "仕絨村",
            "code": "'0006",
          },
          {
            "name": "振興村",
            "code": "'0007",
          },
          {
            "name": "永隆村",
            "code": "'0008",
          },
          {
            "name": "久愛村",
            "code": "'0009",
          },
          {
            "name": "洛陽村",
            "code": "'0010",
          },
          {
            "name": "新二村",
            "code": "'0011",
          },
          {
            "name": "彭厝村",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "高樹鄉",
        "code": "'110",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "高樹村",
            "code": "'0001",
          },
          {
            "name": "長榮村",
            "code": "'0002",
          },
          {
            "name": "東興村",
            "code": "'0003",
          },
          {
            "name": "東振村",
            "code": "'0004",
          },
          {
            "name": "源泉村",
            "code": "'0005",
          },
          {
            "name": "建興村",
            "code": "'0006",
          },
          {
            "name": "大埔村",
            "code": "'0007",
          },
          {
            "name": "菜寮村",
            "code": "'0008",
          },
          {
            "name": "司馬村",
            "code": "'0009",
          },
          {
            "name": "舊寮村",
            "code": "'0010",
          },
          {
            "name": "新豐村",
            "code": "'0011",
          },
          {
            "name": "泰山村",
            "code": "'0012",
          },
          {
            "name": "南華村",
            "code": "'0013",
          },
          {
            "name": "廣福村",
            "code": "'0014",
          },
          {
            "name": "廣興村",
            "code": "'0015",
          },
          {
            "name": "舊庄村",
            "code": "'0016",
          },
          {
            "name": "田子村",
            "code": "'0017",
          },
          {
            "name": "鹽樹村",
            "code": "'0018",
          },
          {
            "name": "新南村",
            "code": "'0019",
          }
        ]
      },
      {
        "name": "三地門鄉",
        "code": "'260",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "三地村",
            "code": "'0001",
          },
          {
            "name": "達來村",
            "code": "'0002",
          },
          {
            "name": "德文村",
            "code": "'0003",
          },
          {
            "name": "大社村",
            "code": "'0004",
          },
          {
            "name": "賽嘉村",
            "code": "'0005",
          },
          {
            "name": "口社村",
            "code": "'0006",
          },
          {
            "name": "馬兒村",
            "code": "'0007",
          },
          {
            "name": "安坡村",
            "code": "'0008",
          },
          {
            "name": "青山村",
            "code": "'0009",
          },
          {
            "name": "青葉村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "霧台鄉",
        "code": "'270",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "霧台村",
            "code": "'0001",
          },
          {
            "name": "阿禮村",
            "code": "'0002",
          },
          {
            "name": "吉露村",
            "code": "'0003",
          },
          {
            "name": "大武村",
            "code": "'0004",
          },
          {
            "name": "佳暮村",
            "code": "'0005",
          },
          {
            "name": "好茶村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "瑪家鄉",
        "code": "'280",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "瑪家村",
            "code": "'0001",
          },
          {
            "name": "北葉村",
            "code": "'0002",
          },
          {
            "name": "凉山村",
            "code": "'0003",
          },
          {
            "name": "佳義村",
            "code": "'0004",
          },
          {
            "name": "排灣村",
            "code": "'0005",
          },
          {
            "name": "三和村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "潮州鎮",
        "code": "'020",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "潮州里",
            "code": "'0001",
          },
          {
            "name": "同榮里",
            "code": "'0002",
          },
          {
            "name": "新榮里",
            "code": "'0003",
          },
          {
            "name": "新生里",
            "code": "'0004",
          },
          {
            "name": "光華里",
            "code": "'0005",
          },
          {
            "name": "彭城里",
            "code": "'0006",
          },
          {
            "name": "三星里",
            "code": "'0007",
          },
          {
            "name": "三共里",
            "code": "'0008",
          },
          {
            "name": "三和里",
            "code": "'0009",
          },
          {
            "name": "五魁里",
            "code": "'0010",
          },
          {
            "name": "蓬萊里",
            "code": "'0011",
          },
          {
            "name": "八爺里",
            "code": "'0012",
          },
          {
            "name": "永春里",
            "code": "'0013",
          },
          {
            "name": "富春里",
            "code": "'0014",
          },
          {
            "name": "光春里",
            "code": "'0015",
          },
          {
            "name": "興美里",
            "code": "'0016",
          },
          {
            "name": "檨子里",
            "code": "'0017",
          },
          {
            "name": "九塊里",
            "code": "'0018",
          },
          {
            "name": "泗林里",
            "code": "'0019",
          },
          {
            "name": "四春里",
            "code": "'0020",
          },
          {
            "name": "崙東里",
            "code": "'0021",
          }
        ]
      },
      {
        "name": "萬巒鄉",
        "code": "'120",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "萬巒村",
            "code": "'0001",
          },
          {
            "name": "萬全村",
            "code": "'0002",
          },
          {
            "name": "萬和村",
            "code": "'0003",
          },
          {
            "name": "鹿寮村",
            "code": "'0004",
          },
          {
            "name": "硫黃村",
            "code": "'0005",
          },
          {
            "name": "泗溝村",
            "code": "'0006",
          },
          {
            "name": "五溝村",
            "code": "'0007",
          },
          {
            "name": "成德村",
            "code": "'0008",
          },
          {
            "name": "萬金村",
            "code": "'0009",
          },
          {
            "name": "赤山村",
            "code": "'0010",
          },
          {
            "name": "佳佐村",
            "code": "'0011",
          },
          {
            "name": "佳和村",
            "code": "'0012",
          },
          {
            "name": "新厝村",
            "code": "'0013",
          },
          {
            "name": "新置村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "內埔鄉",
        "code": "'130",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "內埔村",
            "code": "'0001",
          },
          {
            "name": "內田村",
            "code": "'0002",
          },
          {
            "name": "興南村",
            "code": "'0003",
          },
          {
            "name": "義亭村",
            "code": "'0004",
          },
          {
            "name": "美和村",
            "code": "'0005",
          },
          {
            "name": "和興村",
            "code": "'0006",
          },
          {
            "name": "東寧村",
            "code": "'0007",
          },
          {
            "name": "豐田村",
            "code": "'0008",
          },
          {
            "name": "振豐村",
            "code": "'0009",
          },
          {
            "name": "富田村",
            "code": "'0010",
          },
          {
            "name": "竹圍村",
            "code": "'0011",
          },
          {
            "name": "東勢村",
            "code": "'0012",
          },
          {
            "name": "東片村",
            "code": "'0013",
          },
          {
            "name": "上樹村",
            "code": "'0014",
          },
          {
            "name": "老埤村",
            "code": "'0015",
          },
          {
            "name": "中林村",
            "code": "'0016",
          },
          {
            "name": "龍泉村",
            "code": "'0017",
          },
          {
            "name": "龍潭村",
            "code": "'0018",
          },
          {
            "name": "建興村",
            "code": "'0019",
          },
          {
            "name": "大新村",
            "code": "'0020",
          },
          {
            "name": "黎明村",
            "code": "'0021",
          },
          {
            "name": "隘寮村",
            "code": "'0022",
          },
          {
            "name": "水門村",
            "code": "'0023",
          }
        ]
      },
      {
        "name": "竹田鄉",
        "code": "'140",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "竹田村",
            "code": "'0001",
          },
          {
            "name": "竹南村",
            "code": "'0002",
          },
          {
            "name": "糶糴村",
            "code": "'0003",
          },
          {
            "name": "履豐村",
            "code": "'0004",
          },
          {
            "name": "頭崙村",
            "code": "'0005",
          },
          {
            "name": "二崙村",
            "code": "'0006",
          },
          {
            "name": "美崙村",
            "code": "'0007",
          },
          {
            "name": "南勢村",
            "code": "'0008",
          },
          {
            "name": "西勢村",
            "code": "'0009",
          },
          {
            "name": "福田村",
            "code": "'0010",
          },
          {
            "name": "永豐村",
            "code": "'0011",
          },
          {
            "name": "六巷村",
            "code": "'0012",
          },
          {
            "name": "泗洲村",
            "code": "'0013",
          },
          {
            "name": "大湖村",
            "code": "'0014",
          },
          {
            "name": "鳳明村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "新埤鄉",
        "code": "'150",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "新埤村",
            "code": "'0001",
          },
          {
            "name": "建功村",
            "code": "'0002",
          },
          {
            "name": "打鐵村",
            "code": "'0003",
          },
          {
            "name": "南豐村",
            "code": "'0004",
          },
          {
            "name": "萬隆村",
            "code": "'0005",
          },
          {
            "name": "箕湖村",
            "code": "'0006",
          },
          {
            "name": "餉潭村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "枋寮鄉",
        "code": "'160",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "枋寮村",
            "code": "'0001",
          },
          {
            "name": "新龍村",
            "code": "'0002",
          },
          {
            "name": "保生村",
            "code": "'0003",
          },
          {
            "name": "中寮村",
            "code": "'0004",
          },
          {
            "name": "安樂村",
            "code": "'0005",
          },
          {
            "name": "隆山村",
            "code": "'0006",
          },
          {
            "name": "天時村",
            "code": "'0007",
          },
          {
            "name": "地利村",
            "code": "'0008",
          },
          {
            "name": "人和村",
            "code": "'0009",
          },
          {
            "name": "內寮村",
            "code": "'0010",
          },
          {
            "name": "新開村",
            "code": "'0011",
          },
          {
            "name": "太源村",
            "code": "'0012",
          },
          {
            "name": "玉泉村",
            "code": "'0013",
          },
          {
            "name": "東海村",
            "code": "'0014",
          },
          {
            "name": "大庄村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "泰武鄉",
        "code": "'290",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "佳平村",
            "code": "'0001",
          },
          {
            "name": "武潭村",
            "code": "'0002",
          },
          {
            "name": "平和村",
            "code": "'0003",
          },
          {
            "name": "佳興村",
            "code": "'0004",
          },
          {
            "name": "泰武村",
            "code": "'0005",
          },
          {
            "name": "萬安村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "來義鄉",
        "code": "'300",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "來義村",
            "code": "'0001",
          },
          {
            "name": "義林村",
            "code": "'0002",
          },
          {
            "name": "丹林村",
            "code": "'0003",
          },
          {
            "name": "古樓村",
            "code": "'0004",
          },
          {
            "name": "文樂村",
            "code": "'0005",
          },
          {
            "name": "望嘉村",
            "code": "'0006",
          },
          {
            "name": "南和村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "春日鄉",
        "code": "'310",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "春日村",
            "code": "'0001",
          },
          {
            "name": "古華村",
            "code": "'0002",
          },
          {
            "name": "士文村",
            "code": "'0003",
          },
          {
            "name": "七佳村",
            "code": "'0004",
          },
          {
            "name": "歸崇村",
            "code": "'0005",
          },
          {
            "name": "力里村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "東港鎮",
        "code": "'030",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "新勝里",
            "code": "'0001",
          },
          {
            "name": "頂新里",
            "code": "'0002",
          },
          {
            "name": "頂中里",
            "code": "'0003",
          },
          {
            "name": "東和里",
            "code": "'0004",
          },
          {
            "name": "興台里",
            "code": "'0005",
          },
          {
            "name": "中興里",
            "code": "'0006",
          },
          {
            "name": "朝安里",
            "code": "'0007",
          },
          {
            "name": "八德里",
            "code": "'0008",
          },
          {
            "name": "東隆里",
            "code": "'0009",
          },
          {
            "name": "豐漁里",
            "code": "'0010",
          },
          {
            "name": "盛漁里",
            "code": "'0011",
          },
          {
            "name": "興漁里",
            "code": "'0012",
          },
          {
            "name": "鎮海里",
            "code": "'0013",
          },
          {
            "name": "嘉蓮里",
            "code": "'0014",
          },
          {
            "name": "南平里",
            "code": "'0015",
          },
          {
            "name": "船頭里",
            "code": "'0016",
          },
          {
            "name": "下廍里",
            "code": "'0017",
          },
          {
            "name": "大潭里",
            "code": "'0018",
          },
          {
            "name": "興東里",
            "code": "'0019",
          },
          {
            "name": "興農里",
            "code": "'0020",
          },
          {
            "name": "興和里",
            "code": "'0021",
          },
          {
            "name": "共和里",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "萬丹鄉",
        "code": "'050",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "萬安村",
            "code": "'0001",
          },
          {
            "name": "萬惠村",
            "code": "'0002",
          },
          {
            "name": "萬全村",
            "code": "'0003",
          },
          {
            "name": "萬後村",
            "code": "'0004",
          },
          {
            "name": "萬生村",
            "code": "'0005",
          },
          {
            "name": "寶厝村",
            "code": "'0006",
          },
          {
            "name": "四維村",
            "code": "'0007",
          },
          {
            "name": "竹林村",
            "code": "'0008",
          },
          {
            "name": "廣安村",
            "code": "'0009",
          },
          {
            "name": "田厝村",
            "code": "'0010",
          },
          {
            "name": "崙頂村",
            "code": "'0011",
          },
          {
            "name": "厦北村",
            "code": "'0012",
          },
          {
            "name": "厦南村",
            "code": "'0013",
          },
          {
            "name": "上村村",
            "code": "'0014",
          },
          {
            "name": "加興村",
            "code": "'0015",
          },
          {
            "name": "社口村",
            "code": "'0016",
          },
          {
            "name": "磚寮村",
            "code": "'0017",
          },
          {
            "name": "社皮村",
            "code": "'0018",
          },
          {
            "name": "社上村",
            "code": "'0019",
          },
          {
            "name": "社中村",
            "code": "'0020",
          },
          {
            "name": "水泉村",
            "code": "'0021",
          },
          {
            "name": "新庄村",
            "code": "'0022",
          },
          {
            "name": "香社村",
            "code": "'0023",
          },
          {
            "name": "後村村",
            "code": "'0024",
          },
          {
            "name": "新鐘村",
            "code": "'0025",
          },
          {
            "name": "灣內村",
            "code": "'0026",
          },
          {
            "name": "興全村",
            "code": "'0027",
          },
          {
            "name": "興安村",
            "code": "'0028",
          },
          {
            "name": "水仙村",
            "code": "'0029",
          },
          {
            "name": "甘棠村",
            "code": "'0030",
          }
        ]
      },
      {
        "name": "新園鄉",
        "code": "'170",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "瓦磘村",
            "code": "'0001",
          },
          {
            "name": "仙吉村",
            "code": "'0002",
          },
          {
            "name": "田洋村",
            "code": "'0003",
          },
          {
            "name": "港墘村",
            "code": "'0004",
          },
          {
            "name": "內庄村",
            "code": "'0005",
          },
          {
            "name": "新東村",
            "code": "'0006",
          },
          {
            "name": "新園村",
            "code": "'0007",
          },
          {
            "name": "烏龍村",
            "code": "'0008",
          },
          {
            "name": "興龍村",
            "code": "'0009",
          },
          {
            "name": "中洲村",
            "code": "'0010",
          },
          {
            "name": "南龍村",
            "code": "'0011",
          },
          {
            "name": "港西村",
            "code": "'0012",
          },
          {
            "name": "五房村",
            "code": "'0013",
          },
          {
            "name": "鹽埔村",
            "code": "'0014",
          },
          {
            "name": "共和村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "崁頂鄉",
        "code": "'180",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "崁頂村",
            "code": "'0001",
          },
          {
            "name": "園寮村",
            "code": "'0002",
          },
          {
            "name": "圍內村",
            "code": "'0003",
          },
          {
            "name": "力社村",
            "code": "'0004",
          },
          {
            "name": "洲子村",
            "code": "'0005",
          },
          {
            "name": "北勢村",
            "code": "'0006",
          },
          {
            "name": "越溪村",
            "code": "'0007",
          },
          {
            "name": "港東村",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "林邊鄉",
        "code": "'190",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "林邊村",
            "code": "'0001",
          },
          {
            "name": "光林村",
            "code": "'0002",
          },
          {
            "name": "仁和村",
            "code": "'0003",
          },
          {
            "name": "中林村",
            "code": "'0004",
          },
          {
            "name": "永樂村",
            "code": "'0005",
          },
          {
            "name": "水利村",
            "code": "'0006",
          },
          {
            "name": "崎峯村",
            "code": "'0007",
          },
          {
            "name": "田厝村",
            "code": "'0008",
          },
          {
            "name": "竹林村",
            "code": "'0009",
          },
          {
            "name": "鎮安村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "南州鄉",
        "code": "'200",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "溪洲村",
            "code": "'0001",
          },
          {
            "name": "溪南村",
            "code": "'0002",
          },
          {
            "name": "仁里村",
            "code": "'0003",
          },
          {
            "name": "溪北村",
            "code": "'0004",
          },
          {
            "name": "七塊村",
            "code": "'0005",
          },
          {
            "name": "萬華村",
            "code": "'0006",
          },
          {
            "name": "米崙村",
            "code": "'0007",
          },
          {
            "name": "壽元村",
            "code": "'0008",
          },
          {
            "name": "同安村",
            "code": "'0009",
          },
          {
            "name": "南安村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "佳冬鄉",
        "code": "'210",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "佳冬村",
            "code": "'0001",
          },
          {
            "name": "六根村",
            "code": "'0002",
          },
          {
            "name": "賴家村",
            "code": "'0003",
          },
          {
            "name": "萬建村",
            "code": "'0004",
          },
          {
            "name": "石光村",
            "code": "'0005",
          },
          {
            "name": "玉光村",
            "code": "'0006",
          },
          {
            "name": "昌隆村",
            "code": "'0007",
          },
          {
            "name": "豐隆村",
            "code": "'0008",
          },
          {
            "name": "大同村",
            "code": "'0009",
          },
          {
            "name": "羌園村",
            "code": "'0010",
          },
          {
            "name": "燄溫村",
            "code": "'0011",
          },
          {
            "name": "塭豐村",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "恆春鎮",
        "code": "'040",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "城南里",
            "code": "'0001",
          },
          {
            "name": "城北里",
            "code": "'0002",
          },
          {
            "name": "城西里",
            "code": "'0003",
          },
          {
            "name": "山腳里",
            "code": "'0004",
          },
          {
            "name": "網紗里",
            "code": "'0005",
          },
          {
            "name": "仁壽里",
            "code": "'0006",
          },
          {
            "name": "茄湖里",
            "code": "'0007",
          },
          {
            "name": "頭溝里",
            "code": "'0008",
          },
          {
            "name": "四溝里",
            "code": "'0009",
          },
          {
            "name": "德和里",
            "code": "'0010",
          },
          {
            "name": "龍水里",
            "code": "'0011",
          },
          {
            "name": "山海里",
            "code": "'0012",
          },
          {
            "name": "大光里",
            "code": "'0013",
          },
          {
            "name": "水泉里",
            "code": "'0014",
          },
          {
            "name": "南灣里",
            "code": "'0015",
          },
          {
            "name": "墾丁里",
            "code": "'0016",
          },
          {
            "name": "鵝鑾里",
            "code": "'0017",
          }
        ]
      },
      {
        "name": "車城鄉",
        "code": "'230",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "福興村",
            "code": "'0001",
          },
          {
            "name": "福安村",
            "code": "'0002",
          },
          {
            "name": "田中村",
            "code": "'0003",
          },
          {
            "name": "海口村",
            "code": "'0004",
          },
          {
            "name": "統埔村",
            "code": "'0005",
          },
          {
            "name": "溫泉村",
            "code": "'0006",
          },
          {
            "name": "保力村",
            "code": "'0007",
          },
          {
            "name": "新街村",
            "code": "'0008",
          },
          {
            "name": "埔墘村",
            "code": "'0009",
          },
          {
            "name": "射寮村",
            "code": "'0010",
          },
          {
            "name": "後灣村",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "滿州鄉",
        "code": "'240",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "滿州村",
            "code": "'0001",
          },
          {
            "name": "里德村",
            "code": "'0002",
          },
          {
            "name": "永靖村",
            "code": "'0003",
          },
          {
            "name": "港口村",
            "code": "'0004",
          },
          {
            "name": "响林村",
            "code": "'0005",
          },
          {
            "name": "長樂村",
            "code": "'0006",
          },
          {
            "name": "九棚村",
            "code": "'0007",
          },
          {
            "name": "港仔村",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "枋山鄉",
        "code": "'250",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "枋山村",
            "code": "'0001",
          },
          {
            "name": "加祿村",
            "code": "'0002",
          },
          {
            "name": "楓港村",
            "code": "'0003",
          },
          {
            "name": "善餘村",
            "code": "'0004",
          }
        ]
      },
      {
        "name": "獅子鄉",
        "code": "'320",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "楓林村",
            "code": "'0001",
          },
          {
            "name": "丹路村",
            "code": "'0002",
          },
          {
            "name": "草埔村",
            "code": "'0003",
          },
          {
            "name": "內文村",
            "code": "'0004",
          },
          {
            "name": "竹坑村",
            "code": "'0005",
          },
          {
            "name": "獅子村",
            "code": "'0006",
          },
          {
            "name": "內獅村",
            "code": "'0007",
          },
          {
            "name": "南世村",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "牡丹鄉",
        "code": "'330",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "石門村",
            "code": "'0001",
          },
          {
            "name": "牡丹村",
            "code": "'0002",
          },
          {
            "name": "東源村",
            "code": "'0003",
          },
          {
            "name": "旭海村",
            "code": "'0004",
          },
          {
            "name": "高士村",
            "code": "'0005",
          },
          {
            "name": "四林村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "琉球鄉",
        "code": "'220",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "本福村",
            "code": "'0001",
          },
          {
            "name": "中福村",
            "code": "'0002",
          },
          {
            "name": "漁福村",
            "code": "'0003",
          },
          {
            "name": "大福村",
            "code": "'0004",
          },
          {
            "name": "南福村",
            "code": "'0005",
          },
          {
            "name": "天福村",
            "code": "'0006",
          },
          {
            "name": "上福村",
            "code": "'0007",
          },
          {
            "name": "杉福村",
            "code": "'0008",
          }
        ]
      }
    ]
  },
  {
    "name": "宜蘭縣",
    "code": "'10'002",
    "subLocation": [
      {
        "name": "宜蘭市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "神農里",
            "code": "'0001",
          },
          {
            "name": "民權里",
            "code": "'0002",
          },
          {
            "name": "進士里",
            "code": "'0008",
          },
          {
            "name": "南橋里",
            "code": "'0009",
          },
          {
            "name": "建業里",
            "code": "'0010",
          },
          {
            "name": "南津里",
            "code": "'0011",
          },
          {
            "name": "凱旋里",
            "code": "'0012",
          },
          {
            "name": "黎明里",
            "code": "'0013",
          },
          {
            "name": "東村里",
            "code": "'0014",
          },
          {
            "name": "延平里",
            "code": "'0015",
          },
          {
            "name": "七張里",
            "code": "'0016",
          },
          {
            "name": "慈安里",
            "code": "'0017",
          },
          {
            "name": "新民里",
            "code": "'0018",
          },
          {
            "name": "東門里",
            "code": "'0019",
          },
          {
            "name": "小東里",
            "code": "'0021",
          },
          {
            "name": "菜園里",
            "code": "'0022",
          },
          {
            "name": "孝廉里",
            "code": "'0023",
          },
          {
            "name": "新生里",
            "code": "'0024",
          },
          {
            "name": "茭白里",
            "code": "'0025",
          },
          {
            "name": "北津里",
            "code": "'0026",
          },
          {
            "name": "梅洲里",
            "code": "'0027",
          },
          {
            "name": "北門里",
            "code": "'0028",
          },
          {
            "name": "中山里",
            "code": "'0031",
          },
          {
            "name": "西門里",
            "code": "'0032",
          },
          {
            "name": "南門里",
            "code": "'0034",
          },
          {
            "name": "負郭里",
            "code": "'0035",
          },
          {
            "name": "民族里",
            "code": "'0036",
          },
          {
            "name": "建軍里",
            "code": "'0037",
          },
          {
            "name": "思源里",
            "code": "'0038",
          },
          {
            "name": "文化里",
            "code": "'0039",
          },
          {
            "name": "復興里",
            "code": "'0040",
          },
          {
            "name": "成功里",
            "code": "'0041",
          },
          {
            "name": "新東里",
            "code": "'0042",
          },
          {
            "name": "七結里",
            "code": "'0043",
          },
          {
            "name": "泰山里",
            "code": "'0044",
          },
          {
            "name": "大新里",
            "code": "'0045",
          },
          {
            "name": "中興里",
            "code": "'0046",
          },
          {
            "name": "擺厘里",
            "code": "'0047",
          }
        ]
      },
      {
        "name": "頭城鎮",
        "code": "'040",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "石城里",
            "code": "'0001",
          },
          {
            "name": "大里里",
            "code": "'0002",
          },
          {
            "name": "大溪里",
            "code": "'0003",
          },
          {
            "name": "合興里",
            "code": "'0004",
          },
          {
            "name": "更新里",
            "code": "'0005",
          },
          {
            "name": "外澳里",
            "code": "'0006",
          },
          {
            "name": "港口里",
            "code": "'0007",
          },
          {
            "name": "武營里",
            "code": "'0008",
          },
          {
            "name": "大坑里",
            "code": "'0009",
          },
          {
            "name": "城東里",
            "code": "'0010",
          },
          {
            "name": "城北里",
            "code": "'0011",
          },
          {
            "name": "城西里",
            "code": "'0012",
          },
          {
            "name": "城南里",
            "code": "'0013",
          },
          {
            "name": "竹安里",
            "code": "'0014",
          },
          {
            "name": "新建里",
            "code": "'0015",
          },
          {
            "name": "拔雅里",
            "code": "'0016",
          },
          {
            "name": "福成里",
            "code": "'0017",
          },
          {
            "name": "金面里",
            "code": "'0018",
          },
          {
            "name": "金盈里",
            "code": "'0019",
          },
          {
            "name": "頂埔里",
            "code": "'0020",
          },
          {
            "name": "下埔里",
            "code": "'0021",
          },
          {
            "name": "中崙里",
            "code": "'0022",
          },
          {
            "name": "二城里",
            "code": "'0023",
          },
          {
            "name": "龜山里",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "礁溪鄉",
        "code": "'050",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "白雲村",
            "code": "'0001",
          },
          {
            "name": "玉石村",
            "code": "'0002",
          },
          {
            "name": "德陽村",
            "code": "'0003",
          },
          {
            "name": "大忠村",
            "code": "'0004",
          },
          {
            "name": "大義村",
            "code": "'0005",
          },
          {
            "name": "六結村",
            "code": "'0006",
          },
          {
            "name": "二龍村",
            "code": "'0007",
          },
          {
            "name": "時潮村",
            "code": "'0008",
          },
          {
            "name": "玉田村",
            "code": "'0009",
          },
          {
            "name": "三民村",
            "code": "'0010",
          },
          {
            "name": "林美村",
            "code": "'0011",
          },
          {
            "name": "白鵝村",
            "code": "'0012",
          },
          {
            "name": "玉光村",
            "code": "'0013",
          },
          {
            "name": "光武村",
            "code": "'0014",
          },
          {
            "name": "吳沙村",
            "code": "'0015",
          },
          {
            "name": "龍潭村",
            "code": "'0016",
          },
          {
            "name": "匏崙村",
            "code": "'0017",
          },
          {
            "name": "二結村",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "員山鄉",
        "code": "'070",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "員山村",
            "code": "'0001",
          },
          {
            "name": "尚德村",
            "code": "'0002",
          },
          {
            "name": "惠好村",
            "code": "'0003",
          },
          {
            "name": "七賢村",
            "code": "'0004",
          },
          {
            "name": "深溝村",
            "code": "'0005",
          },
          {
            "name": "蓁巷村",
            "code": "'0006",
          },
          {
            "name": "內城村",
            "code": "'0007",
          },
          {
            "name": "中華村",
            "code": "'0008",
          },
          {
            "name": "永和村",
            "code": "'0009",
          },
          {
            "name": "頭分村",
            "code": "'0010",
          },
          {
            "name": "枕山村",
            "code": "'0011",
          },
          {
            "name": "同樂村",
            "code": "'0012",
          },
          {
            "name": "湖東村",
            "code": "'0013",
          },
          {
            "name": "逸仙村",
            "code": "'0014",
          },
          {
            "name": "湖北村",
            "code": "'0015",
          },
          {
            "name": "湖西村",
            "code": "'0016",
          }
        ]
      },
      {
        "name": "壯圍鄉",
        "code": "'060",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "吉祥村",
            "code": "'0001",
          },
          {
            "name": "美福村",
            "code": "'0002",
          },
          {
            "name": "新南村",
            "code": "'0003",
          },
          {
            "name": "東港村",
            "code": "'0004",
          },
          {
            "name": "復興村",
            "code": "'0005",
          },
          {
            "name": "過嶺村",
            "code": "'0006",
          },
          {
            "name": "永鎮村",
            "code": "'0007",
          },
          {
            "name": "忠孝村",
            "code": "'0008",
          },
          {
            "name": "古亭村",
            "code": "'0009",
          },
          {
            "name": "功勞村",
            "code": "'0010",
          },
          {
            "name": "美城村",
            "code": "'0011",
          },
          {
            "name": "新社村",
            "code": "'0012",
          },
          {
            "name": "大福村",
            "code": "'0013",
          },
          {
            "name": "古結村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "羅東鎮",
        "code": "'020",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "大新里",
            "code": "'0004",
          },
          {
            "name": "東安里",
            "code": "'0005",
          },
          {
            "name": "新群里",
            "code": "'0006",
          },
          {
            "name": "羅莊里",
            "code": "'0007",
          },
          {
            "name": "南昌里",
            "code": "'0008",
          },
          {
            "name": "南豪里",
            "code": "'0009",
          },
          {
            "name": "成功里",
            "code": "'0010",
          },
          {
            "name": "仁德里",
            "code": "'0011",
          },
          {
            "name": "漢民里",
            "code": "'0016",
          },
          {
            "name": "西安里",
            "code": "'0017",
          },
          {
            "name": "北成里",
            "code": "'0018",
          },
          {
            "name": "賢文里",
            "code": "'0019",
          },
          {
            "name": "公正里",
            "code": "'0020",
          },
          {
            "name": "集祥里",
            "code": "'0021",
          },
          {
            "name": "信義里",
            "code": "'0022",
          },
          {
            "name": "竹林里",
            "code": "'0023",
          },
          {
            "name": "仁愛里",
            "code": "'0024",
          },
          {
            "name": "樹林里",
            "code": "'0025",
          },
          {
            "name": "國華里",
            "code": "'0026",
          },
          {
            "name": "維揚里",
            "code": "'0027",
          },
          {
            "name": "開明里",
            "code": "'0028",
          },
          {
            "name": "中山里",
            "code": "'0029",
          },
          {
            "name": "仁和里",
            "code": "'0030",
          }
        ]
      },
      {
        "name": "五結鄉",
        "code": "'090",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "三興村",
            "code": "'0001",
          },
          {
            "name": "二結村",
            "code": "'0002",
          },
          {
            "name": "鎮安村",
            "code": "'0003",
          },
          {
            "name": "上四村",
            "code": "'0004",
          },
          {
            "name": "中興村",
            "code": "'0005",
          },
          {
            "name": "四結村",
            "code": "'0006",
          },
          {
            "name": "福興村",
            "code": "'0007",
          },
          {
            "name": "五結村",
            "code": "'0008",
          },
          {
            "name": "大吉村",
            "code": "'0009",
          },
          {
            "name": "協和村",
            "code": "'0010",
          },
          {
            "name": "孝威村",
            "code": "'0011",
          },
          {
            "name": "錦眾村",
            "code": "'0012",
          },
          {
            "name": "利澤村",
            "code": "'0013",
          },
          {
            "name": "成興村",
            "code": "'0014",
          },
          {
            "name": "季新村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "冬山鄉",
        "code": "'080",
        "group": [
          "'08"
        ],
        "subLocation": [
          {
            "name": "武淵村",
            "code": "'0001",
          },
          {
            "name": "珍珠村",
            "code": "'0002",
          },
          {
            "name": "補城村",
            "code": "'0003",
          },
          {
            "name": "三奇村",
            "code": "'0004",
          },
          {
            "name": "大興村",
            "code": "'0005",
          },
          {
            "name": "東城村",
            "code": "'0006",
          },
          {
            "name": "香和村",
            "code": "'0007",
          },
          {
            "name": "南興村",
            "code": "'0008",
          },
          {
            "name": "冬山村",
            "code": "'0009",
          },
          {
            "name": "安平村",
            "code": "'0010",
          },
          {
            "name": "太和村",
            "code": "'0011",
          },
          {
            "name": "八寶村",
            "code": "'0012",
          },
          {
            "name": "丸山村",
            "code": "'0013",
          },
          {
            "name": "中山村",
            "code": "'0014",
          },
          {
            "name": "順安村",
            "code": "'0015",
          },
          {
            "name": "永美村",
            "code": "'0016",
          },
          {
            "name": "群英村",
            "code": "'0017",
          },
          {
            "name": "清溝村",
            "code": "'0018",
          },
          {
            "name": "鹿埔村",
            "code": "'0019",
          },
          {
            "name": "得安村",
            "code": "'0020",
          },
          {
            "name": "廣興村",
            "code": "'0021",
          },
          {
            "name": "廣安村",
            "code": "'0022",
          },
          {
            "name": "柯林村",
            "code": "'0023",
          },
          {
            "name": "大進村",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "三星鄉",
        "code": "'100",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "員山村",
            "code": "'0001",
          },
          {
            "name": "天山村",
            "code": "'0002",
          },
          {
            "name": "天福村",
            "code": "'0003",
          },
          {
            "name": "雙賢村",
            "code": "'0004",
          },
          {
            "name": "人和村",
            "code": "'0005",
          },
          {
            "name": "義德村",
            "code": "'0006",
          },
          {
            "name": "集慶村",
            "code": "'0007",
          },
          {
            "name": "月眉村",
            "code": "'0008",
          },
          {
            "name": "拱照村",
            "code": "'0009",
          },
          {
            "name": "大隱村",
            "code": "'0010",
          },
          {
            "name": "行健村",
            "code": "'0011",
          },
          {
            "name": "萬德村",
            "code": "'0012",
          },
          {
            "name": "貴林村",
            "code": "'0013",
          },
          {
            "name": "萬富村",
            "code": "'0014",
          },
          {
            "name": "大義村",
            "code": "'0015",
          },
          {
            "name": "尚武村",
            "code": "'0016",
          },
          {
            "name": "大洲村",
            "code": "'0017",
          },
          {
            "name": "尾塹村",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "大同鄉",
        "code": "'110",
        "group": [
          "'09"
        ],
        "subLocation": [
          {
            "name": "寒溪村",
            "code": "'0001",
          },
          {
            "name": "崙埤村",
            "code": "'0002",
          },
          {
            "name": "松羅村",
            "code": "'0003",
          },
          {
            "name": "復興村",
            "code": "'0004",
          },
          {
            "name": "英士村",
            "code": "'0005",
          },
          {
            "name": "樂水村",
            "code": "'0006",
          },
          {
            "name": "太平村",
            "code": "'0007",
          },
          {
            "name": "四季村",
            "code": "'0008",
          },
          {
            "name": "南山村",
            "code": "'0009",
          },
          {
            "name": "茂安村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "蘇澳鎮",
        "code": "'030",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "龍德里",
            "code": "'0001",
          },
          {
            "name": "頂寮里",
            "code": "'0002",
          },
          {
            "name": "永榮里",
            "code": "'0003",
          },
          {
            "name": "存仁里",
            "code": "'0004",
          },
          {
            "name": "港邊里",
            "code": "'0005",
          },
          {
            "name": "隘丁里",
            "code": "'0007",
          },
          {
            "name": "新城里",
            "code": "'0008",
          },
          {
            "name": "聖湖里",
            "code": "'0009",
          },
          {
            "name": "蘇西里",
            "code": "'0010",
          },
          {
            "name": "蘇北里",
            "code": "'0011",
          },
          {
            "name": "蘇南里",
            "code": "'0012",
          },
          {
            "name": "蘇東里",
            "code": "'0013",
          },
          {
            "name": "長安里",
            "code": "'0014",
          },
          {
            "name": "永光里",
            "code": "'0015",
          },
          {
            "name": "永春里",
            "code": "'0016",
          },
          {
            "name": "永樂里",
            "code": "'0017",
          },
          {
            "name": "南安里",
            "code": "'0018",
          },
          {
            "name": "南正里",
            "code": "'0019",
          },
          {
            "name": "南成里",
            "code": "'0020",
          },
          {
            "name": "南興里",
            "code": "'0021",
          },
          {
            "name": "南寧里",
            "code": "'0022",
          },
          {
            "name": "南建里",
            "code": "'0023",
          },
          {
            "name": "東澳里",
            "code": "'0024",
          },
          {
            "name": "南強里",
            "code": "'0025",
          },
          {
            "name": "朝陽里",
            "code": "'0026",
          },
          {
            "name": "聖愛里",
            "code": "'0027",
          }
        ]
      },
      {
        "name": "南澳鄉",
        "code": "'120",
        "group": [
          "'10"
        ],
        "subLocation": [
          {
            "name": "南澳村",
            "code": "'0001",
          },
          {
            "name": "碧候村",
            "code": "'0002",
          },
          {
            "name": "東岳村",
            "code": "'0003",
          },
          {
            "name": "金岳村",
            "code": "'0004",
          },
          {
            "name": "武塔村",
            "code": "'0005",
          },
          {
            "name": "金洋村",
            "code": "'0006",
          },
          {
            "name": "澳花村",
            "code": "'0007",
          }
        ]
      }
    ]
  },
  {
    "name": "花蓮縣",
    "code": "'10'015",
    "subLocation": [
      {
        "name": "花蓮市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "民立里",
            "code": "'0001",
          },
          {
            "name": "民運里",
            "code": "'0002",
          },
          {
            "name": "民族里",
            "code": "'0003",
          },
          {
            "name": "主信里",
            "code": "'0004",
          },
          {
            "name": "主睦里",
            "code": "'0005",
          },
          {
            "name": "主和里",
            "code": "'0006",
          },
          {
            "name": "國威里",
            "code": "'0007",
          },
          {
            "name": "國風里",
            "code": "'0008",
          },
          {
            "name": "民德里",
            "code": "'0009",
          },
          {
            "name": "民意里",
            "code": "'0010",
          },
          {
            "name": "主義里",
            "code": "'0012",
          },
          {
            "name": "主工里",
            "code": "'0013",
          },
          {
            "name": "主農里",
            "code": "'0014",
          },
          {
            "name": "國治里",
            "code": "'0015",
          },
          {
            "name": "國富里",
            "code": "'0016",
          },
          {
            "name": "民政里",
            "code": "'0017",
          },
          {
            "name": "民勤里",
            "code": "'0018",
          },
          {
            "name": "民生里",
            "code": "'0019",
          },
          {
            "name": "主商里",
            "code": "'0020",
          },
          {
            "name": "主學里",
            "code": "'0021",
          },
          {
            "name": "國光里",
            "code": "'0022",
          },
          {
            "name": "國聯里",
            "code": "'0023",
          },
          {
            "name": "國強里",
            "code": "'0024",
          },
          {
            "name": "民心里",
            "code": "'0025",
          },
          {
            "name": "民樂里",
            "code": "'0026",
          },
          {
            "name": "民主里",
            "code": "'0027",
          },
          {
            "name": "主勤里",
            "code": "'0028",
          },
          {
            "name": "主力里",
            "code": "'0029",
          },
          {
            "name": "國安里",
            "code": "'0030",
          },
          {
            "name": "國魂里",
            "code": "'0031",
          },
          {
            "name": "國慶里",
            "code": "'0032",
          },
          {
            "name": "民享里",
            "code": "'0033",
          },
          {
            "name": "民有里",
            "code": "'0034",
          },
          {
            "name": "民權里",
            "code": "'0035",
          },
          {
            "name": "主計里",
            "code": "'0036",
          },
          {
            "name": "主權里",
            "code": "'0037",
          },
          {
            "name": "國華里",
            "code": "'0038",
          },
          {
            "name": "國防里",
            "code": "'0039",
          },
          {
            "name": "國福里",
            "code": "'0040",
          },
          {
            "name": "國裕里",
            "code": "'0041",
          },
          {
            "name": "主安里",
            "code": "'0042",
          },
          {
            "name": "國盛里",
            "code": "'0043",
          },
          {
            "name": "國興里",
            "code": "'0044",
          },
          {
            "name": "民孝里",
            "code": "'0045",
          }
        ]
      },
      {
        "name": "新城鄉",
        "code": "'040",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "新城村",
            "code": "'0001",
          },
          {
            "name": "順安村",
            "code": "'0002",
          },
          {
            "name": "康樂村",
            "code": "'0003",
          },
          {
            "name": "北埔村",
            "code": "'0004",
          },
          {
            "name": "佳林村",
            "code": "'0005",
          },
          {
            "name": "嘉里村",
            "code": "'0006",
          },
          {
            "name": "嘉新村",
            "code": "'0007",
          },
          {
            "name": "大漢村",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "秀林鄉",
        "code": "'110",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "崇德村",
            "code": "'0001",
          },
          {
            "name": "富世村",
            "code": "'0002",
          },
          {
            "name": "秀林村",
            "code": "'0003",
          },
          {
            "name": "水源村",
            "code": "'0004",
          },
          {
            "name": "銅門村",
            "code": "'0005",
          },
          {
            "name": "文蘭村",
            "code": "'0006",
          },
          {
            "name": "景美村",
            "code": "'0007",
          },
          {
            "name": "佳民村",
            "code": "'0008",
          },
          {
            "name": "和平村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "鳳林鎮",
        "code": "'020",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "鳳仁里",
            "code": "'0001",
          },
          {
            "name": "鳳義里",
            "code": "'0002",
          },
          {
            "name": "鳳禮里",
            "code": "'0003",
          },
          {
            "name": "鳳智里",
            "code": "'0004",
          },
          {
            "name": "鳳信里",
            "code": "'0005",
          },
          {
            "name": "山興里",
            "code": "'0006",
          },
          {
            "name": "大榮里",
            "code": "'0007",
          },
          {
            "name": "北林里",
            "code": "'0008",
          },
          {
            "name": "南平里",
            "code": "'0009",
          },
          {
            "name": "林榮里",
            "code": "'0010",
          },
          {
            "name": "長橋里",
            "code": "'0011",
          },
          {
            "name": "森榮里",
            "code": "'0012",
          }
        ]
      },
      {
        "name": "吉安鄉",
        "code": "'050",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "北昌村",
            "code": "'0001",
          },
          {
            "name": "勝安村",
            "code": "'0002",
          },
          {
            "name": "太昌村",
            "code": "'0003",
          },
          {
            "name": "永安村",
            "code": "'0004",
          },
          {
            "name": "慶豐村",
            "code": "'0005",
          },
          {
            "name": "吉安村",
            "code": "'0006",
          },
          {
            "name": "福興村",
            "code": "'0007",
          },
          {
            "name": "南華村",
            "code": "'0008",
          },
          {
            "name": "干城村",
            "code": "'0009",
          },
          {
            "name": "永興村",
            "code": "'0010",
          },
          {
            "name": "稻香村",
            "code": "'0011",
          },
          {
            "name": "南昌村",
            "code": "'0012",
          },
          {
            "name": "宜昌村",
            "code": "'0013",
          },
          {
            "name": "仁里村",
            "code": "'0014",
          },
          {
            "name": "東昌村",
            "code": "'0015",
          },
          {
            "name": "仁安村",
            "code": "'0016",
          },
          {
            "name": "仁和村",
            "code": "'0017",
          },
          {
            "name": "光華村",
            "code": "'0018",
          }
        ]
      },
      {
        "name": "壽豐鄉",
        "code": "'060",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "樹湖村",
            "code": "'0001",
          },
          {
            "name": "溪口村",
            "code": "'0002",
          },
          {
            "name": "豐山村",
            "code": "'0003",
          },
          {
            "name": "豐裡村",
            "code": "'0004",
          },
          {
            "name": "豐坪村",
            "code": "'0005",
          },
          {
            "name": "共和村",
            "code": "'0006",
          },
          {
            "name": "壽豐村",
            "code": "'0007",
          },
          {
            "name": "光榮村",
            "code": "'0008",
          },
          {
            "name": "池南村",
            "code": "'0009",
          },
          {
            "name": "平和村",
            "code": "'0010",
          },
          {
            "name": "志學村",
            "code": "'0011",
          },
          {
            "name": "米棧村",
            "code": "'0012",
          },
          {
            "name": "月眉村",
            "code": "'0013",
          },
          {
            "name": "水璉村",
            "code": "'0014",
          },
          {
            "name": "鹽寮村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "光復鄉",
        "code": "'070",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "大安村",
            "code": "'0001",
          },
          {
            "name": "大華村",
            "code": "'0002",
          },
          {
            "name": "大平村",
            "code": "'0003",
          },
          {
            "name": "大馬村",
            "code": "'0004",
          },
          {
            "name": "大同村",
            "code": "'0005",
          },
          {
            "name": "大進村",
            "code": "'0006",
          },
          {
            "name": "大全村",
            "code": "'0007",
          },
          {
            "name": "大興村",
            "code": "'0008",
          },
          {
            "name": "大富村",
            "code": "'0009",
          },
          {
            "name": "大豐村",
            "code": "'0010",
          },
          {
            "name": "東富村",
            "code": "'0011",
          },
          {
            "name": "西富村",
            "code": "'0012",
          },
          {
            "name": "南富村",
            "code": "'0013",
          },
          {
            "name": "北富村",
            "code": "'0014",
          }
        ]
      },
      {
        "name": "豐濱鄉",
        "code": "'080",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "豐濱村",
            "code": "'0001",
          },
          {
            "name": "新社村",
            "code": "'0002",
          },
          {
            "name": "磯崎村",
            "code": "'0003",
          },
          {
            "name": "港口村",
            "code": "'0004",
          },
          {
            "name": "靜浦村",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "萬榮鄉",
        "code": "'120",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "西林村",
            "code": "'0001",
          },
          {
            "name": "見晴村",
            "code": "'0002",
          },
          {
            "name": "萬榮村",
            "code": "'0003",
          },
          {
            "name": "明利村",
            "code": "'0004",
          },
          {
            "name": "馬遠村",
            "code": "'0005",
          },
          {
            "name": "紅葉村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "玉里鎮",
        "code": "'030",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "中城里",
            "code": "'0001",
          },
          {
            "name": "啟模里",
            "code": "'0002",
          },
          {
            "name": "永昌里",
            "code": "'0003",
          },
          {
            "name": "泰昌里",
            "code": "'0004",
          },
          {
            "name": "國武里",
            "code": "'0005",
          },
          {
            "name": "源城里",
            "code": "'0006",
          },
          {
            "name": "長良里",
            "code": "'0007",
          },
          {
            "name": "樂合里",
            "code": "'0008",
          },
          {
            "name": "東豐里",
            "code": "'0009",
          },
          {
            "name": "觀音里",
            "code": "'0010",
          },
          {
            "name": "松浦里",
            "code": "'0011",
          },
          {
            "name": "春日里",
            "code": "'0012",
          },
          {
            "name": "德武里",
            "code": "'0013",
          },
          {
            "name": "三民里",
            "code": "'0014",
          },
          {
            "name": "大禹里",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "瑞穗鄉",
        "code": "'090",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "瑞穗村",
            "code": "'0001",
          },
          {
            "name": "瑞美村",
            "code": "'0002",
          },
          {
            "name": "瑞良村",
            "code": "'0003",
          },
          {
            "name": "瑞祥村",
            "code": "'0004",
          },
          {
            "name": "瑞北村",
            "code": "'0005",
          },
          {
            "name": "舞鶴村",
            "code": "'0006",
          },
          {
            "name": "鶴岡村",
            "code": "'0007",
          },
          {
            "name": "奇美村",
            "code": "'0008",
          },
          {
            "name": "富源村",
            "code": "'0009",
          },
          {
            "name": "富民村",
            "code": "'0010",
          },
          {
            "name": "富興村",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "富里鄉",
        "code": "'100",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "吳江村",
            "code": "'0001",
          },
          {
            "name": "東里村",
            "code": "'0002",
          },
          {
            "name": "萬寧村",
            "code": "'0003",
          },
          {
            "name": "新興村",
            "code": "'0004",
          },
          {
            "name": "竹田村",
            "code": "'0005",
          },
          {
            "name": "羅山村",
            "code": "'0006",
          },
          {
            "name": "石牌村",
            "code": "'0007",
          },
          {
            "name": "明里村",
            "code": "'0008",
          },
          {
            "name": "富里村",
            "code": "'0009",
          },
          {
            "name": "永豐村",
            "code": "'0010",
          },
          {
            "name": "豐南村",
            "code": "'0011",
          },
          {
            "name": "富南村",
            "code": "'0012",
          },
          {
            "name": "學田村",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "卓溪鄉",
        "code": "'130",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "崙山村",
            "code": "'0001",
          },
          {
            "name": "立山村",
            "code": "'0002",
          },
          {
            "name": "太平村",
            "code": "'0003",
          },
          {
            "name": "卓溪村",
            "code": "'0004",
          },
          {
            "name": "卓清村",
            "code": "'0005",
          },
          {
            "name": "古風村",
            "code": "'0006",
          }
        ]
      }
    ]
  },
  {
    "name": "台東縣",
    "code": "'10'014",
    "subLocation": [
      {
        "name": "台東市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "富岡里",
            "code": "'0001",
          },
          {
            "name": "富豐里",
            "code": "'0002",
          },
          {
            "name": "岩灣里",
            "code": "'0003",
          },
          {
            "name": "南王里",
            "code": "'0004",
          },
          {
            "name": "卑南里",
            "code": "'0005",
          },
          {
            "name": "南榮里",
            "code": "'0006",
          },
          {
            "name": "新生里",
            "code": "'0007",
          },
          {
            "name": "民生里",
            "code": "'0008",
          },
          {
            "name": "寶桑里",
            "code": "'0009",
          },
          {
            "name": "馬蘭里",
            "code": "'0010",
          },
          {
            "name": "中心里",
            "code": "'0011",
          },
          {
            "name": "自強里",
            "code": "'0012",
          },
          {
            "name": "民族里",
            "code": "'0013",
          },
          {
            "name": "四維里",
            "code": "'0014",
          },
          {
            "name": "中華里",
            "code": "'0015",
          },
          {
            "name": "強國里",
            "code": "'0016",
          },
          {
            "name": "建國里",
            "code": "'0017",
          },
          {
            "name": "民權里",
            "code": "'0018",
          },
          {
            "name": "仁愛里",
            "code": "'0019",
          },
          {
            "name": "中正里",
            "code": "'0020",
          },
          {
            "name": "大同里",
            "code": "'0021",
          },
          {
            "name": "成功里",
            "code": "'0022",
          },
          {
            "name": "文化里",
            "code": "'0023",
          },
          {
            "name": "中山里",
            "code": "'0024",
          },
          {
            "name": "復國里",
            "code": "'0025",
          },
          {
            "name": "興國里",
            "code": "'0026",
          },
          {
            "name": "復興里",
            "code": "'0027",
          },
          {
            "name": "鐵花里",
            "code": "'0028",
          },
          {
            "name": "新園里",
            "code": "'0029",
          },
          {
            "name": "豐田里",
            "code": "'0030",
          },
          {
            "name": "豐年里",
            "code": "'0031",
          },
          {
            "name": "光明里",
            "code": "'0032",
          },
          {
            "name": "豐樂里",
            "code": "'0033",
          },
          {
            "name": "永樂里",
            "code": "'0034",
          },
          {
            "name": "康樂里",
            "code": "'0035",
          },
          {
            "name": "東海里",
            "code": "'0036",
          },
          {
            "name": "新興里",
            "code": "'0037",
          },
          {
            "name": "豐榮里",
            "code": "'0038",
          },
          {
            "name": "豐谷里",
            "code": "'0039",
          },
          {
            "name": "豐里里",
            "code": "'0040",
          },
          {
            "name": "豐原里",
            "code": "'0041",
          },
          {
            "name": "建和里",
            "code": "'0042",
          },
          {
            "name": "建興里",
            "code": "'0043",
          },
          {
            "name": "建業里",
            "code": "'0044",
          },
          {
            "name": "知本里",
            "code": "'0045",
          },
          {
            "name": "建農里",
            "code": "'0046",
          }
        ]
      },
      {
        "name": "蘭嶼鄉",
        "code": "'160",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "東清村",
            "code": "'0001",
          },
          {
            "name": "朗島村",
            "code": "'0002",
          },
          {
            "name": "紅頭村",
            "code": "'0003",
          },
          {
            "name": "椰油村",
            "code": "'0004",
          }
        ]
      },
      {
        "name": "卑南鄉",
        "code": "'040",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "嘉豐村",
            "code": "'0001",
          },
          {
            "name": "利吉村",
            "code": "'0002",
          },
          {
            "name": "富山村",
            "code": "'0003",
          },
          {
            "name": "富源村",
            "code": "'0004",
          },
          {
            "name": "明峰村",
            "code": "'0005",
          },
          {
            "name": "初鹿村",
            "code": "'0006",
          },
          {
            "name": "美農村",
            "code": "'0007",
          },
          {
            "name": "賓朗村",
            "code": "'0008",
          },
          {
            "name": "泰安村",
            "code": "'0009",
          },
          {
            "name": "太平村",
            "code": "'0010",
          },
          {
            "name": "利嘉村",
            "code": "'0011",
          },
          {
            "name": "東興村",
            "code": "'0012",
          },
          {
            "name": "溫泉村",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "延平鄉",
        "code": "'130",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "武陵村",
            "code": "'0001",
          },
          {
            "name": "永康村",
            "code": "'0002",
          },
          {
            "name": "紅葉村",
            "code": "'0003",
          },
          {
            "name": "鸞山村",
            "code": "'0004",
          },
          {
            "name": "桃源村",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "成功鎮",
        "code": "'020",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "博愛里",
            "code": "'0001",
          },
          {
            "name": "忠孝里",
            "code": "'0002",
          },
          {
            "name": "三仙里",
            "code": "'0003",
          },
          {
            "name": "三民里",
            "code": "'0004",
          },
          {
            "name": "忠智里",
            "code": "'0005",
          },
          {
            "name": "忠仁里",
            "code": "'0006",
          },
          {
            "name": "和平里",
            "code": "'0007",
          },
          {
            "name": "信義里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "東河鄉",
        "code": "'070",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "北源村",
            "code": "'0001",
          },
          {
            "name": "泰源村",
            "code": "'0002",
          },
          {
            "name": "東河村",
            "code": "'0003",
          },
          {
            "name": "尚德村",
            "code": "'0004",
          },
          {
            "name": "隆昌村",
            "code": "'0005",
          },
          {
            "name": "興昌村",
            "code": "'0006",
          },
          {
            "name": "都蘭村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "長濱鄉",
        "code": "'080",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "樟原村",
            "code": "'0001",
          },
          {
            "name": "三間村",
            "code": "'0002",
          },
          {
            "name": "忠勇村",
            "code": "'0003",
          },
          {
            "name": "長濱村",
            "code": "'0004",
          },
          {
            "name": "竹湖村",
            "code": "'0005",
          },
          {
            "name": "寧埔村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "關山鎮",
        "code": "'030",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "德高里",
            "code": "'0001",
          },
          {
            "name": "豐泉里",
            "code": "'0002",
          },
          {
            "name": "新福里",
            "code": "'0003",
          },
          {
            "name": "中福里",
            "code": "'0004",
          },
          {
            "name": "里壠里",
            "code": "'0005",
          },
          {
            "name": "電光里",
            "code": "'0006",
          },
          {
            "name": "月眉里",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "鹿野鄉",
        "code": "'050",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "瑞和村",
            "code": "'0001",
          },
          {
            "name": "瑞豐村",
            "code": "'0002",
          },
          {
            "name": "瑞源村",
            "code": "'0003",
          },
          {
            "name": "永安村",
            "code": "'0004",
          },
          {
            "name": "瑞隆村",
            "code": "'0005",
          },
          {
            "name": "鹿野村",
            "code": "'0006",
          },
          {
            "name": "龍田村",
            "code": "'0007",
          }
        ]
      },
      {
        "name": "池上鄉",
        "code": "'060",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "福文村",
            "code": "'0001",
          },
          {
            "name": "慶豐村",
            "code": "'0002",
          },
          {
            "name": "大坡村",
            "code": "'0003",
          },
          {
            "name": "大埔村",
            "code": "'0004",
          },
          {
            "name": "福原村",
            "code": "'0005",
          },
          {
            "name": "錦園村",
            "code": "'0006",
          },
          {
            "name": "新興村",
            "code": "'0007",
          },
          {
            "name": "萬安村",
            "code": "'0008",
          },
          {
            "name": "富興村",
            "code": "'0009",
          },
          {
            "name": "振興村",
            "code": "'0010",
          }
        ]
      },
      {
        "name": "海端鄉",
        "code": "'120",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "廣原村",
            "code": "'0001",
          },
          {
            "name": "霧鹿村",
            "code": "'0002",
          },
          {
            "name": "利稻村",
            "code": "'0003",
          },
          {
            "name": "海端村",
            "code": "'0004",
          },
          {
            "name": "崁頂村",
            "code": "'0005",
          },
          {
            "name": "加拿村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "太麻里鄉",
        "code": "'090",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "美和村",
            "code": "'0001",
          },
          {
            "name": "三和村",
            "code": "'0002",
          },
          {
            "name": "華源村",
            "code": "'0003",
          },
          {
            "name": "北里村",
            "code": "'0004",
          },
          {
            "name": "泰和村",
            "code": "'0005",
          },
          {
            "name": "大王村",
            "code": "'0006",
          },
          {
            "name": "香蘭村",
            "code": "'0007",
          },
          {
            "name": "金崙村",
            "code": "'0008",
          },
          {
            "name": "多良村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "大武鄉",
        "code": "'100",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "大竹村",
            "code": "'0001",
          },
          {
            "name": "大鳥村",
            "code": "'0002",
          },
          {
            "name": "大武村",
            "code": "'0003",
          },
          {
            "name": "尚武村",
            "code": "'0004",
          },
          {
            "name": "南興村",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "金峰鄉",
        "code": "'140",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "新興村",
            "code": "'0001",
          },
          {
            "name": "正興村",
            "code": "'0002",
          },
          {
            "name": "嘉蘭村",
            "code": "'0003",
          },
          {
            "name": "賓茂村",
            "code": "'0004",
          },
          {
            "name": "歷坵村",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "達仁鄉",
        "code": "'150",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "台坂村",
            "code": "'0001",
          },
          {
            "name": "土坂村",
            "code": "'0002",
          },
          {
            "name": "新化村",
            "code": "'0003",
          },
          {
            "name": "安朔村",
            "code": "'0004",
          },
          {
            "name": "森永村",
            "code": "'0005",
          },
          {
            "name": "南田村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "綠島鄉",
        "code": "'110",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "公舘村",
            "code": "'0001",
          },
          {
            "name": "中寮村",
            "code": "'0002",
          },
          {
            "name": "南寮村",
            "code": "'0003",
          }
        ]
      }
    ]
  },
  {
    "name": "澎湖縣",
    "code": "'10'016",
    "subLocation": [
      {
        "name": "馬公市",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "復興里",
            "code": "'0002",
          },
          {
            "name": "長安里",
            "code": "'0003",
          },
          {
            "name": "中央里",
            "code": "'0004",
          },
          {
            "name": "啟明里",
            "code": "'0005",
          },
          {
            "name": "重慶里",
            "code": "'0006",
          },
          {
            "name": "中興里",
            "code": "'0007",
          },
          {
            "name": "光復里",
            "code": "'0008",
          },
          {
            "name": "光榮里",
            "code": "'0009",
          },
          {
            "name": "光明里",
            "code": "'0010",
          },
          {
            "name": "朝陽里",
            "code": "'0011",
          },
          {
            "name": "陽明里",
            "code": "'0012",
          },
          {
            "name": "重光里",
            "code": "'0013",
          },
          {
            "name": "西衛里",
            "code": "'0014",
          },
          {
            "name": "西文里",
            "code": "'0015",
          },
          {
            "name": "東文里",
            "code": "'0016",
          },
          {
            "name": "案山里",
            "code": "'0017",
          },
          {
            "name": "光華里",
            "code": "'0018",
          },
          {
            "name": "前寮里",
            "code": "'0019",
          },
          {
            "name": "石泉里",
            "code": "'0020",
          },
          {
            "name": "菜園里",
            "code": "'0021",
          },
          {
            "name": "東衛里",
            "code": "'0022",
          },
          {
            "name": "安宅里",
            "code": "'0023",
          },
          {
            "name": "烏崁里",
            "code": "'0024",
          },
          {
            "name": "興仁里",
            "code": "'0025",
          },
          {
            "name": "鐵線里",
            "code": "'0026",
          },
          {
            "name": "山水里",
            "code": "'0027",
          },
          {
            "name": "鎖港里",
            "code": "'0028",
          },
          {
            "name": "五德里",
            "code": "'0029",
          },
          {
            "name": "井垵里",
            "code": "'0030",
          },
          {
            "name": "嵵裡里",
            "code": "'0031",
          },
          {
            "name": "風櫃里",
            "code": "'0032",
          },
          {
            "name": "虎井里",
            "code": "'0033",
          },
          {
            "name": "桶盤里",
            "code": "'0034",
          }
        ]
      },
      {
        "name": "湖西鄉",
        "code": "'020",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "湖西村",
            "code": "'0001",
          },
          {
            "name": "湖東村",
            "code": "'0002",
          },
          {
            "name": "青螺村",
            "code": "'0003",
          },
          {
            "name": "白坑村",
            "code": "'0004",
          },
          {
            "name": "南寮村",
            "code": "'0005",
          },
          {
            "name": "北寮村",
            "code": "'0006",
          },
          {
            "name": "紅羅村",
            "code": "'0007",
          },
          {
            "name": "西溪村",
            "code": "'0008",
          },
          {
            "name": "成功村",
            "code": "'0009",
          },
          {
            "name": "東石村",
            "code": "'0010",
          },
          {
            "name": "中西村",
            "code": "'0011",
          },
          {
            "name": "潭邊村",
            "code": "'0012",
          },
          {
            "name": "鼎灣村",
            "code": "'0013",
          },
          {
            "name": "許家村",
            "code": "'0014",
          },
          {
            "name": "沙港村",
            "code": "'0015",
          },
          {
            "name": "城北村",
            "code": "'0016",
          },
          {
            "name": "太武村",
            "code": "'0017",
          },
          {
            "name": "隘門村",
            "code": "'0018",
          },
          {
            "name": "林投村",
            "code": "'0019",
          },
          {
            "name": "尖山村",
            "code": "'0020",
          },
          {
            "name": "龍門村",
            "code": "'0021",
          },
          {
            "name": "菓葉村",
            "code": "'0022",
          }
        ]
      },
      {
        "name": "白沙鄉",
        "code": "'030",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "中屯村",
            "code": "'0001",
          },
          {
            "name": "講美村",
            "code": "'0002",
          },
          {
            "name": "城前村",
            "code": "'0003",
          },
          {
            "name": "鎮海村",
            "code": "'0004",
          },
          {
            "name": "港子村",
            "code": "'0005",
          },
          {
            "name": "岐頭村",
            "code": "'0006",
          },
          {
            "name": "小赤村",
            "code": "'0007",
          },
          {
            "name": "赤崁村",
            "code": "'0008",
          },
          {
            "name": "瓦硐村",
            "code": "'0009",
          },
          {
            "name": "後寮村",
            "code": "'0010",
          },
          {
            "name": "通梁村",
            "code": "'0011",
          },
          {
            "name": "吉貝村",
            "code": "'0012",
          },
          {
            "name": "鳥嶼村",
            "code": "'0013",
          },
          {
            "name": "員貝村",
            "code": "'0014",
          },
          {
            "name": "大倉村",
            "code": "'0015",
          }
        ]
      },
      {
        "name": "西嶼鄉",
        "code": "'040",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "橫礁村",
            "code": "'0001",
          },
          {
            "name": "合界村",
            "code": "'0002",
          },
          {
            "name": "小門村",
            "code": "'0003",
          },
          {
            "name": "竹灣村",
            "code": "'0004",
          },
          {
            "name": "大池村",
            "code": "'0005",
          },
          {
            "name": "二崁村",
            "code": "'0006",
          },
          {
            "name": "池東村",
            "code": "'0007",
          },
          {
            "name": "池西村",
            "code": "'0008",
          },
          {
            "name": "赤馬村",
            "code": "'0009",
          },
          {
            "name": "內垵村",
            "code": "'0010",
          },
          {
            "name": "外垵村",
            "code": "'0011",
          }
        ]
      },
      {
        "name": "望安鄉",
        "code": "'050",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "東安村",
            "code": "'0001",
          },
          {
            "name": "西安村",
            "code": "'0002",
          },
          {
            "name": "中社村",
            "code": "'0003",
          },
          {
            "name": "水垵村",
            "code": "'0004",
          },
          {
            "name": "將軍村",
            "code": "'0005",
          },
          {
            "name": "東坪村",
            "code": "'0006",
          },
          {
            "name": "西坪村",
            "code": "'0007",
          },
          {
            "name": "東吉村",
            "code": "'0008",
          },
          {
            "name": "花嶼村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "七美鄉",
        "code": "'060",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "東湖村",
            "code": "'0001",
          },
          {
            "name": "西湖村",
            "code": "'0002",
          },
          {
            "name": "中和村",
            "code": "'0003",
          },
          {
            "name": "平和村",
            "code": "'0004",
          },
          {
            "name": "海豐村",
            "code": "'0005",
          },
          {
            "name": "南港村",
            "code": "'0006",
          }
        ]
      }
    ]
  },
  {
    "name": "基隆市",
    "code": "'10'017",
    "subLocation": [
      {
        "name": "中正區",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "德義里",
            "code": "'0001",
          },
          {
            "name": "正義里",
            "code": "'0002",
          },
          {
            "name": "信義里",
            "code": "'0003",
          },
          {
            "name": "義重里",
            "code": "'0004",
          },
          {
            "name": "港通里",
            "code": "'0005",
          },
          {
            "name": "中船里",
            "code": "'0006",
          },
          {
            "name": "正船里",
            "code": "'0007",
          },
          {
            "name": "入船里",
            "code": "'0008",
          },
          {
            "name": "中砂里",
            "code": "'0009",
          },
          {
            "name": "正砂里",
            "code": "'0010",
          },
          {
            "name": "真砂里",
            "code": "'0011",
          },
          {
            "name": "砂灣里",
            "code": "'0012",
          },
          {
            "name": "建國里",
            "code": "'0013",
          },
          {
            "name": "中濱里",
            "code": "'0014",
          },
          {
            "name": "海濱里",
            "code": "'0015",
          },
          {
            "name": "社寮里",
            "code": "'0016",
          },
          {
            "name": "和憲里",
            "code": "'0017",
          },
          {
            "name": "平寮里",
            "code": "'0018",
          },
          {
            "name": "八斗里",
            "code": "'0019",
          },
          {
            "name": "碧砂里",
            "code": "'0020",
          },
          {
            "name": "長潭里",
            "code": "'0021",
          },
          {
            "name": "砂子里",
            "code": "'0022",
          },
          {
            "name": "中正里",
            "code": "'0023",
          },
          {
            "name": "正濱里",
            "code": "'0024",
          },
          {
            "name": "新豐里",
            "code": "'0025",
          },
          {
            "name": "新富里",
            "code": "'0026",
          }
        ]
      },
      {
        "name": "信義區",
        "code": "'070",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "仁壽里",
            "code": "'0001",
          },
          {
            "name": "仁義里",
            "code": "'0002",
          },
          {
            "name": "義昭里",
            "code": "'0003",
          },
          {
            "name": "義幸里",
            "code": "'0004",
          },
          {
            "name": "義民里",
            "code": "'0005",
          },
          {
            "name": "義和里",
            "code": "'0006",
          },
          {
            "name": "智慧里",
            "code": "'0007",
          },
          {
            "name": "智誠里",
            "code": "'0008",
          },
          {
            "name": "禮儀里",
            "code": "'0009",
          },
          {
            "name": "禮東里",
            "code": "'0010",
          },
          {
            "name": "信綠里",
            "code": "'0011",
          },
          {
            "name": "東光里",
            "code": "'0012",
          },
          {
            "name": "東明里",
            "code": "'0013",
          },
          {
            "name": "東安里",
            "code": "'0014",
          },
          {
            "name": "孝岡里",
            "code": "'0015",
          },
          {
            "name": "東信里",
            "code": "'0016",
          },
          {
            "name": "孝深里",
            "code": "'0017",
          },
          {
            "name": "孝賢里",
            "code": "'0018",
          },
          {
            "name": "孝德里",
            "code": "'0019",
          },
          {
            "name": "孝忠里",
            "code": "'0020",
          }
        ]
      },
      {
        "name": "仁愛區",
        "code": "'040",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "林泉里",
            "code": "'0001",
          },
          {
            "name": "花崗里",
            "code": "'0002",
          },
          {
            "name": "虹橋里",
            "code": "'0003",
          },
          {
            "name": "水錦里",
            "code": "'0004",
          },
          {
            "name": "智仁里",
            "code": "'0005",
          },
          {
            "name": "和明里",
            "code": "'0006",
          },
          {
            "name": "忠勇里",
            "code": "'0007",
          },
          {
            "name": "玉田里",
            "code": "'0008",
          },
          {
            "name": "仁德里",
            "code": "'0009",
          },
          {
            "name": "博愛里",
            "code": "'0010",
          },
          {
            "name": "福仁里",
            "code": "'0011",
          },
          {
            "name": "誠仁里",
            "code": "'0012",
          },
          {
            "name": "吉仁里",
            "code": "'0013",
          },
          {
            "name": "育仁里",
            "code": "'0014",
          },
          {
            "name": "英仁里",
            "code": "'0015",
          },
          {
            "name": "龍門里",
            "code": "'0016",
          },
          {
            "name": "德厚里",
            "code": "'0017",
          },
          {
            "name": "曲水里",
            "code": "'0018",
          },
          {
            "name": "崇文里",
            "code": "'0019",
          },
          {
            "name": "文安里",
            "code": "'0020",
          },
          {
            "name": "兆連里",
            "code": "'0021",
          },
          {
            "name": "獅球里",
            "code": "'0022",
          },
          {
            "name": "書院里",
            "code": "'0023",
          },
          {
            "name": "朝棟里",
            "code": "'0024",
          },
          {
            "name": "明德里",
            "code": "'0025",
          },
          {
            "name": "同風里",
            "code": "'0026",
          },
          {
            "name": "文昌里",
            "code": "'0027",
          },
          {
            "name": "新店里",
            "code": "'0028",
          },
          {
            "name": "光華里",
            "code": "'0029",
          }
        ]
      },
      {
        "name": "中山區",
        "code": "'050",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "新建里",
            "code": "'0001",
          },
          {
            "name": "安民里",
            "code": "'0002",
          },
          {
            "name": "安平里",
            "code": "'0003",
          },
          {
            "name": "中山里",
            "code": "'0004",
          },
          {
            "name": "民治里",
            "code": "'0005",
          },
          {
            "name": "中興里",
            "code": "'0006",
          },
          {
            "name": "仁正里",
            "code": "'0007",
          },
          {
            "name": "健民里",
            "code": "'0008",
          },
          {
            "name": "通化里",
            "code": "'0009",
          },
          {
            "name": "居仁里",
            "code": "'0010",
          },
          {
            "name": "通明里",
            "code": "'0011",
          },
          {
            "name": "協和里",
            "code": "'0012",
          },
          {
            "name": "文化里",
            "code": "'0013",
          },
          {
            "name": "仙洞里",
            "code": "'0014",
          },
          {
            "name": "太白里",
            "code": "'0015",
          },
          {
            "name": "西榮里",
            "code": "'0016",
          },
          {
            "name": "西華里",
            "code": "'0017",
          },
          {
            "name": "西定里",
            "code": "'0018",
          },
          {
            "name": "西康里",
            "code": "'0019",
          },
          {
            "name": "德和里",
            "code": "'0020",
          },
          {
            "name": "中和里",
            "code": "'0021",
          },
          {
            "name": "德安里",
            "code": "'0022",
          },
          {
            "name": "和平里",
            "code": "'0023",
          },
          {
            "name": "和慶里",
            "code": "'0024",
          }
        ]
      },
      {
        "name": "安樂區",
        "code": "'060",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "西川里",
            "code": "'0001",
          },
          {
            "name": "定國里",
            "code": "'0002",
          },
          {
            "name": "新西里",
            "code": "'0003",
          },
          {
            "name": "定邦里",
            "code": "'0004",
          },
          {
            "name": "慈仁里",
            "code": "'0005",
          },
          {
            "name": "干城里",
            "code": "'0006",
          },
          {
            "name": "永康里",
            "code": "'0007",
          },
          {
            "name": "嘉仁里",
            "code": "'0008",
          },
          {
            "name": "安和里",
            "code": "'0009",
          },
          {
            "name": "樂一里",
            "code": "'0010",
          },
          {
            "name": "外寮里",
            "code": "'0011",
          },
          {
            "name": "中崙里",
            "code": "'0012",
          },
          {
            "name": "內寮里",
            "code": "'0013",
          },
          {
            "name": "新崙里",
            "code": "'0014",
          },
          {
            "name": "三民里",
            "code": "'0015",
          },
          {
            "name": "四維里",
            "code": "'0016",
          },
          {
            "name": "五福里",
            "code": "'0017",
          },
          {
            "name": "六合里",
            "code": "'0018",
          },
          {
            "name": "七賢里",
            "code": "'0019",
          },
          {
            "name": "鶯歌里",
            "code": "'0020",
          },
          {
            "name": "武崙里",
            "code": "'0021",
          },
          {
            "name": "長樂里",
            "code": "'0022",
          },
          {
            "name": "興寮里",
            "code": "'0023",
          },
          {
            "name": "鶯安里",
            "code": "'0024",
          },
          {
            "name": "壯觀里",
            "code": "'0025",
          }
        ]
      },
      {
        "name": "暖暖區",
        "code": "'030",
        "group": [
          "'06"
        ],
        "subLocation": [
          {
            "name": "八西里",
            "code": "'0001",
          },
          {
            "name": "八堵里",
            "code": "'0002",
          },
          {
            "name": "八南里",
            "code": "'0003",
          },
          {
            "name": "過港里",
            "code": "'0004",
          },
          {
            "name": "碇內里",
            "code": "'0005",
          },
          {
            "name": "碇和里",
            "code": "'0006",
          },
          {
            "name": "暖同里",
            "code": "'0007",
          },
          {
            "name": "暖暖里",
            "code": "'0008",
          },
          {
            "name": "暖東里",
            "code": "'0009",
          },
          {
            "name": "暖西里",
            "code": "'0010",
          },
          {
            "name": "八中里",
            "code": "'0011",
          },
          {
            "name": "碇安里",
            "code": "'0012",
          },
          {
            "name": "碇祥里",
            "code": "'0013",
          }
        ]
      },
      {
        "name": "七堵區",
        "code": "'020",
        "group": [
          "'07"
        ],
        "subLocation": [
          {
            "name": "長興里",
            "code": "'0001",
          },
          {
            "name": "正光里",
            "code": "'0002",
          },
          {
            "name": "富民里",
            "code": "'0003",
          },
          {
            "name": "永平里",
            "code": "'0004",
          },
          {
            "name": "永安里",
            "code": "'0005",
          },
          {
            "name": "八德里",
            "code": "'0006",
          },
          {
            "name": "自強里",
            "code": "'0007",
          },
          {
            "name": "六堵里",
            "code": "'0008",
          },
          {
            "name": "泰安里",
            "code": "'0009",
          },
          {
            "name": "堵北里",
            "code": "'0010",
          },
          {
            "name": "堵南里",
            "code": "'0011",
          },
          {
            "name": "瑪南里",
            "code": "'0012",
          },
          {
            "name": "瑪東里",
            "code": "'0013",
          },
          {
            "name": "瑪西里",
            "code": "'0014",
          },
          {
            "name": "友一里",
            "code": "'0015",
          },
          {
            "name": "友二里",
            "code": "'0016",
          },
          {
            "name": "正明里",
            "code": "'0017",
          },
          {
            "name": "百福里",
            "code": "'0018",
          },
          {
            "name": "實踐里",
            "code": "'0019",
          },
          {
            "name": "長安里",
            "code": "'0020",
          }
        ]
      }
    ]
  },
  {
    "name": "新竹市",
    "code": "'10'018",
    "subLocation": [
      {
        "name": "東區",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "東門里",
            "code": "'0005",
          },
          {
            "name": "榮光里",
            "code": "'0006",
          },
          {
            "name": "成功里",
            "code": "'0007",
          },
          {
            "name": "育賢里",
            "code": "'0011",
          },
          {
            "name": "中正里",
            "code": "'0012",
          },
          {
            "name": "公園里",
            "code": "'0013",
          },
          {
            "name": "親仁里",
            "code": "'0017",
          },
          {
            "name": "文華里",
            "code": "'0018",
          },
          {
            "name": "復中里",
            "code": "'0019",
          },
          {
            "name": "三民里",
            "code": "'0020",
          },
          {
            "name": "東園里",
            "code": "'0021",
          },
          {
            "name": "東勢里",
            "code": "'0022",
          },
          {
            "name": "光復里",
            "code": "'0023",
          },
          {
            "name": "豐功里",
            "code": "'0024",
          },
          {
            "name": "武功里",
            "code": "'0025",
          },
          {
            "name": "綠水里",
            "code": "'0026",
          },
          {
            "name": "東山里",
            "code": "'0027",
          },
          {
            "name": "仙宮里",
            "code": "'0032",
          },
          {
            "name": "光明里",
            "code": "'0033",
          },
          {
            "name": "立功里",
            "code": "'0035",
          },
          {
            "name": "軍功里",
            "code": "'0036",
          },
          {
            "name": "建功里",
            "code": "'0037",
          },
          {
            "name": "前溪里",
            "code": "'0038",
          },
          {
            "name": "水源里",
            "code": "'0039",
          },
          {
            "name": "千甲里",
            "code": "'0040",
          },
          {
            "name": "埔頂里",
            "code": "'0041",
          },
          {
            "name": "龍山里",
            "code": "'0042",
          },
          {
            "name": "新莊里",
            "code": "'0043",
          },
          {
            "name": "仙水里",
            "code": "'0044",
          },
          {
            "name": "金山里",
            "code": "'0045",
          },
          {
            "name": "關東里",
            "code": "'0046",
          },
          {
            "name": "科園里",
            "code": "'0047",
          },
          {
            "name": "建華里",
            "code": "'0048",
          },
          {
            "name": "復興里",
            "code": "'0050",
          },
          {
            "name": "錦華里",
            "code": "'0051",
          },
          {
            "name": "關新里",
            "code": "'0054",
          }
        ]
      },
      {
        "name": "南區",
        "code": "'010",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "南門里",
            "code": "'0001",
          },
          {
            "name": "福德里",
            "code": "'0002",
          },
          {
            "name": "南市里",
            "code": "'0003",
          },
          {
            "name": "關帝里",
            "code": "'0004",
          },
          {
            "name": "下竹里",
            "code": "'0008",
          },
          {
            "name": "竹蓮里",
            "code": "'0009",
          },
          {
            "name": "寺前里",
            "code": "'0010",
          },
          {
            "name": "頂竹里",
            "code": "'0014",
          },
          {
            "name": "南大里",
            "code": "'0015",
          },
          {
            "name": "振興里",
            "code": "'0016",
          },
          {
            "name": "光鎮里",
            "code": "'0028",
          },
          {
            "name": "新興里",
            "code": "'0029",
          },
          {
            "name": "柴橋里",
            "code": "'0030",
          },
          {
            "name": "高峰里",
            "code": "'0031",
          },
          {
            "name": "新光里",
            "code": "'0049",
          },
          {
            "name": "湖濱里",
            "code": "'0052",
          },
          {
            "name": "明湖里",
            "code": "'0053",
          }
        ]
      },
      {
        "name": "西區",
        "code": "'020",
        "group": [
          "'03"
        ],
        "subLocation": [
          {
            "name": "西門里",
            "code": "'0001",
          },
          {
            "name": "仁德里",
            "code": "'0002",
          },
          {
            "name": "潛園里",
            "code": "'0003",
          },
          {
            "name": "中央里",
            "code": "'0004",
          },
          {
            "name": "崇禮里",
            "code": "'0005",
          },
          {
            "name": "石坊里",
            "code": "'0006",
          },
          {
            "name": "興南里",
            "code": "'0007",
          },
          {
            "name": "客雅里",
            "code": "'0030",
          },
          {
            "name": "育英里",
            "code": "'0031",
          },
          {
            "name": "曲溪里",
            "code": "'0032",
          },
          {
            "name": "西雅里",
            "code": "'0033",
          },
          {
            "name": "南勢里",
            "code": "'0034",
          },
          {
            "name": "大鵬里",
            "code": "'0035",
          },
          {
            "name": "台溪里",
            "code": "'0044",
          },
          {
            "name": "中雅里",
            "code": "'0045",
          }
        ]
      },
      {
        "name": "北區",
        "code": "'020",
        "group": [
          "'04"
        ],
        "subLocation": [
          {
            "name": "北門里",
            "code": "'0008",
          },
          {
            "name": "中興里",
            "code": "'0009",
          },
          {
            "name": "大同里",
            "code": "'0010",
          },
          {
            "name": "中山里",
            "code": "'0011",
          },
          {
            "name": "長和里",
            "code": "'0012",
          },
          {
            "name": "新民里",
            "code": "'0013",
          },
          {
            "name": "民富里",
            "code": "'0014",
          },
          {
            "name": "水田里",
            "code": "'0015",
          },
          {
            "name": "文雅里",
            "code": "'0016",
          },
          {
            "name": "光田里",
            "code": "'0017",
          },
          {
            "name": "士林里",
            "code": "'0018",
          },
          {
            "name": "福林里",
            "code": "'0019",
          },
          {
            "name": "古賢里",
            "code": "'0020",
          },
          {
            "name": "湳雅里",
            "code": "'0021",
          },
          {
            "name": "舊社里",
            "code": "'0022",
          },
          {
            "name": "武陵里",
            "code": "'0023",
          },
          {
            "name": "南寮里",
            "code": "'0024",
          },
          {
            "name": "舊港里",
            "code": "'0025",
          },
          {
            "name": "康樂里",
            "code": "'0026",
          },
          {
            "name": "港北里",
            "code": "'0027",
          },
          {
            "name": "中寮里",
            "code": "'0028",
          },
          {
            "name": "海濱里",
            "code": "'0029",
          },
          {
            "name": "境福里",
            "code": "'0036",
          },
          {
            "name": "磐石里",
            "code": "'0037",
          },
          {
            "name": "新雅里",
            "code": "'0038",
          },
          {
            "name": "光華里",
            "code": "'0039",
          },
          {
            "name": "金華里",
            "code": "'0040",
          },
          {
            "name": "金竹里",
            "code": "'0041",
          },
          {
            "name": "湳中里",
            "code": "'0042",
          },
          {
            "name": "金雅里",
            "code": "'0043",
          }
        ]
      },
      {
        "name": "香山區",
        "code": "'030",
        "group": [
          "'05"
        ],
        "subLocation": [
          {
            "name": "港南里",
            "code": "'0001",
          },
          {
            "name": "虎山里",
            "code": "'0002",
          },
          {
            "name": "虎林里",
            "code": "'0003",
          },
          {
            "name": "浸水里",
            "code": "'0004",
          },
          {
            "name": "樹下里",
            "code": "'0005",
          },
          {
            "name": "埔前里",
            "code": "'0006",
          },
          {
            "name": "中埔里",
            "code": "'0007",
          },
          {
            "name": "牛埔里",
            "code": "'0008",
          },
          {
            "name": "頂埔里",
            "code": "'0009",
          },
          {
            "name": "東香里",
            "code": "'0010",
          },
          {
            "name": "香村里",
            "code": "'0011",
          },
          {
            "name": "香山里",
            "code": "'0012",
          },
          {
            "name": "大庄里",
            "code": "'0013",
          },
          {
            "name": "美山里",
            "code": "'0014",
          },
          {
            "name": "朝山里",
            "code": "'0015",
          },
          {
            "name": "海山里",
            "code": "'0016",
          },
          {
            "name": "茄苳里",
            "code": "'0017",
          },
          {
            "name": "大湖里",
            "code": "'0018",
          },
          {
            "name": "南隘里",
            "code": "'0019",
          },
          {
            "name": "中隘里",
            "code": "'0020",
          },
          {
            "name": "內湖里",
            "code": "'0021",
          },
          {
            "name": "鹽水里",
            "code": "'0022",
          },
          {
            "name": "南港里",
            "code": "'0023",
          },
          {
            "name": "頂福里",
            "code": "'0024",
          }
        ]
      }
    ]
  },
  {
    "name": "嘉義市",
    "code": "'10'020",
    "subLocation": [
      {
        "name": "東區",
        "code": "'010",
        "group": [
          "'01"
        ],
        "subLocation": [
          {
            "name": "荖藤里",
            "code": "'0001",
          },
          {
            "name": "後湖里",
            "code": "'0002",
          },
          {
            "name": "仁義里",
            "code": "'0003",
          },
          {
            "name": "中庄里",
            "code": "'0004",
          },
          {
            "name": "頂庄里",
            "code": "'0005",
          },
          {
            "name": "圳頭里",
            "code": "'0034",
          },
          {
            "name": "新店里",
            "code": "'0035",
          },
          {
            "name": "後庄里",
            "code": "'0036",
          },
          {
            "name": "王田里",
            "code": "'0037",
          },
          {
            "name": "東川里",
            "code": "'0038",
          },
          {
            "name": "太平里",
            "code": "'0039",
          },
          {
            "name": "盧厝里",
            "code": "'0041",
          },
          {
            "name": "鹿寮里",
            "code": "'0042",
          },
          {
            "name": "長竹里",
            "code": "'0043",
          },
          {
            "name": "短竹里",
            "code": "'0044",
          },
          {
            "name": "新開里",
            "code": "'0045",
          },
          {
            "name": "宣信里",
            "code": "'0046",
          },
          {
            "name": "興南里",
            "code": "'0047",
          },
          {
            "name": "豐年里",
            "code": "'0048",
          },
          {
            "name": "芳草里",
            "code": "'0049",
          },
          {
            "name": "芳安里",
            "code": "'0050",
          },
          {
            "name": "頂寮里",
            "code": "'0051",
          },
          {
            "name": "安寮里",
            "code": "'0052",
          },
          {
            "name": "興安里",
            "code": "'0053",
          },
          {
            "name": "興村里",
            "code": "'0054",
          },
          {
            "name": "興仁里",
            "code": "'0055",
          },
          {
            "name": "過溝里",
            "code": "'0056",
          },
          {
            "name": "民族里",
            "code": "'0057",
          },
          {
            "name": "朝陽里",
            "code": "'0058",
          },
          {
            "name": "華南里",
            "code": "'0059",
          },
          {
            "name": "東興里",
            "code": "'0060",
          },
          {
            "name": "中山里",
            "code": "'0061",
          },
          {
            "name": "中央里",
            "code": "'0062",
          },
          {
            "name": "林森里",
            "code": "'0063",
          },
          {
            "name": "北門里",
            "code": "'0064",
          },
          {
            "name": "蘭潭里",
            "code": "'0065",
          },
          {
            "name": "文雅里",
            "code": "'0066",
          },
          {
            "name": "安業里",
            "code": "'0067",
          },
          {
            "name": "義教里",
            "code": "'0068",
          }
        ]
      },
      {
        "name": "西區",
        "code": "'020",
        "group": [
          "'02"
        ],
        "subLocation": [
          {
            "name": "香湖里",
            "code": "'0001",
          },
          {
            "name": "湖邊里",
            "code": "'0002",
          },
          {
            "name": "北榮里",
            "code": "'0003",
          },
          {
            "name": "重興里",
            "code": "'0005",
          },
          {
            "name": "竹圍里",
            "code": "'0009",
          },
          {
            "name": "新厝里",
            "code": "'0010",
          },
          {
            "name": "保安里",
            "code": "'0011",
          },
          {
            "name": "北湖里",
            "code": "'0012",
          },
          {
            "name": "下埤里",
            "code": "'0013",
          },
          {
            "name": "竹村里",
            "code": "'0014",
          },
          {
            "name": "大溪里",
            "code": "'0015",
          },
          {
            "name": "福全里",
            "code": "'0016",
          },
          {
            "name": "西平里",
            "code": "'0017",
          },
          {
            "name": "磚磘里",
            "code": "'0018",
          },
          {
            "name": "港坪里",
            "code": "'0019",
          },
          {
            "name": "頭港里",
            "code": "'0020",
          },
          {
            "name": "劉厝里",
            "code": "'0021",
          },
          {
            "name": "新西里",
            "code": "'0022",
          },
          {
            "name": "書院里",
            "code": "'0031",
          },
          {
            "name": "培元里",
            "code": "'0045",
          },
          {
            "name": "垂楊里",
            "code": "'0046",
          },
          {
            "name": "車店里",
            "code": "'0047",
          },
          {
            "name": "福民里",
            "code": "'0048",
          },
          {
            "name": "湖內里",
            "code": "'0049",
          },
          {
            "name": "美源里",
            "code": "'0050",
          },
          {
            "name": "育英里",
            "code": "'0051",
          },
          {
            "name": "致遠里",
            "code": "'0052",
          },
          {
            "name": "光路里",
            "code": "'0053",
          },
          {
            "name": "翠岱里",
            "code": "'0054",
          },
          {
            "name": "自強里",
            "code": "'0055",
          },
          {
            "name": "永和里",
            "code": "'0056",
          },
          {
            "name": "新富里",
            "code": "'0057",
          },
          {
            "name": "文化里",
            "code": "'0058",
          },
          {
            "name": "西榮里",
            "code": "'0059",
          },
          {
            "name": "國華里",
            "code": "'0060",
          },
          {
            "name": "番社里",
            "code": "'0061",
          },
          {
            "name": "導明里",
            "code": "'0062",
          },
          {
            "name": "慶安里",
            "code": "'0063",
          },
          {
            "name": "後驛里",
            "code": "'0064",
          },
          {
            "name": "福安里",
            "code": "'0065",
          },
          {
            "name": "獅子里",
            "code": "'0066",
          },
          {
            "name": "紅瓦里",
            "code": "'0067",
          },
          {
            "name": "保福里",
            "code": "'0068",
          },
          {
            "name": "保生里",
            "code": "'0069",
          },
          {
            "name": "北新里",
            "code": "'0070",
          }
        ]
      }
    ]
  },
  {
    "name": "金門縣",
    "code": "'09'020",
    "subLocation": [
      {
        "name": "金城鎮",
        "code": "'010",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "東門里",
            "code": "'0001",
          },
          {
            "name": "西門里",
            "code": "'0002",
          },
          {
            "name": "南門里",
            "code": "'0003",
          },
          {
            "name": "北門里",
            "code": "'0004",
          },
          {
            "name": "古城里",
            "code": "'0005",
          },
          {
            "name": "金水里",
            "code": "'0006",
          },
          {
            "name": "珠沙里",
            "code": "'0007",
          },
          {
            "name": "賢庵里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "金沙鎮",
        "code": "'020",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "汶沙里",
            "code": "'0001",
          },
          {
            "name": "三山里",
            "code": "'0002",
          },
          {
            "name": "大洋里",
            "code": "'0003",
          },
          {
            "name": "光前里",
            "code": "'0004",
          },
          {
            "name": "何斗里",
            "code": "'0005",
          },
          {
            "name": "西園里",
            "code": "'0006",
          },
          {
            "name": "官嶼里",
            "code": "'0007",
          },
          {
            "name": "浦山里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "金湖鎮",
        "code": "'030",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "新市里",
            "code": "'0001",
          },
          {
            "name": "山外里",
            "code": "'0002",
          },
          {
            "name": "正義里",
            "code": "'0003",
          },
          {
            "name": "料羅里",
            "code": "'0004",
          },
          {
            "name": "瓊林里",
            "code": "'0005",
          },
          {
            "name": "新湖里",
            "code": "'0006",
          },
          {
            "name": "溪湖里",
            "code": "'0007",
          },
          {
            "name": "蓮庵里",
            "code": "'0008",
          }
        ]
      },
      {
        "name": "金寧鄉",
        "code": "'040",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "古寧村",
            "code": "'0001",
          },
          {
            "name": "安美村",
            "code": "'0002",
          },
          {
            "name": "后盤村",
            "code": "'0003",
          },
          {
            "name": "湖埔村",
            "code": "'0004",
          },
          {
            "name": "榜林村",
            "code": "'0005",
          },
          {
            "name": "盤山村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "烈嶼鄉",
        "code": "'050",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "上岐村",
            "code": "'0001",
          },
          {
            "name": "上林村",
            "code": "'0002",
          },
          {
            "name": "西口村",
            "code": "'0003",
          },
          {
            "name": "林湖村",
            "code": "'0004",
          },
          {
            "name": "黃埔村",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "烏坵鄉",
        "code": "'060",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "大坵村",
            "code": "'0001",
          },
          {
            "name": "小坵村",
            "code": "'0002",
          }
        ]
      }
    ]
  },
  {
    "name": "連江縣",
    "code": "'09'007",
    "subLocation": [
      {
        "name": "南竿鄉",
        "code": "'010",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "介壽村",
            "code": "'0001",
          },
          {
            "name": "復興村",
            "code": "'0002",
          },
          {
            "name": "福沃村",
            "code": "'0003",
          },
          {
            "name": "清水村",
            "code": "'0004",
          },
          {
            "name": "仁愛村",
            "code": "'0005",
          },
          {
            "name": "津沙村",
            "code": "'0006",
          },
          {
            "name": "馬祖村",
            "code": "'0007",
          },
          {
            "name": "珠螺村",
            "code": "'0008",
          },
          {
            "name": "四維村",
            "code": "'0009",
          }
        ]
      },
      {
        "name": "北竿鄉",
        "code": "'020",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "塘岐村",
            "code": "'0001",
          },
          {
            "name": "后沃村",
            "code": "'0002",
          },
          {
            "name": "橋仔村",
            "code": "'0003",
          },
          {
            "name": "芹壁村",
            "code": "'0004",
          },
          {
            "name": "坂里村",
            "code": "'0005",
          },
          {
            "name": "白沙村",
            "code": "'0006",
          }
        ]
      },
      {
        "name": "莒光鄉",
        "code": "'030",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "青帆村",
            "code": "'0001",
          },
          {
            "name": "田沃村",
            "code": "'0002",
          },
          {
            "name": "西坵村",
            "code": "'0003",
          },
          {
            "name": "福正村",
            "code": "'0004",
          },
          {
            "name": "大坪村",
            "code": "'0005",
          }
        ]
      },
      {
        "name": "東引鄉",
        "code": "'040",
        "group": [
          "'00"
        ],
        "subLocation": [
          {
            "name": "中柳村",
            "code": "'0001",
          },
          {
            "name": "樂華村",
            "code": "'0002",
          }
        ]
      }
    ]
  }

]