export const positionName = {
    0: "直轄市長",
    1: "縣市長",
    2: "直轄市議員",
    3: "縣市議員",
    4: "鄉鎮市長",
    5: "直轄市山地原住民區長",
    6: "鄉鎮市民代表",
    7: "直轄市山地原住民區民代表",
    8: "村里長"
};
