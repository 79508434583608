import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './App';
import React, { useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';


const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/*" element={<App />}></Route>
        </Routes>
      </Wrapper>
    </BrowserRouter>
  </React.StrictMode>
);

