import React from 'react'
import { Link } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa'



const Footer = () => {
    const today = new Date();
    const elecDay = new Date("Nov 26, 2022 00:00:00");
    const dayDistance = Math.round((elecDay - today) / (1000 * 60 * 60 * 24));
    return (
        <footer className='flex flex-col items-center justify-center pt-2 px-4 pb-8 mx-auto lg:px-[50px] text-neutral-50 bg-neutral-700 w-full'>
            <div className='columns-3' >
                <div>
                    <Link to={'/disclaimer'}>
                        <p className='p-4 text-neutral-50 hover:text-rose-400'>
                            免責聲明
                        </p>
                    </Link>
                </div>
                <div>
                    <Link to={'/about'}>
                        <p className='flex border-x-2 boder-solid border-neutral-400 p-4 justify-center text-neutral-50 hover:text-rose-400'>
                            關於
                        </p>
                    </Link>
                </div>
                <div>
                    <Link to={'/report'}>
                        <p className='p-4 text-neutral-50 hover:text-rose-400'>
                            回報問題
                        </p>
                    </Link>
                </div>
            </div>
            <div className='border-b-2 border-solid border-neutral-300 w-full m-2 flex justify-center'>
                <div className='py-2 '>距離選舉還有{dayDistance}天</div>
            </div>
            <div className='flex flex-row text-sm '>
                <div className=''>
                    2022 &copy; ysesy 版權所有
                </div>
            </div>
        </footer>
    )
}

export default Footer