import React, { useEffect, useState } from 'react'
import { FaUserAstronaut, FaUserGraduate, FaUserNinja, FaUserTie, FaUser, FaUserMd } from 'react-icons/fa'

const HeadPic = ({ candidateImage, name, type, result, degree }) => {
    const headBorder = result ? 'border-red-400' : 'border-neutral-300';
    // candidateImage => 3.jpeg
    // 'https://storage.cloud.google.com/yssy-elec_public/3.jpeg'
    

    // const [pic, setPic] = useState('')
    // useEffect(() => {
    //     const pic = require(`../../image/${candidateImage}.jpeg`)
    //     setPic(pic)
    // }, [pic])

    // useEffect(() => {
    //     try {
    //         const pic = ''
    //         // const pic = 'https://storage.cloud.google.com/yssy-elec_public/3.jpeg'
    //         // const pic = require('https://storage.googleapis.com/yssy-elec_public/3.jpeg')
    //         setPic(pic)
    //     } catch (err) {
    //         console.error(err)   
    //     }
    // }, [])

    const pic = candidateImage ? `https://storage.googleapis.com/yssy-elec_public/${candidateImage}`:''


    const DefaultHeadIcon = (degree, type) => {
        const size = type === 'large' ? "7rem"
            : type === 'small' ? "5rem" : "6rem"
        // console.log("degree", degree)
        switch (degree) {
            case 7:
                return (<FaUserMd size={size} />)
            case 4:
                return (<FaUserGraduate size={size} />)
            default:
                return (<FaUser size={size} />)
        }
    }

    switch (type) {
        case 'card':
            return (pic ? (
                <img src={pic} alt="Oops..."
                    style={{ width: "100px", minWidth: "100px", height: "100px", minHeight: "100px", objectFit:"cover" }}
                    className={`border-2 border-solid ${headBorder} rounded-full opacity-70 `} />
            ) : (
                <div
                    style={{ width: "100px", minWidth: "100px", height: "100px", minHeight: "100px", objectFit:"cover" }}
                    className={`border-2 border-solid ${headBorder} rounded-full bg-slate-50
                        flex items-center justify-center text-3xl text-slate-600`} >
                    {name[0]}
                </div>
            ))
        case 'large':
            return (pic ? (
                <img src={pic} alt="Oops..."
                    className={`border-2 border-solid border-neutral-300 rounded-full opacity-80`}
                    style={{ width: "250px", height: "250px", objectFit:"cover" }} />
            ) : (
                <div
                    style={{ width: "250px", height: "250px", objectFit:"cover" }}
                    className={`border-2 border-solid border-neutral-300 rounded-full bg-slate-50
                        flex items-center justify-center text-6xl text-slate-600`} >
                    {/* {name[0]} */}
                    {DefaultHeadIcon(degree, type)}
                </div>
            ))

        case 'small':
            return (pic ? (
                <img src={pic} alt="Oops..."
                    className={`border-2 border-solid border-neutral-300 rounded-full opacity-80`}
                    style={{ width: "150px", height: "150px", objectFit:"cover" }} />
            ) : (
                <div
                    style={{ width: "150px", height: "150px", objectFit:"cover" }}
                    className={`border-2 border-solid border-neutral-300 rounded-full bg-slate-50
                        flex items-center justify-center text-5xl text-slate-600`} >
                    {/* {name[0]} */}
                    {DefaultHeadIcon(degree, type)}
                </div>
            ))
    }

    // return (type === 'large') ? (
    //     <img src={require(`../../image/${candidateImage}.jpeg`)} alt="Oops..."
    //         className='border-2 border-solid border-neutral-300 rounded-full opacity-80'
    //         style={{ width: "250px", height: "250px" }}
    //     />

    // )
    //     : (pic ? (
    //         <img src={require(`../../image/${candidateImage}.jpeg`)} alt="Oops..."
    //             style={{ width: "min(100px, 90%)", minWidth: "100px", height: "min(100px, 90%)", minHeight: "100px" }}
    //             className='border-2 border-solid border-neutral-300 rounded-full opacity-70' />
    //     ) : (
    //         <div
    //             style={{ width: "min(100px, 90%)", minWidth: "100px", height: "min(100px, 90%)", minHeight: "100px" }}
    //             className='border-2 border-solid border-neutral-300 rounded-full bg-slate-200
    //     flex items-center justify-center text-4xl text-slate-600' >
    //             {name[0]}
    //         </div>
    //     ))
}

export default HeadPic