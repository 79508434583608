export const partyName = {
  1: { name: "中國國民黨", color: "rgb(118, 175, 239)" },
  15: { name: "勞動黨", color: "" },
  16: { name: "民主進步黨", color: "rgb(170, 220, 181)" },
  42: { name: "農民黨", color: "" },
  73: { name: "公民黨", color: "" },
  74: { name: "新黨", color: "rgb(247, 220, 111)" },
  79: { name: "綠黨", color: "rgb(102, 204, 102)" },
  90: { name: "親民黨", color: "#fdba74" },
  95: { name: "台灣團結聯盟", color: "rgb(210, 175, 121)" },
  98: { name: "中華民族致公黨", color: "" },
  106: { name: "無黨團結聯盟", color: "" },
  113: { name: "中華統一促進黨", color: "" },
  114: { name: "中國民主進步黨", color: "" },
  151: { name: "中華婦女黨", color: "" },
  166: { name: "全民生活政策黨", color: "" },
  188: { name: "正黨", color: "" },
  196: { name: "教科文預算保障e聯盟", color: "" },
  198: { name: "新華勞動黨", color: "" },
  199: { name: "人民民主黨", color: "" },
  201: { name: "正義聯盟", color: "" },
  219: { name: "台灣進步黨", color: "" },
  224: { name: "台灣整復師聯盟工黨", color: "" },
  234: { name: "台灣第一民族黨", color: "" },
  236: { name: "新生黨", color: "" },
  249: { name: "中華文化民主黨", color: "" },
  250: { name: "中國國家社會主義勞工黨", color: "" },
  254: { name: "中華民國國政監督聯盟", color: "" },
  257: { name: "天宙和平統一家庭黨", color: "" },
  258: { name: "軍公教聯盟黨", color: "" },
  259: { name: "樹黨", color: "" },
  264: { name: "經濟黨", color: "" },
  267: { name: "時代力量", color: "rgb(242, 203, 13)" },
  268: { name: "民國黨", color: "rgb(252, 200, 0)" },
  269: { name: "社會民主黨", color: "" },
  272: { name: "自由台灣黨", color: "" },
  275: { name: "新政世紀黨", color: "" },
  278: { name: "臺灣前進黨", color: "" },
  283: { name: "信心希望聯盟", color: "" },
  290: { name: "皇君人民政黨", color: "" },
  292: { name: "聾國黨", color: "" },
  295: { name: "全國人民黨", color: "" },
  302: { name: "金門高粱黨", color: "" },
  303: { name: "基進黨", color: "" },
  309: { name: "華裔和合黨", color: "" },
  310: { name: "青年陽光黨", color: "" },
  315: { name: "臺灣人民共產黨", color: "" },
  321: { name: "台灣學習黨", color: "" },
  324: { name: "世界大同黨", color: "" },
  331: { name: "愛心黨", color: "" },
  333: { name: "舊臺灣", color: "" },
  338: { name: "左翼聯盟", color: "" },
  400: { name: "民眾黨", color: "#60c4c6" },
  999: { name: "無黨籍及未經政黨推薦", color: "#e5e7eb" }
}
