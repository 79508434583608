import React from 'react'
import RatingBoard from './RatingBoard'


const showRatingBoard = (candidate) => {
  return (
    (candidate.term === 2022 || candidate.result === 1) ?
      (<div className='mx-4 my-2'>
        <RatingBoard />
      </div>)
      :
      (<></>)
  )
}

const CommentBlock = ({ candidate }) => {
  return (
    <div>
       {showRatingBoard(candidate)}

    </div>
  )
}

export default CommentBlock