import React, { useContext, useEffect, useState } from 'react'
import Feed from './cardComponent/Feed'
import DataContext from '../context/DataContext'
import ToTop from './controlComponent/ToTop'
import Switch from './controlComponent/Switch'
import TermSelector from './controlComponent/TermSelector'

import { FaUserTimes, FaDiagnoses, FaChevronDown } from 'react-icons/fa'

const Home = () => {
  const { searchResult, fetchError, isLoading, width } = useContext(DataContext);

  const [displayCount, setDisplayCount] = useState(24);
  const [displayResult, setDisplayResult] = useState([])
  // console.log(displayResult.length)

  useEffect(() => {
    // const index = Math.floor(Math.random() * (searchResult.length))
    // console.log("here", searchResult.length)
    setDisplayResult(
      (searchResult.length > displayCount)
        ?
        searchResult.slice(0, displayCount)
        :
        searchResult)
    // console.log(displayResult.length)

  }, [displayCount, searchResult])

  // console.log(displayResult.length)

  const handleLoadmore = () => {
    setDisplayCount(displayCount + 24)
    // console.log("ddd", displayCount)
  }

  return (
    <main className='w-full grow bg-neutral-50 min-h-screen'>
      {
        width >= 1024 ?
          // if add overflow-hidden will make feed bg cover banner
          // <div className='overflow-hidden h-[10]'>
          <div className='spacer4'></div>
          // </div> 
          :
          // <div className='overflow-hidden h-[30]'>
          <div className='spacer5'></div>
        // </div>
      }
      {
        isLoading &&
        <div className='grid place-items-center h-[80vh]'>
          <div>
            <div className="flex space-x-12 p-12 justify-center items-center">
              <div className="flex items-center justify-center space-x-2 ">
                <div className="loading1 w-5 h-5 bg-indigo-500 rounded-full"></div>
                <div className="loading2 w-5 h-5 bg-slate-400 rounded-full"></div>
                <div className="loading3 w-5 h-5 bg-teal-500 rounded-full"></div>
              </div>
            </div>
            <div className='text-2xl text-neutral-800 flex justify-center items-center'>
              {/* {"No candidate found..."} */}
              {"Loading..."}
            </div>
          </div>
        </div>
      }
      {
        !isLoading && fetchError &&
        <div className='grid place-items-center h-[80vh]'>
          <p className="mt-4" style={{ color: "red", font: "2rem" }}>
            {fetchError}
          </p>
        </div>
      }
      {
        !isLoading && !fetchError && (
          searchResult.length
            ?
            <div className='px-2 pb-2'>
              {/* <TermSelector /> */}
              <Feed candidates={displayResult} />
              {(searchResult.length > displayCount)
                ?
                <div className='flex justify-center'>
                  <div onClick={() => handleLoadmore()} className='flex flex-row text-slate-900 bg-slate-200 rounded-lg px-6 py-2
                  hover:bg-rose-400 hover:text-white cursor-pointer'>
                    <div className='p-1'>
                      <FaChevronDown size='1rem' />
                    </div>
                    <div>
                      {"顯示更多候選人"}
                    </div>
                  </div>
                </div>
                :
                // not display shoe more button
                <></>
              }
            </div>
            :
            // FIXME: term selector logic need refactor 
            <div className='h-screen'>
              {/* <TermSelector /> */}
              <div>
                <div className='grid place-items-center text-neutral-800'>
                  <FaUserTimes size={48} />
                </div>
                <div className='grid text-xl text-neutral-800 justify-center'>
                  {/* {"No candidate found..."} */}

                  {"沒有找到相符的候選人"}
                </div>
              </div>
            </div>
        )
      }
      <ToTop />
    </main>
  )
}

export default Home