import React from 'react'
import CardItem from '../cardComponent/CardItem'
// FaGlassCheers FaRegHandshake FaRegEye FaCommentDots FaChess
import { FaChess } from 'react-icons/fa'
import Feed from '../cardComponent/Feed'

const CompetitionSideBar = ({ compCandidates }) => {
  return (
    <div className=''>
      <div className='from-neutral-100 to-neutral-200 px-2 lg:pt-4 pb-2 bg-gradient-to-t text-neutral-800'>
        {/* <FaChess /> */}
        {"同選區競爭對手"}
      </div>
      <div className='p-2 lg:p-4 lg:mx-4'>
        <Feed candidates={compCandidates} />
      </div>
      {/* <div className='holder flex flex-grow justify-evenly items-center flex-row flex-wrap gap-3 mb-4 p-4 mx-2 lg:mx-4'>
        {compCandidates.map((compCandidate) => (
          <CardItem key={compCandidate.id} candidate={compCandidate} />
        ))}
      </div> */}
    </div>
  )
}

export default CompetitionSideBar