import React from 'react';


const About = () => {

  return (
    <main className='w-full grow p-4 overflow-y-auto bg-neutral-100 min-h-screen'>
      <div className='bg-slate-200 rounded-lg mx-4 my-2 text-neutral-700 px-2'>
        <div className='text-2xl p-4 font-bold'>
          {"關於："}
        </div>
        <div className='p-2'>
          {"本網站建置僅為展示供台灣民眾查詢過去及現在選舉資訊之功能。"}
          <div>
            {"若有以下需求："}
            <ul className='p-2'>
              <li>
                {"(1)參與編輯網站所載資訊。"}
              </li>
              <li>
                {"(2)技術合作及參與網站開發與維護。"}
              </li>
            </ul>
            {"歡迎與我們聯絡。"}
          </div>

          <div>
            {"電子信箱："}
            {"youseesy12@gmail.com"}
          </div>
        </div>
      </div>
      <div className='bg-slate-200 rounded-lg mx-4 my-2 text-neutral-700 px-2'>
        <div className='p-4'>
          {"版本： v0.8.6"}
        </div>
      </div>
    </main>

  )
}

export default About