import React, { useState, useContext, useEffect } from 'react'
import { locations } from "../../docs/location";
import { useNavigate } from 'react-router-dom';
import DataContext from '../../context/DataContext'

import { updateLocationOptions } from '../controlComponent/SelectMulti';


const LocationTag = ({ location, type }) => {
    const navigate = useNavigate();
    // const { id } = useParams();
    const { setSelected, setSearch } = useContext(DataContext)
    const [locationNav, setLocationNav] = useState([])
    // const [locationStr, setLocationStr] = useState([])
    // setTheArray(oldArray => [...oldArray, newElement]);

    useEffect(() => {
        let locNav = []

        // location Nav store the option when click the location tag
        // ex: locationStr = ['新北市', "第01選區", '三重區']
        // locationNav = [{
        //     selectedOptions: [
        //         { label: '新北市', value: "'65'000"},
        //     ],
        //         searchText: ""
        // },{
        //     selectedOptions: [
        //         { label: '新北市', value: "'65'000"}
        //     ],
        //         searchText: "第01選區"
        // },{
        //     selectedOptions: [
        //         { label: '新北市', value: "'65'000"},
        //         { label: '三重區', value: "'020" }
        //     ],
        //         searchText: "第01選區"
        // }]
        const addLocationNav = (name, label, value, text, locNav) => {
            // console.log("aaa", locNav)
            let newLocationNavItem = {
                name: name,
                option: {},
                searchText: text
            }
            // if (locNav.length > 0) {
            //     console.log("aaa", locNav[locNav.length - 1].selectedOptions)

            //     newLocationNavItem.selectedOptions = locNav[locNav.length - 1].selectedOptions
            // }
            if (label && value) {
                newLocationNavItem.option = { label: label, value: value }

                // newLocationNavItem.selectedOptions.push({ label: label, value: value })
            }
            locNav.push(newLocationNavItem)

            // setLocationNav(prevData => [...prevData, newLocationNavItem])
            // setData(prevData => ({
            //     ...prevData,
            //     [name]: value
            // }))
        }
        try {
            // FIXME: if not reset there will be two set of nav
            // dont know while this will exec twice
            // setLocationNav([])
            // setLocationStr([])
            // 

            // console.log(locNav)
            if (location.city) {
                // city can only be one
                const city = locations.find(loc => loc.code === location.city)
                // locationStr.push(city.name)
                // setLocationStr(prevData => [...prevData, city.name])
                addLocationNav(city.name, city.name, city.code, "", locNav)
                // locArr.push({label: city.name, value: city.code})

                if (location.district) {
                    let group = location.district.substring(0, 3)
                    let district = location.district.substring(3, 7)
                    // console.log(location)
                    if (group.substring(1, 3) !== "00") {
                        let groupStr = `第${group.substring(1, 3)}選區`
                        // setLocationStr(prevData => [...prevData, groupStr])
                        // locationStr.push(groupStr)

                        addLocationNav(groupStr, "", "", groupStr, locNav)

                        // groupDistricts = city.subLocation.filter(item => item.group.includes(group))
                        // groupDistricts.forEach(item => {
                        //     locationStr.push(item.name)
                        // })
                    }
                    let dist = { subLocation: [] }

                    if (district === "'000") {
                        let groupDistricts = city.subLocation.filter(item => item.group.includes(group))
                        groupDistricts.forEach(item => {
                            // setLocationStr(prevData => [...prevData, item.name])
                            // locationStr.push(item.name)
                            addLocationNav(item.name, item.name, item.code, "", locNav)
                        })

                    } else {
                        dist = city.subLocation.find(item => item.code === district)
                        // setLocationStr(prevData => [...prevData, dist.name])
                        // locationStr.push(dist.name)
                        addLocationNav(dist.name, dist.name, dist.code, "", locNav)

                    }
                    // console.log(group, district)

                    if (location.viliage) {
                        let viliage = dist.subLocation.find(item => item.code === location.viliage)
                        // setLocationStr(prevData => [...prevData, viliage.name])
                        // locationStr.push(viliage.name)
                        addLocationNav(viliage.name, viliage.name, viliage.code, "", locNav)
                    }

                };
            }
            setLocationNav(locNav)


        } catch (err) {
            console.error(err)
        }
        // useEffect(() => {
        //     setSelectedOptions(locArr)
        // }, [])

    }, [location])

    // console.log("3", locationNav)





    const handleClickLocation = (e, name, i) => {
        // console.log(name, i)


        // setSelected(prevData => ({
        //     "options": updateLocationOptions( [{label: '新北市', value: 25700219}]),
        //     "selectedOptions": [{label: '新北市', value: 25700219}]
        // }))
        let searchText = ""
        let selectedOptions = []

        for (let j = 0; j < i + 1; j++) {
            if (locationNav[j].option.label) {
                // console.log(locationNav[j].option)
                selectedOptions.push(locationNav[j].option)
            }
            if (locationNav[j].searchText) {
                searchText = searchText + locationNav[j].searchText
            }

        }
        console.log("searchText", searchText)
        console.log("selectedOptions", selectedOptions)

        setSearch(searchText)
        setSelected(prevData => ({
            "options": updateLocationOptions(selectedOptions, setSelected),
            "selectedOptions": selectedOptions
        }))

        navigate('/')
    }

    return (
        // candidate page view
        <div className='flex flex-row flex-wrap'>
            {locationNav.map((item, i) => (
                <div key={i} className='shadow p-1 mx-1 my-1 rounded-lg bg-slate-200 text-slate-700 w-fit whitespace-nowrap cursor-pointer'
                    onClick={(e) => handleClickLocation(e, item.name, i)}>
                    {item.name}
                </div>
            ))}
        </div>
    )
}

export default LocationTag