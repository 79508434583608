import { locations } from "../docs/location";


export const searchEngine = (candidates, selected, search) => {

    let filterResults = candidates.slice()


    if (selected.selectedOptions.length > 0) {
        switch (selected.selectedOptions.length) {
            case 3:

                filterResults = filterResults.filter(candidate => (
                    (candidate.location.city === selected.selectedOptions[0].value) &&
                    (candidate.location.district.slice(3, 7) === selected.selectedOptions[1].value) &&
                    (candidate.location.viliage === selected.selectedOptions[2].value)
                ))

                break
            case 2:
                // const findDistrictCandidate = (candidate, selected) => {
                //     if (candidate.location.city !== selected.selectedOptions[0].value) { return false}

                // }

                let city = locations.find(item => item.code === selected.selectedOptions[0].value)
                let district = city.subLocation.find(item => item.code === selected.selectedOptions[1].value)
                let g = district.group

                let filterResultsGroup = filterResults.filter(candidate => (
                    (candidate.location.city === selected.selectedOptions[0].value) &&
                    (candidate.location.district.slice(3, 7) === "'000") &&
                    (g.includes(candidate.location.district.slice(0, 3)))
                ))

                let filterResultsDistrict = filterResults.filter(candidate => (
                    (candidate.location.city === selected.selectedOptions[0].value) &&
                    (candidate.location.district.slice(3, 7) === selected.selectedOptions[1].value)
                ))
                // if district '000 then find in group

                filterResults = filterResultsGroup.concat(filterResultsDistrict)
                break
            case 1:

                filterResults = filterResults.filter(candidate => (
                    candidate.location.city === selected.selectedOptions[0].value
                ))
                break
            default:
                console.error('selected error', selected)

        }
    }

    if (search.includes("第") && search.length > 2) {
        let districtStr = `'${search.slice(1, 3)}'`
        // console.log(districtStr)
        return filterResults.filter(candidate =>
            candidate.location.district.includes(districtStr));
    }

    if (search === "原住民") {
        return filterResults.filter(candidate =>
            candidate.abo > 0);
    }

    if (search === "當選") {
        return filterResults.filter(candidate =>
            candidate.result === 1);
    }

    if (search.includes("無黨")) {
        return filterResults.filter(candidate =>
            candidate.party === 999);
    }


    if (search.includes("民進黨") || search.includes("民主進步黨")) {
        return filterResults.filter(candidate =>
            candidate.party === 16);
    }

    if (search.includes("國民黨")) {
        return filterResults.filter(candidate =>
            candidate.party === 1);
    }

    if (search.includes("市議員")) {
        return filterResults.filter(candidate =>
            candidate.position === 2 || candidate.position === 3);
    }

    if (search.includes("市長")) {
        return filterResults.filter(candidate =>
            candidate.position === 0 || candidate.position === 1);
    }


    // const filterResults = filterResults.filter(candidate =>
    //     ((candidate.name).toLowerCase()).includes(search.toLocaleLowerCase()));


    if (search.includes("2022")) {

        return filterResults.filter(candidate =>
            candidate.term === 2022);

    }

    if (search.includes("2018")) {

        return filterResults.filter(candidate =>
            candidate.term === 2018);
    }

    filterResults = filterResults.filter(candidate =>
        ((candidate.name).toLowerCase()).includes(search.toLocaleLowerCase()));


    return filterResults
}