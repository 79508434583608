import React, { useContext } from 'react'
import CardItem from './CardItem'
import ListItem from './ListItem'
import DataContext from '../../context/DataContext'

const Feed = ({ candidates }) => {
  const { width } = useContext(DataContext)


  // 2018(民107年)地方公職人員選舉

  // className='hover:text-red-600 focus::text-red-600 visited:text-red-600'

  if (width <= 640) {
    return (

      <div>
        <section className='holder flex flex-grow justify-evenly items-center flex-row flex-wrap gap-2 mb-4'>
          {candidates.map((candidate) => (
            <ListItem key={candidate.id} candidate={candidate} />
          ))}
        </section>
      </div>
    )

  }

  return (
    <div>
      <section className='holder flex flex-grow justify-evenly items-center flex-row flex-wrap gap-3 mb-4'>
        {candidates.map((candidate) => (
          <CardItem key={candidate.id} candidate={candidate} />
        ))}
      </section>
    </div>
  )

}

export default Feed