import React from 'react'
import RecommendSideBar from './RecommendSideBar';
import CompetitionSideBar from './CompetitionSideBar';
import CommentBlock from '../commentComponent/CommentBlock';
import Details from './Details';
import BasicInfo from './BasicInfo';
import Tickets from './Tickets';


const LargeCandidatePage = ({ candidate, details, ratings, positionName, partyName, recCandidates, compCandidates }) => {

    return (
        <div className='flex h-full'>
            <div className='w-1/5 sticky h-screen overflow-y-scroll overscroll-contain'>
                <RecommendSideBar recCandidates={recCandidates} />
            </div>
            <div className='w-3/5 h-full'>
                <div className='grid grid-rows grid-flow-row w-full'>
                    <BasicInfo candidate={candidate} details={details} ratings={ratings} type='large' partyName={partyName} />
                    {/* <div className='grid grid-flow-row'> */}
                    {candidate.term === 2022 ? <></> :
                        <Tickets details={details} type='large' result={candidate.result} />}
                    <Details details={details} type='large' />
                    <CommentBlock candidate={candidate}/>
                    {/* </div> */}
                </div>
            </div>
            <div className='w-1/5 h-screen sticky overflow-y-scroll overscroll-contain'>
                <CompetitionSideBar compCandidates={compCandidates} />
            </div>
        </div>
    )
}

export default LargeCandidatePage