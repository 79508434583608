import React, { Component, useContext } from "react";
import Select from "react-select";
import { locations } from "../../docs/location";

export const clearSelected = (state, props) => ({

  selectedOptions: [],
  options: locations.map((location) => (
    {
      label: location.name,
      value: location.code
    }
  ))
});


export const updateLocationOptions = (selected, setSelectedState) => {
  let subLocation = locations
  let options = []
  if (selected.length) {
    for (let i = 0; i < selected.length; i++) {
      let selectedLocation = subLocation.find(location => location.code === selected[i].value)
      if (selectedLocation) {
        subLocation = selectedLocation.subLocation
        if (!subLocation) {
          return options
        }
      } else {
        // delete parent location, need to remove childs
        let j = (2 - i);
        while (j--) {
          selected.pop();
        }
        setSelectedState({ selectedOptions: selected });
        options = subLocation.map((location) => ({ label: location.name, value: location.code }))
        break;
      }
      options = subLocation.map((location) => ({ label: location.name, value: location.code }))
    }
    return options
  }

  return locations.map((location) => (
    {
      label: location.name,
      value: location.code
    }
  ))
}


export default class OnSelectResetsInput extends Component {


  // state = {
  //   selectedOptions: [],
  //   options: locations.map((location) => (
  //     {
  //       label: location.name,
  //       value: location.id
  //     }
  //   ))
  // };

  // clearSelected = () => {
  //   this.setState({ selectedOptions: [] });
  //   this.setState({ options: locations.map((location) => (
  //     {
  //       label: location.name,
  //       value: location.id
  //     }
  //   )) });
  // }

  handleClearSelected = () => this.props.setSelectedState(clearSelected);

  customStyles = {
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#fffff",
      // border: "2px solid #06D6A0",
      border: "2px solid #fb7185",
      borderRadius: ".3rem",
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      boxShadow: 'none',
      borderColor: isFocused ? 'lightgray' : "none",
      ':hover': {
        borderColor: "lightgray",
      }
    }),
  }


  onInputChange = (selected) => {
    this.props.setSelectedState({
      selectedOptions: selected,
      options: updateLocationOptions(selected, this.props.setSelectedState)
    });
  };

  render() {
    const { selectedOptions, options } = this.props.selectedState;
    return (
      <Select
        isMulti
        defaultValue={selectedOptions}
        value={selectedOptions}
        onChange={this.onInputChange}
        name="locationSelector"
        options={options}
        placeholder="找 縣市> 鄉鎮區> 村里"
        styles={this.customStyles}
        className="basic-multi-select"
        classNamePrefix="select"
      // menuPlacement="auto"
      // menuPosition="fixed"
      />
    );
  }
}
