import Layout from './Layout';
import Home from './page/Home';
import CandidatePage from './page/candidateComponent/CandidatePage'
import About from './page/About';
import Statics from './page/Statics';
import Missing from './page/Missing';
import Source from './page/Source';
import Report from './page/Report';
import Disclaimer from './page/Disclaimer'
import Thumbtack from './page/Thumbtack';
import { Routes, Route, Navigate } from 'react-router-dom';
import { DataProvider } from './context/DataContext';



function App() {

  return (

    <DataProvider>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />

          <Route path='candidate'>
            <Route index element={<Navigate to="/" replace />} />
            <Route path=':id' element={<CandidatePage />} />
          </Route>
          <Route path='statics'>
            <Route index element={<Statics />} />
            <Route path=':id' element={<Statics />} />
          </Route>
          <Route path='thumbtack'>
            <Route index element={<Thumbtack />} />
          </Route>
          <Route path='about'>
            <Route index element={<About />} />
          </Route>
          <Route path='report'>
            <Route index element={<Report />} />
          </Route>
          <Route path='disclaimer'>
            <Route index element={<Disclaimer />} />
          </Route>


          <Route path='*' element={<Navigate to="/" replace />} />
        </Route>
      </Routes >
    </DataProvider>

  );
}

export default App;
