
import React, { useState } from 'react';
import { FaLongArrowAltUp } from 'react-icons/fa';

const Totop = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <div className='fixed bottom-0 right-0 z-40 flex flex-col mb-4 mr-4 lg:mb-[52px] lg:mr-10 bg-gray-400 text-white items-center justify-center w-[50px] h-[50px] lg:w-[60px] lg:h-[60px] p-2 duration-150 rounded-full hover:opacity-90 opacity-60 text-xl'
        style={{ display: visible ? 'flex' : 'none' }} >
            <FaLongArrowAltUp onClick={scrollToTop} />
        </div>
    );
}


export default Totop


// 2
// 1