import React from 'react'
import RecommendSideBar from './RecommendSideBar';
import CompetitionSideBar from './CompetitionSideBar';
import CommentBlock from '../commentComponent/CommentBlock';
import Details from './Details';
import BasicInfo from './BasicInfo';
import Tickets from './Tickets';
import RateBlock from './RateBlock';

const SmallCandidatePage = ({ candidate, details, ratings, positionName, partyName, recCandidates, compCandidates }) => {

    const showRateBlock = () => {
        return (
            (candidate.term === 2022 || candidate.result === 1) ?
                (<RateBlock ratings={ratings} />)
                :
                (<></>)
        )
    }

    Array.from(document.querySelectorAll('.lbl-toggle')).forEach((label) => {
        label.addEventListener('keydown', e => {
            if (e.which === 32 || e.which === 13) {
                e.preventDefault();
                label.click();
            };
        });
    });
    return (
        <div className='grid grid-flow-row w-full'>
            <BasicInfo candidate={candidate} details={details} partyName={partyName} />
            {/* <Popularity /> */}
            {showRateBlock()}
            {candidate.term === 2022 ? <></> :
                <Tickets details={details} type='small' result={candidate.result} />}
            <Details details={details} type='small' />
            <CommentBlock candidate={candidate}/>
            <RecommendSideBar recCandidates={recCandidates} />
            <CompetitionSideBar compCandidates={compCandidates} />
        </div>
    )
}



export default SmallCandidatePage