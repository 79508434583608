import React from 'react'

const BasicInfoButton = ({ icon, info }) => {
    return (
        <div className='flex flex-row py-1 text-slate-800'>
            <div className='text-xl'>
                {icon}
            </div>
            <div className='text-sm px-2
            shadow p-1 ml-2 rounded-lg bg-slate-200 text-slate-700 w-fit whitespace-nowrap cursor-pointer'>
                {info ? info : "no data"}
            </div>
        </div>
    )
};

export default BasicInfoButton