import React, { useEffect, useState } from 'react'
import { FaUserAstronaut, FaUserGraduate, FaUserNinja, FaUserTie, FaUser, FaUserMd } from 'react-icons/fa'

const ListHeadPic = ({ candidateImage, name, type, result, degree }) => {
    const headBorder = result ? 'border-red-400' : 'border-neutral-300';
    const pic = candidateImage ? `https://storage.googleapis.com/yssy-elec_public/${candidateImage}` : ''

    return (pic ? (
        <img src={pic} alt="Oops..."
            style={{ width: "50px", minWidth: "50px", height: "50px", minHeight: "50px", objectFit: "cover" }}
            className={`border-2 border-solid ${headBorder} rounded-full opacity-70 `} />
    ) : (
        <div
            style={{ width: "50px", minWidth: "50px", height: "50px", minHeight: "50px", objectFit: "cover" }}
            className={`border-2 border-solid ${headBorder} rounded-full bg-slate-50
                        flex items-center justify-center text-2xl text-slate-600`} >
            {name[0]}
        </div>
    ))


}

export default ListHeadPic