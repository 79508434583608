import React from 'react'
import CardItem from '../cardComponent/CardItem'
import { FaSign } from 'react-icons/fa'
import Feed from '../cardComponent/Feed'

const RecommendSideBar = ({ recCandidates }) => {
  return (
    <div className=''>
      <div className='from-neutral-100 to-neutral-200 px-2 lg:pt-4 pb-2 bg-gradient-to-t text-neutral-800'>
        {/* <FaSign /> */}
        {"相關候選人"}
      </div>
      <div className='p-2 lg:p-4 lg:mx-4'>
        <Feed candidates={recCandidates} />
      </div>
      {/* <div className='holder flex flex-grow justify-evenly items-center flex-row flex-wrap gap-3 mb-4 p-4 mx-4'>
        {recCandidates.map((recCandidate) => (
          <CardItem key={recCandidate.id} candidate={recCandidate} />
        ))}
      </div> */}
    </div>
  )
}

export default RecommendSideBar