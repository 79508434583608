import React from 'react'

const Report = () => {
    return (
        <main className='w-full grow p-4 overflow-y-auto bg-neutral-100 min-h-screen'>
            <div className='bg-slate-200 rounded-lg mx-4 my-2 text-neutral-700 px-2'>
                <div className='text-2xl p-4 font-bold'>
                    {"問題回報："}
                </div>
                <div className='p-2'>
                    {"若發現所載資料有誤以及網站技術漏洞，請透過以下信箱和我們聯絡，感謝您的不吝指教。"}
                    <div>
                        {"電子信箱："}
                        {"youseesy12@gmail.com"}
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Report