import React, { useContext } from 'react'
import ListHeadPic from './ListHeadPic'
import { Link } from 'react-router-dom'
import DataContext from '../../context/DataContext'
import Position from '../candidateComponent/Position'
import Location from '../candidateComponent/Location'

const ListItem = ({ candidate }) => {
    const { partyName } = useContext(DataContext)
    const displayName = (candidate.name).length <= 5 ? candidate.name : `${(candidate.name).slice(0, 4)}...`
    const bgColor = partyName[candidate.party].color ? partyName[candidate.party].color : "#e2e8f0";
    const termBadgeColor = candidate.term === 2018 ? 'bg-indigo-300' : 'bg-rose-400';

    return (
        <div style={{
            width: "min(100%, 175px)",
            height: "min(100%, 80px)",
            backgroundColor: bgColor
        }}
            className='shadow-sm items-center scroll-mt-4 bg-slate-200 relative rounded-xl px-2 border-solid border-2 border-slate-200' >
            <Link to={`/candidate/${candidate.id}`}>
                <div className='flex flex-col justify-start'>
                    <div className='flex flex-row justify-start'>
                        <figure className='flex flex-col flex-nowrap items-center justify-center pr-0.5'>
                            <ListHeadPic candidateImage={candidate.image} name={candidate.name} type='card'
                                result={candidate.result} degree={candidate.degree} />
                            {/* <figcaption className='font-bold text-base mt-1 text-center'>{displayName}</figcaption> */}
                        </figure>

                        <div className='text-neutral-700 pb-1 pt-2'>
                            <div className='flex flex-col'>
                                <div className={`inline-flex absolute right-1 justify-center items-center w-10 h-5 text-sm 
                                    text-neutral-50 rounded-lg opacity-90 font-bold ${termBadgeColor}`}>
                                    {candidate.term}
                                </div>
                                <div className='font-bold text-xl mt-2 text-center'>
                                    {displayName}
                                </div>
                                <div className='text-sm ml-1'>
                                    <Location location={candidate.location} type={0} />
                                    <Position position={candidate.position} location={candidate.location} result={candidate.result} term={candidate.term} type={0} />

                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div className='flex flex-row justify-evenly mt-1'>
                        <Location location={candidate.location} type={0} />
                        <Position position={candidate.position} location={candidate.location} result={candidate.result} term={candidate.term} type={1} />
                    </div> */}
                </div>

            </Link>
        </div>
    )
}

export default ListItem