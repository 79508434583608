import React, { useContext } from 'react'
import DataContext from '../context/DataContext'
import Chart from './staticsComponent/Chart'

import { FaCircleNotch } from 'react-icons/fa'

const Statics = () => {
  const { value18, selected, search } = useContext(DataContext)

  return (
    <main className='w-full grow p-4 bg-neutral-50 scroll-m-8 min-h-screen'>
      <div className='grid place-items-center h-screen'>
        <div>
          <div className="flex space-x-12 p-12 justify-center items-center">
            <div className="flex items-center justify-center space-x-2 ">
              <div className="loading1 w-5 h-5 bg-indigo-500 rounded-full"></div>
              <div className="loading2 w-5 h-5 bg-slate-400 rounded-full"></div>
              <div className="loading3 w-5 h-5 bg-teal-500 rounded-full"></div>
            </div>
          </div>
          <div className='text-2xl text-neutral-800 flex justify-center items-center'>
            {/* {"No candidate found..."} */}

            {"網頁開發中"}
          </div>
        </div>
      </div>
    </main>
  )
}

export default Statics