import React, { useState, useEffect, useContext } from 'react'
import DataContext from '../../context/DataContext'
import Rating from '@mui/material/Rating';
import { useParams } from 'react-router-dom'

const RateBlock = ({ ratings, type }) => {
    // FIXME: when rating don't exist
    const { ratedId } = useContext(DataContext)
    const { id } = useParams();
    const [value, setValue] = useState((ratings.rating.rate / 20));
    const [ratedCount, setRatedCount] = useState(ratings.rating.rateCount)

    useEffect(() => {
        if (ratings.rating.rate) {
            setValue((ratings.rating.rate / 20))
        }
        else {
            setValue(0)
        }

        setRatedCount(ratings.rating.rateCount)

    }, [ratings])

    useEffect(() => {
        const item = ratedId.find(item => item.id === id)
        if (item) {
            try {
                const count = ratedCount + 1
                setRatedCount(count)
                const newValue = (value * ratedCount + item.value) / count
                setValue(newValue)
            } catch (err) {
                console.error(err)
            }
        }


    }, [ratedId])

    // const bgColor = 'bg-neutral-100'

    const formatter = Intl.NumberFormat('en', { notation: 'compact' })
    // dark:text-neutral-300
    // sx={customStyle} <Rating/>
    return (
        <div className='flex flex-row justify-center items-center mt-2'>
            <div className='text-md pr-1 text-neutral-700 opacity-70'>
                {value.toFixed(1)}
            </div>
            <Rating
                name="simple-controlled"
                value={value}
                precision={0.5}
                size="large"
                readOnly
            />
            <div className='text-md pl-1 text-neutral-700 opacity-70'>
                {`(${formatter.format(ratedCount)})`}
            </div>
        </div>
    )
}

export default RateBlock