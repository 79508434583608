import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import DataContext from '../../context/DataContext'
import SelectMulti from '../controlComponent/SelectMulti'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

// 找 候選人 職位 選區 政黨 ...
const Nav = () => {
  const { selected, setSelected, search, setSearch, candidatePageDisplay, hideTextSearch } = useContext(DataContext)
  const textSearch = hideTextSearch ? "none" : ""

  return (
    <div style={{ display: (!candidatePageDisplay ? "inline" : "none") }}>
      <nav className='Nav lg:hidden flex-row flex-nowrap justify-between items-center
       bg-neutral-700'>
        <div className='w-full pt-1 px-1'>
          <Link to='/'>
            <SelectMulti
              selectedState={selected}
              setSelectedState={setSelected}
            />
          </Link>
        </div>
        <div className='w-full p-1 bg-neutral-700' style={{ display: textSearch }} >
          {/* <Link to='/'>  #404040 #fafafa */}
          {/* <Box
            component="form"
            sx={{
              '& > :not(style)': { borderRadius: "5px", backgroundColor: "white" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              placeholder="找 候選人 選期 政黨"
              type="search"
              variant="outlined"
              fullWidth
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              // sx={{
              //   '& > :not(style)': {
              //     color: 'pink',
              //     borderColor: 'pink',
              //     border: '1px solid green', borderRadius: 1
              //   }
              // }}
              // sx={{
              //   "& .MuiInputLabel-root": {color: 'green'},//styles the label
              //   "& .MuiOutlinedInput-root": {
              //     "& > fieldset": { borderColor: "orange" },
              //   },
              // }}
              sx={{
                input : {color : '#262626'},
                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "#fb7185"
                  }
                }
              }}

            />
          </Box> */}
          <Link to='/'>
            <form className='searchForm focus:border-2 focus:border-red-400 focus:rounded-md' onSubmit={(e) => e.preventDefault()}>
              <label htmlFor='search' >search posts</label>
              <input
                id='search'
                type="search"
                placeholder='找 候選人 選期 選區 政黨'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </form>
          </Link>
          {/* </Link> */}
        </div>
        {/* <div>
        <ul>
          <li style={{fontSize: '.9rem', color: switchColor}}><Switch
            context={"2018"}
            isOn={value18}
            onColor="#06D6A0"
            handleToggle={() => setValue18(!value18)}
            />
          </li>
          <li><Switch
          context={"2022"}
          isOn={value22}
          onColor="#06D6A0"
          handleToggle={() => setValue22(!value22)} />
        </li>
          <li><Link to='statics'>統計</Link></li>
          <li><Link to='about'>關於</Link></li>
        </ul>
      </div> */}

        {/* <Link to='/' className='searchLink'>
        <form className='searchForm' onSubmit={(e) => e.preventDefault()}>
          <label htmlFor='search' >search posts</label>
          <input
            id='search'
            type="text"
            placeholder='查 候選人 種類 選區 政黨'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </Link> */}

      </nav >
    </div >
  )
}

export default Nav